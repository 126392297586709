import React, { useContext, useState } from "react";
import styles from "../jsSearchResult.module.css";
import { Spinner } from "react-bootstrap";
import { BsChat, BsFillPersonXFill, BsPersonCircle } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { postTReq } from "../../../Services/api";
import { apiEndpoints, navigationRoutes } from "../../../PrEnums/prEnums";
import { useDispatch, useSelector } from "react-redux";
import { saveUserEducationalDetails, saveUserPersonalDetails, saveUserProfessionalDetails } from "../../../Redux/Slices/persistSlice";
import { cancelFriendRequest, getPeopleSearch, startChatDirectlyWithFriend, unfriendConnection } from "../../../Services/commonService";
import { VscVerifiedFilled } from "react-icons/vsc";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import { toasterController } from "../../../Redux/Slices/toasterSlice";
import { FaClock } from "react-icons/fa6";
import { socket } from "../../../Services/socket";
import { IoMdPersonAdd } from "react-icons/io";
import { openLoginModalWhenNotLoggedIn } from "../../../Services/commons";
import AuthContext from "../../../context/authContext";
import { saveActionToPerformAfterLogin } from "../../../Redux/Slices/generalSlice";

const EachPeopleCard = ({ cardData, sendingLocationsList, sendingSkillsList, searchTextFromHome, liveSession }) => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const { chatHistory } = useSelector((state) => state.chatting);
    const { personalDetails } = useSelector((state) => state.persist);

    let context = useContext(AuthContext);
    const isAuthenticated = context.authenticatedStatus;

    const [loadingUnfriend, setLoadingUnfriend] = useState([]);
    const [loading, setLoading] = useState(false);

    const unfriendUser = async (friendListId) => {
        let newLoading = loadingUnfriend.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await unfriendConnection(friendListId);
        if (res === "200 OK") {
            setLoadingUnfriend(newLoading);
            closeConfirmation();
            getPeopleSearch(0, 10, sendingLocationsList, sendingSkillsList, searchTextFromHome, liveSession);
        } else {
            setLoadingUnfriend(newLoading);
        }
    };

    const handleGetProfile = async (obj) => {
        const res = await postTReq(apiEndpoints.getUserProfile, { emailAddress: obj.emailAddress });
        if (res && !res.error) {
            dispatch(saveUserPersonalDetails(res.data.data));
            dispatch(saveUserEducationalDetails(res.data.data.educational_details));
            dispatch(saveUserProfessionalDetails(res.data.data.professional_details));
            navigateTo(navigationRoutes.userProfile);
        } else {
            console.log(res.error);
        }
    };

    const handleFriendRequest = async (email) => {
        setLoading(true);
        const res = await postTReq(apiEndpoints.sendFriendRequest, { reciever_id: email });
        if (res && !res.error) {
            setLoading(false);
            dispatch(toasterController({ toaster: "requestSentSuccessfully", state: true }));
            getPeopleSearch(0, 10, sendingLocationsList, sendingSkillsList, searchTextFromHome, liveSession);
            socket.emit("sendConnectionNotification", {
                receiverEmail: email,
                addData: {
                    notification_message: `${personalDetails?.fullName}  sent you a friend request`,
                    notification_sender_profile_pic: personalDetails?.profileImageUrl,
                    notification_type: "friend_request_sent",
                    notification_time: new Date().getTime(),
                    sender_email: personalDetails.emailAddress,
                },
            });
        } else {
            console.log(res.error);
            setLoading(false);
            dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
        }
    };

    const [confirmation, setConfirmation] = useState(false);
    const [savedFriendListId, setSavedFriendListId] = useState("");
    const closeConfirmation = () => {
        setSavedFriendListId("");
        setConfirmation(false);
    };

    const submitUnfriending = () => {
        setLoadingUnfriend((prev) => {
            return [...prev, savedFriendListId];
        });
        unfriendUser(savedFriendListId);
    };

    const removeFriendRequest = async (event, friendListId) => {
        event.stopPropagation();
        let newLoading = loadingUnfriend.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await cancelFriendRequest(friendListId);
        if (res === "200 OK") {
            setLoadingUnfriend(newLoading);
            getPeopleSearch(0, 10, sendingLocationsList, sendingSkillsList, searchTextFromHome, liveSession);
        } else {
            setLoadingUnfriend(newLoading);
        }
    };
    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-3">Are you sure you want to remove this connection?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={submitUnfriending}>
                            Yes
                        </button>
                        <button className="normal-button" onClick={closeConfirmation}>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.eachCard} onClick={() => handleGetProfile(cardData)}>
                <div className={styles.coverImage}>{cardData.cover_image_url && <img src={cardData.cover_image_url} className={styles.coverImageImage} alt="" />}</div>
                <div className={styles.profileImage}>{cardData.profileImageUrl ? <img src={cardData.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                <div className={styles.cardContent}>
                    <div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className={`${styles.profileName} justify-content-center me-1`}>{cardData.fullName ? cardData.fullName : "-"}</div>
                            {cardData.is_verified_from_admin_for_live_session && (
                                <div className="d-flex justify-content-center align-items-center">
                                    <VscVerifiedFilled color="#f1bb46" fontSize={20} />
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className={styles.ratingPill}>
                                <AiFillStar fontSize={14} color="#f1bb46" className="me-1" />
                                {cardData.over_all_rating}
                            </div>
                        </div>
                        {cardData.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                        {cardData.job_profile_name && <div className={styles.designationName}>{cardData.job_profile_name}</div>}
                        {cardData.location && (
                            <div className={styles.userSectionContainer}>
                                <div className={styles.sectionName}>Location:</div>
                                <div className={styles.sectionValue}>{cardData.location}</div>
                            </div>
                        )}
                        {cardData.companyName && (
                            <div className={styles.userSectionContainer}>
                                <div className={styles.sectionName}>Company Name:</div>
                                <div className={styles.sectionValue}>{cardData.companyName}</div>
                            </div>
                        )}
                    </div>

                    <div className="action-button-container">
                        {cardData.user_connection_status && Object.keys(cardData.user_connection_status).length > 0 ? (
                            cardData.user_connection_status.is_friend ? (
                                <div className="d-flex">
                                    <Tooltip title="Remove Connection">
                                        <button
                                            className={
                                                cardData.user_connection_status && Object.keys(cardData.user_connection_status).length > 0 ? "action-button-reject me-2" : "action-button-connect"
                                            }
                                            disabled={loading}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                console.log(cardData);
                                                setSavedFriendListId(cardData.user_connection_status.friendListId);
                                                setConfirmation(true);
                                            }}
                                        >
                                            {loadingUnfriend.includes(cardData.user_connection_status.friendListId) ? <Spinner animation="border" size="sm" /> : <BsFillPersonXFill />}
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Chat With User">
                                        <button
                                            className={cardData.user_connection_status && Object.keys(cardData.user_connection_status).length > 0 ? "action-button-disabled" : "action-button-connect"}
                                            disabled={loading}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                startChatDirectlyWithFriend(chatHistory, cardData.emailAddress, navigateTo);
                                            }}
                                        >
                                            {loading ? <Spinner animation="border" size="sm" /> : <BsChat />}
                                        </button>
                                    </Tooltip>
                                </div>
                            ) : (
                                <Tooltip title="Revoke Connection Request">
                                    <button
                                        className={cardData.user_connection_status && Object.keys(cardData.user_connection_status).length > 0 ? "action-button-accept" : "action-button-connect"}
                                        disabled={loading}
                                        onClick={(event) => {
                                            setLoadingUnfriend((prev) => {
                                                return [...prev, cardData.user_connection_status.friendListId];
                                            });
                                            removeFriendRequest(event, cardData.user_connection_status.friendListId);
                                        }}
                                    >
                                        {loading ? <Spinner animation="border" size="sm" /> : <FaClock />}
                                    </button>
                                </Tooltip>
                            )
                        ) : (
                            <Tooltip title="Send Connection Request">
                                <button
                                    className={cardData.user_connection_status && Object.keys(cardData.user_connection_status).length > 0 ? "action-button-disabled" : "action-button-connect"}
                                    disabled={loading}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (isAuthenticated) {
                                            handleFriendRequest(cardData.emailAddress);
                                        } else {
                                            openLoginModalWhenNotLoggedIn();
                                            dispatch(
                                                saveActionToPerformAfterLogin({
                                                    doThis: () => handleFriendRequest(cardData.emailAddress),
                                                })
                                            );
                                        }
                                    }}
                                >
                                    {loading ? <Spinner animation="border" size="sm" /> : <IoMdPersonAdd />}
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EachPeopleCard;
