import React from 'react';
import { Form , Button } from 'react-bootstrap';
import '../../style/AdminLogin.css'

const AdminLogin = () => {
    return (
        <div className="container-fluid my-5">
            <div className="row pb-0 my-5 d-flex flex-row justify-content-center align-item-center">
                <div className="col-md-4"></div>
                <div className="col-xs-12 col-sm-12 col-md-4 shadow ant-card ant-card-bordered">
                    <div className="ant-card-body">
                        <div className="row pb-5">
                            <div className="col-12 text-center">
                                <h1>JobSifarish</h1>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-12">
                                <Form.Control placeholder="Username"/>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-12">
                                <Form.Control placeholder="Password"/>
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-4"></div>
                            <div className="col-4 d-flex justify-content-center">
                                <Button>Login</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    );
};

export default AdminLogin;