import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editingProfessionObject: {},
    editingEducationObject: {},
    userRatingsData: [],
    selectedWorkExperienceForEdit: {},
    editingProjectObject: {},
    editingRatingObj: {},
};

export const profileSlice = createSlice({
    name: "profileSlice",
    initialState,
    reducers: {
        saveEditingProfessionObject: (state, action) => {
            state.editingProfessionObject = action.payload;
        },
        inputEditingProfessionObject: (state, action) => {
            state.editingProfessionObject[action.payload.name] = action.payload.value;
        },
        inputEditingProjects: (state, action) => {
            let x = state.editingProfessionObject;
            x.project_details[action.payload.index][action.payload.name] = action.payload.value;
            state.editingProfessionObject = x;
        },
        addOneProject: (state, action) => {
            let x = state.editingProfessionObject;
            x.project_details.push({
                project_name: "",
                tech_used: "",
                discription: "",
                project_prod_url: "",
                role_played: "",
                started_from: new Date().getTime(),
                end_on: new Date().getTime(),
            });
            state.editingProfessionObject = x;
        },
        removeProject: (state, action) => {
            let x = state.editingProfessionObject;
            let projects = x.project_details.filter((curElem, index) => {
                return index !== action.payload;
            });
            x.project_details = projects;
            state.editingProfessionObject = x;
        },
        saveEditingEducationObject: (state, action) => {
            state.editingEducationObject = action.payload;
        },
        inputEditingEducationObject: (state, action) => {
            state.editingEducationObject[action.payload.name] = action.payload.value;
        },

        saveUserRatingData: (state, action) => {
            state.userRatingsData = action.payload;
        },

        saveSelectedWorkExperienceForEdit: (state, action) => {
            state.selectedWorkExperienceForEdit = action.payload;
        },

        saveEditingProjectObject: (state, action) => {
            state.editingProjectObject = action.payload;
        },

        saveEditingRatingObj: (state, action) => {
            state.editingRatingObj = action.payload;
        },
    },
});

export const {
    saveEditingProfessionObject,
    saveEditingEducationObject,
    inputEditingProfessionObject,
    inputEditingProjects,
    addOneProject,
    removeProject,
    inputEditingEducationObject,
    saveUserRatingData,
    saveSelectedWorkExperienceForEdit,
    saveEditingProjectObject,
    saveEditingRatingObj,
} = profileSlice.actions;

export default profileSlice.reducer;
