import React from "react";
import styles from "./userProfilePage.module.css";
// import ConnectionsBox from "./ProfilePageComponents/ConnectionsBox/ConnectionsBox";
// import CreateResumeBox from "./ProfilePageComponents/CreateResumeBox/CreateResumeBox";
import UserWorkExperienceBox from "./ProfilePageComponents/UserWorkExperienceBox/UserWorkExperienceBox";
import UserEducationBox from "./ProfilePageComponents/UserEducationBox/UserEducationBox";
// import ActivityBox from "./ProfilePageComponents/ActivityBox/ActivityBox";
import UserProfileInfoBox from "./ProfilePageComponents/UserProfileInfoBox/UserProfileInfoBox";
import UserAboutBox from "./ProfilePageComponents/UserAboutBox/UserAboutBox";
import RatingSection from "./ProfilePageComponents/RatingSection/RatingSection";
import ReportUserBox from "./ProfilePageComponents/ReportUserBox/ReportUserBox";

const UserProfilePage = () => {
    return (
        <div className="main-content-container-flexible">
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <UserProfileInfoBox />
                    <UserAboutBox />
                    {/* <ConnectionsBox /> */}
                    {/* <CreateResumeBox /> */}
                    <ReportUserBox />
                </div>
                <div className={styles.rightPanel}>
                    <UserWorkExperienceBox />
                    <UserEducationBox />
                    {/* <ActivityBox /> */}
                    <RatingSection />
                </div>
            </div>
        </div>
    );
};

export default UserProfilePage;
