import React, { useEffect, useState } from "react";
import styles from "./userAboutBox.module.css";
import { useSelector } from "react-redux";

const UserAboutBox = () => {
    const { userPersonalDetails } = useSelector((state) => state.persist);

    const [aboutData, setAboutData] = useState(userPersonalDetails);

    useEffect(() => {
        if (Object.keys(userPersonalDetails).length > 0) {
            setAboutData(userPersonalDetails);
        }
    }, [userPersonalDetails]);

    return (
        <div className={`content-card`}>
            <div className="content-card-heading">About</div>
            <div className={styles.aboutSection}>{aboutData.about_me ? <div className={styles.aboutContent}>{aboutData.about_me}</div> : <div className="nothing-to-show">Nothing to show</div>}</div>
            <div className={styles.aboutSection}>
                <div className={styles.subHeadings}>Skills</div>
                {aboutData && aboutData.skillset && aboutData.skillset.length > 0 ? (
                    <div className={`${styles.aboutSection} ${styles.skillPillsContainer}`}>
                        {aboutData.skillset &&
                            aboutData.skillset.map((curElem) => {
                                return <div className={styles.skillPill}>{curElem.skill}</div>;
                            })}
                    </div>
                ) : (
                    <div className="nothing-to-show">Nothing to show</div>
                )}
            </div>

            <div className={styles.aboutSection}>
                <div className={styles.subHeadings}>Languages</div>
                {aboutData && aboutData.language && aboutData.language.length > 0 ? (
                    <div className={`${styles.aboutSection} ${styles.skillPillsContainer}`}>
                        {aboutData.language &&
                            aboutData.language.map((curElem) => {
                                return <div className={styles.skillPill}>{curElem}</div>;
                            })}
                    </div>
                ) : (
                    <div className="nothing-to-show">Nothing to show</div>
                )}
            </div>
        </div>
    );
};

export default UserAboutBox;
