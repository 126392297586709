import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../../style/Resume.css';

const ShiviResume2 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };

    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          var skillsset = res.data.skillset ? res.data.skillset : [];
          setSkillList(skillsset);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };

    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  return (
    <React.Fragment>
      <div className="container" style={{ padding: "0 40px", fontSize: "14px", fontFamily: "'Lato', sans-serif" }}>
        <div className="boot" style={{ display: "flex", alignItems: "center", flexDirection: "row", paddingTop: "3rem" }}>
          <div className="col_8" style={{ width: "66.66%" }}>
            <div
              className="resume6_n"
              style={{ display: "flex", flexDirection: "column", paddingLeft: "1.5rem", textTransform: "uppercase", border: "3px solid #aeb0b3", paddingTop: "2rem", paddingBottom: "2rem" }}
            >
              <span style={{ fontSize: "30px", letterSpacing: "1.35px", fontWeight: "800" }}>
                {personalData && personalData.firstName} <span style={{ color: "#b98757" }}> {personalData && personalData.lastName}</span>
              </span>
              <span style={{ fontWeight: "bold" }}>{professionalData.length > 0 ? professionalData[0].designation : ""}</span>
            </div>
          </div>
          <div className="col_4" style={{ width: "33.33%", display: "flex", justifyContent: "space-between" }}>
            <div className="resume6_c" style={{ fontSize: "14px", lineHeight: "1", marginBottom: "-4px", marginLeft: "-39px", backgroundColor: "white", fontWeight: "bold" }}>
              <div className=" pt_1" style={{ display: "flex", justifyContent: "space-between", paddingTop: ".2rem", paddingBottom: "10px" }}>
                <div className="col_1" style={{ width: "10%" }}>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+115.svg" alt="phonepic" />
                </div>
                <div className="col_9" style={{ width: "90%" }}>
                  {personalData && personalData.mobileNumber ? " Phone: " + personalData.mobileNumber : null}
                </div>
              </div>
              <div className="p_2" style={{ display: "flex", justifyContent: "space-between", paddingTop: ".20rem" }}>
                <div className="col_1" style={{ width: "10%" }}>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+111.svg" alt="address" />
                </div>
                <div className="col_9" style={{ width: "90%" }}>
                  {personalData && personalData.address ? (
                    <span>
                      Address: {personalData.address}, {personalData.city && personalData.city}, {personalData.country && personalData.country}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="p_3" style={{ display: "flex", justifyContent: "space-between", paddingTop: ".50rem" }}>
                <div className="col_1" style={{ width: "10%" }}>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+113.svg" alt="emailpic" />
                </div>
                <div className="col_9" style={{ width: "90%" }}>
                  {personalData && personalData.emailAddress ? <span>Email: {personalData.emailAddress}</span> : null}
                </div>
              </div>

              {/* <p>Web: www.companyname.com </p> */}
            </div>
          </div>
        </div>
        <div className="mt_3" style={{ display: "flex", marginTop: "1.5rem" }}>
          <div className="md_8" style={{ width: "66%" }}>
            <div className="right_b" style={{ borderRight: "3px solid #aeb0b3" }}>
              <div className="resume6_exp">
                <h5 className="resume6_heading" style={{ color: "#b98757", fontWeight: "600", fontSize: "1.25rem" }}>
                  EXPERIENCE
                </h5>
                <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "0px ", fontWeight: "400" }} />
                {professionalData.length > 0
                  ? professionalData.map((res, index) => (
                      <div className="row_nogp" style={{ display: "flex", paddingLeft: "1.rem", fontWeight: "400" }} key={index}>
                        <div className="col_padding" style={{ display: "flex", width: "30.66%", paddingTop: "1.25rem" }}>
                          {DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()} -{" "}
                          {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}
                        </div>
                        <div className="col_10" style={{ width: "83.34%", marginLeft: "1.25rem" }}>
                          <div className="description_pt_3" style={{ fontSize: "16px", fontWeight: "600", paddingTop: "1.25rem" }}>
                            {res.designation}
                          </div>
                          <div className="descrip_sub_title" style={{ fontSize: "14px", fontWeight: "500" }}>
                            {res.companyName} / {res.location}
                          </div>
                          <span className="descrip_desc" style={{ fontSize: "13.2px" }}>
                            Projects :- <br />{" "}
                            {res.projects_details.map((response, key) => (
                              <Fragment key={response.project_id}>
                                {key + 1}. {response.project_name} - {response.role_played} <br />
                                Tech Used - {response.tech_used} <br />
                                Project Url - {response.project_prod_url} <br />
                                {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                                <br />
                              </Fragment>
                            ))}{" "}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="resume6_edu_mt_4" sytye={{ marginTop: "1.25rem" }}>
                <h5 className="resume6_head" style={{ color: "#b98757", fontWeight: "600", fontSize: "1.25rem" }}>
                  EDUCATION
                </h5>
                <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "0px " }} />

                {educationData.length > 0
                  ? educationData.map((res, index) => (
                      <div className="row_nogap" style={{ display: "flex", padding: "0" }} key={index}>
                        <div className="col_padding" style={{ width: "30.66%", paddingTop: "1.25rem", paddingRight: "0" }}>
                          {res.session_start} - {res.session_end}
                        </div>
                        <div className="col_10" style={{ width: "83.34%", paddingLeft: "1rem" }}>
                          <div className="descrip_title_pt_3" style={{ fontSize: "16px", fontWeight: "600", paddingTop: "1.25rem", textTransform: "uppercase" }}>
                            {res.educationType} ({res.boardName})
                          </div>
                          <div className="descrip_sub_title" style={{ fontSize: "15px", fontWeight: "400" }}>
                            {res.schoolName}, {res.location}
                          </div>

                          <span className="descrip_desc" style={{ fontSize: "13.2px" }}>
                            with {getPercentage(res.scoredMarks, res.totalMarks)} %
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {professionalData.length > 0 ? (
                <div className="resume6_org_mt_4" style={{ marginTop: "1.25rem" }}>
                  <h5 className="resume6_head" style={{ color: "#b98757", fontWeight: "600", fontSize: "1.25rem" }}>
                    ORGANIZATION
                  </h5>
                  <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "0px " }} />
                  <div className="ro_pt_3" style={{ paddingTop: "1.25rem" }}>
                    {professionalData.length > 0
                      ? professionalData.map((res, index) => (
                          <div className="col_6" style={{ width: "50%" }} key={index}>
                            <div className="descrip_title" style={{ fontSize: "16px", fontWeight: "600" }}>
                              {res.companyName}
                            </div>
                            <p>
                              {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                            </p>
                            <p style={{ paddingBottom: "1.5rem" }}>{res.designation}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col_4" style={{ width: "33.33%", paddingLeft: "1.5rem" }}>
            {personalData && personalData.about_me ? (
              <>
                <h5 className="resume6_head" style={{ color: "#b98757", fontWeight: "600", paddingLeft: ".25rem", fontSize: "1.25rem" }}>
                  PROFILE
                </h5>
                <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "0px !important", marginLeft: "0.25rem" }} />
                <p className="pt_3" style={{ paddingTop: "0.25rem", marginLeft: "0.25rem" }}>
                  {" "}
                  {personalData && personalData.about_me}
                </p>
              </>
            ) : null}
            {skillList.length > 0 ? (
              <>
                <h5 className="resume6_head" style={{ color: "#b98757", fontWeight: "600", paddingLeft: ".25rem", fontSize: "1.25rem" }}>
                  SKILLS
                </h5>
                <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "0px ", marginLeft: "0.25rem" }} />

                {skillList.length > 0
                  ? skillList.map((res, index) => (
                      <div className="skill_bar_mt_3" style={{ display: "flex", marginTop: "1.25rem", marginLeft: "0.25rem", alignItems: "center" }} key={index}>
                        <div className="skill_Label" style={{}}>
                          {res.skill}
                        </div>
                        <div className="contain_skill" style={{ backgroundColor: "#aeb0b3", height: "7px", width: "56%", marginLeft: "1rem" }}>
                          <div className="skill_Html" style={{ backgroundColor: "#b98757", height: "6px", width: "58%" }}></div>
                        </div>
                      </div>
                    ))
                  : null}
              </>
            ) : null}
            {personalData && personalData.achievement_details.length > 0 ? (
              <div className="mt_4" style={{ marginTop: "1.25rem" }}>
                <h5 className="resume6_head" style={{ color: "#b98757", fontWeight: "600", paddingLeft: ".25rem", fontSize: "1.25rem" }}>
                  AWARDS / ACHIEVEMENTS
                </h5>
                <hr className="devid" style={{ height: "1px", backgroundColor: "#aeb0b3", marginRight: "40px", marginBottom: "20px ", paddingLeft: ".25rem", marginLeft: "0.25rem" }} />
                {personalData &&
                  personalData.achievement_details.map((item, id) => (
                    <>
                      <span className="descrip_Desc" style={{ fontSize: "13.2px", marginLeft: ".25rem", paddingTop: ".75rem" }} key={item.achievement_id}>
                        {id + 1}- {item.achievement}
                      </span>
                      <br />
                    </>
                  ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShiviResume2;
