import React, { useState } from "react";
import styles from "./chatBubble.module.css";
import {
    convertTimeStringToHHMM,
    getChatByUserEmail,
    getDateFromTimeString,
    getDateNumberFromTimeString,
    getMonthFromTimeString,
    getTimeFromTimeString,
    getWeekdayFromTimeString,
    toasterControl,
} from "../../../Services/commonService";
import { Dropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { useDispatch, useSelector } from "react-redux";
import { modalController, saveMeetToReschedule } from "../../../Redux/Slices/noPersistSlice";
import { CircularProgress, Dialog, DialogContent, Tooltip } from "@mui/material";
import { IoMdClose, IoMdDoneAll } from "react-icons/io";
import axios from "axios";
import config from "../../../config.json";

const ChatBubble = ({ type, messageData, isDesktop }) => {
    const dispatch = useDispatch();
    const { selectedChat } = useSelector((state) => state.chatting);
    const { personalDetails } = useSelector((state) => state.persist);
    const deleteMessage = async () => {
        const res = await postTReq(apiEndpoints.deleteMessage, {
            message_id: messageData.message_type.message_id,
            delete_type: "delete_fully",
        });
        if (res && !res.error) {
            getChatByUserEmail(selectedChat.emailAddress, 0, 10);
        } else {
            console.log(res.error);
        }
    };

    const handleRescheduling = (obj) => {
        dispatch(saveMeetToReschedule(obj));
        dispatch(modalController({ modal: "rescheduleModal", state: true }));
    };

    const [loading, setLoading] = useState([]);
    const sessionAction = async (message_id, status) => {
        const newLoading = loading.filter((curElem) => {
            return curElem !== message_id;
        });
        const res = await postTReq(apiEndpoints.acceptMeetingRequest, {
            message_id: message_id,
            request_status: status,
        });
        if (res && !res.error) {
            getChatByUserEmail(selectedChat.emailAddress, 0, 10);
            setLoading(newLoading);
        } else {
            console.log(res.error);
            setLoading(newLoading);
        }
    };

    const goToLink = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const [messageDataToSave, setMessageDataToSave] = useState({});
    const [warningModal, setWarningModal] = useState(false);
    const closeWarningModal = () => {
        setMessageDataToSave({});
        setWarningModal(false);
    };
    const handleWarning = async (e, messageData) => {
        e.preventDefault();
        setMessageDataToSave(messageData);
        setWarningModal(true);
    };

    const savePaymentData = async (paymentdata, messageData) => {
        const res = await postTReq(apiEndpoints.savePaymentData, {
            message_id: messageData.message_type.message_id,
            order_id: paymentdata.razorpay_order_id,
            transaction_id: paymentdata.razorpay_payment_id,
            payment_amount: 100,
            payment_status: "success",
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Payment completed successfully");
            goToLink(messageDataToSave.message_type.meeting_url);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Payment could not be saved");
        }
    };
    console.log(messageDataToSave);

    const handlePayment = async () => {
        const amount = 100;

        try {
            const response = await axios.post(
                `${config.apiConfig.apiEndPoint}/payment/createOrder`,
                { amount: amount },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = response.data;

            const options = {
                key: "rzp_live_MpAlUIsoaB8dV6", // Replace with your Razorpay key
                amount: amount,
                currency: "INR",
                order_id: data.id,
                name: "Jobsifarish",
                description: "Payment for your order",
                image: "https://faviconsimages.s3.amazonaws.com/jobsifarish.png", // Replace with your company logo URL
                handler: function (response) {
                    console.log(response);
                    // alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
                    savePaymentData(response, messageDataToSave);
                },
                prefill: {
                    name: "John Doe",
                    email: "john.doe@example.com",
                    contact: "+919876543210",
                },
                theme: {
                    color: "#F1BB46",
                },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
            setWarningModal(false);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <React.Fragment>
            <Dialog open={warningModal} onClose={closeWarningModal} fullWidth>
                <DialogContent>
                    <div className={`${styles.warningMainLine} mb-1`}>Please take care about these points before proceeding with the payment:-</div>
                    <div className={`${styles.warningSubLine}`}>
                        1. You are about to pay <b>₹1000</b> for <b>45 minutes</b> of this session.
                    </div>
                    <div className={`${styles.warningSubLine}`}>2. Please take screenshots at the start of the meeting and at the end of the meeting.</div>
                    <div className={`${styles.warningSubLine}`}>3. These screenshots would help you in case of any dispute that arises.</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-button me-1" onClick={handlePayment}>
                            Proceed
                        </button>
                        <button className="normal-disabled-button" onClick={closeWarningModal}>
                            Cancel
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={type === "receiver" ? styles.receiverContainer : styles.senderContainer}>
                <div className={type === "receiver" ? styles.receiverBubble : styles.senderBubble}>
                    {messageData.type === "meeting" ? (
                        <div className={isDesktop ? styles.meetingDetailsContainer : styles.meetingDetailsContainerMob}>
                            <div className={styles.calendarContainer}>
                                <div className="d-flex justify-content-start">
                                    <div className={styles.theCalendar}>
                                        <div className={styles.monthPart}>
                                            {messageData && messageData.message_type && messageData.message_type.meet_start_time && getMonthFromTimeString(messageData.message_type.meet_start_time)}
                                        </div>
                                        <div className={styles.datePart}>
                                            {messageData &&
                                                messageData.message_type &&
                                                messageData.message_type.meet_start_time &&
                                                getDateNumberFromTimeString(messageData.message_type.meet_start_time)}
                                        </div>
                                        <div className={styles.dayPart}>
                                            {messageData && messageData.message_type && messageData.message_type.meet_start_time && getWeekdayFromTimeString(messageData.message_type.meet_start_time)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.dataContainer}>
                                    <div className={styles.meetingName}>{messageData && messageData.message_type && messageData.message_type.message}</div>
                                    <div className={styles.meetingDate}>
                                        {messageData && messageData.message_type && messageData.message_type.meet_start_time && getDateFromTimeString(messageData.message_type.meet_start_time)}
                                    </div>
                                    <div className={styles.meetingDate}>
                                        {messageData && messageData.message_type && messageData.message_type.meet_start_time && getTimeFromTimeString(messageData.message_type.meet_start_time)} to{" "}
                                        {messageData && messageData.message_type && messageData.message_type.meet_end_time && getTimeFromTimeString(messageData.message_type.meet_end_time)}
                                    </div>
                                    {messageData.message_type.sender_email === personalDetails.emailAddress && (
                                        <div className={styles.meetLink} onClick={() => goToLink(messageData.message_type.meeting_url)}>
                                            {messageData && messageData.message_type && messageData.message_type.meeting_url && messageData.message_type.meeting_url}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="me-2 fw-bold">Description</div>
                                <div style={{ wordBreak: "break-all" }}>{messageData && messageData.message_type && messageData.message_type.comment && messageData.message_type.comment}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-100">{messageData && messageData.message_type && messageData.message_type.message && messageData.message_type.message}</div>
                    )}
                    <div className="mt-1 d-flex justify-content-end w-100">
                        {messageData &&
                            messageData.type === "meeting" &&
                            (messageData.message_type.sender_email === personalDetails.emailAddress ? (
                                <div>
                                    <button className="normal-button" onClick={() => handleRescheduling(messageData)}>
                                        Reschedule
                                    </button>
                                </div>
                            ) : loading.includes(messageData.message_id) ? (
                                <button className="action-button-disabled">
                                    <CircularProgress size={12} style={{ color: "#000000" }} />
                                </button>
                            ) : messageData.message_type.request_status === undefined ? (
                                <div className="d-flex align-items-center">
                                    <Tooltip title="Accept Session Request">
                                        <button
                                            className="opaque-approve-button me-1"
                                            onClick={() => {
                                                setLoading((prev) => {
                                                    return [...prev, messageData.message_type.message_id];
                                                });
                                                sessionAction(messageData.message_type.message_id, "accept");
                                            }}
                                        >
                                            <IoMdDoneAll />
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Reject Session Request">
                                        <button
                                            className="opaque-reject-button"
                                            onClick={() => {
                                                setLoading((prev) => {
                                                    return [...prev, messageData.message_type.message_id];
                                                });
                                                sessionAction(messageData.message_type.message_id, "reject");
                                            }}
                                        >
                                            <IoMdClose />
                                        </button>
                                    </Tooltip>
                                </div>
                            ) : messageData.message_type.request_status === "accept" ? (
                                <button className="normal-button" onClick={(event) => handleWarning(event, messageData)}>
                                    Join Session
                                </button>
                            ) : (
                                <div className={styles.declinedWarning}>Session request declined!</div>
                            ))}
                    </div>
                    <div className={styles.time}>{convertTimeStringToHHMM(messageData.message_type.message_time)}</div>
                </div>
                <Dropdown>
                    <Dropdown.Toggle className={`${styles.bubbleDropButton} no-down-arrow`}>
                        <BsChevronDown fontSize={12} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className={styles.dropdownMenuItem} onClick={deleteMessage}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </React.Fragment>
    );
};

export default ChatBubble;
