import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../../style/PersonalDetails.css";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import ErrorModal from "../Modal/ErrorModal";
import ImageContent from "../../content/ImageContent";

export default function EducationalDetails(props) {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();

  const educationType = [
    {
      id: "Matric",
      text: "Matric",
    },
    {
      id: "Board",
      text: "Board",
    },
    {
      id: "Graduation",
      text: "Graduation",
    },
    {
      id: "B.Tech",
      text: "B.Tech",
    },
    {
      id: "Others",
      text: "Others",
    },
  ];

  const dummyData = {
    id: 0,
    educationType: "",
    schoolName: "",
    sessionPeriod: "",
    scoredMarks: "",
    totalMarks: "",
    boardName: "",
    location: "",
  };

  const [show, setShow] = useState(false);

  const initQualification = useMemo(() => [], []);
  initQualification.push(dummyData);

  const [numberOfQualification, setNumberOfQualification] = useState(initQualification);

  const isUserProfileValidated = useCallback(() => {
    profileService.validateUserProfile(header).then((res) => {
      if (res.status === "200 OK") {
        setShow(!res.data.is_profile_completed);
      }
    });
  }, [header, setShow, profileService]);

  const getUserInfoEducation = useCallback(() => {
    profileService.getEducationDetail(header).then((res) => {
      if (res.status === "200 OK") {
        if (res.data.length > 0) {
          setNumberOfQualification(res.data);
        } else {
          setNumberOfQualification(initQualification);
        }
      }
    });
  }, [header, setNumberOfQualification, profileService, initQualification]);

  let [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    isUserProfileValidated();
    getUserInfoEducation();
  }, [getUserInfoEducation, isUserProfileValidated]);

  const addhandler = () => {
    let newData = { ...dummyData };
    newData.id = numberOfQualification.length;
    let newList = [...numberOfQualification];
    newList.unshift(newData);
    setNumberOfQualification([]);
    setNumberOfQualification(newList);
  };

  const onChangeHandler = (e, index) => {
    let currentIndex = [...numberOfQualification];
    currentIndex[index] = {
      ...numberOfQualification[index],
      [e.target.name]: e.target.value,
    };
    setNumberOfQualification(currentIndex);
  };

  const onSubmit = () => {
    setBtnLoader(true);
    profileService
      .postEducationDetails(numberOfQualification, header)
      .then((res) => {
        if (res.status === "200 OK") {
          if (props.updateScreen !== undefined) {
            props.updateScreen(3);
          }
          if (props.updateEdit !== undefined) {
            props.updateEdit(false);
          }
          setBtnLoader(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setBtnLoader(false);
      });
  };

  const onDelete = (delIndex, edId) => {
    let newList = [...numberOfQualification];
    let filterList = [];
    newList.forEach((item, index) => {
      if (index !== delIndex) {
        filterList.push(item);
      }
    });
    setNumberOfQualification([]);
    setNumberOfQualification(filterList);
    if (edId !== null) {
      profileService.deletingEducationalDataCorrespondingToId(header, { educationId: edId });
      getUserInfoEducation();
    }
  };

  return (
    <Fragment>
      <ErrorModal
        icon="text-danger"
        title="Warning"
        message={`This is a required part for creating and accessing your Resume.So fill all details of your Educational background.`}
        isShow={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <div className="row createProfileSideBar completeProfileHeader pt-2 pb-0">
        <div className="col-12">
          <h4>
            <b>Educational Details</b>
          </h4>
        </div>
      </div>
      <div className="row pt-2 pb-0">
        <Button className="educationalDetailPlusBtn" onClick={addhandler}>
          <i className="fa fa-plus"></i> ADD FIELD
        </Button>
      </div>
      {numberOfQualification &&
        numberOfQualification.map((res, index) => (
          <div key={index} className="row educationalRow createProfileSideBar mt-2 pt-2 pl-3 pr-3">
            <div className="col-12">
              <Button
                onClick={() => {
                  onDelete(index, res.educationId);
                }}
                className="educationDelBtn d-flex justify-content-center align-item-center"
              >
                <img src={ImageContent.getDelImage()} alt="" height="20" />
                &nbsp;&nbsp;Delete
              </Button>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-3">
              <label>School Name</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="schoolName" value={res?.schoolName} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-4">
              <label>Location</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="location" value={res?.location} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-4">
              <label>Education Type</label>
              <br />
              <select style={{ width: "100%" }} className="inputFieldStyle" name="educationType" value={res?.educationType} onChange={(e) => onChangeHandler(e, index)}>
                <option value="-1">Select Education Type</option>
                {educationType.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 pt-4">
              <label>Start Date</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="session_start" value={res?.session_start} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 pt-4">
              <label>End Date</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="session_end" value={res?.session_end} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-4">
              <label>Scored Marks</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="scoredMarks" value={res?.scoredMarks} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-4">
              <label>Total Marks</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="totalMarks" value={res?.totalMarks} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-4">
              <label>Board Name</label>
              <br />
              <Form.Control className="inputFieldStyle" type="text" name="boardName" value={res?.boardName} onChange={(e) => onChangeHandler(e, index)} />
            </div>
            {res?.educationType === "graduation" || res?.educationType === "diploma" || res?.educationType === "others" ? (
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pt-4">
                <label>Stream</label>
                <br />
                <Form.Control className="inputFieldStyle" type="text" name="education_type_stream" value={res?.education_type_stream} onChange={(e) => onChangeHandler(e, index)} />
              </div>
            ) : null}
          </div>
        ))}
      <div className="row pt-4">
        <div className="col-9"></div>
        {/* <div className='col-sm-12 col-md-4 col-lg-4'></div> */}
        <div className="col-sm-12 col-md-3">
          {!btnLoader ? (
            <Button className="addProjectBtn" style={{ width: "100%" }} onClick={onSubmit}>
              Submit
            </Button>
          ) : (
            <Button className="text-white connectButton">
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </Fragment>
  );
}
