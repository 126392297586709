import { apiEndpoints, navigationRoutes } from "../PrEnums/prEnums";
import { postTReq } from "./api";
import store from "../Redux/store";
import {
    saveConnectionsList,
    saveEducationalDetails,
    savePendingList,
    savePersonalDetails,
    saveProfessionalDetails,
    saveSearchedConnections,
    saveSentList,
    saveSkillsList,
    saveSuggestedConnections,
    saveUserEducationalDetails,
    saveUserPersonalDetails,
    saveUserProfessionalDetails,
} from "../Redux/Slices/persistSlice";
import { monthList } from "./constants";
import { modalController, saveApiMoreNotifications, saveJobArray, saveNotifications, savePeopleArray, saveScheduledMeetList, saveUnreadDataCount } from "../Redux/Slices/noPersistSlice";
import { clearUnreadChatsInConnection, saveChatHistory, saveMessageDetails, saveMessages, saveMoreMessagesForInfinite, selectChat } from "../Redux/Slices/chatSlice";
import { toasterController } from "../Redux/Slices/toasterSlice";
import { saveAllPaymentsList, saveAllReportedList, saveAllUserList, saveSessionRequestList } from "../Redux/Slices/adminSlice";
import { saveAppliedJobsByUser, saveJobApplicants, saveJobsBookmarkedByUser, saveJobsPostedByUser, saveSingleJobData } from "../Redux/Slices/jobSlice";
import { saveUserRatingData } from "../Redux/Slices/profileSlice";
import { savePaginationTotalPages } from "../Redux/Slices/generalSlice";

export const convertDateMMYYYY = (dateNum) => {
    let month = new Date(dateNum).getMonth();
    let year = new Date(dateNum).getFullYear();
    return `${monthList[month]}, ${year}`;
};

export const convertDateDDMMYYYY = (dateNum) => {
    let date = new Date(dateNum).getDate();
    let month = new Date(dateNum).getMonth();
    let year = new Date(dateNum).getFullYear();
    return `${date} ${monthList[month]}, ${year}`;
};

export const toasterControl = (name, state, message = "") => {
    store.dispatch(toasterController({ toaster: name, state: state, message: message }));
};
export const modalControl = (name, state) => {
    store.dispatch(modalController({ modal: name, state: state }));
};

export const getPersonalDetails = async () => {
    const res = await postTReq(apiEndpoints.getPersonalDetails);
    if (res && !res.error) {
        store.dispatch(savePersonalDetails(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getEducationalDetails = async () => {
    const res = await postTReq(apiEndpoints.getEducationalDetails);
    if (res && !res.error) {
        store.dispatch(saveEducationalDetails(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getProfessionalDetails = async () => {
    const res = await postTReq(apiEndpoints.getProfessionalDetails);
    if (res && !res.error) {
        store.dispatch(saveProfessionalDetails(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getChatHistory = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.chatHistory, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveChatHistory(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getConnectionsList = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getConnectionList, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveConnectionsList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getReceivedRequest = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getReceivedRequests, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(savePendingList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getSentRequest = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getSentRequest, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveSentList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getSearchedUsers = async (pageNum, recordsNum, searchText) => {
    const res = await postTReq(apiEndpoints.getSuggestedUserList, { page_no: pageNum, no_of_records: recordsNum, user_search_key: searchText });
    if (res && !res.error) {
        store.dispatch(saveSearchedConnections(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getSuggestedUsers = async (pageNum, recordsNum, locationArr) => {
    const res = await postTReq(apiEndpoints.getSuggestedUserList, { page_no: pageNum, no_of_records: recordsNum, location: locationArr });
    if (res && !res.error) {
        store.dispatch(saveSuggestedConnections(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getNotifications = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getNotifications, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveNotifications(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getMoreNotifications = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getNotifications, { page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveApiMoreNotifications(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getChatByUserEmail = async (email, pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getMessages, { reciever_id: email, page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveMessages(res.data.data));
        store.dispatch(saveMessageDetails(res.data.page_details));
    } else {
        console.log(res.error);
    }
};
export const getMoreChatByUserEmail = async (email, pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getMessages, { reciever_id: email, page_no: pageNum, no_of_records: recordsNum });
    if (res && !res.error) {
        store.dispatch(saveMoreMessagesForInfinite(res.data.data));
        store.dispatch(saveMessageDetails(res.data.page_details));
    } else {
        console.log(res.error);
    }
};

export const readMessages = async (email) => {
    const res = await postTReq(apiEndpoints.readMessages, {
        reciever_id: email,
    });
    if (res && !res.error) {
        store.dispatch(clearUnreadChatsInConnection(email));
    } else {
        console.log(res.error);
    }
};

export const getScheduledMeetList = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.scheduledMeetList, {
        meet_start_time: 0,
        meet_end_time: new Date().getTime(),
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveScheduledMeetList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const convertTimeStringToDuration = (timeString) => {
    const currentTime = new Date().getTime();
    const messageTime = parseInt(timeString, 10);
    const differenceInMillis = currentTime - messageTime;
    const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInMinutes < 1) {
        return "Just Now";
    } else if (differenceInMinutes < 60) {
        return `${differenceInMinutes} min ago`;
    } else if (differenceInHours < 15) {
        return `${differenceInHours} hrs ago`;
    } else if (differenceInHours < 24) {
        return "Today";
    } else if (differenceInDays < 3) {
        return `${differenceInDays}d ago`;
    } else {
        const messageDate = new Date(messageTime);
        const day = messageDate.getDate().toString().padStart(2, "0");
        const month = (messageDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
        const year = messageDate.getFullYear();
        return `${day}/${month}/${year}`;
    }
};

export const getGlobalSearchData = async (searchText) => {
    const res = await postTReq(apiEndpoints.globalSearch, {
        page_no: 0,
        no_of_records: 10,
        searchKey: searchText,
    });
    if (res && !res.error) {
        store.dispatch(savePeopleArray(res.data.people_data.data));
        store.dispatch(saveJobArray(res.data.job_data.data));
    } else {
        console.log(res.error);
    }
};

export const startChatDirectlyWithFriend = (chatHistory, email, navigation) => {
    let x = chatHistory.filter((curElem) => {
        return curElem.emailAddress === email;
    });
    store.dispatch(selectChat(x[0]));
    getChatByUserEmail(x[0].emailAddress, 0, 10);
    store.dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
    readMessages(email);
    navigation("/message");
};

export const handleGetProfileAndGoThere = async (obj, navigateTo) => {
    const res = await postTReq(apiEndpoints.getUserProfile, { emailAddress: obj.emailAddress });
    if (res && !res.error) {
        store.dispatch(saveUserPersonalDetails(res.data.data));
        store.dispatch(saveUserEducationalDetails(res.data.data.educational_details));
        store.dispatch(saveUserProfessionalDetails(res.data.data.professional_details));
        navigateTo(navigationRoutes.userProfile);
    } else {
        console.log(res.error);
    }
};
export const getOtherUserProfileData = async (obj) => {
    const res = await postTReq(apiEndpoints.getUserProfile, { emailAddress: obj.emailAddress });
    if (res && !res.error) {
        store.dispatch(saveUserPersonalDetails(res.data.data));
        store.dispatch(saveUserEducationalDetails(res.data.data.educational_details));
        store.dispatch(saveUserProfessionalDetails(res.data.data.professional_details));
    } else {
        console.log(res.error);
    }
};

export const getLiveSessionRequestList = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getLiveSessionRequestList, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        console.log(res);
        store.dispatch(saveSessionRequestList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getSkillsArray = async () => {
    const res = await postTReq(apiEndpoints.getSkills, {
        page_no: 0,
        no_of_records: 10000,
    });
    if (res && !res.error) {
        store.dispatch(saveSkillsList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getJobDataWithJobId = async (jobId) => {
    const res = await postTReq(apiEndpoints.viewJob, {
        job_id: jobId,
    });
    if (res && !res.error) {
        store.dispatch(saveSingleJobData(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getUserRatings = async (userEmail, pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getUserRateRemark, {
        remark_given_to_email_name: userEmail,
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveUserRatingData(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getJobsPostedByUser = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getPostedJob, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveJobsPostedByUser(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getJobsAppliedByUser = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getAppliedJobList, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveAppliedJobsByUser(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getAllJobApplicants = async (jobId, pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getJobApplicants, {
        page_no: pageNum,
        no_of_records: recordsNum,
        job_id: jobId,
    });
    if (res && !res.error) {
        store.dispatch(saveJobApplicants(res.data.data));
        modalControl("jobApplicantsModal", true);
    } else {
        console.log(res.error);
    }
};

export const convertTimeStringToHHMM = (timestamp) => {
    const currentDate = new Date();
    const date = new Date(timestamp);
    const timeDiff = currentDate.getTime() - date.getTime();
    const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    if (timeDiff < 24 * 60 * 60 * 1000) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedTime = `${hours % 12}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
        return formattedTime;
    } else if (dayDiff < 3) {
        return `${dayDiff}d ago`;
    } else {
        const formattedDate = date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
        return formattedDate;
    }
};

export const getDateFromTimeString = (timeString) => {
    let date = new Date(timeString).toLocaleDateString();
    return `${date}`;
};
export const getTimeFromTimeString = (timeString) => {
    let time = new Date(timeString).toLocaleTimeString();
    return `${time}`;
};

export const dateFromTimeStringForInput = (timeString) => {
    let date = new Date(timeString).toISOString().split("T")[0];
    return `${date}`;
};
export const timeFromTimeStringForInput = (timeString) => {
    let hours = new Date(timeString).getHours();
    let minutes = new Date(timeString).getMinutes();
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

const weekdayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const getWeekdayFromTimeString = (timeString) => {
    let day = new Date(timeString).getDay();
    return `${weekdayArr[day]}`;
};

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const getMonthFromTimeString = (timeString) => {
    let month = new Date(timeString).getMonth();
    return `${monthArr[month]}`;
};

export const getDateNumberFromTimeString = (timeString) => {
    let date = new Date(timeString).getDate();
    return `${date}`;
};

export const capitalizeFirstCharacter = (fullString) => {
    let splitData = fullString.split(" ");
    let resultArr = [];
    splitData.forEach((curElem) => {
        let newWord = "";
        let data = curElem.split("");
        data[0] = data[0].toUpperCase();
        data.forEach((cur) => {
            newWord = newWord + cur;
        });
        resultArr.push(newWord);
    });
    return resultArr.join(" ");
};

export const getEveryUser = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getUserList, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveAllUserList(res.data.data));
        store.dispatch(savePaginationTotalPages(res.data.page_details.total_pages));
    } else {
        console.log(res.error);
    }
};

export const getReportedUser = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.reportedUser, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveAllReportedList(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const getPeopleSearch = async (pageNum, recordsNum, locationArr, skillArr, searchText, livesession) => {
    const res = await postTReq(apiEndpoints.getSuggestedUserList, {
        page_no: pageNum,
        no_of_records: recordsNum,
        location: locationArr,
        skillset: skillArr,
        user_search_key: searchText,
        is_open_for_live_session: livesession,
        is_verified_from_admin_for_live_session: livesession,
    });
    if (res && !res.error) {
        store.dispatch(savePeopleArray(res.data.data));
    } else {
        console.log(res.error);
    }
};
export const getJobSearch = async (pageNum, recordsNum, locationArr, skillArr, searchText) => {
    const res = await postTReq(apiEndpoints.getAdvJobSearchData, { page_no: pageNum, no_of_records: recordsNum, location: locationArr, skillset: skillArr, searchKey: searchText });
    if (res && !res.error) {
        store.dispatch(saveJobArray(res.data.data));
    } else {
        console.log(res.error);
    }
};

export const addNewSkill = async (skillname) => {
    const res = await postTReq(apiEndpoints.saveSkill, {
        skill_name: skillname,
    });
    if (res && !res.error) {
        getSkillsArray();
    } else {
        toasterControl("errorToaster", true, "An error occured");
    }
};

export const sendFriendRequest = async (email) => {
    const res = await postTReq(apiEndpoints.sendFriendRequest, { reciever_id: email });
    if (res && !res.error) {
        toasterControl("successToaster", true, "Connection request sent");
        return res.data.status;
    } else {
        console.log(res.error);
        toasterControl("errorToaster", true, "An error occured");
    }
};

export const cancelFriendRequest = async (friendListId) => {
    const res = await postTReq(apiEndpoints.cancelRequest, {
        friendListId: friendListId,
    });
    if (res && !res.error) {
        toasterControl("successToaster", true, "Connection request revoked");
        return res.data.status;
    } else {
        toasterControl("errorToaster", true, "An error occured");
        console.log(res.error);
    }
};

export const handleFriendRequestAction = async (friendListId, request_type) => {
    const res = await postTReq(apiEndpoints.respondRequest, { friendListId, request_type });
    if (res && !res.error) {
        if (request_type === "accept") {
            toasterControl("successToaster", true, "Connection added");
        } else {
            toasterControl("successToaster", true, "Connection request declined");
        }
        return res.data.status;
    } else {
        console.log(res.error);
        toasterControl("errorToaster", true, "An error occured");
    }
};

export const unfriendConnection = async (friendListId) => {
    const res = await postTReq(apiEndpoints.unfriend, { friendListId: friendListId });
    if (res && !res.error) {
        toasterControl("successToaster", true, "Connection removed from Connection list");
        return res.data.status;
    } else {
        console.log(res.error);
        toasterControl("errorToaster", true, "An error occured");
    }
};

export const reportUserByEmail = async (comment, email) => {
    const res = await postTReq(apiEndpoints.reportUser, {
        comment: comment,
        reciever_id: email,
    });
    if (res && !res.error) {
        toasterControl("successToaster", true, "The user has been reported");
        return res.data.status;
    } else {
        console.log(res.error);
        toasterControl("errorToaster", true, "An error occured");
    }
};

export const getUnreadDataCount = async () => {
    const res = await postTReq(apiEndpoints.getTopHeaderUnReadCount);
    if (res && !res.error) {
        store.dispatch(saveUnreadDataCount(res.data.data.notification));
    } else {
        console.log(res.error);
    }
};

export const sendRequestMail = async (senderName, receiverName, receiverEmail) => {
    await postTReq(apiEndpoints.sendRequestMail, {
        sender_name: senderName,
        reciever_name: receiverName,
        reciever_email: receiverEmail,
    });
};

export const respondRequestMail = async (receiverName, receiverEmail) => {
    await postTReq(apiEndpoints.respondRequestMail, {
        reciever_name: receiverName,
        reciever_email: receiverEmail,
    });
};

export const scheduleMeetingMail = async (senderName, receiverName, receiverEmail, meetingDate, meetingTime) => {
    await postTReq(apiEndpoints.scheduleMeetingMail, {
        sender_name: senderName,
        reciever_name: receiverName,
        reciever_email: receiverEmail,
        meeting_date: meetingDate,
        meeting_time: meetingTime,
    });
};

export const applyJobMail = async (jobId) => {
    await postTReq(apiEndpoints.applyJobMail, {
        job_id: jobId,
    });
};

export const postJobMail = async (jobId) => {
    await postTReq(apiEndpoints.postJobMail, {
        job_id: jobId,
    });
};

export const getJobsBookmarkedByUser = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getBookmarkJobListByUser, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveJobsBookmarkedByUser(res.data));
    } else {
        console.log(res.error);
    }
};

export const getAllPayments = async (pageNum, recordsNum) => {
    const res = await postTReq(apiEndpoints.getPaymentList, {
        page_no: pageNum,
        no_of_records: recordsNum,
    });
    if (res && !res.error) {
        store.dispatch(saveAllPaymentsList(res.data.data));
        store.dispatch(savePaginationTotalPages(res.data.page_details.total_pages));
    } else {
        console.log(res.error);
    }
};
