import React from "react";
// import styles from "./userWorkExperienceBox.module.css";
import { useSelector } from "react-redux";
import UserProfessionalDetailsCard from "../../../../component/UserProfessionalDetailsCard/UserProfessionalDetailsCard";

const UserWorkExperienceBox = () => {
    const { userProfessionalDetails } = useSelector((state) => state.persist);

    return (
        <div className={`content-card`}>
            <div className="content-card-heading">Work Experience</div>
            {userProfessionalDetails && userProfessionalDetails.length > 0 ? (
                userProfessionalDetails.map((curElem) => {
                    return <UserProfessionalDetailsCard data={curElem} />;
                })
            ) : (
                <div className="wide-nothing-to-show">Nothing to show</div>
            )}
        </div>
    );
};

export default UserWorkExperienceBox;
