import { useCallback, useEffect, useRef } from "react";
import React, { useState } from "react";
import "../../style/accordion.css";

export const DataFiltersEmplyement = (props) => {
  const { componentProps, handleForFilters = null } = props;

  const [checkBoxes, setCheckBoxes] = useState([]);

  useEffect(() => {
    setCheckBoxes(componentProps && componentProps.dataList && [...componentProps.dataList]);
  }, [componentProps]);

  useEffect(() => {
    let chekedData = checkBoxes && checkBoxes.filter((d) => d.isChecked);
    if (chekedData) {
      handleForFilters({
        job_type: chekedData && chekedData.map((i) => i.value),
      });
    }
  }, [checkBoxes, handleForFilters]);

  const handleChange = (e, value) => {
    let event = e.target;
    if (event.checked) {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = true;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    } else {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = false;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    }
  };

  return (
    <div>
      {checkBoxes &&
        checkBoxes.map((res, key) => (
          <div className="form-check" key={key}>
            <label className="form-check-label">
              <input className="form-check-input" onChange={(e) => handleChange(e, res.value)} type="checkbox" checked={res.isChecked} value={res.value} id="defaultCheck1" />
              <span style={{ marginLeft: "8px" }}>{res.label}</span>
            </label>
          </div>
        ))}
    </div>
  );
};
export const DataFiltersSkills = (props) => {
  const { componentProps, handleForFilters = null } = props;

  const [checkBoxes, setCheckBoxes] = useState([]);

  useEffect(() => {
    setCheckBoxes(componentProps && componentProps.dataList && [...componentProps.dataList]);
  }, [componentProps]);

  useEffect(() => {
    let checkedData = checkBoxes && checkBoxes.filter((d) => d.isChecked);
    if (checkedData) {
      handleForFilters({
        skills: checkedData && checkedData.map((i) => i.value),
      });
    }
  }, [checkBoxes, handleForFilters]);

  const handleChange = (e, value) => {
    let event = e.target;
    if (event.checked) {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = true;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    } else {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = false;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    }
  };

  return (
    <div>
      {checkBoxes &&
        checkBoxes.map((res, key) => (
          <div className="form-check" key={key}>
            <label className="form-check-label">
              <input className="form-check-input" onChange={(e) => handleChange(e, res.value)} type="checkbox" checked={res.isChecked} value={res.value} id="defaultCheck1" />
              <span style={{ marginLeft: "8px" }}>{res.label}</span>
            </label>
          </div>
        ))}
    </div>
  );
};
export const DataFiltersLocation = (props) => {
  const { componentProps, handleForFilters = null } = props;

  const [checkBoxes, setCheckBoxes] = useState([]);

  useEffect(() => {
    setCheckBoxes(componentProps && componentProps.dataList && [...componentProps.dataList]);
  }, [componentProps]);

  useEffect(() => {
    let checkedData = checkBoxes && checkBoxes.filter((d) => d.isChecked);
    if (checkedData) {
      handleForFilters({ location: checkedData.map((i) => i.value) });
    }
  }, [checkBoxes, handleForFilters]);

  const handleChange = (e, value) => {
    let event = e.target;
    if (event.checked) {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = true;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    } else {
      let checkedOne = checkBoxes.filter((d) => d.value === value)[0];
      checkedOne.isChecked = false;
      let finalChecked = checkBoxes.map((item, i) => Object.assign({}, item, checkedOne[i]));
      setCheckBoxes(finalChecked);
    }
  };

  return (
    <div>
      {checkBoxes &&
        checkBoxes.map((res, key) => (
          <div className="form-check" key={key}>
            <label className="form-check-label">
              <input className="form-check-input" onChange={(e) => handleChange(e, res.value)} type="checkbox" checked={res.isChecked} value={res.value} id="defaultCheck1" />
              <span style={{ marginLeft: "8px" }}>{res.label}</span>
            </label>
          </div>
        ))}
    </div>
  );
};
export const DataFilters = (props) => {
  const { componentProps } = props;

  //   const [checkBoxes, setCheckBoxes] = useState([]);

  //   useEffect(() => {
  //     setCheckBoxes((prev) => {
  //       return [...prev, componentProps.dataList];
  //     });
  //   }, [componentProps]);

  const handleChange = (e) => {
    // handleForFilters({ job_type: e.target.value })
  };

  return (
    <div>
      {componentProps &&
        componentProps.dataList &&
        componentProps.dataList.map((res, key) => (
          <div className="form-check" key={key}>
            <label className="form-check-label">
              <input className="form-check-input" onChange={handleChange} type="checkbox" checked={res.isChecked} value={res.value} id="defaultCheck1" />
              <span style={{ marginLeft: "8px" }}>{res.label}</span>
            </label>
          </div>
        ))}
    </div>
  );
};

export const Experience = ({ handleForFilters }) => {
  const [experience, setExperience] = useState({
    expRequired: {
      min_exp_required: 0,
      max_exp_required: 12,
    },
  });

  const onChangeHandler = (e) => {
    let event = e.target;
    if (event.value >= 0) {
      setExperience({
        ...experience,
        expRequired: {
          ...experience.expRequired,
          [event.name]: Number(event.value),
        },
      });
      if (event.name === "min_exp_required") {
        handleForFilters({
          expRequired: {
            min_exp_required: Number(event.value),
            max_exp_required: experience.expRequired.max_exp_required,
          },
        });
      } else {
        handleForFilters({
          expRequired: {
            min_exp_required: experience.expRequired.min_exp_required,
            max_exp_required: Number(event.value),
          },
        });
      }
    }
  };

  return (
    <div className="d-flex">
      <select style={{ width: "100%", marginRight: "1rem" }} name="min_exp_required" className="select-box" value={experience?.expRequired?.min_exp_required} onChange={onChangeHandler}>
        <option value="-1">Minimum Exp.</option>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => (
          <option key={item} value={item}>
            {item} Yr(s)
          </option>
        ))}
      </select>
      <select style={{ width: "100%", marginLeft: "1rem" }} className="select-box" name="max_exp_required" value={experience?.expRequired?.max_exp_required} onChange={onChangeHandler}>
        <option value="-1">Maximum Exp.</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].slice(experience?.expRequired?.min_exp_required).map((item) => (
          <option key={item} value={item}>
            {item} Yr(s)
          </option>
        ))}
      </select>
    </div>
  );
};

export const OfferPackage = ({ handleForFilters }) => {
  const [offerPackage, setOfferPackage] = useState([
    { id: 0, min: 0, max: 3, isChecked: false },
    { id: 1, min: 3, max: 6, isChecked: false },
    { id: 2, min: 6, max: 10, isChecked: false },
    { id: 3, min: 10, max: 15, isChecked: false },
    { id: 4, min: 15, max: 25, isChecked: false },
    { id: 5, min: 25, max: 50, isChecked: false },
    { id: 6, min: 50, max: 75, isChecked: false },
    { id: 7, min: 75, max: 99, isChecked: false },
  ]);

  const onChangeHandler = (e, id, min, max) => {
    let event = e.target;
    let newChecked = [...offerPackage];
    let currentIndex = offerPackage.findIndex((item) => item.id === id);
    if (event.checked) {
      newChecked.map((item) => (item.isChecked = false));
      newChecked[currentIndex].isChecked = true;
      setOfferPackage(newChecked);
      handleForFilters({
        offerPackage: {
          min_offer_package: min,
          max_offer_package: max,
        },
      });
    } else {
      // newChecked.map(item => item.isChecked = true)
      newChecked[currentIndex].isChecked = false;
      setOfferPackage(newChecked);
      handleForFilters({
        offerPackage: {
          min_offer_package: 0,
          max_offer_package: 99,
        },
      });
    }
  };

  return (
    <>
      {offerPackage.map((item) => (
        <div className="form-check" style={{ marginLeft: "12px" }} key={item.id}>
          <label className="form-check-label">
            <input className="form-check-input" onChange={(e) => onChangeHandler(e, item.id, item.min, item.max)} type="checkbox" checked={item.isChecked} value={item.id} id="defaultCheck1" />
            <span style={{ marginLeft: "8px" }}>{`${item.min} - ${item.max}  Lakh(s)`}</span>
          </label>
        </div>
      ))}
    </>
  );
};
// const active = '#212e3f'
// const inactive = '#dbdbdb'
// export const Experience12 = (props) => {
// 	const [state, setState] = useState({ value: 1 });
//    const inputRef = useRef()
// 	const minValue = 0;
// 	const maxValue = 12;
// 	const progress = (state.value / maxValue) * 100 + "%";

// 	const styleInput = {
// 		background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`,
// 	}

//    const handleChange = event => {
//       const value = event.target.value
// 	   const progress = (value / maxValue) * 100 + '%'
// 	   setState({ value: value } )
// 	   const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`
// 	   inputRef.current.style.background = newBackgroundStyle
//    }
// console.log(state)
//    return (
//       <div>
//          <div className='label' style={{ textAlign: "center" }}>
// 					<h6> {state.value} Years</h6>
// 				</div>
// 				<input
// 					ref={inputRef}
// 					id='sliderId'
// 					className='inputR'
// 					name='sliderName'
// 					type='range'
// 					min={minValue}
// 					max={maxValue}
// 					value={state.value}
// 					onChange={handleChange}
// 					style={styleInput}
// 				/>
// 				<span className='slider_years'>
// 					<span>
// 						0<span>Yrs</span>
// 					</span>
// 					<span>
// 						12<span>Yrs</span>
// 					</span>
// 				</span>
//          </div>
//    )
// };

// export class Experience extends React.Component {
//    inputRef = React.createRef()

//    state = { value: 1 }

//    handleChange = (min, max) => (event) => {
//       const value = event.target.value
//       const progress = (value / max) * 100 + '%'
//       this.setState({ value: value })
//       const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`
//       this.inputRef.current.style.background = newBackgroundStyle
//    }

//    render() {
//       const minValue = 0
//       const maxValue = 12
//       const progress = (this.state.value / maxValue) * 100 + '%'

//       const styleInput = {
//          background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`
//       }

//       return (
//          <div>
//             <div className='label' style={{ textAlign: 'center' }}>
//                <h6> {this.state.value} Years</h6>
//             </div>
//             <input
//                ref={this.inputRef}
//                id='sliderId'
//                className='inputR'
//                name='sliderName'
//                type='range'
//                min={minValue}
//                max={maxValue}
//                value={this.state.value}
//                onChange={this.handleChange(minValue, maxValue)}
//                style={styleInput}
//             />
//             <span className='slider_years'>
//                <span>
//                   0<span>Yrs</span>
//                </span>
//                <span>
//                   12<span>Yrs</span>
//                </span>
//             </span>
//          </div>
//       )
//    }
// }

export const OfferSlider = ({ min, max }) => {
  // Creating the state variables
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to change from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to change from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);
  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
        }}
      >
        <div className="slider__left-value">{minVal} - </div>
        <div className="slider__right-value" style={{ marginLeft: "4px" }}>
          {" "}
          {maxVal} Lakhs
        </div>
      </div>
    </>
  );
};
//    const [avg, setAvg] = useState((min + max) / 2)
//    const [minVal, setMinVal] = useState(avg)
//    const [maxVal, setMaxVal] = useState(avg)

//    const width = 300
//    const minWidth =
//       thumbsize + ((avg - min) / (max - min)) * (width - 2 * thumbsize)
//    const minPercent = ((minVal - min) / (avg - min)) * 100
//    const maxPercent = ((maxVal - avg) / (max - avg)) * 100
//    const styles = {
//       min: {
//          width: minWidth,
//          left: 0,
//          '--minRangePercent': `${minPercent}%`
//       },
//       max: {
//          width:
//             thumbsize + ((max - avg) / (max - min)) * (width - 2 * thumbsize),
//          left: minWidth,
//          '--maxRangePercent': `${maxPercent}%`
//       }
//    }

//    useLayoutEffect(() => {
//       setAvg((maxVal + minVal) / 2)
//    }, [minVal, maxVal])

//    console.log(maxVal, avg, min, max, maxPercent)

//    return (
//       <div
//          className='min-max-slider'
//          data-legendnum='2'
//          data-rangemin={min}
//          data-rangemax={max}
//          data-thumbsize={thumbsize}
//          data-rangewidth={width}
//       >
//          <label htmlFor='min'>Minimum price</label>
//          <input
//             id='min'
//             className='min'
//             style={styles.min}
//             name='min'
//             type='range'
//             step='1'
//             min={min}
//             max={avg}
//             value={minVal}
//             onChange={({ target }) => setMinVal(Number(target.value))}
//          />
//          <label htmlFor='max'>Maximum price</label>
//          <input
//             id='max'
//             className='max'
//             style={styles.max}
//             name='max'
//             type='range'
//             step='1'
//             min={avg}
//             max={max}
//             value={maxVal}
//             onChange={({ target }) => setMaxVal(Number(target.value))}
//          />
//       </div>
//    )
// }
