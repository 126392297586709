import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedChat: {},
    previousChat: {},
    messagesArray: [],
    chatHistory: [],
    messageDetails: {},
};

export const chatSlice = createSlice({
    name: "chatSlice",
    initialState,
    reducers: {
        selectChat: (state, action) => {
            state.previousChat = state.selectedChat;
            state.selectedChat = action.payload;
        },
        saveMessages: (state, action) => {
            state.messagesArray = action.payload;
        },
        saveMoreMessagesForInfinite: (state, action) => {
            state.messagesArray = [...state.messagesArray, ...action.payload];
        },
        saveMoreMessages: (state, action) => {
            let check = state.messagesArray.some((curElem) => {
                return curElem.message_type.message_id === action.payload.message_type.message_id;
            });
            if (!check) {
                state.messagesArray = [action.payload, ...state.messagesArray];
            }
        },
        saveChatHistory: (state, action) => {
            state.chatHistory = action.payload;
        },

        saveUnreadChatInConnection: (state, action) => {
            let newChatHistory = state.chatHistory;
            let changingIndex = newChatHistory.findIndex((curElem) => {
                return curElem.emailAddress === action.payload.sender_email;
            });
            if (changingIndex !== -1) {
                if (newChatHistory[changingIndex]["unreadMessages"]) {
                    const checkIfUnreadAvailable = newChatHistory[changingIndex]["unreadMessages"].some((curElem) => {
                        return curElem.message_id === action.payload.message_id;
                    });
                    if (!checkIfUnreadAvailable) {
                        newChatHistory[changingIndex]["unreadMessages"] = [...newChatHistory[changingIndex]["unreadMessages"], action.payload];
                    }
                } else {
                    newChatHistory[changingIndex]["unreadMessages"] = [action.payload];
                }
                newChatHistory[changingIndex]["last_message"] = action.payload.message;
                newChatHistory[changingIndex]["last_message_sent_time"] = action.payload.message_time;
            }
        },

        updateNewChatInConnection: (state, action) => {
            let newChatHistory = state.chatHistory;
            let changingIndex = newChatHistory.findIndex((curElem) => {
                return curElem.emailAddress === action.payload.reciever_email;
            });
            if (changingIndex !== -1) {
                newChatHistory[changingIndex]["last_message"] = action.payload.message;
                newChatHistory[changingIndex]["last_message_sent_time"] = action.payload.message_time;
            }
        },

        clearUnreadChatsInConnection: (state, action) => {
            let newChatHistory = state.chatHistory;
            const changingIndex = newChatHistory.findIndex((curElem) => {
                return curElem.emailAddress === action.payload;
            });
            if (changingIndex !== -1) {
                if (newChatHistory[changingIndex]["unreadMessages"]) {
                    newChatHistory[changingIndex]["unreadMessages"] = [];
                }
                newChatHistory[changingIndex]["un_read_message_count"] = 0;
            }
        },

        saveMessageDetails: (state, action) => {
            state.messageDetails = action.payload;
        },
    },
});

export const {
    selectChat,
    saveMessages,
    saveChatHistory,
    saveMoreMessages,
    saveUnreadChatInConnection,
    clearUnreadChatsInConnection,
    updateNewChatInConnection,
    saveMessageDetails,
    saveMoreMessagesForInfinite,
} = chatSlice.actions;

export default chatSlice.reducer;
