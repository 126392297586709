import React from "react";
import styles from "./profilePage.module.css";
import ProfileInfoBox from "./ProfilePageComponents/ProfileInfoBox/ProfileInfoBox";
import AboutBox from "./ProfilePageComponents/AboutBox/AboutBox";
import ConnectionsBox from "./ProfilePageComponents/ConnectionsBox/ConnectionsBox";
// import CreateResumeBox from "./ProfilePageComponents/CreateResumeBox/CreateResumeBox";
import WorkExperienceBox from "./ProfilePageComponents/WorkExperienceBox/WorkExperienceBox";
import EducationBox from "./ProfilePageComponents/EducationBox/EducationBox";
import ActivityBox from "./ProfilePageComponents/ActivityBox/ActivityBox";
import PersonalRatingSection from "./ProfilePageComponents/PersonalRatingSection/PersonalRatingSection";
import LiveSessionSwitch from "./ProfilePageComponents/LiveSessionSwitch/LiveSessionSwitch";
import RequestLiveSessionBox from "../UserProfilePage/ProfilePageComponents/RequestLiveSessionBox/RequestLiveSessionBox";

const ProfilePage = () => {
    return (
        <div className="main-content-container-flexible">
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <ProfileInfoBox />
                    <RequestLiveSessionBox />
                    <LiveSessionSwitch />
                    <AboutBox />
                    <ConnectionsBox />
                    {/* <CreateResumeBox /> */}
                </div>
                <div className={styles.rightPanel}>
                    <WorkExperienceBox />
                    <EducationBox />
                    <ActivityBox />
                    <PersonalRatingSection />
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
