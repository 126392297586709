import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserRatings, modalControl, toasterControl } from "../../../Services/commonService";
import styles from "./editRatingModal.module.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const EditRatingModal = () => {
    const { userPersonalDetails } = useSelector((state) => state.persist);
    const { editRatingModal } = useSelector((state) => state.nopersist);
    const { editingRatingObj } = useSelector((state) => state.profile);

    const [rating, setRating] = useState(0);
    const [ratingArr, setRatingArr] = useState(new Array(5).fill(false));
    const [comment, setComment] = useState("");
    const handleRatingHover = (ind, clicking = false) => {
        if (!clicking) {
            if (rating < 1) {
                let x = new Array(5).fill(false);
                for (let i = 0; i <= ind; i++) {
                    x[i] = true;
                }
                setRatingArr([...x]);
            }
        } else {
            let x = new Array(5).fill(false);
            for (let i = 0; i <= ind; i++) {
                x[i] = true;
            }
            setRatingArr([...x]);
        }
    };

    const cleanRatingStars = () => {
        setRatingArr(new Array(5).fill(false));
    };

    const handleSubmitRating = async () => {
        const res = await postTReq(apiEndpoints.ratingUser, {
            rating_id: editingRatingObj.rating_id,
            remark: comment,
            rating: rating,
            reciever_id: editingRatingObj.remark_given_to_email_name,
        });
        if (res && !res.error) {
            cleanRatingStars();
            setComment("");
            getUserRatings(userPersonalDetails.emailAddress, 0, 10);
            modalControl("editRatingModal", false);
        } else {
            toasterControl("errorToaster", true, "An error occured");
            console.log(res.error);
        }
    };

    useEffect(() => {
        if (editingRatingObj) {
            setRating(editingRatingObj.rating);
            setComment(editingRatingObj.remark);
        }
    }, [editingRatingObj]);

    return (
        <Dialog open={editRatingModal} onClose={() => modalControl("editRatingModal", false)}>
            <DialogTitle>Edit Rating</DialogTitle>
            <DialogContent>
                <div className={styles.container}>
                    <div className={styles.writeReview}>
                        <div className="d-flex flex-column align-items-center">
                            <div className={styles.giveRating}>Give rating</div>
                            <div className="d-flex align-items-center">
                                {ratingArr.map((curElem, index) => {
                                    if (curElem) {
                                        return (
                                            <AiFillStar
                                                color="#f1bb46"
                                                fontSize={24}
                                                className={styles.star}
                                                onMouseOver={() => handleRatingHover(index)}
                                                onClick={() => {
                                                    setRating(index + 1);
                                                    handleRatingHover(index, true);
                                                }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <AiOutlineStar
                                                color="#000000"
                                                fontSize={24}
                                                className={styles.star}
                                                onMouseOver={() => handleRatingHover(index)}
                                                onClick={() => {
                                                    setRating(index + 1);
                                                    console.log(index);
                                                    handleRatingHover(index, true);
                                                }}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <textarea rows="3" placeholder="Write a review..." value={comment} onChange={(event) => setComment(event.target.value)} className="jobsifarish-textarea mb-2"></textarea>
                        <div className="js-submit-button-container">
                            <button onClick={handleSubmitRating}>Submit</button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditRatingModal;
