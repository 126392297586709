export const liveSessionValidationObj = {
    meet_start_time: {
        required: true,
    },
    meet_end_time: {
        required: true,
    },
    message: {
        required: true,
    },
    meeting_url: {
        required: true,
    },
    comment: {
        required: false,
    },
    meetDate: {
        required: true,
    },
};

export const contactUsValidation = {
    fullName: {
        required: true,
    },
    emailAddress: {
        required: true,
    },
    mobileNumber: {
        required: false,
    },
    subject: {
        required: true,
    },
    query: {
        required: true,
    },
};
