class HeaderUtility{
    token = localStorage.getItem('jwtToken');
    returnHeaderContainingToken=()=>{
        let header ={
            "Authorization":"Bearer "+this.token,
            "Content-type": "application/json; charset=UTF-8"
        }
        return header;
    }
    getHeaderForFileUpload(){
        return { "Authorization": "Bearer "+this.token }
    }
}

export default HeaderUtility;