import React from "react";
import styles from "./chatUsersContainer.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { clearUnreadChatsInConnection, selectChat } from "../../../Redux/Slices/chatSlice";
import { convertTimeStringToHHMM, getChatByUserEmail, getUnreadDataCount, readMessages } from "../../../Services/commonService";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { socket } from "../../../Services/socket";

const ChatUsersContainer = ({ userData, setPageNum, scrollChatToBottom }) => {
    const dispatch = useDispatch();
    const { selectedChat } = useSelector((state) => state.chatting);
    const { personalDetails } = useSelector((state) => state.persist);

    const openChat = () => {
        setPageNum(1);
        if (selectedChat && selectedChat.userId) {
            socket.emit("leaveRoom", {
                receiverUserId: selectedChat.userId,
                senderUserId: personalDetails.userId,
            });
        }
        getUnreadDataCount();
        dispatch(selectChat(userData));
        getChatByUserEmail(userData.emailAddress, 0, 10);
        dispatch(clearUnreadChatsInConnection(userData.emailAddress));
        socket.emit("joinRoom", {
            receiverUserId: userData.userId,
            senderUserId: personalDetails.userId,
        });
        dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
        readMessages(userData.emailAddress);
        if (scrollChatToBottom) {
            scrollChatToBottom();
        }
    };

    return (
        <div className={`${styles.eachChat} ${selectedChat.emailAddress === userData.emailAddress ? styles.active : ""}`} onClick={openChat}>
            <div style={{ width: "3.5rem" }}>
                <div className={styles.profileImageContainer}>
                    {userData && userData.profileImageUrl ? <img src={userData.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={40} color="#dfdfdf" />}
                </div>
            </div>
            <div className={styles.chatUserContent}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={styles.userName}>
                        <div className={styles.fullName}>{userData && userData.fullName && `${userData.fullName}`}</div>
                        <div className={styles.companyName}>{`${userData && userData.companyName ? `${userData.companyName}` : ""}`}</div>
                    </div>
                    {userData.last_message_sent_time > 0 && <div className={styles.time}>{convertTimeStringToHHMM(userData.last_message_sent_time)}</div>}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={styles.lastMessage}>{userData && userData.last_message && userData.last_message}</div>
                    {userData && userData.un_read_message_count ? (
                        userData.un_read_message_count > 0 && <div className={styles.notificationCounter}>{userData.un_read_message_count}</div>
                    ) : userData.unreadMessages !== undefined && userData.unreadMessages.length > 0 ? (
                        <div className={styles.notificationCounter}>{userData.unreadMessages.length}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ChatUsersContainer;
