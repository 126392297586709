import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import ImageContent from "../../content/ImageContent";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";
import { DataUtility } from "../../utility/DataUtility";

const ResumeNew3 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let imageContent = new ImageContent();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          var skillsset = res.data.skillset ? res.data.skillset : [];
          setSkillList(skillsset);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  let profileUrl = !personalData?.profileImageUrl ? imageContent.getProfilemage() : `https://jobsifarish.s3.ap-south-1.amazonaws.com/${personalData?.profileImageUrl}`;

  return (
    <React.Fragment>
      <div
        className="Containe_new_resume_3"
        style={{
          border: "1px solid grey",
          backgroundImage: " url('https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+92.svg')",
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          fontSize: "14px",
          fontFamily: "'Poppins', sans-serif",
          paddingTop: "0px",
          paddingRight: "0px",
          paddingBottom: "0px",
          paddingLeft: "0px",
        }}
      >
        <div className="ro_w pt_5" style={{ display: "flex", paddingTop: "1.5rem", paddingLeft: "1.5rem" }}>
          <div className="col_7" style={{ width: "58.33%", paddingTop: "80px" }}>
            <div className="resume3_new_contact_Info text_center pt_5" style={{ fontSize: "15px", paddingTop: "50px", textAlign: "center" }}>
              <h2 className="font-weight-bold" style={{ textTransform: "uppercase", fontFamily: "'Lato', sans-serif", fontWeight: "600", fontSize: "26px" }}>
                {personalData && personalData.firstName} {personalData && personalData.lastName}
              </h2>
              <h4 style={{ fontFamily: "'Lato', sans-serif", fontWeight: "400", fontSize: "15px" }}>{professionalData.length > 0 ? professionalData[0].designation.toUpperCase() : ""}</h4>
              <div className="mt_5 d_flex justify-content_Start" style={{ display: "flex", justifyContent: "flex-start", marginTop: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
                <span className="d_flex ml_4" style={{ display: "flex", marginLeft: "1.2rem" }}>
                  <span className="fa_phone mr_2" style={{ marginRight: "0.50rem", fontSize: "13px" }} aria-hidden="true"></span>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+63.svg" alt="MObileNUm" /> &emsp; {personalData && personalData.mobileNumber}
                </span>
                <span className="d_flex ml_4" style={{ marginLeft: "1.2rem", display: "flex" }}>
                  <span className="fa_envelope mr_2" style={{ marginRight: "0.6rem", fontSize: "13px" }} aria-hidden="true"></span>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+68.svg" alt="Email" /> &emsp; {personalData && personalData.emailAddress}
                </span>
              </div>
              <div className="mt_3 d_flex justify-content_Start" style={{ display: "flex", marginTop: ".8rem", justifyContent: "flex-start", fontFamily: "'Lato', sans-serif" }}>
                <span className="d_flex ml_4" style={{ display: "flex", marginLeft: "1.2rem" }}>
                  <span className="fa_marker mr_2" style={{ marginRight: "0.6rem", fontSize: "13px" }} aria-hidden="true"></span>
                  <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+60.svg" alt="address" /> &emsp; {personalData && personalData.address}
                </span>
              </div>
            </div>
          </div>
          <div className="col_5" style={{ width: "41.67%" }}>
            <div className="profile_image_resume_New_3">
              <img
                className="mr_2"
                style={{ marginRight: "0.75rem", width: "200px", height: "200px", objectFit: "cover", borderRadius: "50%", border: "5px solid white" }}
                src={profileUrl}
                alt="profileimage"
              />
            </div>
          </div>
        </div>
        <div className="ro_w d_flex" style={{ display: "flex", fontFamily: "'Lato', sans-serif" }}>
          <div className="col_6" style={{ width: "50%", borderRight: "2px solid #BAD1D8", fontFamily: "'Lato', sans-serif", paddingLeft: "1.5rem" }}>
            {personalData && personalData.about_me && (
              <div className="resume3_new_Heading" style={{ marginTop: "1.30rem" }}>
                <h6
                  style={{
                    backgroundColor: "#bad1d8",
                    maxWidth: "70%",
                    paddingTop: ".3rem",
                    paddingRight: ".625rem",
                    paddingBottom: ".3rem",
                    paddingLeft: ".625rem",
                    fontFamily: "'Lato', sans-serif",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  PROFILE
                </h6>
                <p className="resume_3_new_text_Color mt_3" style={{ marginTop: ".80rem", color: "#3d3d3d", fontSize: "16px" }}>
                  {personalData.about_me}
                </p>
              </div>
            )}
            <div className="resume3_new_Heading mt_4" style={{ marginTop: "1.20rem" }}>
              <h6
                style={{
                  backgroundColor: "#bad1d8",
                  maxWidth: "70%",
                  paddingTop: "4px",
                  paddingRight: "10px",
                  paddingBottom: "4px",
                  paddingLeft: "10px",
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                EDUCATION
              </h6>
              {educationData.length > 0
                ? educationData.map((res, index) => (
                    <Fragment key={index}>
                      <span className="d_flex justify-content_Between mt_3" style={{ fontSize: "16px", display: "flex", justifyContent: "space-between", marginTop: "0.80rem", fontWeight: "400" }}>
                        <span className="sb_Heading" styele={{ fontSize: "16px", fontWeight: "400" }}>
                          &#10095; &nbsp; {res.schoolName}{" "}
                        </span>
                        <span style={{ paddingRight: "1.50rem" }}>
                          {res.session_start} - {res.session_end}
                        </span>
                      </span>
                      <p className="resume_3_new_text_Color pl_4" style={{ paddingLeft: "1.20rem", color: "#3d3d3d" }}>
                        <span className="mr_2" style={{ marginRight: ".60rem", fontWeight: "350", textTransform: "uppercase" }}>
                          {res.educationType}
                        </span>
                        from {res.boardName} <br />
                        with {getPercentage(res.scoredMarks, res.totalMarks)} %
                      </p>
                    </Fragment>
                  ))
                : null}
            </div>
            {skillList.length > 0 && (
              <div className="resume3_new_Heading mt_4" style={{ marginTop: "1rem" }}>
                <h6
                  style={{
                    backgroundColor: "#bad1d8",
                    maxWidth: "70%",
                    paddingTop: "4px",
                    paddingRight: "10px",
                    paddingBottom: "4px",
                    paddingLeft: "10px",
                    fontFamily: "'Lato', sans-serif",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  SKILLS
                </h6>
                {skillList.length > 0
                  ? skillList.map((res, index) => (
                      <div
                        className="Software_Resume9 pb_1"
                        style={{ paddingBottom: "0.15rem", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "20px" }}
                        key={index}
                      >
                        <div className="Software_Name">{res.skill.toUpperCase()}</div>
                        <div className="software_Dots" style={{ fontSize: "25px", marginLeft: "8px" }}>
                          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                            <span>&#8226;</span>
                          ))}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          </div>
          <div className="col_6" style={{ width: "50%", fontFamily: "'Lato', sans-serif", marginLeft: "1.25rem" }}>
            <div className="resume3_new_Heading" style={{ marginTop: "1.30rem" }}>
              <h6
                style={{
                  backgroundColor: "#bad1d8",
                  maxWidth: "70%",
                  paddingTop: "4px",
                  paddingRight: "10px",
                  paddingBottom: "4px",
                  paddingLeft: "10px",
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                WORK EXPERIENCE
              </h6>
              {professionalData.length > 0
                ? professionalData.map((res, index) => (
                    <Fragment key={index}>
                      <span className="d_flex justify-content_Start mt_3" style={{ fontSize: "16px", display: "flex", justifyContent: "flex-start", marginTop: "0.80rem" }}>
                        <span className="sb_Heading" style={{ fontSize: "16px", fontWeight: "400" }}>
                          &#10095; &nbsp; {res.designation} <br />
                          <span className="pl_4" style={{ paddingLeft: "1.rem" }}>
                            {res.companyName}
                          </span>
                        </span>
                        <span className="pl_2" style={{ paddingTop: "3px", paddingLeft: "1.5rem" }}>
                          ({DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                          {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}){" "}
                        </span>
                      </span>
                      <span className="resume_3_new_text_Color pl_4" style={{ paddingLeft: "1.rem", color: "#3d3d3d" }}>
                        Projects :- <br />{" "}
                        {res.projects_details.map((response, key) => (
                          <Fragment key={response.project_id}>
                            {key + 1}. {response.project_name} - {response.role_played} <br />
                            Tech Used - {response.tech_used} <br />
                            Project Url - {response.project_prod_url} <br />
                            {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                            <br />
                          </Fragment>
                        ))}
                      </span>
                    </Fragment>
                  ))
                : null}
            </div>
            <div className="resume3_new_Heading mt_4" style={{ marginTop: "1.25rem" }}>
              <h6
                className="mb_2"
                style={{
                  marginBottom: "0.6rem",
                  backgroundColor: "#bad1d8",
                  maxWidth: "70%",
                  paddingTop: "4px",
                  paddingRight: "10px",
                  paddingBottom: "4px",
                  paddingLeft: "10px",
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                ORGANIZATION
              </h6>
              {professionalData.length > 0
                ? professionalData.map((res, index) => (
                    <Fragment key={index}>
                      <span className="sb_Heading" style={{ fontWeight: "400", fontSize: "16px" }}>
                        &#10095; &nbsp;{res.companyName} - {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                      </span>
                      <br />
                      <span className="pl_3" style={{ paddingLeft: "0.75rem" }}>
                        {res.designation}
                      </span>
                    </Fragment>
                  ))
                : null}
            </div>
            {personalData && personalData.achievement_details.length > 0 && (
              <div className="resume3_new_Heading mt_4" style={{ marginTop: "1rem" }}>
                <h6
                  style={{
                    marginBottom: "0.6rem",
                    backgroundColor: "#bad1d8",
                    maxWidth: "70%",
                    paddingTop: "4px",
                    paddingRight: "10px",
                    paddingBottom: "4px",
                    paddingLeft: "10px",
                    fontFamily: "'Lato', sans-serif",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  HONOURS/AWARDS
                </h6>
                {personalData.achievement_details.map((item, id) => (
                  <>
                    <span className="pl_3" style={{ paddingLeft: ".80rem" }} key={item.achievement_id}>
                      {id + 1}- {item.achievement}
                    </span>
                    <br />
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeNew3;
