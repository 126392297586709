import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { handleGetProfileAndGoThere, modalControl } from "../../../Services/commonService";
import styles from "./jobApplicantsModal.module.css";
import { useNavigate } from "react-router-dom";

const JobApplicantsModal = () => {
    const navigateTo = useNavigate();
    const { jobApplicantsModal } = useSelector((state) => state.nopersist);
    const { jobApplicants } = useSelector((state) => state.jobslice);

    const handleNavigate = (curElem) => {
        modalControl("jobApplicantsModal", false);
        handleGetProfileAndGoThere(curElem, navigateTo);
    };

    return (
        <Dialog open={jobApplicantsModal} onClose={() => modalControl("jobApplicantsModal", false)}>
            <DialogTitle>Applicants</DialogTitle>
            <DialogContent>
                <div className={styles.container}>
                    <div className="d-flex flex-wrap">
                        {jobApplicants &&
                            jobApplicants.map((curElem) => {
                                return (
                                    <div className={styles.extraDataBox} onClick={() => handleNavigate(curElem)}>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className={styles.uploaderImage}>{curElem && curElem.profileImageUrl && <img src={curElem.profileImageUrl} alt="" />}</div>
                                            <div>
                                                <div className={styles.uploaderName}>{curElem && curElem.fullName}</div>
                                                <div className={styles.uploaderDesignation}>{curElem && curElem.job_profile_name}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center w-100 flex-wrap">
                                            <div className="d-flex w-100 align-items-center">
                                                <div className={`${styles.title} me-1`}>Email:</div>
                                                <div className={styles.dataVal}>{curElem.emailAddress}</div>
                                            </div>
                                            <div className="d-flex w-100 align-items-center">
                                                <div className={`${styles.title} me-1`}>Message:</div>
                                                <div className={styles.dataVal}>{curElem.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default JobApplicantsModal;
