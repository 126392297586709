import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../style/Modal.css";
import ImageContent from "../../content/ImageContent";
import { ValidationUtility } from "../../utility/ValidationUtility";
import { ValidationType } from "../../enum/ValidationType";
import ServiceProvider from "../../provider/serviceProvider";
import ErrorModal from "./ErrorModal";
import styles from "./registrationForm.module.css";

const RegistrationForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  // const [cPwd, setCpwd] = useState("");

  let [cPwdError, setCpwdError] = useState("");
  const [mobile, setMobile] = useState("");
  // const [registerError, setRegisterError] = useState();
  const [registerError] = useState();
  // const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabled] = useState(false);
  const [spinner] = useState(false);
  // const [spinner, setSpinner] = useState(false);
  const imageContent = new ImageContent();
  const serviceProvider = new ServiceProvider();
  let [show, setShow] = useState({
    show: false,
    message: "",
  });
  function handleFirstNameInput(value) {
    setFirstName(value);
    ValidationUtility.validateElement("firstName", ValidationType.Text, true, "firstNameError", "First Name");
  }
  function handleLastNameInput(value) {
    setLastName(value);
    ValidationUtility.validateElement("lastName", ValidationType.Text, true, "lastNameError", "Last Name");
  }
  function handleMiddleNameInput(value) {
    setMiddleName(value);
    // ValidationUtility.validateElement("middleName", ValidationType.Text, true, "middleNameError", "Middle Name");
  }
  function handleEmailInput(value) {
    setEmail(value);
    onEmailValidation(value);
    ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email");
  }
  function handleMobileInput(value) {
    setMobile(value);
    ValidationUtility.validateElement("mob", ValidationType.Mobile, true, "mobError", "Mobile");
  }
  function handlePasswordInput(value) {
    setPwd(value);
    ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password");
  }
  function handleConfirmPasswordInput(value) {
    // setCpwd(value);
    validatePassword(value);
    ValidationUtility.validateElement("cPwd", ValidationType.Text, true, "cPwdError", "Confirm Password");
  }
  const validatePassword = (value) => {
    if (pwd === value && pwd.length === value.length) {
      console.log(value + " " + pwd);
      cPwdError = "";
      setCpwdError("");
    } else {
      cPwdError = "Password didn't match";
      setCpwdError("Password didn't match");
    }
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };
  const generateInputJsonForSignUp = () => {
    let userInfo = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      emailAddress: email,
      mobileNumber: mobile,
      password: pwd,
    };
    return userInfo;
  };
  const generateInputJsonForEmailValidation = (value) => {
    let inputJson = {
      emailAddress: value,
    };
    return inputJson;
  };
  const onEmailValidation = (value) => {
    let emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(value)) {
      let authService = serviceProvider.returnAuthenticationService();
      authService.onEmailValidation(generateInputJsonForEmailValidation(value)).then((res) => {
        if (res.status === "200 OK") {
          setEmailValid(!res.data.isAvailable);
        }
      });
    }
    setEmail(value);
  };
  const onSignUp = () => {
    validateOnSignUp().then(() => {
      let authService = serviceProvider.returnAuthenticationService();
      authService.onSignUp(generateInputJsonForSignUp()).then((res) => {
        if (res.message === "User Registered SuccessFully") {
          props.manageModal("L");
        } else {
          setShow({ ...show, show: !show.show, message: res["error message"] });
        }
      });
    });
    typeof props.onCLoseSideNav === "function" && props.onCLoseSideNav();
  };
  const onCloseModal = () => {
    props.handleClose();
    props.manageModal("L");
  };

  return (
    <div>
      <img className="crossicon" alt="cross" onClick={onCloseModal} src={imageContent.getCrossImg()} />
      <div className={`text-center ${styles.heading}`}>Create Account</div>

      <Form>
        <span className="errorMessageRegister">{registerError}</span>
        <div className="row pt-4">
          <div className="col-4">
            <Form.Control
              className="rounded-pill"
              onKeyDown={ValidationUtility.onKeyUpText}
              id="firstName"
              type="text"
              placeholder="Enter First Name"
              onChange={(e) => handleFirstNameInput(e.target.value)}
            />
            <span className="text-danger" id="firstNameError"></span>
          </div>
          <div className="col-4">
            <Form.Control
              className="rounded-pill"
              onKeyDown={ValidationUtility.onKeyUpText}
              id="middleName"
              type="text"
              placeholder="Enter Middle Name"
              onChange={(e) => handleMiddleNameInput(e.target.value)}
            />
            <span className="text-danger" id="middleNameError"></span>
          </div>
          <div className="col-4">
            <Form.Control
              className="rounded-pill"
              onKeyDown={ValidationUtility.onKeyUpText}
              id="lastName"
              type="text"
              placeholder="Enter Last Name"
              onChange={(e) => handleLastNameInput(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Control
              className="rounded-pill"
              id="mob"
              type="text"
              onKeyDown={ValidationUtility.onkeyUpNumber}
              onInput={maxLengthCheck}
              pattern="\d*"
              maxLength={10}
              onChange={(e) => handleMobileInput(e.target.value)}
              placeholder="Enter Mobile"
            />
            <span className="text-danger" id="mobError"></span>
          </div>
          <div className="col-5 pr-0">
            <Form.Control className="rounded-pill" id="email" type="email" placeholder="Enter Email" onChange={(e) => handleEmailInput(e.target.value)} />
            <span className="text-danger" id="emailError"></span>
          </div>
          <div className="col-1 pl-0 pt-1">
            {!emailValid ? (
              <span style={{ color: "red" }} className="float-left">
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            ) : (
              <span style={{ color: "green" }} className="float-left">
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Control
              className="rounded-pill"
              id="pwd"
              type="password"
              onChange={(e) => {
                handlePasswordInput(e.target.value);
              }}
              placeholder="Password"
            />
            <span className="text-danger" id="pwdError"></span>
          </div>
          <div className="col-6">
            <Form.Control
              className="rounded-pill"
              id="cPwd"
              type="password"
              disabled={pwd === "" ? true : false}
              onChange={(e) => {
                handleConfirmPasswordInput(e.target.value);
              }}
              placeholder="Confirm Password"
            />
            <span className="text-danger" id="cPwdError"></span>
            <span className="text-danger">{cPwdError}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-3"></div>
          <div className="col-6 pt-3 text-center">
            <Button className="rounded-pill" onClick={onSignUp} disabled={isDisabled} variant="primary" size="lg" block>
              {!spinner ? (
                "Continue"
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </Button>
          </div>
          <div className="col-3"></div>
        </div>
        {/* <div className="col-sm-3"></div>
        <h6 className="newuser">
          <b>If you are an existing user ?</b>
        </h6>
        <h6 className="newuserReg">
          <a
            onClick={() => {
              props.manageModal("L");
            }}
            className="link"
          >
            <b>Login</b>
          </a>
          <b> Here</b>
        </h6> */}
      </Form>
      <ErrorModal
        icon="text-danger"
        title="Warning"
        message={show.message}
        isShow={show.show}
        handleClose={() => {
          setShow({ ...show, show: !show.show });
        }}
      />
    </div>
  );
};

const validateOnSignUp = () => {
  let responseArray = [];
  responseArray.push(ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email"));
  responseArray.push(ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password"));
  responseArray.push(ValidationUtility.validateElement("mob", ValidationType.Mobile, true, "mobError", "Mobile"));
  responseArray.push(ValidationUtility.validateElement("firstName", ValidationType.Text, true, "firstNameError", "First Name"));
  responseArray.push(ValidationUtility.validateElement("lastName", ValidationType.Text, true, "lastNameError", "Last Name"));
  // responseArray.push(ValidationUtility.validateElement("middleName", ValidationType.Text, true, "middleNameError", "Middle Name"));
  responseArray.push(ValidationUtility.validateElement("cPwd", ValidationType.Text, true, "cPwdError", "Confirm Password"));
  let errors = responseArray.filter((x) => {
    return !x.isValid;
  });
  return new Promise((resolve, reject) => {
    errors?.length > 0 ? reject(false) : resolve(true);
  });
};

export default RegistrationForm;
