import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalControl, toasterControl } from "../../../Services/commonService";
import { saveChangePasswordOtp } from "../../../Redux/Slices/noPersistSlice";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const EnterNewPasswordChangeModal = () => {
    const dispatch = useDispatch();
    const { enterNewPasswordChangeModal, changePasswordOtp } = useSelector((state) => state.nopersist);

    const [passwordText, setPasswordText] = useState("");
    const [confirmPasswordText, setConfirmPasswordText] = useState("");

    const closeModal = () => {
        dispatch(saveChangePasswordOtp(""));
        setPasswordText("");
        setConfirmPasswordText("");
        modalControl("enterNewPasswordChangeModal", false);
    };

    const [cnfPasswordErr, setCnfPasswordErr] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false);

    const submitNewPassword = async () => {
        if (passwordText && confirmPasswordText) {
            if (!passwordErr && !cnfPasswordErr) {
                const res = await postTReq(apiEndpoints.changeToNewPassword, {
                    otp: changePasswordOtp,
                    password: passwordText,
                });
                if (res && !res.error) {
                    toasterControl("successToaster", true, "Your password has been changed.");
                    closeModal();
                } else {
                    console.log(res.error);
                }
            }
        } else {
            toasterControl("errorToaster", true, "Please enter a new password");
        }
    };

    useEffect(() => {
        if (passwordText === confirmPasswordText) {
            setCnfPasswordErr(false);
        } else {
            setCnfPasswordErr(true);
        }
    }, [passwordText, confirmPasswordText]);
    return (
        <Dialog open={enterNewPasswordChangeModal} onClose={closeModal}>
            <DialogTitle>Enter New Password</DialogTitle>
            <DialogContent>
                <div className="d-flex flex-column">
                    <input
                        type="text"
                        className={`jobsifarish-input2 ${!passwordErr ? "mb-2" : ""}`}
                        style={{ width: "20rem" }}
                        placeholder="Enter New Password"
                        value={passwordText}
                        onChange={(event) => {
                            if (event.target.value.length >= 8) {
                                setPasswordErr(false);
                            } else {
                                setPasswordErr(true);
                            }
                            setPasswordText(event.target.value);
                        }}
                    />
                    {passwordErr && <div className="input-error-text">Password should be at least 8 characters long</div>}
                    <input
                        type="text"
                        className="jobsifarish-input2"
                        style={{ width: "20rem" }}
                        placeholder="Confirm Password"
                        value={confirmPasswordText}
                        onChange={(event) => setConfirmPasswordText(event.target.value)}
                    />
                    {cnfPasswordErr && <div className="input-error-text">Passwords do not match</div>}
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <button className="normal-disabled-button me-1" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="normal-button" onClick={submitNewPassword}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EnterNewPasswordChangeModal;
