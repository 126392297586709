import React from "react";
import styles from "./userEducationBox.module.css";
import { useSelector } from "react-redux";
import { convertDateMMYYYY } from "../../../../Services/commonService";
import { BiSolidInstitution } from "react-icons/bi";
import { AiOutlinePercentage } from "react-icons/ai";
import { LiaUniversitySolid } from "react-icons/lia";
import { FaLocationDot } from "react-icons/fa6";

const EducationBox = () => {
    const { userEducationalDetails } = useSelector((state) => state.persist);

    return (
        <div className={`content-card`}>
            <div className="content-card-heading">Education</div>
            {userEducationalDetails && userEducationalDetails.length > 0 ? (
                userEducationalDetails.map((curElem) => {
                    return (
                        <div className={styles.educationCard}>
                            <div className={styles.line1}>
                                <div className={styles.educationType}>{curElem.educationType}</div>
                                <div className={styles.sessionDuration}>
                                    <div className={styles.sessionDate}>{convertDateMMYYYY(curElem.session_start)}</div>
                                    <div style={{ fontSize: "14px", margin: "0 0.5rem" }}>to</div>
                                    <div className={styles.sessionDate}>{convertDateMMYYYY(curElem.session_end)}</div>
                                </div>
                            </div>
                            <div className={`${styles.line2} mt-2`}>
                                <div className="d-flex align-items-center">
                                    <BiSolidInstitution color="#f1bb46" />
                                    <div style={{ fontSize: "14px", fontWeight: "500", marginLeft: "0.5rem" }}>{curElem.schoolName}</div>
                                </div>
                                <div className="d-flex align-items-center" style={{ fontSize: "14px" }}>
                                    <AiOutlinePercentage color="#f1bb46" />
                                    <div className="ms-2">
                                        {curElem.scoredMarks}/{curElem.totalMarks}
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.line2} mt-1`}>
                                <div className="d-flex align-items-center">
                                    <LiaUniversitySolid color="#f1bb46" />
                                    <div style={{ fontSize: "14px", fontWeight: "500", marginLeft: "0.5rem" }}>{curElem.boardName}</div>
                                </div>
                                <div className="d-flex align-items-center" style={{ fontSize: "14px" }}>
                                    <FaLocationDot color="#f1bb46" />
                                    <div className="ms-2">{curElem.location}</div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="wide-nothing-to-show">Nothing to show</div>
            )}
        </div>
    );
};

export default EducationBox;
