import React, { useEffect, useState } from "react";
import styles from "./addWorkExperienceModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { Modal } from "react-bootstrap";
import { getProfessionalDetails } from "../../../Services/commonService";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { Checkbox } from "@mui/material";
import DatePicker from "react-datepicker";

const AddWorkExperienceModal = () => {
    const dispatch = useDispatch();
    const { addWorkExperienceModal } = useSelector((state) => state.nopersist);

    const currentMonth = new Date().toISOString().slice(0, 7);

    const [currentWorking, setCurrentWorking] = useState(false);

    const initialData = {
        companyName: "",
        designation: "",
        location: "",
        joiningType: "",
        joined_from: "",
        worked_till: "",
        description: "",
    };
    const [inputData, setInputData] = useState(initialData);

    const handleInput = (event, index) => {
        const { name, value } = event.target;
        if (index !== undefined) {
            let x = JSON.parse(JSON.stringify(inputData));
            x["project_details"][index][name] = value;
            setInputData(x);
        } else {
            if (name === "joined_from" || name === "worked_till") {
                setInputData((prev) => {
                    return {
                        ...prev,
                        [name]: value.getTime(),
                    };
                });
            } else {
                setInputData((prev) => {
                    return {
                        ...prev,
                        [name]: value,
                    };
                });
            }
        }
    };

    const handleSubmit = async () => {
        let x = JSON.parse(JSON.stringify(inputData));
        [x].forEach((curElem) => {
            curElem["joined_from"] = new Date(curElem["joined_from"]).getTime();
            curElem["worked_till"] = curElem["worked_till"] ? new Date(curElem["worked_till"]).getTime() : 0;
            curElem.project_details &&
                curElem.project_details.forEach((cur) => {
                    cur["started_from"] = new Date(cur["started_from"]).getTime();
                    cur["end_on"] = new Date(cur["end_on"]).getTime();
                });
        });
        const res = await postTReq(apiEndpoints.updateProfessionalDetails, [x]);
        if (res && !res.error) {
            setInputData(initialData);
            dispatch(modalController({ modal: "addWorkExperienceModal", state: false }));
            getProfessionalDetails();
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        if (currentWorking) {
            setInputData((prev) => {
                return {
                    ...prev,
                    worked_till: "",
                };
            });
        }
    }, [currentWorking]);

    return (
        <Modal
            centered
            className="transparentModal"
            dialogClassName="d-flex justify-content-center"
            show={addWorkExperienceModal}
            size="lg"
            onHide={() => dispatch(modalController({ modal: "addWorkExperienceModal", state: false }))}
        >
            <Modal.Body className="p-0">
                <div className={styles.container}>
                    <div className={styles.headingContainer}>Add Work Experience</div>
                    <div className="px-3 w-100" style={{ height: "60vh", overflowY: "scroll" }}>
                        <div className="w-100 d-flex flex-wrap justify-content-between" style={{ height: "calc(100% - 2rem)", overflowY: "scroll" }}>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Company Name</div>
                                <input type="text" className="jobsifarish-input2" name="companyName" value={inputData.companyName} onChange={handleInput} />
                            </div>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Designation</div>
                                <input type="text" className="jobsifarish-input2" name="designation" value={inputData.designation} onChange={handleInput} />
                            </div>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Location</div>
                                <input type="text" className="jobsifarish-input2" name="location" value={inputData.location} onChange={handleInput} />
                            </div>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Joining Type</div>
                                <select className="jobsifarish-input2" name="joiningType" value={inputData.joiningType} onChange={handleInput}>
                                    <option value="">Select</option>
                                    <option value="Intern">Intern</option>
                                    <option value="Full Time">Full Time</option>
                                </select>
                            </div>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Joined from</div>
                                {/* <input type="month" className="jobsifarish-input2" name="joined_from" value={inputData.joined_from} max={inputData.worked_till || currentMonth} onChange={handleInput} /> */}
                                <DatePicker
                                    className="jobsifarish-input2"
                                    selected={inputData.joined_from}
                                    onChange={(date) => handleInput({ target: { name: "joined_from", value: date } })}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    maxDate={inputData.worked_till || currentMonth}
                                />
                            </div>
                            <div className="w-49 mb-2">
                                <div className="jobsifarish-input2-head">Worked till</div>
                                {/* <input
                                type="month"
                                className="jobsifarish-input2"
                                disabled={currentWorking}
                                name="worked_till"
                                value={inputData.worked_till}
                                min={inputData.joined_from}
                                max={currentMonth}
                                onChange={handleInput}
                            /> */}
                                <DatePicker
                                    className="jobsifarish-input2"
                                    selected={inputData.worked_till}
                                    onChange={(date) => handleInput({ target: { name: "worked_till", value: date } })}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    minDate={inputData.joined_from}
                                    maxDate={currentMonth}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <Checkbox id="currentlyWorking" checked={currentWorking} onChange={(event) => setCurrentWorking(event.target.checked)} />
                                <label htmlFor="currentlyWorking" className={styles.currentlyWorking}>
                                    Currently working
                                </label>
                            </div>
                            <div className="w-100 mb-2">
                                <div className="jobsifarish-input2-head">Description</div>
                                <textarea rows={3} type="text" className="jobsifarish-textarea" name="description" value={inputData.description} onChange={handleInput} />
                            </div>
                        </div>
                        <div className="w-100 mb-2">
                            <div className="w-100 d-flex justify-content-end">
                                <button className={styles.submitButton} onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddWorkExperienceModal;
