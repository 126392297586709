import store from "../Redux/store";
import { convertDateDDMMYYYY } from "./commonService";

export const keysForValidation = {
    email: {
        label: "Email",
        type: "Email",
    },
    pwd: {
        label: "Password",
        type: "Text",
    },
};

export const widthArr = ["100%", "49%", "32%", "24%"];

export const noFooterRoutes = ["/message"];

export const validateField = (field, value) => {
    const { validation } = field;

    if (validation.required && value.trim() === "") {
        return "Field is required";
    }
    if (validation.minLength && value.length < validation.minLength) {
        return `Minimum length is ${validation.minLength}`;
    }
    if (validation.maxLength && value.length > validation.maxLength) {
        return `Maximum length is ${validation.maxLength}`;
    }
    if (validation.pattern && !validation.pattern.test(value)) {
        return `Invalid ${field.label}`;
    }
    if (validation.matchTo) {
        let x = store.getState().nopersist.registrationData;
        if (value !== x[validation.matchTo]) {
            return "Passwords don't match";
        } else {
            return "";
        }
    }

    return "";
};

export const commonValidateField = (field, value) => {
    if (typeof value === "string") {
        if (field.required && value.trim() === "") {
            return "Field is required";
        }
    } else if (typeof value === "number") {
        if (field.required && value === 0) {
            return "Field is required";
        }
    }

    if (field.minLength && value.length < field.minLength) {
        return `Minimum length is ${field.minLength}`;
    }
    if (field.maxLength && value.length > field.maxLength) {
        return `Maximum length is ${field.maxLength}`;
    }
    if (field.pattern && !field.pattern.test(value)) {
        return `Invalid ${field.label}`;
    }

    return "";
};
export const validationErrorChecker = (dataObj, errorObj, validationObj) => {
    let newObj = errorObj;
    let fieldArray = Object.keys(validationObj).map((curElem) => {
        return curElem;
    });
    fieldArray.forEach((curElem) => {
        let valText = commonValidateField(validationObj[curElem], dataObj[curElem]);
        newObj[curElem] = valText;
    });
    if (
        Object.keys(newObj).length === 0 ||
        Object.values(newObj).some((curElem) => {
            return curElem !== "";
        })
    ) {
        return true;
    } else {
        return false;
    }
};

export const convertDateStringForDateInput = (timestring) => {
    return new Date(timestring).toISOString().slice(0, 10);
};

export const convertDateStringForMonthInput = (timestring) => {
    if (timestring) {
        return new Date(timestring).toISOString().slice(0, 7);
    } else {
        return "";
    }
};

export const getDurationString = (timeString) => {
    let currentTimeString = new Date().getTime();
    let timeDifferenceUnix = currentTimeString - timeString;
    let timeDifferenceHours = timeDifferenceUnix / 1000 / 60 / 60;
    let timeDifferenceDays = Math.floor(timeDifferenceHours / 24);
    let dateString = convertDateDDMMYYYY(timeString);
    if (timeDifferenceHours < 24) {
        return "Today";
    } else if (timeDifferenceHours > 24 && timeDifferenceHours < 48) {
        return "1 day ago";
    } else if (timeDifferenceDays < 30) {
        return `${timeDifferenceDays} days ago`;
    } else {
        return `${dateString}`;
    }
};

export const getPercentage = (num1, total) => {
    let percent = ((parseFloat(num1 || "0") / parseFloat(total)) * 100).toFixed(2);
    return percent;
};

export const openLoginModalWhenNotLoggedIn = () => {
    let element = document.getElementById("loginRegister");
    if (element !== null) {
        element.click();
    }
};
