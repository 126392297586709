import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginDataErrorSetter, loginDataSetter, modalController } from "../../../Redux/Slices/noPersistSlice";
import styles from "./loginModal.module.css";
import { BiLogoFacebook } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import AuthContext from "../../../context/authContext";
// import ServiceProvider from "../../../provider/serviceProvider";
import { useNavigate } from "react-router";
// import { ValidationUtility } from "../../../utility/ValidationUtility";
// import { ValidationType } from "../../../enum/ValidationType";
import { loginForm } from "../../../Services/dynamicForms";
import { validateField, widthArr } from "../../../Services/commons";
import { apiEndpoints, navigationRoutes } from "../../../PrEnums/prEnums";
import { getReq, postReq } from "../../../Services/api";
import { toasterController } from "../../../Redux/Slices/toasterSlice";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../Services/firebase";
import { getChatHistory, modalControl, toasterControl } from "../../../Services/commonService";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const LoginModal = () => {
    const dispatch = useDispatch();
    const { loginModal, loginData, loginDataError } = useSelector((state) => state.nopersist);
    const { actionToPerformAfterLogin } = useSelector((state) => state.general);
    // const serviceProvider = new ServiceProvider();
    const navigateTo = useNavigate();

    let context = useContext(AuthContext);

    const [goForward, setGoForward] = useState(false);

    // const [email] = useState("");
    // const [pwd] = useState("");

    // const onEmailInput = (value) => {
    //   setEmail(value);
    //   ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email");
    // };
    // const onPasswordInput = (value) => {
    //   setPwd(value);
    //   ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password");
    // };

    const handleInput = async (event, field) => {
        const { name, value } = event.target;
        dispatch(loginDataSetter({ fieldName: name, value: value }));

        const errorMessage = validateField(field, value);
        dispatch(loginDataErrorSetter({ fieldName: `${name}_error`, value: errorMessage }));
    };

    const handleSubmit = async () => {
        if (goForward) {
            let payload = JSON.parse(JSON.stringify(loginData));
            const res = await postReq(`${apiEndpoints.login}`, payload);
            if (res && !res.error) {
                signInWithEmailAndPassword(auth, loginData.emailAddress, loginData.password)
                    .then((userData) => {
                        let user = userData.user;
                        console.log(user);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                const profCompleteRes = await getReq(`${apiEndpoints.validateProfile}`, {}, { Authorization: `Bearer ${res.data.data["Authorization-Token"]}` });
                if (profCompleteRes && !profCompleteRes.error) {
                    localStorage.setItem("jwtToken", res.data.data["Authorization-Token"]);
                    localStorage.setItem("auth", "true");
                    localStorage.setItem("userId", res.data.data.userId);
                    context.setAuthenticationStatus(true);
                    getChatHistory(0, 10);
                    if (actionToPerformAfterLogin && Object.keys(actionToPerformAfterLogin).length > 0) {
                        dispatch(modalController({ modal: "loginModal", state: false }));
                    } else {
                        if (profCompleteRes.data.data.is_profile_completed) {
                            navigateTo(navigationRoutes.home);
                            dispatch(modalController({ modal: "loginModal", state: false }));
                        } else {
                            navigateTo(navigationRoutes.profile);
                            dispatch(modalController({ modal: "loginModal", state: false }));
                        }
                    }
                } else {
                    console.log(res.error);
                    dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
                }
            } else {
                if (res.error.response.data["error message"] === "Bad credentials") {
                    toasterControl("errorToaster", true, "Invalid Credentials");
                } else {
                    dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
                }
                console.log(res.error);
            }
        } else {
            loginForm.fields.forEach((curElem) => {
                let name = curElem.identifier;
                let value = loginData[curElem.identifier] ? loginData[curElem.identifier] : "";

                const errorMessage = validateField(curElem, value);
                dispatch(loginDataErrorSetter({ fieldName: `${name}_error`, value: errorMessage }));
            });
        }
    };

    // const generateInputJsonForLogin = () => {
    //     let inputJson = {
    //         emailAddress: email,
    //         password: pwd,
    //     };
    //     return inputJson;
    // };

    // const isUserProfileValidated = (header) => {
    //     serviceProvider
    //         .returnProfileService()
    //         .validateUserProfile(header)
    //         .then((res) => {
    //             if (res.status === "200 OK") {
    //                 if (res.data.is_profile_completed) {
    //                     navigateTo("/");
    //                 } else {
    //                     navigateTo("/createProfile");
    //                 }
    //             }
    //         });
    // };

    // const onLoginRequest = () => {
    //     validateOnLOgin().then(() => {
    //         let authService = serviceProvider.returnAuthenticationService();
    //         authService.onLogin(generateInputJsonForLogin()).then((res) => {
    //             if (res.status === "200 OK" && res.data) {
    //                 let token = res.data["Authorization-Token"];
    //                 localStorage.setItem("jwtToken", token);
    //                 localStorage.setItem("auth", "true");
    //                 context.setAuthenticationStatus(true);
    //                 let header = {
    //                     Authorization: "Bearer " + token,
    //                     "Content-type": "application/json; charset=UTF-8",
    //                 };
    //                 getUserFirstAndLastName(header);
    //                 isUserProfileValidated(header);
    //             } else {
    //                 //   setShow({ ...show, show: !show.show, message: res["error message"] });
    //                 console.log(res["error message"]);
    //             }
    //         });
    //     });
    // };
    // const getUserFirstAndLastName = (header) => {
    //     let profileService = serviceProvider.returnProfileService();
    //     profileService
    //         .getPersonalDetail(header)
    //         .then((res) => {
    //             if (res.status === "200 OK") {
    //                 localStorage.setItem("fname", res.data.firstName);
    //                 localStorage.setItem("lname", res.data.lastName);
    //                 localStorage.setItem("email", res.data.emailAddress);
    //                 context.setprofilePic(res.data.profileImageUrl);
    //                 localStorage.setItem("profileUrl", res.data.profileImageUrl);
    //                 context.setFirstName(res.data.firstName);
    //                 context.setLastName(res.data.lastName);
    //                 context.setEmail(res.data.emailAddress);
    //             }
    //         })
    //         .catch((err) => {})
    //         .finally(() => {});
    // };

    // const validateOnLOgin = () => {
    //     let responseArray = [];
    //     responseArray.push(ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email"));
    //     responseArray.push(ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password"));
    //     let errors = responseArray.filter((x) => {
    //         return !x.isValid;
    //     });
    //     return new Promise((resolve, reject) => {
    //         errors?.length > 0 ? reject(false) : resolve(true);
    //     });
    // };

    const loginOnEnter = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    const [fieldType, setFieldType] = useState("password");

    const handleForgotPassword = () => {
        modalControl("loginModal", false);
        modalControl("forgotPasswordEnterEmailModal", true);
    };

    useEffect(() => {
        if (Object.values(loginData).length > 0) {
            let x = Object.values(loginDataError).some((curElem) => {
                return curElem && curElem.length > 0;
            });
            setGoForward(!x);
        } else {
            setGoForward(false);
        }
    }, [loginDataError, loginData]);

    return (
        <Modal centered className="transparentModal" dialogClassName="d-flex justify-content-center" show={loginModal} onHide={() => dispatch(modalController({ modal: "loginModal", state: false }))}>
            <Modal.Body className="p-0">
                <div className={styles.container}>
                    <div className={styles.headingContainer}>{loginForm.label}</div>
                    <div className="px-4 w-100">
                        {Object.keys(loginForm).length > 0 &&
                            loginForm.fields.map((curElem) => {
                                return curElem.type === "select" ? (
                                    <div className="d-flex flex-column align-items-start mb-3 position-relative" key={curElem.identifier} style={{ width: widthArr[curElem.fullWidth - 1] }}>
                                        <select
                                            className={loginDataError[`${curElem.identifier}_error`] ? "jobsifarish-input-error" : "jobsifarish-input"}
                                            name={curElem.identifier}
                                            value={loginData[curElem.identifier]}
                                            onChange={(event) => handleInput(event, curElem)}
                                        >
                                            <option>{curElem.label}</option>
                                            {curElem.options &&
                                                curElem.options.length > 0 &&
                                                curElem.options.map((cur) => {
                                                    return (
                                                        <option key={cur.sno} value={cur.iden}>
                                                            {cur.label}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                ) : curElem.type === "password" ? (
                                    <div className="d-flex flex-column align-items-start mb-3 position-relative" key={curElem.identifier} style={{ width: widthArr[curElem.fullWidth - 1] }}>
                                        <input
                                            type={fieldType}
                                            name={curElem.identifier}
                                            placeholder={curElem.label}
                                            className={loginDataError[`${curElem.identifier}_error`] ? "jobsifarish-input-error" : "jobsifarish-input"}
                                            onChange={(event) => handleInput(event, curElem)}
                                            onKeyDown={(event) => loginOnEnter(event)}
                                        />
                                        <div className={styles.iconBox}>{curElem.icon}</div>
                                        {fieldType === "password" ? (
                                            <div className={styles.eyeIcon} onClick={() => setFieldType("text")}>
                                                <AiFillEye />
                                            </div>
                                        ) : (
                                            <div className={styles.eyeIcon} onClick={() => setFieldType("password")}>
                                                <AiFillEyeInvisible />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column align-items-start mb-3 position-relative" key={curElem.identifier} style={{ width: widthArr[curElem.fullWidth - 1] }}>
                                        <input
                                            type={curElem.type}
                                            name={curElem.identifier}
                                            placeholder={curElem.label}
                                            className={loginDataError[`${curElem.identifier}_error`] ? "jobsifarish-input-error" : "jobsifarish-input"}
                                            onChange={(event) => handleInput(event, curElem)}
                                            onKeyDown={(event) => loginOnEnter(event)}
                                        />
                                        <div className={styles.iconBox}>{curElem.icon}</div>
                                    </div>
                                );
                            })}
                        <div className="w-100 d-flex justify-content-end">
                            <div className={styles.forgotPass} onClick={handleForgotPassword}>
                                Forgot password?
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center mt-3">
                            <button className={styles.loginButton} onClick={handleSubmit}>
                                Login
                            </button>
                        </div>
                        <div className="d-flex align-items-center justify-content-center py-3">
                            <div className={styles.line}></div>
                            <div style={{ fontSize: "12px", color: "#ffffff", width: "20%", display: "flex", justifyContent: "center" }}>OR</div>
                            <div className={styles.line}></div>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center mb-3">
                            <button className={styles.socialLogin}>
                                <FcGoogle />
                            </button>
                            <button className={styles.socialLogin}>
                                <BiLogoFacebook color="#3b5997" fontSize={20} />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center aling-items-center">
                            <div style={{ fontSize: "12px", marginRight: "0.25rem" }}>Don't have an account?</div>
                            <div
                                className={styles.registerHere}
                                onClick={() => {
                                    dispatch(modalController({ modal: "loginModal", state: false }));
                                    dispatch(modalController({ modal: "registrationModal", state: true }));
                                }}
                            >
                                Register here
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
