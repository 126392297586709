import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { modalControl, toasterControl } from "../../../Services/commonService";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { useSelector } from "react-redux";

const EnterOldPasswordModal = () => {
    const { enterOldPasswordModal } = useSelector((state) => state.nopersist);
    const [oldPasswordText, setOldPasswordText] = useState("");
    const submitOldPasswordAndGetOtp = async () => {
        const res = await postTReq(apiEndpoints.changeUserPassword, {
            old_password: oldPasswordText,
        });
        if (res && !res.error) {
            setOldPasswordText("");
            modalControl("enterOldPasswordModal", false);
            modalControl("changeEnterOtpModal", true);
        } else {
            console.log(res);
            if (res.error.response.data["error message"] === "Password Not matched") {
                toasterControl("errorToaster", true, "The password you have entered is incorrect");
            }
        }
    };
    const closeModal = () => {
        setOldPasswordText("");
        modalControl("enterOldPasswordModal", false);
    };
    return (
        <Dialog open={enterOldPasswordModal} onClose={closeModal}>
            <DialogTitle>Enter Old Password</DialogTitle>
            <DialogContent>
                <input type="text" className="jobsifarish-input2" style={{ width: "20rem" }} value={oldPasswordText} onChange={(event) => setOldPasswordText(event.target.value)} />
                <div className="d-flex justify-content-end mt-2">
                    <button className="normal-disabled-button me-1" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="normal-button" onClick={submitOldPasswordAndGetOtp}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EnterOldPasswordModal;
