import React, { useEffect } from "react";
import styles from "./personalRatingSection.module.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getUserRatings } from "../../../../Services/commonService";
import RatingCard from "../../../UserProfilePage/ProfilePageComponents/RatingSection/Components/RatingCard";

const PersonalRatingSection = () => {
    const { personalDetails } = useSelector((state) => state.persist);
    const { userRatingsData } = useSelector((state) => state.profile);

    useEffect(() => {
        if (personalDetails && personalDetails.emailAddress) {
            getUserRatings(personalDetails.emailAddress, 0, 10);
        }
    }, [personalDetails]);

    return (
        <div className="content-card">
            <div className="d-flex justify-content-between align-items-center">
                <div className="content-card-heading">Ratings</div>
                <div className="d-flex flex-column align-items-end">
                    <div className="d-flex align-items-center">
                        {personalDetails &&
                            personalDetails.over_all_rating &&
                            [...new Array(personalDetails.over_all_rating)].map(() => {
                                return <AiFillStar color="#f1bb46" />;
                            })}
                        {personalDetails &&
                            personalDetails.over_all_rating &&
                            [...new Array(5 - personalDetails.over_all_rating)].map(() => {
                                return <AiOutlineStar color="#000000" />;
                            })}
                    </div>
                    <div className={styles.ratingDesc}>({`${personalDetails && personalDetails.over_all_rating} avg. rating, ${personalDetails && personalDetails.rating_count} reviews`})</div>
                </div>
            </div>

            <div className={styles.container}>
                {userRatingsData && userRatingsData.length > 0 ? (
                    userRatingsData.map((curElem) => {
                        return <RatingCard cardData={curElem} />;
                    })
                ) : (
                    <div className={styles.noRatings}>No ratings</div>
                )}
            </div>
        </div>
    );
};

export default PersonalRatingSection;
