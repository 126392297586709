export const languages = {'Abkhaz': 'ab',
'Afar': 'aa',
'Afrikaans': 'af',
'Akan': 'ak',
'Albanian': 'sq',
'Amharic': 'am',
'Arabic': 'ar',
'Aragonese': 'an',
'Armenian': 'hy',
'Assamese': 'as',
'Avaric': 'av',
'Avestan': 'ae',
'Aymara': 'ay',
'Azerbaijani': 'az',
'Bambara': 'bm',
'Bashkir': 'ba',
'Basque': 'eu',
'Belarusian': 'be',
'Bengali': 'bn',
'Bihari': 'bh',
'Bislama': 'bi',
'Bosnian': 'bs',
'Breton': 'br',
'Bulgarian': 'bg',
'Burmese': 'my',
'Catalan; Valencian': 'ca',
'Chamorro': 'ch',
'Chechen': 'ce',
'Chichewa; Chewa; Nyanja': 'ny',
'Chinese': 'zh',
'Chuvash': 'cv',
'Cornish': 'kw',
'Corsican': 'co',
'Cree': 'cr',
'Croatian': 'hr',
'Czech': 'cs',
'Danish': 'da',
'Divehi; Dhivehi; Maldivian;': 'dv',
'Dutch': 'nl',
'English': 'en',
'Esperanto': 'eo',
'Estonian': 'et',
'Ewe': 'ee',
'Faroese': 'fo',
'Fijian': 'fj',
'Finnish': 'fi',
'French': 'fr',
'Fula; Fulah; Pulaar; Pular': 'ff',
'Galician': 'gl',
'Georgian': 'ka',
'German': 'de',
'Greek, Modern': 'el',
'Guaraní': 'gn',
'Gujarati': 'gu',
'Haitian; Haitian Creole': 'ht',
'Hausa': 'ha',
'Hebrew (modern)': 'he',
'Herero': 'hz',
'Hindi': 'hi',
'Hiri Motu': 'ho',
'Hungarian': 'hu',
'Interlingua': 'ia',
'Indonesian': 'id',
'Interlingue': 'ie',
'Irish': 'ga',
'Igbo': 'ig',
'Inupiaq': 'ik',
'Ido': 'io',
'Icelandic': 'is',
'Italian': 'it',
'Inuktitut': 'iu',
'Japanese': 'ja',
'Javanese': 'jv',
'Kalaallisut, Greenlandic': 'kl',
'Kannada': 'kn',
'Kanuri': 'kr',
'Kashmiri': 'ks',
'Kazakh': 'kk',
'Khmer': 'km',
'Kikuyu, Gikuyu': 'ki',
'Kinyarwanda': 'rw',
'Kirghiz, Kyrgyz': 'ky',
'Komi': 'kv',
'Kongo': 'kg',
'Korean': 'ko',
'Kurdish': 'ku',
'Kwanyama, Kuanyama': 'kj',
'Latin': 'la',
'Luxembourgish, Letzeburgesch': 'lb',
'Luganda': 'lg',
'Limburgish, Limburgan, Limburger': 'li',
'Lingala': 'ln',
'Lao': 'lo',
'Lithuanian': 'lt',
'Luba-Katanga': 'lu',
'Latvian': 'lv',
'Manx': 'gv',
'Macedonian': 'mk',
'Malagasy': 'mg',
'Malay': 'ms',
'Malayalam': 'ml',
'Maltese': 'mt',
'Māori': 'mi',
'Marathi (Marāṭhī)': 'mr',
'Marshallese': 'mh',
'Mongolian': 'mn',
'Nauru': 'na',
'Navajo, Navaho': 'nv',
'Norwegian Bokmål': 'nb',
'North Ndebele': 'nd',
'Nepali': 'ne',
'Ndonga': 'ng',
'Norwegian Nynorsk': 'nn',
'Norwegian': 'no',
'Nuosu': 'ii',
'South Ndebele': 'nr',
'Occitan': 'oc',
'Ojibwe, Ojibwa': 'oj',
'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic': 'cu',
'Oromo': 'om',
'Oriya': 'or',
'Ossetian, Ossetic': 'os',
'Panjabi, Punjabi': 'pa',
'Pāli': 'pi',
'Persian': 'fa',
'Polish': 'pl',
'Pashto, Pushto': 'ps',
'Portuguese': 'pt',
'Quechua': 'qu',
'Romansh': 'rm',
'Kirundi': 'rn',
'Romanian, Moldavian, Moldovan': 'ro',
'Russian': 'ru',
'Sanskrit (Saṁskṛta)': 'sa',
'Sardinian': 'sc',
'Sindhi': 'sd',
'Northern Sami': 'se',
'Samoan': 'sm',
'Sango': 'sg',
'Serbian': 'sr',
'Scottish Gaelic; Gaelic': 'gd',
'Shona': 'sn',
'Sinhala, Sinhalese': 'si',
'Slovak': 'sk',
'Slovene': 'sl',
'Somali': 'so',
'Southern Sotho': 'st',
'Spanish; Castilian': 'es',
'Sundanese': 'su',
'Swahili': 'sw',
'Swati': 'ss',
'Swedish': 'sv',
'Tamil': 'ta',
'Telugu': 'te',
'Tajik': 'tg',
'Thai': 'th',
'Tigrinya': 'ti',
'Tibetan Standard, Tibetan, Central': 'bo',
'Turkmen': 'tk',
'Tagalog': 'tl',
'Tswana': 'tn',
'Tonga (Tonga Islands)': 'to',
'Turkish': 'tr',
'Tsonga': 'ts',
'Tatar': 'tt',
'Twi': 'tw',
'Tahitian': 'ty',
'Uighur, Uyghur': 'ug',
'Ukrainian': 'uk',
'Urdu': 'ur',
'Uzbek': 'uz',
'Venda': 've',
'Vietnamese': 'vi',
'Volapük': 'vo',
'Walloon': 'wa',
'Welsh': 'cy',
'Wolof': 'wo',
'Western Frisian': 'fy',
'Xhosa': 'xh',
'Yiddish': 'yi',
'Yoruba': 'yo',
'Zhuang, Chuang': 'za'}
