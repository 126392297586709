import React, { useEffect } from "react";
import ImageContent from '../../content/ImageContent';
import "../../style/Loader.css";

const Loader = (props) => {
    let imageContent = new ImageContent();
    useEffect(() => { }, [props.isVisible]);
    return (
        <div>
            {props.isVisible === true ? (
                <div className="lds-roller">
                    <div id="containerLoader">
                    </div>
                    <img src={imageContent.getLoader()} alt="loading" height={64} width={64} />
                </div>
            ) : null}
        </div>
    );
};

export default Loader;
