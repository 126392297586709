import React, { useEffect, useState } from "react";
import styles from "./registrationModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalController, registrationDataErrorSetter, registrationDataSetter, saveEmailForOtp } from "../../../Redux/Slices/noPersistSlice";
import { Modal, Spinner } from "react-bootstrap";
import { registrationForm } from "../../../Services/dynamicForms";
import { validateField, widthArr } from "../../../Services/commons";
import { checkEmailExistence } from "../../../Services/authService";
import { postReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { toasterController } from "../../../Redux/Slices/toasterSlice";
import { modalControl } from "../../../Services/commonService";
import { CircularProgress } from "@mui/material";

const RegistrationModal = () => {
    const dispatch = useDispatch();
    const { registrationModal, registrationData, registrationDataError, onInputEmailLoading } = useSelector((state) => state.nopersist);

    const [goForward, setGoForward] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleInput = async (event, field) => {
        const { name, value } = event.target;
        dispatch(registrationDataSetter({ fieldName: name, value: value }));

        if (name === "emailAddress") {
            const x = await checkEmailExistence(value);
            const emailError = x ? "Email already exists" : "";
            dispatch(registrationDataErrorSetter({ fieldName: `${name}_error`, value: emailError }));
        } else {
            const errorMessage = validateField(field, value);
            dispatch(registrationDataErrorSetter({ fieldName: `${name}_error`, value: errorMessage }));
        }
    };

    const handleSubmit = async () => {
        if (goForward) {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(registrationData));
            delete payload.cnfPassword;
            const res = await postReq(`${apiEndpoints.signUp}`, payload);
            if (res && !res.error) {
                setLoading(false);
                if (res.status === 200) {
                    modalControl("registrationModal", false);
                    modalControl("enterOtpModal", true);
                    dispatch(saveEmailForOtp(payload.emailAddress));
                }
            } else {
                setLoading(false);
                console.log(res.error);
                dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
            }
        } else {
            registrationForm.fields.forEach((curElem) => {
                let name = curElem.identifier;
                let value = registrationData[curElem.identifier] ? registrationData[curElem.identifier] : "";
                if (name === "email") {
                    const emailExists = checkEmailExistence(value);
                    const emailError = emailExists ? "Email already exists" : "";
                    dispatch(registrationDataErrorSetter({ fieldName: `${name}_error`, value: emailError }));
                } else {
                    const errorMessage = validateField(curElem, value);
                    dispatch(registrationDataErrorSetter({ fieldName: `${name}_error`, value: errorMessage }));
                }
            });
        }
    };

    useEffect(() => {
        if (Object.values(registrationData).length > 0) {
            let x = Object.values(registrationDataError).some((curElem) => {
                return curElem && curElem.length > 0;
            });
            setGoForward(!x);
        } else {
            setGoForward(false);
        }
    }, [registrationDataError, registrationData]);

    return (
        <Modal
            centered
            className="transparentModal"
            dialogClassName="d-flex justify-content-center"
            show={registrationModal}
            size="lg"
            onHide={() => dispatch(modalController({ modal: "registrationModal", state: false }))}
        >
            <Modal.Body className="p-0">
                <div className={styles.container}>
                    <div className={styles.headingContainer}>{registrationForm.label}</div>
                    <div className="px-3 w-100 d-flex flex-wrap justify-content-between">
                        {Object.keys(registrationForm).length > 0 &&
                            registrationForm.fields.map((curElem) => {
                                return curElem.type === "select" ? (
                                    <div className="d-flex flex-column align-items-start mb-3 position-relative" key={curElem.identifier} style={{ width: widthArr[curElem.fullWidth - 1] }}>
                                        <select
                                            className={registrationDataError[`${curElem.identifier}_error`] ? "jobsifarish-input-error" : "jobsifarish-input"}
                                            name={curElem.identifier}
                                            value={registrationData[curElem.identifier]}
                                            onChange={(event) => handleInput(event, curElem)}
                                        >
                                            <option>{curElem.label}</option>
                                            {curElem.options &&
                                                curElem.options.length > 0 &&
                                                curElem.options.map((cur) => {
                                                    return (
                                                        <option key={cur.sno} value={cur.iden}>
                                                            {cur.label}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column align-items-start mb-3 position-relative" key={curElem.identifier} style={{ width: widthArr[curElem.fullWidth - 1] }}>
                                        <input
                                            type={curElem.type}
                                            name={curElem.identifier}
                                            placeholder={curElem.label}
                                            style={{ paddingRight: curElem.identifier === "email" && "1.5rem" }}
                                            className={registrationDataError[`${curElem.identifier}_error`] ? "jobsifarish-input-error" : "jobsifarish-input"}
                                            onChange={(event) => handleInput(event, curElem)}
                                        />
                                        <div className={styles.iconBox}>{curElem.icon}</div>
                                        {curElem.identifier === "email" && onInputEmailLoading && <Spinner animation="border" size="sm" style={{ position: "absolute", right: "5px", top: "8px" }} />}
                                    </div>
                                );
                            })}
                        <div className="w-100 d-flex justify-content-center align-items-center mt-3 mb-2">
                            <button className={styles.loginButton} disabled={loading} onClick={handleSubmit}>
                                {loading ? <CircularProgress size={12} style={{ color: "#ffffff" }} /> : "Register"}
                            </button>
                        </div>

                        <div className="w-100 d-flex justify-content-end align-items-center">
                            <div style={{ fontSize: "12px", marginRight: "0.25rem" }}>Already have an account?</div>
                            <div
                                className={styles.registerHere}
                                onClick={() => {
                                    dispatch(modalController({ modal: "registrationModal", state: false }));
                                    dispatch(modalController({ modal: "loginModal", state: true }));
                                }}
                            >
                                Login
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RegistrationModal;
