import React, { useEffect, useState } from "react";
import styles from "./ratingSection.module.css";
import RatingCard from "./Components/RatingCard";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { postTReq } from "../../../../Services/api";
import { apiEndpoints } from "../../../../PrEnums/prEnums";
import { useSelector } from "react-redux";
import { getOtherUserProfileData, getUserRatings, toasterControl } from "../../../../Services/commonService";

const RatingSection = () => {
    const { userPersonalDetails } = useSelector((state) => state.persist);
    const { userRatingsData } = useSelector((state) => state.profile);
    const [rating, setRating] = useState(0);
    const [ratingArr, setRatingArr] = useState(new Array(5).fill(false));
    const [comment, setComment] = useState("");
    const handleRatingHover = (ind, clicking = false) => {
        if (!clicking) {
            if (rating < 1) {
                let x = new Array(5).fill(false);
                for (let i = 0; i <= ind; i++) {
                    x[i] = true;
                }
                setRatingArr([...x]);
            }
        } else {
            let x = new Array(5).fill(false);
            for (let i = 0; i <= ind; i++) {
                x[i] = true;
            }
            setRatingArr([...x]);
        }
    };

    const cleanRatingStars = () => {
        setRatingArr(new Array(5).fill(false));
    };

    const handleSubmitRating = async () => {
        const res = await postTReq(apiEndpoints.ratingUser, {
            reciever_id: userPersonalDetails.emailAddress,
            rating: rating,
            remark: comment,
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Feedback Submitted");
            getUserRatings(userPersonalDetails.emailAddress, 0, 10);
            setRating(0);
            setComment("");
            cleanRatingStars();
            getOtherUserProfileData(userPersonalDetails);
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        if (userPersonalDetails && userPersonalDetails.emailAddress) {
            getUserRatings(userPersonalDetails.emailAddress, 0, 10);
        }
    }, [userPersonalDetails]);

    return (
        <div className="content-card">
            <div className="d-flex justify-content-between align-items-center">
                <div className="content-card-heading">Ratings</div>
                <div className="d-flex flex-column align-items-end">
                    <div className="d-flex align-items-center">
                        {userPersonalDetails &&
                            [...new Array(userPersonalDetails.over_all_rating)].map(() => {
                                return <AiFillStar color="#f1bb46" />;
                            })}
                        {userPersonalDetails &&
                            [...new Array(5 - userPersonalDetails.over_all_rating)].map(() => {
                                return <AiOutlineStar color="#000000" />;
                            })}
                    </div>
                    <div className={styles.ratingDesc}>
                        ({`${userPersonalDetails && userPersonalDetails.over_all_rating} avg. rating, ${userPersonalDetails && userPersonalDetails.rating_count} reviews`})
                    </div>
                </div>
            </div>

            {userPersonalDetails && !userPersonalDetails.is_already_remark_given && (
                <div className={styles.writeReview}>
                    <div className="d-flex flex-column align-items-center">
                        <div className={styles.giveRating}>Give rating</div>
                        <div className="d-flex align-items-center">
                            {ratingArr.map((curElem, index) => {
                                if (curElem) {
                                    return (
                                        <AiFillStar
                                            color="#f1bb46"
                                            fontSize={24}
                                            className={styles.star}
                                            onMouseOver={() => handleRatingHover(index)}
                                            onClick={() => {
                                                setRating(index + 1);
                                                handleRatingHover(index, true);
                                            }}
                                        />
                                    );
                                } else {
                                    return (
                                        <AiOutlineStar
                                            color="#000000"
                                            fontSize={24}
                                            className={styles.star}
                                            onMouseOver={() => handleRatingHover(index)}
                                            onClick={() => {
                                                setRating(index + 1);
                                                handleRatingHover(index, true);
                                            }}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    {rating > 0 && (
                        <React.Fragment>
                            <textarea rows="3" placeholder="Write a review..." value={comment} onChange={(event) => setComment(event.target.value)} className="jobsifarish-textarea mb-2"></textarea>
                            <div className="js-submit-button-container">
                                <button onClick={handleSubmitRating}>Submit</button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}

            <div className={styles.container}>
                {userRatingsData && userRatingsData.length > 0 ? (
                    userRatingsData.map((curElem) => {
                        return <RatingCard cardData={curElem} />;
                    })
                ) : (
                    <div className="wide-nothing-to-show mt-3">No ratings available</div>
                )}
            </div>
        </div>
    );
};

export default RatingSection;
