import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEducationalDetails, modalControl, toasterControl } from "../../../Services/commonService";
import styles from "./editEducationModal.module.css";
import { convertDateStringForDateInput } from "../../../Services/commons";
import { inputEditingEducationObject } from "../../../Redux/Slices/profileSlice";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const EditEducationModal = () => {
    const dispatch = useDispatch();
    const { editEducationModal } = useSelector((state) => state.nopersist);
    const { editingEducationObject } = useSelector((state) => state.profile);

    const inputHeadObj = {
        educationId: "Education ID",
        schoolName: "School Name",
        education_type_stream: "Stream",
        designation: "Designation",
        location: "Location",
        totalMarks: "Total Marks",
        scoredMarks: "Scored Marks",
        boardName: "Board/University Name",
        session_end: "Session End",
        session_start: "Session Start",
        educationType: "Education Type",
    };
    const doNotShow = ["educationId", "educationType"];
    const dateInput = ["session_end", "session_start"];

    const handleInput = (event) => {
        const { name, value } = event.target;
        dispatch(inputEditingEducationObject({ name, value }));
    };

    const handleSubmit = async () => {
        let payload = JSON.parse(JSON.stringify(editingEducationObject));
        const res = await postTReq(apiEndpoints.updateEducationalDetails, [payload]);
        if (res && !res.error) {
            getEducationalDetails();
            toasterControl("successToaster", true, "Detail updated successfully");
            modalControl("editEducationModal", false);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };
    return (
        <Dialog open={editEducationModal} onClose={() => modalControl("editEducationModal", false)}>
            <DialogTitle>Edit Education</DialogTitle>
            <DialogContent>
                <div className={`d-flex flex-wrap ${styles.professionInputsContainer}`}>
                    <div className={`w-50 px-1 mb-2`}>
                        <div className={styles.inputHead}>{inputHeadObj["educationType"]}</div>
                        <select className="jobsifarish-input2" name="educationType" value={editingEducationObject["educationType"]} onChange={handleInput}>
                            <option value="">Select</option>
                            <option value="10th">10th</option>
                            <option value="Diploma">Diploma</option>
                            <option value="12th">12th</option>
                            <option value="Graduation">Graduation</option>
                            <option value="Masters">Masters</option>
                            <option value="Post-Grad">Post-Grad</option>
                            <option value="PhD">PhD</option>
                        </select>
                    </div>
                    {editingEducationObject &&
                        Object.keys(editingEducationObject).map((curElem) => {
                            if (!doNotShow.includes(curElem)) {
                                if (dateInput.includes(curElem)) {
                                    return (
                                        <div className={`w-50 px-1 mb-2`}>
                                            <div className={styles.inputHead}>{inputHeadObj[curElem]}</div>
                                            <input
                                                type="date"
                                                className="jobsifarish-input2"
                                                name={curElem}
                                                value={convertDateStringForDateInput(editingEducationObject[curElem])}
                                                onChange={handleInput}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className={`w-50 px-1 mb-2`}>
                                            <div className={styles.inputHead}>{inputHeadObj[curElem]}</div>
                                            <input type="text" className="jobsifarish-input2" name={curElem} value={editingEducationObject[curElem]} onChange={handleInput} />
                                        </div>
                                    );
                                }
                            } else {
                                return null;
                            }
                        })}
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button className="normal-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditEducationModal;
