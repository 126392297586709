import React from 'react'
import '../../style/Modal.css'
import '../../style/applicants.css'
import { MDBNotification } from "mdbreact";

const ErrorModal = (props) => {
   // let imageContent = new ImageContent();

   return (
      <React.Fragment>
         {props.isShow ? <MDBNotification
            autohide={5000}
            show
            fade
            iconClassName={props.icon}
            title={props.title}
            message={props.message}
            // text="11 mins ago"
            style={{
               position: "fixed",
               top: "80px",
               right: "2px",
               zIndex: 1,
               backgroundColor:'white'
            }}
         />:null}
      </React.Fragment>
   )
}

export default React.memo(ErrorModal);
