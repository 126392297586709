import React, { useEffect, useState } from "react";
import styles from "./userReportList.module.css";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { Tooltip } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { getReportedUser } from "../../../Services/commonService";
import { BsPersonCircle } from "react-icons/bs";
import { VscVerifiedFilled } from "react-icons/vsc";

const UserReportList = ({ emailAddress, emailSetter, reportedUserList }) => {
    const [allReportedList, setAllReportedList] = useState([]);
    const getAllReportList = async (pageNum, recordsNum, email) => {
        const res = await postTReq(apiEndpoints.getReportedListForUser, {
            page_no: pageNum,
            no_of_records: recordsNum,
            emailAddress: email,
        });
        if (res && !res.error) {
            setAllReportedList(res.data.data);
        } else {
            console.log(res.error);
        }
    };

    const [userBlockState, setUserBlockState] = useState(false);

    const handleUserBlockOrReactive = async () => {
        const res = await postTReq(apiEndpoints.blockUser, {
            emailAddress: emailAddress,
            is_blocked: userBlockState ? false : true,
        });
        if (res && !res.error) {
            getReportedUser(0, 10);
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        getAllReportList(0, 10, emailAddress);
    }, [emailAddress]);

    useEffect(() => {
        let userState = reportedUserList.filter((curElem) => {
            return curElem.emailAddress === emailAddress;
        });
        setUserBlockState(userState[0].is_blocked);
    }, [reportedUserList, emailAddress]);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <div className={styles.mainHeading}>REPORT LIST</div>
                <button className={userBlockState ? "normal-done-button" : "normal-delete-button"} onClick={handleUserBlockOrReactive}>
                    {userBlockState ? "Unblock User" : "Block User"}
                </button>
            </div>
            <div className="d-flex justify-content-start">
                <div className={styles.backButton} onClick={() => emailSetter("")}>
                    <IoMdArrowRoundBack /> Back
                </div>
            </div>
            <div className={styles.actualTable}>
                <div className={styles.fittingBox}>
                    <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                        <div className={`${styles.headingName} ps-3`}>REPORTED BY</div>
                        <div className={`${styles.headingName} text-center`}>EMAIL VERIFIED</div>
                        <div className={`${styles.headingName}`}>COMMENT</div>
                    </div>

                    {/* details */}
                    <div className="d-flex flex-column mt-2">
                        {allReportedList?.map((curelem) => {
                            return (
                                <>
                                    <div
                                        className="d-flex align-items-center py-3"
                                        style={curelem.is_blocked ? { backgroundColor: "#f6f6f6", borderBottom: "2px solid #e5e5e6" } : { borderBottom: "2px solid #e5e5e6" }}
                                    >
                                        <div className={`${styles.detailsText} d-flex align-items-center`}>
                                            <div className={styles.profileImageContainer}>
                                                {curelem.reported_by.profileImageUrl ? <img src={curelem.reported_by.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={32} color="#888888" />}
                                            </div>
                                            <div>
                                                <div>{curelem.reported_by.fullName}</div>
                                                <div className={styles.emailPill}>{curelem.reported_by.emailAddress}</div>
                                            </div>
                                        </div>
                                        <div className={`${styles.detailsText} text-center`}>
                                            {curelem.reported_by.is_email_verifed ? <VscVerifiedFilled color="#f1bb46" fontSize={20} /> : "Unverified"}
                                        </div>
                                        <Tooltip title={curelem.comment}>
                                            <div className={`${styles.detailsText} ${styles.commentCell}`}>{curelem.comment}</div>
                                        </Tooltip>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserReportList;
