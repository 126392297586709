import React, { useState } from "react";
import styles from "./connectionsList.module.css";
import { getChatByUserEmail, getConnectionsList, handleGetProfileAndGoThere, readMessages, unfriendConnection } from "../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { BsChat, BsFillPersonXFill, BsPersonCircle } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import { selectChat } from "../../Redux/Slices/chatSlice";
import { modalController } from "../../Redux/Slices/noPersistSlice";
import { useNavigate } from "react-router-dom";
import { GoOrganization } from "react-icons/go";
import { MdOutlineWork } from "react-icons/md";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import { VscVerifiedFilled } from "react-icons/vsc";
import { AiFillStar } from "react-icons/ai";
import { socket } from "../../Services/socket";

const ConnectionsList = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { connectionsList, personalDetails } = useSelector((state) => state.persist);
    const { chatHistory, selectedChat } = useSelector((state) => state.chatting);

    const [confirmation, setConfirmation] = useState(false);
    const [savedFriendListId, setSavedFriendListId] = useState("");

    const [loading, setLoading] = useState([]);

    const unfriendUser = async (friendListId) => {
        let newLoading = loading.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await unfriendConnection(friendListId);
        if (res === "200 OK") {
            setLoading(newLoading);
            closeConfirmation();
            getConnectionsList(0, 10);
        } else {
            setLoading(newLoading);
        }
    };

    const startChatWithFriend = (event, elem) => {
        event.stopPropagation();
        socket.emit("leaveRoom", {
            receiverUserId: selectedChat.userId,
            senderUserId: personalDetails.userId,
        });
        let x = chatHistory.filter((curElem) => {
            return curElem?.emailAddress === elem.emailAddress;
        });
        if (x.length > 0) {
            socket.emit("joinRoom", {
                receiverUserId: x[0].userId,
                senderUserId: personalDetails.userId,
            });
            dispatch(selectChat(x[0]));
            getChatByUserEmail(x[0]?.emailAddress, 0, 10);
            dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
            readMessages(elem.emailAddress);
            navigateTo("/message");
        } else {
            socket.emit("joinRoom", {
                receiverUserId: elem.userId,
                senderUserId: personalDetails.userId,
            });
            dispatch(selectChat(elem));
            getChatByUserEmail(elem?.emailAddress, 0, 10);
            dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
            readMessages(elem.emailAddress);
            navigateTo("/message");
        }
    };

    const closeConfirmation = () => {
        setSavedFriendListId("");
        setConfirmation(false);
    };

    const submitUnfriending = () => {
        setLoading((prev) => {
            return [...prev, savedFriendListId];
        });
        unfriendUser(savedFriendListId);
    };

    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-3">Are you sure you want to remove this connection?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={submitUnfriending}>
                            Yes
                        </button>
                        <button className="normal-button" onClick={closeConfirmation}>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.container}>
                {connectionsList &&
                    connectionsList.map((curElem) => {
                        return (
                            <div className={styles.eachCard} onClick={() => handleGetProfileAndGoThere(curElem, navigateTo)}>
                                <div className={styles.coverImage}>{curElem.cover_image_url && <img src={curElem.cover_image_url} alt="" style={{ width: "100%" }} />}</div>
                                <div className={styles.profileImage}>{curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                                <div className={styles.cardContent}>
                                    <div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className={styles.profileName}>{curElem.fullName ? curElem.fullName : "-"}</div>
                                            {curElem.is_verified_from_admin_for_live_session && (
                                                <div className="ms-1">
                                                    <VscVerifiedFilled color="#f1bb46" fontSize={18} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className={styles.ratingPill}>
                                                <AiFillStar fontSize={14} color="#f1bb46" className="me-1" />
                                                {curElem.over_all_rating}
                                            </div>
                                        </div>
                                        {curElem.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                                        {curElem.designation && (
                                            <div className={styles.designationName}>
                                                <MdOutlineWork color="#f1bb46" className="me-1" />
                                                {`${curElem.designation ? curElem.designation : "-"}`}
                                            </div>
                                        )}
                                        {curElem.companyName && (
                                            <div className={styles.companyName}>
                                                <GoOrganization color="#f1bb46" className="me-1" />
                                                {`${curElem.companyName ? curElem.companyName : "-"}`}
                                            </div>
                                        )}
                                        {curElem.location && (
                                            <div className={styles.sectionContainer}>
                                                <div className={styles.sectionName}>Location:</div>
                                                <div className={styles.sectionValue}>{curElem.location ? curElem.location : "-"}</div>
                                            </div>
                                        )}
                                        {curElem.joiningType && (
                                            <div className={styles.sectionContainer}>
                                                <div className={styles.sectionName}>Joining type:</div>
                                                <div className={styles.sectionValue}>{curElem.joiningType ? curElem.joiningType : "-"}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="action-button-container">
                                        {loading.includes(curElem.friendListId) ? (
                                            <button className="action-button-disabled">
                                                <Spinner animation="border" size="sm" />
                                            </button>
                                        ) : (
                                            <>
                                                <Tooltip title="Remove Connection">
                                                    <button
                                                        className="action-button-disabled me-2"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setSavedFriendListId(curElem.friendListId);
                                                            setConfirmation(true);
                                                        }}
                                                    >
                                                        <BsFillPersonXFill />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Chat with connection">
                                                    <button className="action-button-disabled" onClick={(event) => startChatWithFriend(event, curElem)}>
                                                        <BsChat />
                                                    </button>
                                                </Tooltip>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </React.Fragment>
    );
};

export default ConnectionsList;
