import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalControl } from "../../../Services/commonService";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { saveForgotPasswordEmail } from "../../../Redux/Slices/noPersistSlice";

const EnterForgotPasswordEmailModal = () => {
    const dispatch = useDispatch();
    const { forgotPasswordEnterEmailModal } = useSelector((state) => state.nopersist);
    const closeModal = () => {
        setEmailText("");
        modalControl("forgotPasswordEnterEmailModal", false);
    };
    const [emailText, setEmailText] = useState("");
    const submitForgotPassword = async () => {
        const res = await postTReq(apiEndpoints.sendOtp, {
            emailAddress: emailText,
        });
        if (res && !res.error) {
            dispatch(saveForgotPasswordEmail(emailText));
            closeModal();
            modalControl("forgotPasswordOtpModal", true);
        } else {
            console.log(res.error);
        }
    };
    return (
        <Dialog open={forgotPasswordEnterEmailModal} onClose={closeModal}>
            <DialogTitle>Enter Email</DialogTitle>
            <DialogContent>
                <input type="text" className="jobsifarish-input2" style={{ width: "20rem" }} value={emailText} onChange={(event) => setEmailText(event.target.value)} />
                <div className="d-flex justify-content-end mt-2">
                    <button className="normal-disabled-button me-1" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="normal-button" onClick={submitForgotPassword}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EnterForgotPasswordEmailModal;
