import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    personalDetails: {},
    educationalDetails: [],
    professionalDetails: [],

    connectionsList: [],
    pendingList: [],
    sentList: [],
    searchedConnections: [],
    suggestedConnections: [],

    userPersonalDetails: {},
    userEducationalDetails: [],
    userProfessionalDetails: [],

    skillsList: [],
    unreadChatCount: 0,
};

export const persistSlice = createSlice({
    name: "persistSlice",
    initialState,
    reducers: {
        savePersonalDetails: (state, action) => {
            state.personalDetails = action.payload;
        },
        saveEducationalDetails: (state, action) => {
            state.educationalDetails = action.payload;
        },
        saveProfessionalDetails: (state, action) => {
            state.professionalDetails = action.payload;
        },

        saveConnectionsList: (state, action) => {
            state.connectionsList = action.payload;
        },
        savePendingList: (state, action) => {
            state.pendingList = action.payload;
        },
        saveSentList: (state, action) => {
            state.sentList = action.payload;
        },
        saveSearchedConnections: (state, action) => {
            state.searchedConnections = action.payload;
        },
        saveSuggestedConnections: (state, action) => {
            state.suggestedConnections = action.payload;
        },

        saveUserPersonalDetails: (state, action) => {
            state.userPersonalDetails = action.payload;
        },
        saveUserEducationalDetails: (state, action) => {
            state.userEducationalDetails = action.payload;
        },
        saveUserProfessionalDetails: (state, action) => {
            state.userProfessionalDetails = action.payload;
        },

        saveSkillsList: (state, action) => {
            state.skillsList = action.payload;
        },
    },
});

export const {
    savePersonalDetails,
    saveEducationalDetails,
    saveProfessionalDetails,
    saveConnectionsList,
    savePendingList,
    saveSentList,
    saveSearchedConnections,
    saveSuggestedConnections,
    saveUserEducationalDetails,
    saveUserPersonalDetails,
    saveUserProfessionalDetails,
    saveSkillsList,
} = persistSlice.actions;

export default persistSlice.reducer;
