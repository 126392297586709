import axios from "axios";
import { getToken } from "./authService";
import config from "../config.json";

const baseUrl = config.apiConfig.apiEndPoint;

export const postReq = (url, payload) => {
    return axios
        .post(`${baseUrl}${url}`, payload)
        .then((response) => response)
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 403) && window.location.pathname !== "/") {
                window.location.pathname = "/";
            }
            return { error: error };
        });
};

export const getReq = (url, payload, headers) => {
    return axios
        .get(`${baseUrl}${url}`, {
            headers: headers || { Authorization: getToken() },
            data: payload,
        })
        .then((response) => response)
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 403) && window.location.pathname !== "/") {
                window.location.pathname = "/";
            }
            return { error: error };
        });
};

export const postTReq = (url, payload, headers) => {
    return axios
        .post(`${baseUrl}${url}`, payload, {
            headers: headers || { Authorization: getToken() },
        })
        .then((response) => response)
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 403) && window.location.pathname !== "/") {
                window.location.pathname = "/";
            }
            return { error: error };
        });
};

export const delReq = (url, payload) => {
    return axios
        .delete(`${baseUrl}${url}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: getToken(),
            },
            data: payload,
        })
        .then((response) => response)
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 403) && window.location.pathname !== "/") {
                window.location.pathname = "/";
            }
            return { error: error };
        });
};

export const putReq = (url, payload) => {
    return axios
        .put(`${baseUrl}${url}`, payload, {
            headers: { Authorization: getToken() },
        })
        .then((response) => response)
        .catch((error) => {
            if ((error?.response?.status === 401 || error?.response?.status === 403) && window.location.pathname !== "/") {
                window.location.pathname = "/";
            }
            return { error: error };
        });
};

export const callAPI = async (method = "POST", url = "", data = {}, resType = "json") => {
    try {
        if (method !== "GET") {
            const response = await fetch(`${baseUrl}${url}`, {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (resType !== "json") {
                return response;
            }
            return response.json();
        } else {
            const response = await fetch(`${baseUrl}${url}`, {
                method: method,
                mode: "no-cors",
            });
            if (resType !== "json") {
                return response;
            }
            return response.json();
        }
    } catch (error) {
        console.log(error);
        return { error: "error" };
    }
};
