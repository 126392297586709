import React, { useState } from 'react'
import ErrorModal from '../../component/Modal/ErrorModal'
// import ImageContent from '../../content/ImageContent'
import ServiceProvider from '../../provider/serviceProvider'
import HeaderUtility from '../../utility/HeaderUtility'
import '../../style/changePassword.css'
import { useNavigate } from 'react-router'

const ChangePassword = (props) => {
   // const imageContent = new ImageContent()
   const serviceProvider = new ServiceProvider()
   let headerUtility = new HeaderUtility();
   const navigateTo = useNavigate();
   let header = headerUtility.returnHeaderContainingToken();

   const [passwords, setPasswords] = useState({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
   })
   const [ alerts, setAlerts ] = useState({
       show: false,
       message :''
   })

   const handleChange = (e) => {
      let event = e.target
      if (event.name === 'oldPassword') {
         setPasswords({ ...passwords, oldPassword: event.value })
      } else if (event.name === 'newPassword') {
         setPasswords({ ...passwords, newPassword: event.value })
      } else if (event.name === 'confirmPassword') {
         setPasswords({ ...passwords, confirmPassword: event.value })
      }
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      if(passwords.newPassword !== passwords.confirmPassword) {
        setAlerts({...alerts, show: true, message: 'Your new password and confirm password did not match.'}) 
      } else {
        let authService = serviceProvider.returnAuthenticationService()
        authService.onChangePassword({ passwordString: passwords.oldPassword, newpasswordString: passwords.newPassword }, header).then((res) => {
           if (res.status === '200' && res.dataString === 'password not matched' ) {
            setAlerts({...alerts, show: true, message: 'Your old password did not match.'}) 
           } else if (res.status === '200' && res.dataString === 'password changed') {
                setAlerts({...alerts, show: true, message: 'Password changed successfully!'}) 
                navigateTo('/');
           }
           else {
            setAlerts({...alerts, show: true, message: 'Fetched some error, please contact admin!'})
           }
        })
      }
   }

   return (
      <div className='mainDivContainer'>
         <ErrorModal icon='text-danger' title='Warning' message={alerts.message} isShow={alerts.show} handleClose={() =>  setAlerts({...alerts, show: false}) } />
         <div className='cardStyleContainer'>
            <form  name='signupForm'  onSubmit={handleSubmit}>
               <h2 className='formTitlePassword'>Change Password...</h2>
               <div className='inputDivPassword'>
                  <label className='inputLabelPassword' htmlFor='password'>
                     Old Password
                  </label>
                  <input
                  className='passwordInput'
                     type='password'
                     name='oldPassword'
                     required
                     onChange={handleChange}
                     />
               </div>

               <div className='inputDivPassword'>
                  <label className='inputLabelPassword' htmlFor='newPassword'>
                     New Password
                  </label>
                  <input
                     type='password'
                     className='passwordInput'
                     name='newPassword'
                     required
                     onChange={handleChange}
                     />
               </div>
               <div className='inputDivPassword'>
                  <label className='inputLabelPassword' htmlFor='confirmPassword'>
                     Confirm Password
                  </label>
                  <input
                     type='password'
                     name='confirmPassword'
                     className='passwordInput'
                     onChange={handleChange}
                     required
                     />
               </div>

               <div className='buttonWrapper'>
                  <button
                     type='submit'
                     id='submitButtonPassword'
                     className='submitButtonPassword pure-button pure-button-primary'
                     >Submit
                  </button>
               </div>
            </form>
         </div>
      </div>
   )
}

export default ChangePassword
