import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: {},
};

export const firebaseSlice = createSlice({
  name: "firebaseSlice",
  initialState,
  reducers: {
    saveCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { saveCurrentUser } = firebaseSlice.actions;

export default firebaseSlice.reducer;
