import React, { useEffect, useState } from "react";
import styles from "./createdJobs.module.css";
import { getAllJobApplicants, getJobsPostedByUser, modalControl, toasterControl } from "../../../../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { IoPeople } from "react-icons/io5";
import { BiSolidEdit, BiSolidTrashAlt } from "react-icons/bi";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import { getDurationString } from "../../../../../Services/commons";
import { GoClockFill } from "react-icons/go";
import { delReq } from "../../../../../Services/api";
import { apiEndpoints } from "../../../../../PrEnums/prEnums";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { saveEditingJobObj } from "../../../../../Redux/Slices/jobSlice";

const CreatedJobs = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const { jobsPostedByUser } = useSelector((state) => state.jobslice);

    const [confirmation, setConfirmation] = useState(false);
    const [savedJobId, setSavedJobId] = useState("");
    const closeModal = () => {
        setSavedJobId("");
        setConfirmation(false);
    };

    const deleteMyPostedJob = async (jobId) => {
        const res = await delReq(apiEndpoints.deleteJob, {
            job_id: jobId,
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Job deleted successfully");
            closeModal();
            getJobsPostedByUser(0, 10);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const editJobData = async (theJob) => {
        dispatch(saveEditingJobObj(theJob));
        modalControl("editJobModal", true);
    };

    useEffect(() => {
        getJobsPostedByUser(0, 10);
    }, []);
    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-2">Are you sure you want to delete this job?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={closeModal}>
                            No
                        </button>
                        <button className="normal-button" onClick={() => deleteMyPostedJob(savedJobId)}>
                            Yes
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.container}>
                {jobsPostedByUser && jobsPostedByUser.length > 0 ? (
                    jobsPostedByUser.map((curElem) => {
                        return (
                            <div className={styles.jobCard}>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div>
                                        <div className={styles.joiningType}>{curElem.joiningType}</div>
                                        <div className={styles.jobTitle}>{curElem.jobTitle}</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title="View Job">
                                            <div className={`${styles.actionButton} me-2`} style={{ position: "relative" }} onClick={() => navigateTo(`/view-job/${curElem.job_id}`)}>
                                                <IoSearchOutline />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Applicants">
                                            <div className={`${styles.actionButton} me-2`} style={{ position: "relative" }} onClick={() => getAllJobApplicants(curElem.job_id, 0, 10)}>
                                                <IoPeople />
                                                <div className={styles.applicants}>{curElem.job_applied_user_count}</div>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Edit Job">
                                            <div className={`${styles.actionButton} me-2`} onClick={() => editJobData(curElem)}>
                                                <BiSolidEdit />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Delete Job">
                                            <div
                                                className={`${styles.deleteAction} me-2`}
                                                onClick={() => {
                                                    setSavedJobId(curElem.job_id);
                                                    setConfirmation(true);
                                                }}
                                            >
                                                <BiSolidTrashAlt />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 flex-wrap">
                                    {curElem.job_type_details.map((cur) => {
                                        return <div className={styles.typePill}>{cur.job_type}</div>;
                                    })}
                                </div>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Role:</div>
                                        <div className={styles.dataValue}>{curElem.role}</div>
                                    </div>
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Location:</div>
                                        <div className={styles.dataValue}>{curElem.location}</div>
                                    </div>
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Job Stream:</div>
                                        <div className={styles.dataValue}>{curElem.jobStreams}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className={`${styles.label} mb-1`}>Skills Required:</div>
                                    <div className={`${styles.dataValue} d-flex flex-wrap`}>
                                        {curElem.skillset.map((cur) => {
                                            return <div className={styles.skillPill}>{cur.skill}</div>;
                                        })}
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end align-items-center ${styles.uploadTime}`}>
                                    <GoClockFill color="#888888" fontSize={12} className="me-1" />
                                    {getDurationString(curElem.date)}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="colorback-nothing-to-show">No Created Jobs</div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CreatedJobs;
