import React, { memo } from "react";
import LoginModal from "../Modal/LoginModal/LoginModal";
import RegistrationModal from "../Modal/RegistrationModal/RegistrationModal";
import AddWorkExperienceModal from "../Modal/AddWorkExperienceModal/AddWorkExperienceModal";
import AddEducationalDetailModal from "../Modal/AddEducationalDetailModal/AddEducationalDetailModal";
import ScheduleSessionModal from "../Modal/ScheduleSessionModal/ScheduleSessionModal";
import ScheduleSessionDrawer from "../Modal/ScheduleSessionDrawer/ScheduleSessionDrawer";
import RescheduleModal from "../Modal/RescheduleModal/RescheduleModal";
import EditProfessionModal from "../Modal/EditProfessionModal/EditProfessionModal";
import ProfessionDeleteConfirmationModal from "../Modal/ProfessionDeleteConfirmationModal/ProfessionDeleteConfirmationModal";
import EditEducationModal from "../Modal/EditEducationModal/EditEducationModal";
import JobApplicantsModal from "../Modal/JobApplicantsModal/JobApplicantsModal";
import AddProjectModal from "../Modal/AddProjectModal/AddProjectModal";
import EditProjectModal from "../Modal/EditProjectModal/EditProjectModal";
import EditJobModal from "../Modal/EditJobModal/EditJobModal";
import EditRatingModal from "../Modal/EditRatingModal/EditRatingModal";
import EnterOtpModal from "../Modal/EnterOtpModal/EnterOtpModal";
import EnterForgotPasswordEmailModal from "../Modal/EnterForgotPasswordEmailModal/EnterForgotPasswordEmailModal";
import ForgotPasswordOtpModal from "../Modal/ForgotPasswordOtpModal/ForgotPasswordOtpModal";
import EnterNewPasswordModal from "../Modal/EnterNewPasswordModal/EnterNewPasswordModal";
import EnterOldPasswordModal from "../Modal/EnterOldPasswordModal/EnterOldPasswordModal";
import ChangeEnterOtpModal from "../Modal/ChangeEnterOtpModal/ChangeEnterOtpModal";
import EnterNewPasswordChangeModal from "../Modal/EnterNewPasswordChangeModal/EnterNewPasswordChangeModal";

const ModalCollection = () => {
    return (
        <React.Fragment>
            <LoginModal />
            <RegistrationModal />
            <AddWorkExperienceModal />
            <AddEducationalDetailModal />
            <ScheduleSessionModal />
            <ScheduleSessionDrawer />
            <RescheduleModal />
            <EditProfessionModal />
            <ProfessionDeleteConfirmationModal />
            <EditEducationModal />
            <JobApplicantsModal />
            <AddProjectModal />
            <EditProjectModal />
            <EditJobModal />
            <EditRatingModal />
            <EnterOtpModal />
            <EnterForgotPasswordEmailModal />
            <ForgotPasswordOtpModal />
            <EnterNewPasswordModal />
            <EnterOldPasswordModal />
            <ChangeEnterOtpModal />
            <EnterNewPasswordChangeModal />
        </React.Fragment>
    );
};

export default memo(ModalCollection);
