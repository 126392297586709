import React, { useState, useEffect } from "react";
import styles from "./activityBox.module.css";
import ScheduledMeet from "./ScheduledMeet/ScheduledMeet";
import CreatedJobs from "./CreatedJobs/CreatedJobs";
import AppliedJobs from "./AppliedJobs/AppliedJobs";
import BookmarkJobs from "../../../BookmarkJobs/BookmarkJobs";

const ActivityBox = () => {
    const [selectedTab, setSelectedTab] = useState("created");

    const componentObj = {
        created: <CreatedJobs />,
        meeting: <ScheduledMeet />,
        applied: <AppliedJobs />,
        bookmark: <BookmarkJobs />,
    };

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

    const updateMedia = () => {
        setIsDesktop(window.innerWidth > 767);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div className={`content-card ${styles.activityContainer}`}>
            <div className="content-card-heading">Activity</div>
            <div className={styles.tabsContainer}>
                <div className={`${selectedTab === "created" ? styles.eachTabActive : styles.eachTab}`} onClick={() => setSelectedTab("created")}>
                    {isDesktop ? "Created Job" : "Created"}
                </div>
                <div className={`${selectedTab === "applied" ? styles.eachTabActive : styles.eachTab}`} onClick={() => setSelectedTab("applied")}>
                    {isDesktop ? "Applied Job" : "Applied"}
                </div>
                <div className={`${selectedTab === "bookmark" ? styles.eachTabActive : styles.eachTab}`} onClick={() => setSelectedTab("bookmark")}>
                    {isDesktop ? "Bookmark Jobs" : "Bookmark"}
                </div>
                <div className={`${selectedTab === "meeting" ? styles.eachTabActive : styles.eachTab}`} onClick={() => setSelectedTab("meeting")}>
                    {isDesktop ? "Scheduled Meets" : "Meets"}
                </div>
            </div>
            {componentObj[selectedTab]}
        </div>
    );
};

export default ActivityBox;
