import React, { useEffect } from "react";
import styles from "./connections.module.css";
import { BsFillPeopleFill, BsPersonFillAdd, BsPersonFillUp, BsSearch } from "react-icons/bs";
import ConnectionsList from "../../component/ConnectionsList/ConnectionsList";
import { useDispatch, useSelector } from "react-redux";
import { selectConnectionsTab, setConnectionSearchText } from "../../Redux/Slices/noPersistSlice";
import PendingList from "../../component/PendingList/PendingList";
import SentRequestList from "../../component/SentRequestList/SentRequestList";
import SearchResults from "../../component/SearchResults/SearchResults";
import PeopleYouMayKnow from "../../component/PeopleYouMayKnow/PeopleYouMayKnow";
import { getChatHistory, getConnectionsList, getReceivedRequest, getSearchedUsers, getSentRequest } from "../../Services/commonService";

const Connections = () => {
    const dispatch = useDispatch();
    const { selectedTab, connectionSearchText, searchTextFromHome } = useSelector((state) => state.nopersist);
    const { suggestedConnections, connectionsList, pendingList, sentList, searchedConnections } = useSelector((state) => state.persist);

    const tabPages = {
        connection: {
            label: "Connections",
            component: <ConnectionsList />,
            emptyText: "No activity here. Connect with people.",
            dataArr: connectionsList || [],
        },
        pending: {
            label: "Pending Requests",
            component: <PendingList />,
            emptyText: "Nothing here. Wait for people to connect.",
            dataArr: pendingList || [],
        },
        sent: {
            label: "Sent Requests",
            component: <SentRequestList />,
            emptyText: "Send some requests. Know some people.",
            dataArr: sentList || [],
        },
        search: {
            label: "Search Results",
            component: <SearchResults />,
            emptyText: "We couldn't find the one you're looking for.",
            dataArr: searchedConnections || [],
        },
    };

    useEffect(() => {
        if (connectionSearchText.length > 0) {
            dispatch(selectConnectionsTab("search"));
        } else {
            dispatch(selectConnectionsTab("connection"));
        }
    }, [connectionSearchText, dispatch]);

    useEffect(() => {
        if (searchTextFromHome.length > 0) {
            dispatch(setConnectionSearchText(searchTextFromHome));
        }
    }, [searchTextFromHome, dispatch]);

    useEffect(() => {
        if (selectedTab === "search" && connectionSearchText.length > 0) {
            getSearchedUsers(0, 10, connectionSearchText);
        }

        switch (selectedTab) {
            case "sent":
                getSentRequest(0, 10);
                break;
            case "pending":
                getReceivedRequest(0, 10);
                break;
            case "connection":
                getConnectionsList(0, 10);
                getChatHistory(0, 10);
                break;

            default:
                break;
        }
    }, [connectionSearchText, selectedTab, dispatch]);

    return (
        <div className="main-content-container-flexible">
            <div className={styles.container}>
                <div className="content-card p-2" style={{ background: "#444444" }}>
                    <div className={styles.tabAndSearch}>
                        <div className={styles.tabsContainer}>
                            <div className={`${styles.eachTab} ${selectedTab === "connection" && styles.activeTab}`} onClick={() => dispatch(selectConnectionsTab("connection"))}>
                                <BsFillPeopleFill fontSize={16} />
                                <div className="ms-2">Connections</div>
                            </div>
                            <div className={`${styles.eachTab} ${selectedTab === "pending" && styles.activeTab}`} onClick={() => dispatch(selectConnectionsTab("pending"))}>
                                <BsPersonFillAdd fontSize={16} />
                                <div className="ms-2">Pending</div>
                            </div>
                            <div className={`${styles.eachTab} ${selectedTab === "sent" && styles.activeTab}`} onClick={() => dispatch(selectConnectionsTab("sent"))}>
                                <BsPersonFillUp fontSize={16} />
                                <div className="ms-2">Sent</div>
                            </div>
                        </div>
                        <div className={styles.searchingContainer}>
                            <div className={styles.searchInputContainer}>
                                <input type="text" value={connectionSearchText} placeholder="Search Users" onChange={(event) => dispatch(setConnectionSearchText(event.target.value))} />
                            </div>
                            <div className={`${styles.searchIconContainer}`}>
                                <BsSearch />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-card">
                    <div className={styles.pageHead}>{tabPages[selectedTab].label}</div>
                    <div className={styles.componentContainer}>
                        {tabPages[selectedTab].dataArr.length > 0 ? tabPages[selectedTab].component : <div className="wide-nothing-to-show">{tabPages[selectedTab].emptyText}</div>}
                    </div>
                </div>

                <div className="content-card">
                    <div className={styles.pageHead}>People you may know</div>
                    <div className={styles.suggestedPeopleContainer}>
                        {suggestedConnections && suggestedConnections.length > 0 ? <PeopleYouMayKnow /> : <div className="wide-nothing-to-show">Apologies. We've come up empty!</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connections;
