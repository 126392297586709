export class DataUtility {
    static paginateData(data, currentPage, pazeSize) {
        if (data.length > 0) {
            const skip = (currentPage - 1) * 10;
            return data.slice(skip, (skip + pazeSize));
        } else {
            return [];
        }
    }
    static generateEducationalJsonFromList = (educationList) => {
        let listForRequestJson = [];
        educationList.forEach((education) => {
            let educationRequestObj = {
                "educationType": education.qualification !== '' ? education.qualification : undefined,
                "schoolName": education.institution !== '' ? education.institution : undefined,
                "sessionPeriod": education.board !== '' ? education.board : undefined,
                "scoredMarks": education.start !== '' ? education.start : undefined,
                "totalMarks": education.end !== '' ? education.end : undefined,
                "boardName": education.cgpa !== '' ? education.cgpa : undefined,
                "location": education.location !== '' ? education.location : undefined
            }
            if (educationRequestObj.educationType && educationRequestObj.schoolName && educationRequestObj.sessionPeriod && educationRequestObj.scoredMarks && educationRequestObj.totalMarks && educationRequestObj.boardName && educationRequestObj.location) {
                listForRequestJson.push(educationRequestObj);
            }
        });
        return listForRequestJson;
    }
    static checkKeyForProfessionalJson = (inputJson) => {
        let isKey = false;
        let keyList = [
            "project_name",
            "started_from",
            "end_on",
            "discription",
            "tech_used",
            "project_prod_url",
            "role_played",
            "projects_details"
        ];
        keyList.forEach(key => {
            if (inputJson.hasOwnProperty(key)) {
                isKey = true;
            }
        });
        return isKey;
    }
    static generateProfessionalJsonFromList = (expList, isExp) => {
        let expDummyList = [];
        expList.forEach((exp, index) => {
            let inputExpJson = {
                "companyName": exp.companyName,
                "designation": exp.role,
                "location": exp.location,
                "joiningType": isExp ? 'Experienced' : exp.apprenticeType,
                "joined_from": DataUtility.dateToEpochValue(exp.fromDate),
                "worked_till": !exp.currentlyWorking ? (exp.toDate !== undefined && exp.toDate !== '') ? DataUtility.dateToEpochValue(exp.toDate) : 0 : 0,
                "projects_details": []
            };
            if (exp.professional_id !== null) {
                inputExpJson.professional_id = exp.professional_id;
            }
            exp.projects.forEach(project => {
                let inputProjectJson = {};
                if (project.project_id !== null) {
                    inputProjectJson["project_id"] = project.project_id;
                }
                if (project.name !== '') {
                    inputProjectJson["project_name"] = project.name;
                }
                if (project.pFromDate !== '') {
                    inputProjectJson["started_from"] = DataUtility.dateToEpochValue(project.pFromDate);
                }
                if (project.pToDate !== '') {
                    inputProjectJson["end_on"] = DataUtility.dateToEpochValue(project.pToDate);
                }
                if (project.pDescription !== '') {
                    inputProjectJson["discription"] = project.pDescription;
                }
                if (project.pTechUsed !== '') {
                    inputProjectJson["tech_used"] = project.pTechUsed;
                }
                if (project.pProdUrl !== '') {
                    inputProjectJson["project_prod_url"] = project.pProdUrl;
                }
                if (project.pRole !== '') {
                    inputProjectJson["role_played"] = project.pRole;
                }
                if (DataUtility.checkKeyForProfessionalJson(inputProjectJson)) {
                    inputExpJson.projects_details.push(inputProjectJson);
                }
            });
            expDummyList.push(inputExpJson);
        });
        return expDummyList;
    }
    static generateProfessionalListForEdit = (dataList) => {
        let professionalList = [];
        let isExp = 'Fresher';
        dataList?.forEach((item, index) => {
            let profJson = {
                id: index,
                professional_id: item?.professional_id,
                companyName: item?.companyName,
                apprenticeType: item?.joiningType,
                role: item?.designation,
                fromDate: DataUtility.returnDateInmmDDyyyFormat(new Date(item?.joined_from)),
                toDate: DataUtility.returnDateInmmDDyyyFormat(new Date(item?.worked_till)),
                currentlyWorking: item?.worked_till === 0 ? true : false,
                location: item?.location,
                projects: []
            };
            if (item?.joiningType === 'Experienced') {
                isExp = 'Experienced';
            }
            item?.projects_details.forEach((proj, subindex) => {
                let projJson = {
                    project_id: proj?.project_id,
                    pid: subindex,
                    name: proj?.project_name,
                    pRole: proj?.role_played,
                    pFromDate: DataUtility.returnDateInmmDDyyyFormat(new Date(proj?.started_from)),
                    pToDate: DataUtility.returnDateInmmDDyyyFormat(new Date(proj?.end_on)),
                    pDescription: proj?.discription,
                    pTechUsed: proj?.tech_used,
                    pProdUrl: proj?.project_prod_url,
                };
                profJson.projects.push(projJson);
            })
            professionalList.push(profJson);
        })
        return { professionalList, isExp };
    }
    static generateSkillSetListFromSkillList = (skillList) => {
        console.log(skillList);
        let skillName = [];
        skillList.forEach((skill, index) => {
            let obj = {
                'skill_id':skill.value,
                'skill':skill.label,
                'skill_reference_id':null
            };
            skillName.push(obj);
        });
        return skillName;
    }
    static generateSkillSetStringFromSkillList = (skillList) => {
        let skillName = '';
        skillList.forEach((skill, index) => {
            if (index === 0) {
                skillName = skill.text;
            } else {
                skillName = skillName + ',' + skill.text;
            }
        });
        return skillName;
    }
    static getValidDateForDob = (todayDate) => {
        let validYear = todayDate.getFullYear() - 10;
        let month = todayDate.getMonth() + 1;
        let monthString = month.toString();
        if (month < 10) {
            monthString = "0" + monthString;
        }
        let dateNumber = todayDate.getDate();
        let dateNumberString = dateNumber.toString();
        if (dateNumber < 10) {
            dateNumberString = '0' + dateNumberString;
        }
        return validYear + "-" + monthString + "-" + dateNumberString;
    }
    static getTodayDate = (todayDate) => {
        let validYear = todayDate.getFullYear();
        let month = todayDate.getMonth() + 1;
        let monthString = month.toString();
        if (month < 10) {
            monthString = "0" + monthString;
        }
        let dateNumber = todayDate.getDate();
        let dateNumberString = dateNumber.toString();
        if (dateNumber < 10) {
            dateNumberString = '0' + dateNumberString;
        }
        return validYear + "-" + monthString + "-" + dateNumberString;
    }
    static dateToEpochValue = (date) => {
        let dateInfo = new Date(date);
        let epochValue = dateInfo.getTime();
        return epochValue;
    }
    static getProfessionalData = (list) => {
        list.forEach((item, index) => {
            item.userExp = DataUtility.expCalc(item.joined_from, item.worked_till);
        })
        return list;
    }
    static expCalc = (fromDate, toDate) => {
        if (toDate === 0) {
            let today = new Date();
            let diff = (today.getTime() / 1000) - new Date(fromDate / 1000);
            return DataUtility.timeDiffCalc(diff);
        } else {
            let diff = new Date(toDate / 1000) - new Date(fromDate / 1000);
            return DataUtility.timeDiffCalc(diff);
        }
    }
    static timeDiffCalc = (diff) => {
        let expValue = '';
        let diffQuo = diff / 86400;
        expValue = { diff: parseInt(diffQuo), duration: ' days' };
        if (diffQuo > 30.44) {
            expValue = { diff: parseInt(diffQuo / 30), duration: ' months' };
        }
        if (diffQuo > 365.24) {
            expValue = { diff: parseInt(diffQuo / 365), duration: ' years' };
        }
        return expValue;
    }
    static epochToDateValue = (epochTime) => {
        let date = new Date(epochTime);
        return DataUtility.returnDateInddMonthyyyy(date);
    }
    static returnDateInddMonthyyyy = (date) => {
        return date.getDate() + ' ' + DataUtility.getMonth(date.getMonth()) + ' ' + date.getFullYear();
    }
    static returnDateInmmDDyyyFormat = (date) => {
        return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    }
    static getMonth(monthNumber) {
        switch (monthNumber) {
            case 0: return "January";

            case 1: return "February";

            case 2: return "March";

            case 3: return "April";

            case 4: return "May";

            case 5: return "June";

            case 6: return "July";

            case 7: return "August";

            case 8: return "September";

            case 9: return "October";

            case 10: return "November";

            case 11: return "December";

            default: return null;
        }
    }
    static returnVideoList(videoList) {
        videoList.forEach((video) => {
            video.file_name = 'https://jobsifarish.s3.ap-south-1.amazonaws.com/' + video.file_name;
        });
        return videoList;
    }
    static returnNotificationList(notifyList) {
        notifyList.forEach((notify) => {
            notify.notification_time = DataUtility.returnDateInmmDDyyyFormat(new Date(notify.notification_time))
        });
        return notifyList;
    }
    static returnSuggestedUserList(users) {
        // users.forEach(user => {
        //     if (user.profileImageUrl){
        //         user.profileImageUrl = 'https://jobsifarish.s3.ap-south-1.amazonaws.com/' + user.profileImageUrl;
        //     }
        // })
        return users;
    }
}