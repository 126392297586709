import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../style/Modal.css";
import "../../style/applicants.css";
import ImageContent from "../../content/ImageContent";
import ServiceProvider from "../../provider/serviceProvider";
import { Link, useParams } from "react-router-dom";
import HeaderUtility from "../../utility/HeaderUtility";

const ApplicantsModal = (props) => {
  let imageContent = new ImageContent();
  let [users, setUsers] = useState([]);
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const params = useParams();
  let [jobId, setJobId] = useState("");
  let [loading] = useState(false);
  let [posC, setPosC] = useState(0);
  let [tPageC, setTPageC] = useState(0);
  let observer = useRef();

  const getUserListonScroll = useCallback(
    (page) => {
      serviceProvider
        .returnJobService()
        .getAppliedJobUserList(headerUtility.returnHeaderContainingToken(), {
          job_id: jobId,
          page_no: page,
          no_of_records: 5,
        })
        .then((res) => {
          if (res.status === "200 OK") {
            let userList = [...users];
            userList = userList.concat(res.data);
            setUsers(userList);
            setTPageC(res.page_details.total_pages);
          }
        });
    },
    [users, setUsers, setTPageC, jobId, serviceProvider, headerUtility]
  );

  const onScrollOfUser = useCallback(() => {
    let np = posC + 1;
    if (np < tPageC) {
      setPosC(np);
      getUserListonScroll(np);
    }
  }, [posC, tPageC, setPosC, getUserListonScroll]);

  const lastUser = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollOfUser();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, onScrollOfUser]
  );

  const getUserList = useCallback(
    (id) => {
      serviceProvider
        .returnJobService()
        .getAppliedJobUserList(headerUtility.returnHeaderContainingToken(), {
          job_id: id,
          page_no: 0,
          no_of_records: 5,
        })
        .then((res) => {
          if (res.status === "200 OK") {
            setUsers(res.data);
            console.log(res.data);
            setTPageC(res.page_details.total_pages);
          }
        });
    },
    [setUsers, setTPageC, serviceProvider, headerUtility]
  );
  
  const returnApplicantsList = () => {
    return users.map((res, index) => {
      if (users.length === index + 1) {
        return (
          <div ref={lastUser} className="single_applicant" key={index}>
            <div className="applicant_details">
              <div className="applicant_profile">
                <img src={"https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res?.profileImageUrl} alt="profile_photo" height="50" width="50" />
                <div
                  style={{
                    lineHeight: ".1",
                    marginTop: "15px",
                  }}
                >
                  <p>
                    {res?.firstName} {res?.lastName}
                  </p>
                  <span>Web Developer</span>
                </div>
              </div>
              {/* <div className='message_button'> */}
              <Link to={{ pathname: "/message", user: res }}>
                <button type="button">Message Applicant</button>
              </Link>
              {/* </div> */}
            </div>
            <p className="applicant_info">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
              illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
              ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh
            </p>
            <div className="resume">
              <img src={imageContent.getCounslingImg()} alt="profile_photo" height="50" width="50" />
              <div style={{ lineHeight: ".2", marginTop: "10px" }}>
                <p>View Resume</p>
                <span>
                  <a href={"https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res?.file_upload_url} target="_blank" rel="noreferrer">
                    {res?.file_upload_url}
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="single_applicant" key={index}>
            <div className="applicant_details">
              <div className="applicant_profile">
                <img src={"https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res?.profileImageUrl} alt="profile_photo" height="50" width="50" />
                <div
                  style={{
                    lineHeight: ".1",
                    marginTop: "15px",
                  }}
                >
                  <p>
                    {res?.firstName} {res?.lastName}
                  </p>
                  <span>Web Developer</span>
                </div>
              </div>
              {/* <div className='message_button'> */}
              <Link to={{ pathname: "/message", user: res }}>
                <button type="button">Message Applicant</button>
              </Link>
              {/* </div> */}
            </div>
            <p className="applicant_info">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
              nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
              illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
              ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh
            </p>
            <div className="resume">
              <img src={imageContent.getCounslingImg()} alt="profile_photo" height="50" width="50" />
              <div style={{ lineHeight: ".2", marginTop: "10px" }}>
                <p>View Resume</p>
                <span>
                  <a href={"https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res?.file_upload_url} target="_blank" rel="noreferrer">
                    {res?.file_upload_url}
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      }
    });
  };
  useEffect(() => {
    getUserList(params.id);
    setJobId(params.id);
  }, [getUserList, params.id]);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row applyForJobDiv p-3">
          <div className="col-9">
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span className="title_popup">Applicants</span>
              <span style={{ fontSize: "10px", color: "#6D6D6D" }}>{users.length} Applicants</span>
            </span>
          </div>
          <div className="col-3">
            {/* <img
                     className='crossicon'
                     alt='cross'
                     onClick={props.handleClose}
                     src={imageContent.getCrossImg()}
                     style={{ marginTop: '12px' }}
                  /> */}
          </div>
        </div>
        <div className="all_applicants jobList">{returnApplicantsList()}</div>
      </div>
    </React.Fragment>
  );
};

export default ApplicantsModal;
