import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../style/Modal.css";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegistrationForm";
import { useDispatch } from "react-redux";
import { modalController } from "../../Redux/Slices/noPersistSlice";

export const LoginRegister = (props) => {
    const dispatch = useDispatch();
    const modalList = {
        currentModal: "L",
        currentSize: "lg",
        loginModal: true,
        regModal: false,
        mobileOtpModal: false,
        otpModal: false,
    };
    const [modals, setModals] = useState(modalList);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        props.closeModal(false);
    };
    const manageView = (currentModal) => {
        let initValue = {
            currentModal: currentModal,
            loginModal: false,
            regModal: false,
            otpModal: false,
            forgotPass: false,
            mobileOtpModal: false,
            currentSize: "lg",
        };
        switch (currentModal) {
            case "R":
                initValue.regModal = true;
                initValue.currentSize = "lg";
                break;
            case "O":
                initValue.otpModal = true;
                initValue.currentSize = "lg";
                break;
            case "M":
                initValue.mobileOtpModal = true;
                initValue.currentSize = "lg";
                break;
            case "F":
                initValue.forgotPass = true;
                initValue.currentSize = "lg";
                break;
            default:
                initValue.loginModal = true;
                initValue.currentSize = "lg";

                break;
        }

        setModals(initValue);
    };
    useEffect(() => {
        if (props.currentModal !== undefined) {
            manageView(props.currentModal);
        }
    }, [show, props.currentModal]);
    return (
        <div>
            <Modal
                onHide={() => {
                    setShow(false);
                }}
                show={props.showPopup ? props.showPopup : show}
                size={"lg"}
                centered
            >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                <Modal.Body>
                    {modals.loginModal === true ? <LoginForm manageModal={manageView} IsShow={show} handleClose={handleClose} onCLoseSideNav={props.onCLoseSideNav} /> : <></>}
                    {modals.regModal === true ? <RegisterForm manageModal={manageView} IsShow={show} handleClose={handleClose} onCLoseSideNav={props.onCLoseSideNavs} /> : <></>}
                    {modals.forgotPass === true ? <ForgotPassword manageModal={manageView} IsShow={show} handleClose={handleClose} onCLoseSideNav={props.onCLoseSideNavs} /> : <></>}
                </Modal.Body>
            </Modal>
            <Button
                variant="regButton"
                id={props.id ? props.id : ""}
                className={`text-white rounded-pill loginButton ${props.hideButton ? "hide" : ""}`}
                onClick={() => {
                    // setShow(true);
                    dispatch(modalController({ modal: "loginModal", state: true }));
                }}
                style={{ fontSize: "12px", fontWeight: "600" }}
            >
                Sign Up/Sign In
            </Button>
        </div>
    );
};

export default LoginRegister;
