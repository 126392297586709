import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
    name: "socketSlice",
    initialState: null,
    reducers: {
        saveSocket: (state, action) => action.payload,
    },
});

export const { saveSocket } = socketSlice.actions;

export default socketSlice.reducer;
