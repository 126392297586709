import React, { useEffect, useState } from "react";
import styles from "./userProfileInfoBox.module.css";
import { BsChat, BsPersonCircle } from "react-icons/bs";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import { FaCheck, FaLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";
import {
    cancelFriendRequest,
    capitalizeFirstCharacter,
    getChatHistory,
    getOtherUserProfileData,
    handleFriendRequestAction,
    sendFriendRequest,
    startChatDirectlyWithFriend,
    unfriendConnection,
} from "../../../../Services/commonService";
import { useNavigate } from "react-router-dom";
import { BsPersonFillX } from "react-icons/bs";
import { CircularProgress, Dialog, DialogContent, Tooltip } from "@mui/material";
import { VscVerifiedFilled } from "react-icons/vsc";

const UserProfileInfoBox = () => {
    const navigateTo = useNavigate();
    const { userPersonalDetails } = useSelector((state) => state.persist);
    const { personalDetails } = useSelector((state) => state.persist);
    const { chatHistory } = useSelector((state) => state.chatting);

    const [profileData, setProfileData] = useState(userPersonalDetails);

    const handleOpenChat = () => {
        startChatDirectlyWithFriend(chatHistory, profileData.emailAddress, navigateTo);
    };

    const [loading, setLoading] = useState(false);

    const [confirmation, setConfirmation] = useState(false);
    const [savedFriendListId, setSavedFriendListId] = useState("");
    const closeConfirmation = () => {
        setSavedFriendListId("");
        setConfirmation(false);
    };
    const handleRequest = async (event, friendListId, request_type) => {
        event.stopPropagation();
        setLoading(true);
        const res = await handleFriendRequestAction(friendListId, request_type);
        if (res === "200 OK") {
            setLoading(false);
            getOtherUserProfileData(userPersonalDetails);
        } else {
            setLoading(false);
        }
    };
    const unfriendUser = async (event) => {
        event.stopPropagation();
        setLoading(true);
        const res = await unfriendConnection(savedFriendListId);
        if (res === "200 OK") {
            setLoading(false);
            closeConfirmation();
            getOtherUserProfileData(userPersonalDetails);
        } else {
            setLoading(false);
        }
    };

    const removeFriendRequest = async (event, friendListId) => {
        event.stopPropagation();
        setLoading(true);
        const res = await cancelFriendRequest(friendListId);
        if (res === "200 OK") {
            setLoading(false);
            getOtherUserProfileData(userPersonalDetails);
        } else {
            setLoading(false);
        }
    };

    const doFriendRequestTask = async () => {
        setLoading(true);
        const res = await sendFriendRequest(profileData.emailAddress);
        if (res === "200 OK") {
            setLoading(false);
            getOtherUserProfileData(userPersonalDetails);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(userPersonalDetails).length > 0) {
            setProfileData(userPersonalDetails);
        }
    }, [userPersonalDetails]);

    const [totalExperience, setTotalExperience] = useState(0);
    useEffect(() => {
        if (userPersonalDetails && Object.keys(userPersonalDetails).length > 0) {
            setProfileData(userPersonalDetails);
            if (userPersonalDetails && userPersonalDetails.professional_details) {
                let professionalArr = userPersonalDetails.professional_details;
                let totalExp = 0;
                professionalArr.forEach((curElem) => {
                    let diff = (curElem.worked_till || new Date().getTime()) - curElem.joined_from;
                    totalExp = totalExp + diff;
                });
                totalExp = (totalExp / 1000 / 60 / 60 / 24 / 365).toFixed(1);
                setTotalExperience(totalExp);
            }
        }
    }, [userPersonalDetails]);

    useEffect(() => {
        getChatHistory(0, 10);
    }, []);

    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-3">Are you sure you want to remove this connection?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={unfriendUser}>
                            Yes
                        </button>
                        <button className="normal-button" onClick={closeConfirmation}>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.profileBox}>
                <div className={styles.coverImageContainer}>{profileData.cover_image_url && <img src={profileData.cover_image_url} alt="" style={{ width: "100%" }} />}</div>
                <div className={styles.profilePositionBox}>
                    <div className={styles.profileImageContainer}>
                        {profileData.profileImageUrl ? <img src={profileData.profileImageUrl} alt="" style={{ height: "100%" }} /> : <BsPersonCircle fontSize={88} color="#888888" />}
                    </div>
                </div>
                <div className={styles.profileName}>
                    <span className="me-1">{profileData && profileData.fullName && capitalizeFirstCharacter(profileData.fullName)}</span>
                    {userPersonalDetails && userPersonalDetails.is_verified_from_admin_for_live_session && (
                        <div className={styles.verifiedMark}>
                            <VscVerifiedFilled color="#f1bb46" />
                        </div>
                    )}
                </div>
                {userPersonalDetails.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                <div className={styles.designation}>
                    <span>{`${profileData.job_profile_name ? profileData.job_profile_name : "Designation"}`}</span>
                    {`(${totalExperience} YoE)`}
                </div>
                <div className="mb-3">
                    <div className={styles.infoContainer}>
                        <GoMail color="#f1bb46" />
                        <span>{profileData.emailAddress ? profileData.emailAddress : "johndoe@example.com"}</span>
                    </div>
                    <div className={styles.infoContainer}>
                        <FaLocationDot color="#f1bb46" />
                        <span>
                            <span className="ms-0">{profileData.city ? profileData.city : "City"},</span>
                            <span>{profileData.state ? profileData.state : "State"},</span>
                            <span>{profileData.country ? profileData.country : "Country"}</span>
                        </span>
                    </div>
                </div>
                <div className="w-100 px-3 d-flex justify-content-center">
                    {loading ? (
                        <button className="action-button-disabled">
                            <CircularProgress size={12} style={{ color: "#ffffff" }} />
                        </button>
                    ) : profileData && profileData.user_connection_status && Object.keys(profileData.user_connection_status).length > 0 ? (
                        profileData.user_connection_status.is_friend ? (
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <Tooltip title="Remove Connection">
                                    <button
                                        onClick={() => {
                                            setSavedFriendListId(userPersonalDetails?.user_connection_status?.friendListId);
                                            setConfirmation(true);
                                        }}
                                        className="action-button-reject me-2"
                                    >
                                        <BsPersonFillX />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Chat with connection">
                                    <button onClick={handleOpenChat} className="action-button-connect">
                                        <BsChat />
                                    </button>
                                </Tooltip>
                            </div>
                        ) : userPersonalDetails.user_connection_status.emailAddress !== personalDetails.emailAddress ? (
                            <div className="d-flex justify-content-center">
                                <Tooltip title="Connection request sent click to cancel">
                                    <button className="action-button-disabled">
                                        <FaCheck />
                                    </button>
                                </Tooltip>
                            </div>
                        ) : userPersonalDetails.user_connection_status.request_type === "request" ? (
                            <div className="d-flex align-items-center justify-content-center">
                                <Tooltip title="Accept connection request">
                                    <button
                                        className="action-button-accept me-2"
                                        onClick={(event) => {
                                            handleRequest(event, userPersonalDetails.user_connection_status.friendListId, "accept");
                                        }}
                                    >
                                        <FaCheck />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Reject connection request">
                                    <button
                                        className="action-button-reject"
                                        onClick={(event) => {
                                            handleRequest(event, userPersonalDetails.user_connection_status.friendListId, "reject");
                                        }}
                                    >
                                        <AiOutlineClose />
                                    </button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <Tooltip title="Connection request sent click to cancel">
                                    <button
                                        className="action-button-disabled"
                                        onClick={(event) => {
                                            removeFriendRequest(event, userPersonalDetails.user_connection_status.friendListId);
                                        }}
                                    >
                                        <AiOutlineClose />
                                    </button>
                                </Tooltip>
                            </div>
                        )
                    ) : (
                        <div className="d-flex justify-content-center">
                            <Tooltip title="Send connection request">
                                <button className="action-button-connect" onClick={doFriendRequestTask}>
                                    <AiOutlinePlus />
                                </button>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default UserProfileInfoBox;
