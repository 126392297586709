import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import "../../style/accordion.css";
import ImageContent from "../../content/ImageContent";
import ServiceProvider from "../../provider/serviceProvider";
import { OfferPackage, Experience, OfferSlider } from "./faltuComponent";
import HeaderUtility from "../../utility/HeaderUtility";
import SearchComponent from "../../component/SearchBar/Search1";
import utils from "../../utility/DateUtitlity";
import { Link, useNavigate } from "react-router-dom";

// const data = [
//    {
//       title: 'Type of employment',
//       component: DataFiltersEmplyement,
//       componentProps: {
//          // type: 'employementType',
//          // dataList: typeofEmployement
//       },
//    },
//    {
//       title: 'Experience required',
//       component: Experience,
//       componentProps: {},
//    },
//    {
//       title: 'Offer Package',
//       component: OfferSlider,
//       componentProps: {
//          // type: 'offerPackage',
//          // dataList: offerPackage
//       },
//    },
//    {
//       title: 'Skills',
//       component: DataFiltersSkills,
//       componentProps: {
//          // type: 'skills',
//          // dataList: skills
//       },
//    },
//    {
//       title: 'Location',
//       component: DataFiltersLocation,
//       componentProps: {
//          // type: 'locations',
//          // dataList: locations
//       },
//    },
// ]

const AccordionItem = ({ listItems, handleForFilters = null, title }) => {
  const [searchText, setSearchText] = useState("");
  const [checkboxItems, setcheckboxItems] = useState([]);
  const [visible, setVisible] = useState(8);

  useEffect(() => {
    if (listItems?.length > 0) {
      const fetchData = () => {
        if (searchText) {
          setcheckboxItems(listItems.filter((item) => item?.label?.toLowerCase().includes(searchText.toLowerCase())));
        } else {
          setcheckboxItems(listItems);
        }
      };

      const timer = setTimeout(() => {
        fetchData();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchText, listItems]);

  const handleChange = (e, value) => {
    let newChecked = [...checkboxItems];
    let currentIndex = checkboxItems.findIndex((item) => item.label === value);

    if (e.target.checked) {
      newChecked[currentIndex].isChecked = true;
      if (title === "Type of employment") {
        handleForFilters({
          job_type: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      } else if (title === "Skills") {
        handleForFilters({
          skills: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      } else if (title === "Location") {
        handleForFilters({
          location: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      }
      setcheckboxItems(newChecked);
    } else {
      newChecked[currentIndex].isChecked = false;
      if (title === "Type of employment") {
        handleForFilters({
          job_type: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      } else if (title === "Skills") {
        handleForFilters({
          skills: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      } else if (title === "Location") {
        handleForFilters({
          location: newChecked.filter((item) => item.isChecked).map((item) => item.label),
        });
      }
      setcheckboxItems(newChecked);
    }
  };

  return (
    <Fragment>
      <div className="wrap mb-2">
        <div className="search">
          <input
            type="text"
            className="searchTerm2"
            placeholder="What are you looking for?"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ backgroundColor: "#f6fbfe", height: "2.2rem" }}
          />
          <button type="submit" className="searchButton" style={{ backgroundColor: "#f6fbfe", height: "2.2rem" }}>
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
      {checkboxItems &&
        checkboxItems.slice(0, visible).map((res, index) => (
          <div className="form-check" style={{ marginLeft: "12px" }} key={index}>
            <label className="form-check-label">
              <input className="form-check-input" onChange={(e) => handleChange(e, res.value)} type="checkbox" checked={res.isChecked} value={res.label} id="defaultCheck1" />
              <span style={{ marginLeft: "8px" }}>{res.label}</span>
            </label>
          </div>
        ))}
      {visible < checkboxItems?.length && (
        <p className="text-primary font-weight-bold ml-3" onClick={() => setVisible(checkboxItems.length)}>
          + show more
        </p>
      )}
    </Fragment>
  );
};

export const Accordion = (props) => {
  const { handleForFilters = null, filtersData = {} } = props;
  const [accordionItems, setAccordionItems] = useState([]);

  useEffect(() => {
    if (Object.keys(filtersData).length > 0) {
      setAccordionItems([
        {
          title: "Type of employment",
          open: true,
          list: filtersData?.job_type,
        },
        {
          title: "Experience required",
          open: true,
        },
        {
          title: "Offer Package",
          open: true,
        },
        {
          title: "Skills",
          open: true,
          list: filtersData?.skillset,
        },
        {
          title: "Location",
          open: true,
          list: filtersData?.location,
        },
      ]);
    }
  }, [filtersData]);

  const handleClick = (i) => {
    const newAccordion = [...accordionItems];
    const index = newAccordion.indexOf(i);

    newAccordion[index].open = !newAccordion[index].open;
    setAccordionItems(newAccordion);
  };

  return (
    <div className="accordion">
      {accordionItems.map((i, key) => (
        <div key={key}>
          <div className="title" onClick={() => handleClick(i)}>
            <div className="arrow-wrapper">
              <i className={i.open ? "fa fa-angle-down fa-rotate-180" : "fa fa-angle-down"}></i>
            </div>
            <span className="title-text">{i.title}</span>
          </div>
          <div className={i.open ? "content content-open" : "content"}>
            <div className={i.open ? "content-text content-text-open" : "content-text"}>
              {i.title === "Experience required" ? (
                <Experience handleForFilters={handleForFilters} />
              ) : i.title === "Offer Package" ? (
                <OfferPackage handleForFilters={handleForFilters} />
              ) : (
                <AccordionItem title={i.title} listItems={i?.list} handleForFilters={handleForFilters} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const AccordionConnection = (props) => {
  const { handleForFilters = null, filtersData = {} } = props;
  const [accordionItems, setAccordionItems] = useState([]);

  useEffect(() => {
    if (Object.keys(filtersData).length > 0) {
      setAccordionItems([
        {
          title: "Skills",
          open: true,
          list: filtersData?.skillset,
        },
        {
          title: "Location",
          open: true,
          list: filtersData?.location,
        },
      ]);
    }
  }, [filtersData]);

  const handleClick = (i) => {
    const newAccordion = [...accordionItems];
    const index = newAccordion.indexOf(i);

    newAccordion[index].open = !newAccordion[index].open;
    setAccordionItems(newAccordion);
  };

  return (
    <div className="accordion">
      {accordionItems.map((i, key) => (
        <div key={key}>
          <div className="title" onClick={() => handleClick(i)}>
            <div className="arrow-wrapper">
              <i className={i.open ? "fa fa-angle-down fa-rotate-180" : "fa fa-angle-down"}></i>
            </div>
            <span className="title-text">{i.title}</span>
          </div>
          <div className={i.open ? "content content-open" : "content"}>
            <div className={i.open ? "content-text content-text-open" : "content-text"}>
              {i.title === "Experience required" ? (
                <Experience handleForFilters={handleForFilters} />
              ) : i.title === "Offer Package" ? (
                <OfferSlider min={0} max={40} handleForFilters={handleForFilters} />
              ) : (
                <AccordionItem title={i.title} listItems={i?.list} handleForFilters={handleForFilters} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const FilteredJobs = (props) => {
  let imageContent = new ImageContent();
  let serviceProvider = new ServiceProvider();

  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let jobService = serviceProvider.returnJobService();
  const [filtersData, setFiltersData] = useState({});
  const navigateTo = useNavigate();

  const [jobData, setJobData] = useState([]);

  const searchText = localStorage.getItem("searchItem");

  const [inputJsonData, setInputJson] = useState({
    offerPackage: {
      min_offer_package: 0,
      max_offer_package: 10,
    },
    expRequired: {
      min_exp_required: 0,
      max_exp_required: 10,
    },
    // location:[],
    page_no: 0,
    no_of_records: 155,
  });

  const navigateToJobInfoPage = (id) => {
    navigateTo("/jobInfo/" + id);
  };
  const navigateToJobInfoApplyPage = (id) => {
    navigateTo("/jobInfoApply/" + id);
  };

  const handleJobSearch = (data) => {
    const inputJson = {
      ...inputJsonData,
      searchKey: data.jobTitle,
      // location: data.location.length > 0 ? [data.location] : []
    };
    jobService.searchJob(inputJson, header).then((res) => {
      if (res.status === "200 OK") {
        setJobData(res.data);
      }
    });
  };

  useEffect(() => {
    const getAllJobPostBySearch = () => {
      const data = { ...inputJsonData, searchKey: searchText };
      const inputJson = { ...data };
      jobService.searchJob(inputJson, header).then((res) => {
        if (res.status === "200 OK") {
          setJobData(res.data);
        }
      });
    };
    // if (inputJsonData.location && inputJsonData.location.length > 0) {
    getAllJobPostBySearch();
    // }
  }, [inputJsonData, header, jobService, searchText]);

  const handleForFilters = (data) => {
    setInputJson({ ...inputJsonData, ...data });
  };

  // const handleSidebar = () => {
  //    document.getElementById('mySidenav').style.width = '250px'
  // }

  const [sideNav, setSideNav] = useState(false);

  useEffect(() => {
    let elementStyle = document.getElementById("app-root").style;
    if (sideNav) {
      elementStyle.position = "fixed";
    } else {
      elementStyle.position = "relative";
    }
  }, [sideNav]);

  const [matchs, setMatchs] = useState(window.matchMedia("(min-width: 600px)").matches);

  useEffect(() => {
    const handler = (e) => setMatchs(e.matches);
    window.matchMedia("(min-width: 600px)").addListener(handler);
    jobService.filterData(header).then((res) => {
      if (res.status === "200 OK") {
        setFiltersData((prev) => {
          return {
            ...prev,
            job_type: res.data.job_type.map((item, index) => ({
              label: item,
              value: item,
              isChecked: false,
            })),
            skillset: res.data.skillset.map((item, index) => ({
              label: item,
              value: item,
              isChecked: false,
            })),
            location: res.data.location.map((item, index) => ({
              label: item,
              value: item,
              isChecked: false,
            })),
          };
        });
      } else console.log("fetched some error");
    });
  }, [header, jobService]);

  return (
    <Fragment>
      <SearchComponent onSearch={handleJobSearch} />
      <span className="sideNavToggle" onClick={() => setSideNav(!sideNav)}>
        &#9776; Apply Filters
      </span>

      <div className="filtered_container">
        <div className="filtered_container_left">
          <div>
            <div className="post_job_container">
              <h4>
                <b>Looking for Employees</b>
              </h4>
              <p>Create jobs specific to your needs</p>
              <Link to="/postJob">
                <button className="post_jobs_button">Post jobs</button>
              </Link>
            </div>
            <div className="filters_wrapper">
              <div className="filters_text">
                <h5>
                  <b>Filters</b>
                </h5>
              </div>

              <Accordion handleForFilters={handleForFilters} filtersData={filtersData} />
            </div>
          </div>
        </div>
        <div id="mySidenav" className="sidenav" style={sideNav ? { width: "100%" } : { width: "0px" }}>
          <div href="#" className="closebtn" onClick={() => setSideNav(!sideNav)}>
            &times;
          </div>
          <div className="filters_wrapper">
            <div className="filters_text">
              <h5>
                <b>Filters</b>
              </h5>
            </div>

            <Accordion handleForFilters={handleForFilters} filtersData={filtersData} />
          </div>
        </div>

        <div className="filtered_container_right">
          <div className="post_job_container_mobile">
            <h4>
              <b>Looking for Employees</b>
            </h4>
            <p>Create jobs specific to your needs</p>
            <button className="post_jobs_button">Post jobs</button>
          </div>
          <div className="filtered_container">
            <div>
              {jobData.length > 0 ? (
                jobData.map((res, index) => (
                  <div className="job_container" key={res.job_id}>
                    {!matchs ? (
                      <div>
                        <div className="job_title">
                          <div className="job_title_details">
                            <span>{res.joiningType} joining</span>
                            <h2
                              onClick={() => {
                                navigateToJobInfoPage(res.job_id);
                              }}
                            >
                              {res.jobTitle}
                            </h2>
                          </div>
                        </div>
                        <h5 className="job_description">{res.description}</h5>
                        <div className="details_poster">
                          <div>
                            <span className="experience_location">
                              <img src={imageContent.getOrgImage()} alt="" />
                              <span>Org : {res.companyName}</span>
                            </span>
                            <span className="experience_location">
                              <img src={imageContent.getExperienceicon()} alt="" />
                              <span>Experience : {res.expRequired} yr(s)</span>
                            </span>
                            <span className="experience_location">
                              <span className="dollarIcon mr-1">
                                <i className="fa fa-usd" style={{ fontSize: "16px" }} aria-hidden="true"></i>
                              </span>
                              <span>Offer Package: {res?.offerPackage} Lakh(s)</span>
                            </span>
                          </div>
                          <div></div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span className="jobPosted mt-3 ">Posted {utils.timeAgo(res.date)} ago</span>
                          <div className="uploadedby">
                            <p
                              style={{
                                marginTop: "8px",
                                marginBottom: 0,
                              }}
                            >
                              Posted by:
                            </p>
                            <div className="d-flex align-items-center">
                              <div
                                style={{
                                  lineHeight: ".1",
                                }}
                              >
                                <p>{res.posted_by}</p>
                                <span>{res.job_profile_name}</span>
                              </div>
                              <img
                                // src={imageContent.getCounslingImg()}
                                src={res.user_details.profileImageUrl ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res.user_details.profileImageUrl : imageContent.getProfilemage()}
                                alt="profile_photo"
                                height="50"
                                width="50"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="apply_button mt-3"
                          onClick={() => {
                            navigateToJobInfoApplyPage(res.job_id);
                          }}
                        >
                          Apply Now
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <span>
                          <div className="job_title">
                            <div className="job_title_details">
                              <span>{res.joiningType} joining</span>
                              <h2
                                onClick={() => {
                                  navigateToJobInfoPage(res.job_id);
                                }}
                              >
                                {res.jobTitle}
                              </h2>
                            </div>
                            <button
                              type="button"
                              className="apply_button"
                              onClick={() => {
                                navigateToJobInfoApplyPage(res.job_id);
                              }}
                            >
                              Apply Now
                            </button>
                          </div>
                          <h5 className="job_description">{res.description}</h5>
                        </span>
                        <div className="details_poster">
                          <div>
                            <span className="experience_location">
                              <img src={imageContent.getOrgImage()} alt="" />
                              <span>Organization : {res.companyName}</span>
                            </span>
                            <span className="experience_location">
                              <img src={imageContent.getExperienceicon()} alt="" />
                              <span>Experience : {res.expRequired} yr(s)</span>
                            </span>
                            <span className="experience_location">
                              <img src={imageContent.getLocationImg()} alt="" />
                              <span>Location : {res.location}</span>
                            </span>
                            <span className="experience_location">
                              <span className="dollarIcon mr-1">
                                <i className="fa fa-usd" style={{ fontSize: "16px" }} aria-hidden="true"></i>
                              </span>
                              <span>Offer Package: {res?.offerPackage} Lakh(s)</span>
                            </span>
                          </div>
                          <div></div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <span className="jobPosted">Posted {utils.timeAgo(res.date)} ago</span>
                          <div className="uploadedby">
                            <p style={{ marginTop: "4px" }}>Posted by:</p>
                            <div
                              style={{
                                lineHeight: ".1",
                                marginTop: "15px",
                                marginLeft: "15px",
                              }}
                            >
                              <p>{res.posted_by}</p>
                              <span>{res.job_profile_name}</span>
                            </div>
                            <img
                              // src={imageContent.getCounslingImg()}
                              src={res.user_details.profileImageUrl ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res.user_details.profileImageUrl : imageContent.getProfilemage()}
                              alt="profile_photo"
                              height="50"
                              width="50"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <h2>No jobs found now.. But dont worry.. Wait for your opportunity..!</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FilteredJobs;
