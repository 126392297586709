import React from "react";
import styles from "./ratingCard.module.css";
import { Dropdown } from "react-bootstrap";
import { HiEllipsisVertical } from "react-icons/hi2";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { getDurationString } from "../../../../../Services/commons";
import { modalControl } from "../../../../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { saveEditingRatingObj } from "../../../../../Redux/Slices/profileSlice";

const RatingCard = ({ cardData }) => {
    const dispatch = useDispatch();
    const { personalDetails } = useSelector((state) => state.persist);
    const editRating = () => {
        dispatch(saveEditingRatingObj(cardData));
        modalControl("editRatingModal", true);
    };
    return (
        <div className={styles.ratingCard}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className={styles.cardName}>{cardData && cardData.remark_given_by_name ? cardData.remark_given_by_name : "Anonymous"}</div>
                    <div className={styles.cardTime}>({cardData && cardData.create_time && getDurationString(cardData.create_time)})</div>
                </div>
                <Dropdown>
                    <Dropdown.Toggle className="dropdown-no-style">
                        <div className={styles.ellipsisBox}>
                            <HiEllipsisVertical />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {personalDetails && personalDetails.emailAddress === cardData.remark_given_by_email_name && (
                            <Dropdown.Item className={styles.dropOption} onClick={editRating}>
                                Edit
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item className={styles.dropOption}>Report</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="d-flex align-items-center mb-2">
                <div className={styles.starsSection}>
                    {cardData &&
                        cardData.rating &&
                        [...new Array(cardData.rating)].map((curElem) => {
                            return <AiFillStar color="#f1bb46" />;
                        })}
                    {cardData &&
                        cardData.rating &&
                        [...new Array(5 - cardData.rating)].map((curElem) => {
                            return <AiOutlineStar color="#000000" />;
                        })}
                </div>
            </div>
            <div className={styles.descriptionText}>{cardData && cardData.remark ? cardData.remark : "(No remark)"}</div>
        </div>
    );
};

export default RatingCard;
