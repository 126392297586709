import React, { useEffect, useRef, useState } from "react";
import styles from "./profileInfoBox.module.css";
import { BsFillCameraFill, BsPersonCircle } from "react-icons/bs";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import { FaLocationDot } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import { postTReq } from "../../../../Services/api";
import { apiEndpoints } from "../../../../PrEnums/prEnums";
import { capitalizeFirstCharacter, getPersonalDetails } from "../../../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { savePersonalDetails } from "../../../../Redux/Slices/persistSlice";
import { Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { VscVerifiedFilled } from "react-icons/vsc";

const ProfileInfoBox = ({ data }) => {
    const dispatch = useDispatch();
    const { user } = useParams();
    const { personalDetails } = useSelector((state) => state.persist);

    const [loading, setLoading] = useState(false);

    const profileImageRef = useRef(null);

    const uploadImageToS3 = async (uploadUrl, imageFile) => {
        try {
            const imgRes = await axios.put(uploadUrl, imageFile, {
                headers: {
                    "Content-Type": imageFile.type, // Set the content type of the image
                },
            });
            return imgRes.data;
        } catch (error) {
            return { error: error };
        }
    };

    const handleProfileImageUpload = async (event) => {
        setLoading(true);
        const { files } = event.target;
        const res = await postTReq(apiEndpoints.uploadProfileImage, {
            file_name: files[0].name,
        });
        if (res && !res.error) {
            const fileUploadUrl = res.data.file_upload_url; // Extract the file_upload_url from the response
            const imageFile = files[0];

            try {
                const imgRes = await uploadImageToS3(fileUploadUrl, imageFile);
                if (imgRes && !imgRes.error) {
                    console.log("Error");
                } else {
                    // console.log("Image upload error:", imgRes.error);
                    let x = JSON.parse(JSON.stringify(personalDetails));
                    x["profileImageUrl"] = res.data.file_download_url;
                    dispatch(savePersonalDetails(x));
                    setLoading(false);
                }
            } catch (error) {
                console.log("Error uploading image:", error);
                setLoading(false);
            }
        } else {
            console.log(res.error);
            setLoading(false);
        }
    };

    const coverImageRef = useRef(null);
    const handleCoverImageUpload = async (event) => {
        const { files } = event.target;
        const res = await postTReq(apiEndpoints.uploadCoverImage, {
            file_name: files[0].name,
        });
        if (res && !res.error) {
            const fileUploadUrl = res.data.file_upload_url;
            const imageFile = files[0];

            try {
                const imgRes = await uploadImageToS3(fileUploadUrl, imageFile);
                if (imgRes && !imgRes.error) {
                    console.log("Error");
                } else {
                    let x = JSON.parse(JSON.stringify(personalDetails));
                    x["cover_image_url"] = res.data.file_download_url;
                    dispatch(savePersonalDetails(x));
                    setLoading(false);
                }
            } catch (error) {
                console.log("Error uploading image:", error);
            }
        } else {
            console.log(res.error);
        }
    };

    const [editMode, setEditMode] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const howToShowEdit = () => {
        if (!editMode) {
            setShowButtons(true);
        }
    };
    const howToUnshowEdit = () => {
        if (!editMode) {
            setShowButtons(false);
        }
    };

    const [profileData, setProfileData] = useState(personalDetails);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setProfileData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        const res = await postTReq(`${apiEndpoints.updatePersonalDetails}`, profileData);
        if (res && !res.error) {
            setEditMode(false);
            getPersonalDetails();
        } else {
            console.log(res.error);
        }
    };

    const [totalExperience, setTotalExperience] = useState(0);
    useEffect(() => {
        if (personalDetails && Object.keys(personalDetails).length > 0) {
            setProfileData(personalDetails);
            if (personalDetails && personalDetails.professional_details) {
                let professionalArr = personalDetails.professional_details;
                let totalExp = 0;
                professionalArr.forEach((curElem) => {
                    let diff = (curElem.worked_till || new Date().getTime()) - curElem.joined_from;
                    totalExp = totalExp + diff;
                });
                totalExp = (totalExp / 1000 / 60 / 60 / 24 / 365).toFixed(1);
                setTotalExperience(totalExp);
            }
        }
    }, [personalDetails]);

    useEffect(() => {
        if (user !== "user") {
            getPersonalDetails();
        }
    }, [editMode, user]);

    return (
        <React.Fragment>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.profileBox} onMouseOver={howToShowEdit} onMouseOut={howToUnshowEdit}>
                {!editMode && showButtons && (
                    <div className={`content-card-edit ${styles.editProfileIcon}`} onClick={() => setEditMode(true)}>
                        <FiEdit2 />
                    </div>
                )}
                {editMode && (
                    <div className={`content-card-confirm ${styles.editProfileIcon}`} onClick={handleSubmit}>
                        <AiOutlineCheck />
                    </div>
                )}
                <div className={styles.coverImageContainer}>
                    {personalDetails && personalDetails.cover_image_url && <img src={personalDetails.cover_image_url} alt="" />}
                    <div className={styles.editImageIcon} onClick={() => coverImageRef.current.click()}>
                        <BsFillCameraFill />
                    </div>
                    <input type="file" className="d-none" ref={coverImageRef} onChange={handleCoverImageUpload} />
                </div>
                <div className={styles.profilePositionBox}>
                    <div className={styles.profileImageContainer}>
                        {profileData && profileData.profileImageUrl ? <img src={profileData.profileImageUrl} alt="" style={{ height: "100%" }} /> : <BsPersonCircle fontSize={88} color="#888888" />}
                        <div className={styles.editProfilePicOverlay} onClick={() => profileImageRef.current.click()}>
                            <AiOutlinePlus />
                            Edit Image
                        </div>
                        <input className="d-none" type="file" ref={profileImageRef} onChange={handleProfileImageUpload} />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className={styles.profileName}>
                        {editMode ? (
                            <div className={styles.profileNameInputContainer}>
                                <input type="text" value={profileData.fullName} name="fullName" onChange={handleInput} />
                            </div>
                        ) : (
                            <span>{profileData && profileData.fullName && capitalizeFirstCharacter(profileData.fullName)}</span>
                        )}
                    </div>
                    {personalDetails && personalDetails?.is_verified_from_admin_for_live_session && (
                        <div className={styles.verifiedMark}>
                            <VscVerifiedFilled color="#f1bb46" />
                        </div>
                    )}
                </div>
                {personalDetails?.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                <div className={styles.designation}>
                    {editMode ? (
                        <span className={styles.editInputContainer}>
                            <input type="text" placeholder="Department" name="job_profile_name" value={profileData.job_profile_name} onChange={handleInput} />
                        </span>
                    ) : (
                        <span>{`${
                            profileData && profileData.job_profile_name
                                ? profileData.job_profile_name
                                : profileData?.professional_details && profileData.professional_details[0] && profileData.professional_details[0].designation
                                ? profileData.professional_details[0].designation
                                : "-"
                        } in ${
                            profileData && profileData.professional_details && profileData.professional_details[0] && profileData.professional_details[0].companyName
                                ? profileData.professional_details[0].companyName
                                : ""
                        }`}</span>
                    )}
                    {`(${totalExperience} YoE)`}
                </div>
                <div className="mb-3">
                    <div className={styles.infoContainer}>
                        <GoMail color="#f1bb46" />
                        <span>{profileData && profileData.emailAddress ? profileData.emailAddress : "johndoe@example.com"}</span>
                    </div>
                    <div className={styles.infoContainer}>
                        <FaLocationDot color="#f1bb46" />
                        <span>
                            {editMode ? (
                                <span className={styles.locationEditContainer} style={{ marginLeft: "0" }}>
                                    <input type="text" placeholder="City" name="city" value={profileData.city} onChange={handleInput} />
                                </span>
                            ) : (
                                <span className="ms-0">{profileData && profileData.city ? profileData.city : "City"},</span>
                            )}

                            {editMode ? (
                                <span className={styles.locationEditContainer}>
                                    <input type="text" placeholder="State" name="state" value={profileData.state} onChange={handleInput} />
                                </span>
                            ) : (
                                <span>{profileData && profileData.state ? profileData.state : "State"},</span>
                            )}

                            {editMode ? (
                                <span className={styles.locationEditContainer}>
                                    <input type="text" placeholder="Country" name="country" value={profileData.country} onChange={handleInput} />
                                </span>
                            ) : (
                                <span>{profileData && profileData.country ? profileData.country : "Country"}</span>
                            )}
                        </span>
                    </div>
                </div>
                {/* <div className={styles.connectButtonContainer}>
                <button>
                    <AiOutlinePlus />
                    Connect
                </button>
            </div> */}
            </div>
        </React.Fragment>
    );
};

export default ProfileInfoBox;
