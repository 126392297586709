import React, { useState } from 'react';
import CreateProfileSidebar from '../../component/CreateProfileSidebar/CreateProfileSidebar';
import Welcome from '../../component/Welcome/Welcome';
import PersonalDetails from '../../component/PersonalDetails/PersonalDetails';
import EducationalDetails from '../../component/Educational/EducationalDetails';
import ProfessionalDetails from '../../component/Professional/ProfessionalDetails';
import Loader from '../../component/Loader/Loader';
import '../../style/CompleteProfile.css';

const CreateProfile = () => {
    const [screenIndex,setScreenIndex] = useState(0);
    const [loader,setLoader] = useState(true);
    const updateScreenIndex=(index)=>{
        setScreenIndex(index);
    }
    const returnSelectedScreen=()=>{
        if(screenIndex===0){
            return <Welcome updateLoader={setLoader}/>
        }
        if(screenIndex===1){
            return <PersonalDetails updateLoader={setLoader} updateScreen={updateScreenIndex}/>
        }
        if(screenIndex===2){
            return <EducationalDetails updateLoader={setLoader} updateScreen={updateScreenIndex}/>
        }
        if(screenIndex===3){
            return <ProfessionalDetails updateLoader={setLoader}/>
        }
    }
    return (
        <React.Fragment>
            <Loader isVisible={loader}/>
            <div className="container-fluid createProfileContainer ml-0 mr-0">
                <div className="row pl-5 ml-0 mr-0">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4"></div>
                    <div className='pt-5 col-xs-12 col-sm-12 col-md-8 col-lg-8'>
                        <p className='pl-1 screenTitle font-3'>Complete Your Profile</p>
                    </div>
                </div>
                <div className='row ml-0 mr-0'>
                    <div className='createProfileSideBar col-xs-12 col-sm-12 col-md-4 col-lg-4 pl-4'>
                        {/* <div className='createProfileSideBar float-right col-xs-10 col-sm-10 col-md-10 col-lg-10'>
                            <CreateProfileSidebar screenIndex={screenIndex} updateScreenIndex={updateScreenIndex}/>
                        </div> */}
                        <CreateProfileSidebar screenIndex={screenIndex} updateScreenIndex={updateScreenIndex}/>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mt-xs-2 mt-sm-2 mt-md-0'>
                        <div className='col-xs-11 col-sm-11 col-md-11 col-lg-11'>
                            {returnSelectedScreen()}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateProfile;