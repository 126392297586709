import React, { useContext } from "react";
import styles from "../jsSearchResult.module.css";
import { PiWarningCircleLight, PiWarningCircleFill } from "react-icons/pi";
import { BsBookmarkPlus, BsFillBookmarkCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { delReq, postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { applyJobMail, toasterControl } from "../../../Services/commonService";
import { useDispatch } from "react-redux";
import { makeJobApplied, makeJobBookmarked, makeJobNotBookmarked } from "../../../Redux/Slices/noPersistSlice";
import AuthContext from "../../../context/authContext";
import { openLoginModalWhenNotLoggedIn } from "../../../Services/commons";
import { saveActionToPerformAfterLogin } from "../../../Redux/Slices/generalSlice";

const EachJobCard = ({ cardData }) => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    let context = useContext(AuthContext);
    const isAuthenticated = context.authenticatedStatus;

    const applyToThisJob = async (event, jobId) => {
        const res = await postTReq(apiEndpoints.applyJob, {
            job_id: jobId,
            message: "I am interested.",
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Applied to the job");
            dispatch(makeJobApplied(jobId));
            applyJobMail(jobId);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const handleBookmark = async (event, jobId) => {
        event.stopPropagation();
        const res = await postTReq(apiEndpoints.bookmarkJob, {
            job_id: jobId,
        });
        if (res && !res.error) {
            dispatch(makeJobBookmarked({ jobId: jobId, bookmarkNum: res.data.data.job_bookmarked_id }));
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const removeBookmark = async (event, jobId, bookId) => {
        event.stopPropagation();
        const res = await delReq(apiEndpoints.removeBookmark, {
            job_bookmarked_id: bookId,
        });
        if (res && !res.error) {
            dispatch(makeJobNotBookmarked(jobId));
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const reportJob = async (event, jobId) => {
        event.stopPropagation();
        const res = await postTReq(apiEndpoints.reportJob, {
            job_id: jobId,
        });
        if (res && !res.error) {
            console.log(res);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };
    return (
        <div className={styles.eachJobCard} onClick={() => navigateTo(`/view-job/${cardData.job_id}`)}>
            <div className={styles.jobCardContent}>
                <div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                            <div className={styles.joiningType}>{cardData.joiningType ? cardData.joiningType : "-"}</div>
                            <div className={styles.jobName}>{cardData.jobTitle ? cardData.jobTitle.toUpperCase() : "-"}</div>
                        </div>
                        <div>
                            {cardData.job_applied_status && Object.keys(cardData.job_applied_status).length > 0 ? (
                                <button className="normal-done-button me-2" style={{ width: "10rem" }}>
                                    Already Applied
                                </button>
                            ) : (
                                <button
                                    className="normal-button me-2"
                                    style={{ width: "10rem" }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (isAuthenticated) {
                                            applyToThisJob(event, cardData.job_id);
                                        } else {
                                            openLoginModalWhenNotLoggedIn();
                                            dispatch(
                                                saveActionToPerformAfterLogin({
                                                    doThis: () => applyToThisJob(event, cardData.job_id),
                                                })
                                            );
                                        }
                                    }}
                                >
                                    Apply Now
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={styles.jobPostName}>{`${cardData.role ? cardData.role : "-"}`}</div>
                    <div className="d-flex flex-wrap mb-2">
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionName}>Experience Required:</div>
                            <div className={styles.sectionValue}>{cardData.expRequired ? cardData.expRequired : "-"}</div>
                        </div>
                        <div className={styles.sectionContainer}>
                            <div className={styles.sectionName}>Location:</div>
                            <div className={styles.sectionValue}>{cardData.location ? cardData.location : "-"}</div>
                        </div>
                        {/* <div className={styles.sectionContainer}>
                            <div className={styles.sectionName}>Job Profile:</div>
                            <div className={styles.sectionValue}>{cardData.job_profile_name ? cardData.job_profile_name : "-"}</div>
                        </div> */}
                    </div>
                    <div className="mb-2">
                        <div className={styles.sectionName}>Skills:</div>
                        <div className="d-flex align-items-center flex-wrap">
                            {cardData.skillset &&
                                cardData.skillset.map((curElem) => {
                                    return <div className={styles.skillPill}>{curElem}</div>;
                                })}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className={styles.postedByContainer}>
                            <div className={styles.posterImageContainer}>
                                <img src={cardData.user_details.profileImageUrl} alt="" />
                            </div>
                            <div>
                                <div className={styles.posterName}>{cardData.posted_by ? cardData.posted_by : "-"}</div>
                                <div className={styles.posterEmail}>{`${cardData.user_details && cardData.user_details.designation ? cardData.user_details.designation : "-"} ${
                                    cardData.user_details && cardData.user_details.companyName ? "in" : ""
                                } ${cardData.user_details && cardData.user_details.companyName ? cardData.user_details.companyName : ""}`}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className={`${styles.reportButton} me-2`} onClick={(event) => reportJob(event, cardData.job_id)}>
                                {true ? <PiWarningCircleLight fontSize={24} /> : <PiWarningCircleFill fontSize={24} />}
                            </div>
                            {cardData.job_bookmarked_id ? (
                                <div className={styles.actionButton} onClick={(event) => removeBookmark(event, cardData.job_id, cardData.job_bookmarked_id)}>
                                    <BsFillBookmarkCheckFill fontSize={20} />
                                </div>
                            ) : (
                                <div className={styles.actionButton} onClick={(event) => handleBookmark(event, cardData.job_id)}>
                                    <BsBookmarkPlus fontSize={20} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EachJobCard;
