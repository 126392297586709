import React from "react";
import styles from "./footer.module.css";
import { footerLinks } from "../../Services/constants";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigateTo = useNavigate();
    return (
        <React.Fragment>
            {/* <div className="container-fluid">
        <div className="row" style={{ backgroundColor: "#020202" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 pl-md-5 pr-md-5">
            <div className="row pl-md-4">
              <div className="col-6 pl-md-5 pt-5">
                <div>
                  <h6>
                    <b style={{ color: "rgb(241, 187, 70)" }}>Contact Us</b>
                  </h6>
                  <span className="footerWebInfo" style={{ color: "rgb(241, 187, 70)" }}>
                    service@jobsifarish.com
                  </span>
                </div>
              </div>
              <div className="col-6 pl-5 pt-5">
                <div>
                  <h6>
                    <b style={{ color: "rgb(241, 187, 70)" }}>Follow Us</b>
                  </h6>
                </div>
                <div className="footerWebInfo" style={{ color: "rgb(241, 187, 70)" }}>
                  Linkdin
                </div>
                <div className="footerWebInfo" style={{ color: "rgb(241, 187, 70)" }}>
                  Instagram
                </div>
                <div className="footerWebInfo" style={{ color: "rgb(241, 187, 70)" }}>
                  Youtube
                </div>
                <div className="footerWebInfo" style={{ color: "rgb(241, 187, 70)" }}>
                  Facebook
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
            <div className={styles.container}>
                {footerLinks.map((curElem, index) => {
                    return (
                        <div key={`${curElem.label}-${index}`} className={styles.section}>
                            <div className="d-flex flex-column align-items-start">
                                <div className={styles.heading}>{curElem.label}</div>
                                {curElem.linksArray &&
                                    curElem.linksArray.map((cur, ind) => {
                                        return (
                                            <div key={`${cur.label}-${ind}`} className={styles.eachLink} onClick={() => navigateTo(cur.route)}>
                                                {cur.icon && <cur.icon className="me-2" />}
                                                {cur.label}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default Footer;
