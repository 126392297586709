import React from "react";
import styles from "./connectionsBox.module.css";
import { BsPeopleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { navigationRoutes } from "../../../../PrEnums/prEnums";
import { useSelector } from "react-redux";

const ConnectionsBox = () => {
    const navigateTo = useNavigate();
    const { personalDetails } = useSelector((state) => state.persist);
    return (
        <div className={`content-card`}>
            <div className="content-card-heading">Your Connections</div>
            <div className={styles.connectionBoxLine} onClick={() => navigateTo(navigationRoutes.connections)}>
                <div className="d-flex align-items-center">
                    <BsPeopleFill className="me-2" color="#f1bb46" fontSize={16} />
                    <div className={styles.lineText}>Connections</div>
                </div>
                <div className={styles.lineValue}>{personalDetails && personalDetails.connection_count}</div>
            </div>
        </div>
    );
};

export default ConnectionsBox;
