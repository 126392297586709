import React, { useState } from "react";
import styles from "./sentRequestList.module.css";
import { useSelector } from "react-redux";
import { cancelFriendRequest, getSentRequest, handleGetProfileAndGoThere } from "../../Services/commonService";
import { AiFillStar, AiOutlineClose } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdOutlineWork } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { CircularProgress, Tooltip } from "@mui/material";
import { VscVerifiedFilled } from "react-icons/vsc";

const SentRequestList = () => {
    const navigateTo = useNavigate();
    const { sentList } = useSelector((state) => state.persist);

    const [loading, setLoading] = useState([]);
    const removeFriendRequest = async (event, friendListId) => {
        event.stopPropagation();
        let newLoading = loading.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await cancelFriendRequest(friendListId);
        if (res === "200 OK") {
            setLoading(newLoading);
            getSentRequest(0, 10);
        } else {
            setLoading(newLoading);
        }
    };

    return (
        <div className={styles.container}>
            {sentList &&
                sentList.map((curElem) => {
                    return (
                        <div className={styles.eachCard} onClick={() => handleGetProfileAndGoThere(curElem, navigateTo)}>
                            <div className={styles.coverImage}>{curElem.cover_image_url && <img src={curElem.cover_image_url} alt="" style={{ width: "100%" }} />}</div>
                            <div className={styles.profileImage}>{curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                            <div className={styles.cardContent}>
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className={styles.profileName}>{curElem.fullName ? curElem.fullName : "-"}</div>
                                        {curElem.is_verified_from_admin_for_live_session && (
                                            <div className="ms-1">
                                                <VscVerifiedFilled color="#f1bb46" fontSize={18} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className={styles.ratingPill}>
                                            <AiFillStar fontSize={14} color="#f1bb46" className="me-1" />
                                            {curElem.over_all_rating}
                                        </div>
                                    </div>
                                    {curElem.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                                    {curElem.designation && (
                                        <div className={styles.designationName}>
                                            <MdOutlineWork color="#f1bb46" className="me-1" />
                                            {`${curElem.designation ? curElem.designation : "-"}`}
                                        </div>
                                    )}
                                    {curElem.companyName && (
                                        <div className={styles.companyName}>
                                            <GoOrganization color="#f1bb46" className="me-1" />
                                            {`${curElem.companyName ? curElem.companyName : "-"}`}
                                        </div>
                                    )}
                                    {curElem.location && (
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionName}>Location:</div>
                                            <div className={styles.sectionValue}>{curElem.location ? curElem.location : "-"}</div>
                                        </div>
                                    )}
                                    {curElem.joiningType && (
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionName}>Joining type:</div>
                                            <div className={styles.sectionValue}>{curElem.joiningType ? curElem.joiningType : "-"}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-center">
                                    {loading.includes(curElem.friendListId) ? (
                                        <Tooltip title="Loading...">
                                            <button className="action-button-disabled">
                                                <CircularProgress size={12} style={{ color: "#000000" }} />
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Cancel sent connection request">
                                            <button
                                                className="action-button-reject"
                                                onClick={(event) => {
                                                    setLoading((prev) => {
                                                        return [...prev, curElem.friendListId];
                                                    });
                                                    removeFriendRequest(event, curElem.friendListId);
                                                }}
                                            >
                                                <AiOutlineClose />
                                            </button>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default SentRequestList;
