import React, { useEffect } from "react";
import styles from "./notificationAnimBox.module.css";
import { FaBell } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { notificationController } from "../../Redux/Slices/notificationSlice";

const NotificationAnimBox = () => {
    const dispatch = useDispatch();
    const { notificationState, notificationObj } = useSelector((state) => state.notifications);
    useEffect(() => {
        if (notificationState) {
            setTimeout(() => {
                dispatch(notificationController({ state: false, obj: {} }));
            }, 4000);
        }
    }, [dispatch, notificationState]);
    return (
        <div className={styles.animBox} style={{ right: notificationState ? "1rem" : "-100%" }}>
            <div className={styles.bellIcon}>
                <FaBell />
            </div>
            <div className={styles.notificationMessage}>{notificationObj && notificationObj.notification_message}</div>
        </div>
    );
};

export default NotificationAnimBox;
