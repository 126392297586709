import React, { useEffect, useState } from "react";
import ImageContent from "../../content/ImageContent";
import "../../style/Home.css";
import styles from "./searchBar.module.css";
import { useDispatch } from "react-redux";
import { saveSearchTextFromHome } from "../../Redux/Slices/noPersistSlice";
import { useNavigate } from "react-router-dom";

const Search = (props) => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    let imageContent = new ImageContent();

    let [jobTitle, setJobTitle] = useState("");

    // const switchImg = (img) => {
    //    switch (img) {
    //       case bgImagesList[0]:
    //          setBgImg(imageContent.getSeachBgImgOne())
    //          break
    //       case bgImagesList[1]:
    //          setBgImg(imageContent.getSeachBgImgTwo())
    //          break
    //       case bgImagesList[2]:
    //          setBgImg(imageContent.getSeachBgImg())
    //          break
    //       default:
    //          setBgImg(imageContent.getSeachBgImg())
    //    }
    // }
    // const handleJobLocation = (value) => {
    //    setLocation(value)
    //    setJobTitle('')
    //    if (value.length > 2) {
    //       setIsDisabled(false)
    //    } else {
    //       setIsDisabled(true)
    //    }
    // }
    // const init = () => {
    //    setTimeout(() => {
    //       switchImg(bgImg)
    //    }, 5000)
    // }
    // const generateInputJson = () => {
    //    let inputJson = {
    //       searchKey: jobTitle !== '' ? jobTitle : undefined
    //       // "location": location!==''?location:undefined
    //    }
    //    return inputJson
    // }
    // useEffect(() => {
    //    init()
    // })

    let bgImagesList = [imageContent.getSeachBgImg(), imageContent.getSeachBgImgOne(), imageContent.getSeachBgImgTwo()];
    let [bgImg, setBgImg] = useState(imageContent.getSeachBgImg());

    const switchImg = (img) => {
        switch (img) {
            case bgImagesList[0]:
                setBgImg(imageContent.getSeachBgImgOne());
                break;
            case bgImagesList[1]:
                setBgImg(imageContent.getSeachBgImgTwo());
                break;
            case bgImagesList[2]:
                setBgImg(imageContent.getSeachBgImg());
                break;
            default:
                setBgImg(imageContent.getSeachBgImg());
        }
    };
    const handleJobTitle = (value) => {
        setJobTitle(value);
        // setLocation('')
        // if (value.length > 2) {
        //    setIsDisabled(false)
        // } else {
        //    setIsDisabled(true)
        // }
        dispatch(saveSearchTextFromHome(value));
    };

    const init = () => {
        setTimeout(() => {
            switchImg(bgImg);
        }, 5000);
    };

    const onSearch = (e) => {
        e.preventDefault();
        localStorage.setItem("searchItem", jobTitle);
        // props.onSearch(generateInputJson())
        props.history.push({
            pathname: "/filterJobs",
            jobTitle: jobTitle,
        });
    };

    useEffect(() => {
        init();
    });

    return (
        <React.Fragment>
            {/* <div className="container-fluid homeSearchComponent">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-6 pr-0 pl-0">
                        <Form.Control className="searchInput" placeholder="Search By Job Title or Location" value={jobTitle} type="text" onChange={e => handleJobTitle(e.target.value)} />
                    </div>
                    <div className="col-2 pl-0">
                        <Button className="searchBtn" onClick={onSearch}>Search</Button>
                    </div>
                </div>
            </div> */}
            <div className="container-fluid" style={{ margin: "8rem 0 50px" }}>
                <div className="row mt-5">
                    <div className="col-xs-6 col-md-7" style={{ padding: "0% 6% " }}>
                        <div className="title_ mt-4 text-center">
                            {/* Your <span className="title_yellow"> Dream job </span> is Waiting for you */}
                            <span className="title_yellow">Engage</span> with verified professionals now
                        </div>
                        <div className={`${styles.searchInputContainer} mt-4`} style={{ backgroundColor: "#ffffff" }}>
                            <form onSubmit={onSearch} className="form_search">
                                <input type="text" value={jobTitle} onChange={(e) => handleJobTitle(e.target.value)} className="searchTerm" required placeholder="Ex: John Doe" />
                                <button type="submit" id="search" className={styles.searchButton} onClick={() => navigateTo("/search-results")}>
                                    Search
                                </button>
                            </form>
                        </div>
                        <div className="mt-4">
                            <button className="example_button">Ex: Web developer</button>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-5 d-flex justify-content-center">
                        <div style={{ padding: "1rem", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px" }}>
                            <img
                                src={imageContent.getJobSearchImage()}
                                className="getJobSearchImage"
                                alt="job_image"
                                // height={420}
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Search;
