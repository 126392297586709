import { BiSolidLockAlt } from "react-icons/bi";
import { BsPersonCircle, BsPhone } from "react-icons/bs";
import { GoMail } from "react-icons/go";

// export const employeeRegistrationSteps = [
//   {
//     step: 2,
//     name: "Professional Details",
//     tabIcon: <BsBriefcase />,
//     fields: [
//       {
//         label: "Salary",
//         identifier: "salary",
//         fullWidth: 3,
//         type: "number",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Currency",
//         identifier: "currency",
//         fullWidth: 3,
//         type: "select",
//         options: [],
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Paid",
//         identifier: "paid",
//         fullWidth: 3,
//         type: "select",
//         options: [
//           {
//             iden: "monthly",
//             label: "Monthly",
//           },
//         ],
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Start Date",
//         identifier: "start_date",
//         fullWidth: 3,
//         type: "date",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Termination Date",
//         identifier: "termination_date",
//         fullWidth: 3,
//         type: "date",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Re-Hire Date",
//         identifier: "hire_date",
//         fullWidth: 3,
//         type: "date",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Reporting Manager Email",
//         identifier: "report_manager_email",
//         fullWidth: 2,
//         type: "select",
//         options: [
//           {
//             iden: "this@that.com",
//             label: "this@that.com",
//           },
//         ],
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Reporting Manager Name",
//         identifier: "report_manager_name",
//         fullWidth: 2,
//         type: "text",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Internal ID",
//         identifier: "internal_id",
//         fullWidth: 2,
//         type: "text",
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Department",
//         identifier: "department",
//         fullWidth: 2,
//         type: "select",
//         options: [],
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Pre Employment Verification",
//         identifier: "verification",
//         fullWidth: 2,
//         type: "select",
//         options: [
//           {
//             iden: true,
//             label: "Yes",
//           },
//           {
//             iden: false,
//             label: "No",
//           },
//         ],
//         validation: {
//           required: true,
//         },
//       },
//       {
//         label: "Probation Period",
//         identifier: "probation",
//         fullWidth: 2,
//         type: "select",
//         options: [
//           {
//             iden: true,
//             label: "Yes",
//           },
//           {
//             iden: false,
//             label: "No",
//           },
//         ],
//         validation: {
//           required: true,
//         },
//       },
//     ],
//   },
// ];

export const registrationForm = {
    label: "Register User",
    fields: [
        {
            label: "Full Name",
            icon: <BsPersonCircle color="#888888" />,
            identifier: "fullName",
            fullWidth: 1,
            type: "text",
            validation: {
                required: true,
            },
        },
        {
            label: "Mobile Number",
            icon: <BsPhone color="#888888" />,
            identifier: "mobileNumber",
            fullWidth: 1,
            type: "number",
            validation: {
                required: true,
                maxLength: 10,
            },
        },
        {
            label: "Email",
            icon: <GoMail color="#888888" />,
            identifier: "emailAddress",
            fullWidth: 1,
            type: "text",
            validation: {
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                unique: "email",
            },
        },
        {
            label: "Password",
            icon: <BiSolidLockAlt color="#888888" />,
            identifier: "password",
            fullWidth: 1,
            type: "password",
            validation: {
                required: true,
                minLength: 8,
                maxLength: 16,
            },
        },
        {
            label: "Confirm Password",
            icon: <BiSolidLockAlt color="#888888" />,
            identifier: "cnfPassword",
            fullWidth: 1,
            type: "password",
            validation: {
                required: true,
                matchTo: "password",
            },
        },
    ],
};

export const loginForm = {
    label: "Login",
    fields: [
        {
            label: "Email ID",
            icon: <BsPersonCircle color="#f1bb46" />,
            identifier: "emailAddress",
            fullWidth: 1,
            type: "text",
            validation: {
                required: true,
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            },
        },
        {
            label: "Password",
            icon: <BiSolidLockAlt color="#f1bb46" />,
            identifier: "password",
            fullWidth: 1,
            type: "password",
            validation: {
                required: true,
                minLength: 8,
                maxLength: 16,
            },
        },
    ],
};
