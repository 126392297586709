import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../style/Modal.css";
import "../../style/PersonalDetails.css";
import ImageContent from "../../content/ImageContent";
import { Button } from "react-bootstrap";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
import { Link, useNavigate } from "react-router-dom";
import styles from "./connectionModal.module.css";
import { BsFillPersonPlusFill } from "react-icons/bs";

const ConnectionModal = (props) => {
  let imageContent = new ImageContent();
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  const navigateTo = useNavigate();
  const tabList = useMemo(
    () => [
      {
        id: 0,
        text: "Connected",
        active: true,
      },
      {
        id: 1,
        text: "Pending",
        active: false,
      },
      {
        id: 2,
        text: "Send",
        active: false,
      },
    ],
    []
  );
  let [users, setUsers] = useState([]);
  let [tabArray, setTabArray] = useState([]);
  let [tabStatus, setTabStatus] = useState(tabList[0].text);
  let [isSure, setIsSure] = useState(false);
  let [loading] = useState(false);
  let [posC, setPosC] = useState(0);
  let [posP, setPosP] = useState(0);
  let [posS, setPosS] = useState(0);
  let [tPageC, setTPageC] = useState(0);
  let [tPageP, setTPageP] = useState(0);
  let [tPageS, setTPageS] = useState(0);
  const observer = useRef();
  const observerP = useRef();
  const observerS = useRef();

  const getFriendListonScroll = useCallback(
    (page) => {
      let inputJson = {
        page_no: page,
        no_of_records: 6,
      };
      serviceProvider
        .returnConnectService()
        .getFriendList(header, inputJson)
        .then((res) => {
          if (res.status === "200 OK") {
            let userArray = DataUtility.returnSuggestedUserList(res.data);
            let oldUserArray = [...users];
            oldUserArray = oldUserArray.concat(userArray);
            setUsers(oldUserArray);
            setTPageC(res.page_details.total_pages);
          }
        });
    },
    [header, setUsers, users, setTPageC, serviceProvider]
  );

  const getPendingListOnScroll = useCallback(
    (page) => {
      let inputJson = {
        page_no: page,
        no_of_records: 6,
      };
      serviceProvider
        .returnConnectService()
        .getPendingList(header, inputJson)
        .then((res) => {
          if (res.status === "200 OK") {
            let userArray = DataUtility.returnSuggestedUserList(res.data);
            let oldUserArray = [...users];
            oldUserArray = oldUserArray.concat(userArray);
            setUsers(oldUserArray);
            setTPageP(res.page_details.total_pages);
          }
        });
    },
    [header, setUsers, users, setTPageP, serviceProvider]
  );

  const getSendListOnScroll = useCallback(
    (page) => {
      let inputJson = {
        page_no: page,
        no_of_records: 6,
      };
      serviceProvider
        .returnConnectService()
        .getSendList(header, inputJson)
        .then((res) => {
          if (res.status === "200 OK") {
            let userArray = DataUtility.returnSuggestedUserList(res.data);
            let oldUserArray = [...users];
            oldUserArray = oldUserArray.concat(userArray);
            setUsers(oldUserArray);
            setTPageS(res.page_details.total_pages);
          }
        });
    },
    [header, setUsers, users, setTPageS, serviceProvider]
  );

  const onScrollOfConnectedUser = useCallback(() => {
    let np = posC + 1;
    if (np < tPageC) {
      setPosC(np);
      getFriendListonScroll(np);
    }
  }, [posC, tPageC, setPosC, getFriendListonScroll]);

  const onScrollOfPendingUser = useCallback(() => {
    let np = posP + 1;
    if (np < tPageP) {
      setPosP(np);
      getPendingListOnScroll(np);
    }
  }, [posP, tPageP, setPosP, getPendingListOnScroll]);

  const onScrollOfSendUser = useCallback(() => {
    let np = posS + 1;
    if (np < tPageS) {
      setPosS(np);
      getSendListOnScroll(np);
    }
  }, [posS, tPageS, setPosS, getSendListOnScroll]);

  const lastConnectedUser = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollOfConnectedUser();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, onScrollOfConnectedUser]
  );
  const lastPendingUser = useCallback(
    (node) => {
      if (loading) return;
      if (observerP.current) observerP.current.disconnect();
      observerP.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollOfPendingUser();
        }
      });
      if (node) {
        if (observerP && observerP.current) {
          observerP.current.observe(node);
        }
      }
    },
    [loading, onScrollOfPendingUser]
  );
  const lastSendUser = useCallback(
    (node) => {
      if (loading) return;
      if (observerS.current) observerS.current.disconnect();
      observerS.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollOfSendUser();
        }
      });
      if (node) {
        if (observerS && observerS.current) {
          observerS.current.observe(node);
        }
      }
    },
    [loading, onScrollOfSendUser]
  );
  const onTabSelction = (index) => {
    let dummyArray = [...tabArray];
    dummyArray.forEach((tab, ind) => {
      if (ind === index) {
        tab.active = true;
        if (tab.text === "Connected") {
          getFriendList();
          setTabStatus(tab.text);
        }
        if (tab.text === "Pending") {
          getPendingList();
          setTabStatus(tab.text);
        }
        if (tab.text === "Send") {
          getSendList();
          setTabStatus(tab.text);
        }
      } else {
        tab.active = false;
      }
    });
    setTabArray(dummyArray);
  };

  const getFriendList = useCallback(() => {
    let inputJson = {
      page_no: 0,
      no_of_records: 6,
    };
    serviceProvider
      .returnConnectService()
      .getFriendList(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          let userArray = DataUtility.returnSuggestedUserList(res.data);
          setUsers(userArray);
          setTPageC(res.page_details.total_pages);
        }
      });
  }, [header, serviceProvider, setUsers, setTPageC]);

  const getPendingList = () => {
    let inputJson = {
      page_no: 0,
      no_of_records: 6,
    };
    serviceProvider
      .returnConnectService()
      .getPendingList(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          let userArray = DataUtility.returnSuggestedUserList(res.data);
          setUsers(userArray);
          setTPageP(res.page_details.total_pages);
        }
      });
  };

  const getSendList = () => {
    let inputJson = {
      page_no: 0,
      no_of_records: 6,
    };
    serviceProvider
      .returnConnectService()
      .getSendList(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          let userArray = DataUtility.returnSuggestedUserList(res.data);
          setUsers(userArray);
          setTPageS(res.page_details.total_pages);
        }
      });
  };

  const returnTabs = () => {
    return tabArray.map((tab, index) => {
      return (
        <span id={index + "tabs" + tab.id}>
          <span
            onClick={() => {
              onTabSelction(index);
            }}
            className={tab.active ? "activeTab" : "inActiveTab"}
          >
            {tab.text}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      );
    });
  };
  const removeRequest = (email) => {
    serviceProvider
      .returnConnectService()
      .removeFromFriendListRequest(header, { userId: email })
      .then((res) => {
        if (res.status === "200 OK") {
          if (tabStatus === "Connected") {
            getFriendList();
          }
          if (tabStatus === "Pending") {
            getPendingList();
          }
          if (tabStatus === "Send") {
            getSendList();
          }
          setIsSure(false);
        }
      });
  };
  const respondRequest = (id) => {
    serviceProvider
      .returnConnectService()
      .respondRequest(header, { friendListId: id, request_type: "accept" })
      .then((res) => {
        if (res.status === "200 OK") {
          if (tabStatus === "Connected") {
            getFriendList();
          }
          if (tabStatus === "Pending") {
            getPendingList();
          }
          if (tabStatus === "Send") {
            getSendList();
          }
        }
      });
  };
  const removeRequestPending = (id) => {
    serviceProvider
      .returnConnectService()
      .respondRequest(header, { friendListId: id, request_type: "reject" })
      .then((res) => {
        if (res.status === "200 OK") {
          if (tabStatus === "Connected") {
            getFriendList();
          }
          if (tabStatus === "Pending") {
            getPendingList();
          }
          if (tabStatus === "Send") {
            getSendList();
          }
        }
      });
  };
  const navigateToViewProfile = (email) => {
    navigateTo("/viewProfile/" + email);
  };
  const sendRequestCard = (user, index) => {
    if (users.length === index + 1) {
      return (
        <React.Fragment>
          <div ref={lastSendUser} className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div
                      className="jobInfoImgTwo"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-5 ml-0 mr-0 d-flex justify-content-center">
                  <Button
                    className="btn-danger"
                    onClick={() => {
                      removeRequestPending(user.friendListId);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div
                      className="jobInfoImgTwo"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-5 ml-0 mr-0 d-flex justify-content-center">
                  <Button
                    className="btn-danger"
                    onClick={() => {
                      removeRequestPending(user.friendListId);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <button className={styles.eachCardConnectButton}>
                <BsFillPersonPlusFill fontSize={18} style={{ marginRight: "0.5rem" }} />
                <div className="pt-1">Connect</div>
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
  const pendingRequestCard = (user, index) => {
    if (users.length === index + 1) {
      return (
        <React.Fragment>
          <div ref={lastPendingUser} className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div
                      className="jobInfoImgTwo"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-5 ml-0 mr-0">
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      className="d-flex justify-content-center"
                      onClick={() => {
                        respondRequest(user.friendListId);
                      }}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </Button>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      className="btn-danger d-flex justify-content-center"
                      onClick={() => {
                        removeRequestPending(user.friendListId);
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div
                      className="jobInfoImgTwo"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-5 ml-0 mr-0">
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      className="d-flex justify-content-center"
                      onClick={() => {
                        respondRequest(user.friendListId);
                      }}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </Button>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      className="btn-danger d-flex justify-content-center"
                      onClick={() => {
                        removeRequestPending(user.friendListId);
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
  const connectedUsersCard = (user, index) => {
    if (users.length === index + 1) {
      return (
        <React.Fragment>
          <div ref={lastConnectedUser} className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div className="jobInfoImgTwo">
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                        onClick={() => {
                          navigateToViewProfile(user.emailAddress);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-3 ml-0 mr-0">
                  {!isSure ? (
                    <React.Fragment>
                      <div className="col-6 d-flex justify-content-center">
                        <Link
                          to={{
                            pathname: "/message",
                            user: user,
                          }}
                        >
                          <Button className="d-flex justify-content-center">
                            <i className="fa fa-comments" aria-hidden="true"></i>
                          </Button>
                        </Link>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="btn-danger d-flex justify-content-center"
                          onClick={() => {
                            setIsSure(true);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="col-12 d-flex mb-3 justify-content-center">
                        <b>Are you sure ??</b>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="btn-danger d-flex justify-content-center"
                          onClick={() => {
                            removeRequest(user.emailAddress);
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="d-flex justify-content-center"
                          onClick={() => {
                            setIsSure(false);
                          }}
                        >
                          No
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="col-xs-12 col-sm-12 col-md-3 pt-2" id={"connectionProfileModal" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div className="jobInfoImgTwo">
                      <img
                        alt=""
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                        onClick={() => {
                          navigateToViewProfile(user.emailAddress);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div
                    className="col-12"
                    onClick={() => {
                      navigateToViewProfile(user.emailAddress);
                    }}
                  >
                    <div className="text-center">
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.position}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.location}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-3 ml-0 mr-0">
                  {!isSure ? (
                    <React.Fragment>
                      <div className="col-6 d-flex justify-content-center">
                        <Link
                          to={{
                            pathname: "/message",
                            user: user,
                          }}
                        >
                          <Button className="d-flex justify-content-center">
                            <i className="fa fa-comments" aria-hidden="true"></i>
                          </Button>
                        </Link>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="btn-danger d-flex justify-content-center"
                          onClick={() => {
                            setIsSure(true);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="col-12 d-flex mb-3 justify-content-center">
                        <b>Are you sure ??</b>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="btn-danger d-flex justify-content-center"
                          onClick={() => {
                            removeRequest(user.emailAddress);
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                      <div className="col-6 d-flex justify-content-center">
                        <Button
                          className="d-flex justify-content-center"
                          onClick={() => {
                            setIsSure(false);
                          }}
                        >
                          No
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className={`d-flex flex-wrap ${styles.userListContainer}`}>{returnUserList()}</div>
          </div>
        </React.Fragment>
      );
    }
  };
  const returnUserList = () => {
    return users.map((user, index) => {
      return (
        <React.Fragment>
          {tabStatus === "Send" ? sendRequestCard(user, index) : null}
          {tabStatus === "Connected" ? connectedUsersCard(user, index) : null}
          {tabStatus === "Pending" ? pendingRequestCard(user, index) : null}
        </React.Fragment>
      );
    });
  };
  useEffect(() => {
    getFriendList();
    setTabArray(tabList);
  }, [tabList, getFriendList]);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row applyForJobDiv p-3">
          <div className="col-9">
            <h5>
              <b>Connections</b>
            </h5>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="row pt-4">
          <div className="col-12">{returnTabs()}</div>
        </div>
        <div className="row jobList">{returnUserList()}</div>
      </div>
    </React.Fragment>
  );
};

export default ConnectionModal;
