import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import styles from "./addProjectModal.module.css";
import { useSelector } from "react-redux";
import { getProfessionalDetails, modalControl } from "../../../Services/commonService";
import { convertDateStringForDateInput } from "../../../Services/commons";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const AddProjectModal = () => {
    const { addProjectModal } = useSelector((state) => state.nopersist);
    const { selectedWorkExperienceForEdit } = useSelector((state) => state.profile);
    const initialProject = {
        project_name: "",
        tech_used: "",
        description: "",
        project_prod_url: "",
        role_played: "",
        started_from: new Date().getTime(),
        end_on: new Date().getTime(),
    };
    const [projectArray, setProjectArray] = useState([initialProject]);

    const projectDoNotShow = ["project_id", "description"];
    const dateInput = ["joined_from", "worked_till", "started_from", "end_on"];
    const projectInputHeadObj = {
        project_id: "Project ID",
        project_name: "Project Name",
        tech_used: "Tech Used",
        description: "Description",
        project_prod_url: "Project URL",
        role_played: "Role",
        started_from: "Started From",
        end_on: "Ended On",
    };

    const handleProjectInput = (event, index) => {
        const { name, value } = event.target;
        let data = JSON.parse(JSON.stringify(projectArray));
        if (dateInput.includes(name)) {
            data[index][name] = new Date(value).getTime();
            setProjectArray(data);
        } else {
            data[index][name] = value;
            setProjectArray(data);
        }
    };

    const submitProject = async () => {
        const res = await postTReq(apiEndpoints.addProject, {
            professional_id: selectedWorkExperienceForEdit.professional_id,
            project_details: projectArray,
        });
        if (res && !res.error) {
            getProfessionalDetails();
            setProjectArray([initialProject]);
            modalControl("addProjectModal", false);
        } else {
            console.log(res.error);
        }
    };

    return (
        <Dialog open={addProjectModal} onClose={() => modalControl("addProjectModal", false)}>
            <DialogTitle>Add New Project</DialogTitle>
            <DialogContent>
                <div className={styles.container}>
                    {projectArray.map((curElem, index) => {
                        return (
                            <div className={styles.projectInputsContainer}>
                                {Object.keys(curElem).map((cur) => {
                                    if (!projectDoNotShow.includes(cur)) {
                                        if (dateInput.includes(cur)) {
                                            return (
                                                <div className={`w-50 px-1`}>
                                                    <div className={styles.inputHead}>{projectInputHeadObj[cur]}</div>
                                                    <input
                                                        type="date"
                                                        className="jobsifarish-input2"
                                                        name={cur}
                                                        value={convertDateStringForDateInput(curElem[cur])}
                                                        onChange={(event) => handleProjectInput(event, index)}
                                                        min={cur === "end_on" ? convertDateStringForDateInput(curElem["started_from"]) : ""}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className={`w-50 px-1`}>
                                                    <div className={styles.inputHead}>{projectInputHeadObj[cur]}</div>
                                                    <input type="text" className="jobsifarish-input2" name={cur} value={curElem[cur]} onChange={(event) => handleProjectInput(event, index)} />
                                                </div>
                                            );
                                        }
                                    } else {
                                        return null;
                                    }
                                })}
                                <div className={`w-100 px-1`}>
                                    <div className={styles.inputHead}>Description</div>
                                    <textarea className="jobsifarish-textarea" name="description" rows={3} value={curElem["description"]} onChange={(event) => handleProjectInput(event, index)} />
                                </div>
                                <div className="d-flex justify-content-end w-100 mt-2">
                                    <button className="normal-button" onClick={submitProject}>
                                        Add Project
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddProjectModal;
