import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuthContext from "../../../context/authContext";
import { Modal } from "react-bootstrap";
import styles from "./enterOtpModal.module.css";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { modalControl, toasterControl } from "../../../Services/commonService";

const EnterOtpModal = () => {
    const { enterOtpModal, emailSavedForOtp } = useSelector((state) => state.nopersist);
    const context = useContext(AuthContext);

    const [otpText, setOtpText] = useState("");
    const [seconds, setSeconds] = useState(60);

    const handleSubmit = async () => {
        const res = await postTReq(apiEndpoints.validateOTP, {
            emailAddress: emailSavedForOtp,
            otp: otpText,
        });
        if (res && !res.error) {
            if (res.data.data.is_otp_validate) {
                context.setAuthenticationStatus(true);
                modalControl("enterOtpModal", false);
                localStorage.setItem("jwtToken", res.data.data["Authorization-Token"]);
                localStorage.setItem("auth", "true");
                localStorage.setItem("userId", res.data.data.user_details.userId);
            } else {
                toasterControl("errorToaster", true, "The OTP entered is wrong.");
            }
        } else {
            toasterControl("errorToaster", true, "An error occured");
        }
    };

    const resendOtp = async () => {
        const res = await postTReq(apiEndpoints.generateOTP, {
            emailAddress: emailSavedForOtp,
        });
        if (res && !res.error) {
            setSeconds(60);
        } else {
            toasterControl("errorToaster", true, "An error occured");
        }
    };

    const closeModal = () => {
        modalControl("enterOtpModal", false);
        setOtpText("");
        modalControl("registrationModal", true);
    };

    useEffect(() => {
        if (seconds === 0) return;
        const intervalId = setInterval(() => {
            setSeconds((seconds) => seconds - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [seconds]);
    useEffect(() => {
        if (enterOtpModal) {
            setSeconds(60);
        } else {
            setOtpText("");
        }
    }, [enterOtpModal]);
    return (
        <Modal show={enterOtpModal} onHide={closeModal} centered size="sm">
            <Modal.Title className={styles.modalHead}>Enter OTP</Modal.Title>
            <Modal.Body>
                <div className="mb-2">
                    <input type="text" className="jobsifarish-input2" value={otpText} onChange={(event) => setOtpText(event.target.value)} />
                    <div className="d-flex justify-content-start">
                        {seconds > 0 ? (
                            <div className={`d-flex align-items-center ${styles.countdownText}`}>
                                Resend OTP in <div className="mx-1">{seconds}</div> seconds
                            </div>
                        ) : (
                            <div className={styles.resendOtp} onClick={resendOtp}>
                                Resend OTP
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                    <button className="normal-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EnterOtpModal;
