import React, { useEffect, useState } from "react";
import "../../style/accordion.css";

const SearchComponent = (props) => {
  // const { onSearch = null, initialParams = {} } = props;
  const { onSearch = null } = props;
  const [jobTitle, setJobTitle] = useState({
    jobTitle: "",
    // location: []
  });

  useEffect(() => {
    const job_title = localStorage.getItem("searchItem");
    setJobTitle((prev) => {
      return { ...prev, jobTitle: job_title };
    });
  }, []);

  const onSearchJobs = (e) => {
    e.preventDefault();
    onSearch(jobTitle);
  };

  const handleJobTitle = (e) => {
    setJobTitle({ ...jobTitle, [e.target.name]: e.target.value });
  };

  return (
    <div className="container-fluid">
      <div className="search_container">
        <form onSubmit={onSearchJobs}>
          <div className="row">
            <div className="col-md-2 no_col_padding"></div>
            <div className="col-md-5 no_col_padding">
              <input type="text" name="jobTitle" value={jobTitle.jobTitle} onChange={handleJobTitle} className="searchItem" placeholder="Job Category" />
            </div>
            {/* <div className='col-md-3 no_col_padding'>
                     <select
                        name='location'
                        id='location_search'
                        name='location'
                        value={jobTitle.location}
                        onChange={handleJobTitle}
                     >
                        <option value=''>Select</option>
                        <option value='rajasthan'>Rajasthan</option>
                        <option value='punjab'>Punjab</option>
                        <option value='delhi'>Delhi</option>
                        <option value='maharashtra'>Maharashtra</option>
                     </select>
                  </div> */}
            <div className="col-md-3 button_search_margin">
              <button type="submit" id="search_button">
                Search
              </button>
            </div>
            <div className="col-md-2 no_col_padding"></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchComponent;
