import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalDetails, modalControl, toasterControl } from "../../../Services/commonService";
import styles from "./professionDeleteConfirmationModal.module.css";
import { saveEditingProfessionObject } from "../../../Redux/Slices/profileSlice";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const ProfessionDeleteConfirmationModal = () => {
    const dispatch = useDispatch();
    const { professionDeleteConfirmationModal } = useSelector((state) => state.nopersist);
    const { editingProfessionObject } = useSelector((state) => state.profile);

    const cancelThis = () => {
        dispatch(saveEditingProfessionObject({}));
        modalControl("professionDeleteConfirmationModal", false);
    };
    const deleteThis = async () => {
        let payload = {
            professional_id: editingProfessionObject.professional_id,
        };

        const res = await postTReq(apiEndpoints.deleteProfessionalDetail, payload);
        if (res && !res.error) {
            toasterControl("successToaster", true, "Professional detail deleted successfully");
            getProfessionalDetails();
            modalControl("professionDeleteConfirmationModal", false);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };
    return (
        <Dialog open={professionDeleteConfirmationModal} onClose={() => modalControl("professionDeleteConfirmationModal", false)}>
            <DialogContent>
                <div className={styles.question}>Are you sure you want to delete this detail about your profession?</div>
                <div className="d-flex align-items-center justify-content-end">
                    <button className="normal-button me-2" onClick={deleteThis}>
                        Yes
                    </button>
                    <button className="normal-disabled-button" onClick={cancelThis}>
                        No
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ProfessionDeleteConfirmationModal;
