export const ValidationType = {
    "Email":"Email",
    "Mobile":"Mobile",
    "Integer":"Integer",
    "Float":"Float",
    "Pan":"Pan",
    "Adhar":"Adhar",
    "Password":"Password",
    "Date":"Date",
    "Select":"Select",
    "Text":"Text"
}