import React, { useContext, useEffect, useState } from "react";
import styles from "./notificationDropdown.module.css";
import { Dropdown } from "react-bootstrap";
import { convertTimeStringToDuration, getMoreNotifications, getNotifications, getUnreadDataCount, handleGetProfileAndGoThere, startChatDirectlyWithFriend } from "../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { BsPersonCircle } from "react-icons/bs";
import AuthContext from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { postTReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { clearNotificationArray } from "../../Redux/Slices/noPersistSlice";
import { FaBell } from "react-icons/fa6";

const NotificationDropdown = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { notificationArray, unreadNotifications, unreadNotificationCount } = useSelector((state) => state.nopersist);
    const { chatHistory } = useSelector((state) => state.chatting);
    let context = useContext(AuthContext);
    const isAuthenticated = context.authenticatedStatus;

    const [pageNum, setPageNum] = useState(0);

    const doNotificationAction = (curElem) => {
        switch (curElem.notification_type) {
            case "friend_request_sent":
                handleGetProfileAndGoThere({ emailAddress: curElem.sender_email }, navigateTo);
                break;

            default:
                startChatDirectlyWithFriend(chatHistory, curElem.sender_email, navigateTo);
                break;
        }
    };

    const clearNotifications = async () => {
        const res = await postTReq(apiEndpoints.readNotification);
        if (res && !res.error) {
            getNotifications(0, 10);
            getUnreadDataCount();
            dispatch(clearNotificationArray());
            setPageNum(0);
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (pageNum > 0) {
                getMoreNotifications(pageNum, 10);
            } else {
                getNotifications(pageNum, 10);
            }
        }
    }, [isAuthenticated, pageNum]);

    return (
        <Dropdown drop="down">
            <Dropdown.Toggle className={`${styles.headerDropDown} no-down-arrow`}>
                <FaBell fontSize={22} onClick={clearNotifications} />
                {unreadNotificationCount || unreadNotifications
                    ? (unreadNotificationCount || 0) + unreadNotifications.length > 0 && <div className={styles.countCircle}>{unreadNotificationCount + unreadNotifications.length}</div>
                    : null}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.notificationDropdownMenu}>
                <>
                    {notificationArray && notificationArray.length > 0 ? (
                        notificationArray.map((curElem) => {
                            return (
                                <div className={styles.eachNotification} onClick={() => doNotificationAction(curElem)}>
                                    <div className={styles.profileImageContainer}>
                                        {curElem.notification_sender_profile_pic && curElem.notification_sender_profile_pic.length > 0 ? (
                                            <img src={curElem.notification_sender_profile_pic} alt="" height={42} />
                                        ) : (
                                            <BsPersonCircle fontSize={42} color="#888888" />
                                        )}
                                    </div>
                                    <div className={styles.messageContainer}>
                                        <div className={styles.dataContainer}>{curElem.notification_message && curElem.notification_message}</div>
                                    </div>
                                    <div className={styles.notificationTime}>{curElem.notification_time && convertTimeStringToDuration(curElem.notification_time)}</div>
                                </div>
                            );
                        })
                    ) : (
                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#888888", fontStyle: "italic" }}>No notifications</div>
                    )}
                    {notificationArray && notificationArray.length > 0 && (
                        <div className={styles.seeMoreAction} onClick={() => setPageNum((prev) => prev + 1)}>
                            See More
                        </div>
                    )}
                </>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationDropdown;
