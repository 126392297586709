import LoginRegister from "../Modal/LoginRegister";
import "../../style/Header.css";
import { Dropdown } from "react-bootstrap";
import ImageContent from "../../content/ImageContent";
import AuthContext from "../../context/authContext";
import React, { useContext, useEffect, useState } from "react";
import "../../style/Header.css";
import { useNavigate } from "react-router-dom";
import styles from "./header.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { clearNotificationArray, modalController } from "../../Redux/Slices/noPersistSlice";
import HeaderDrawer from "../HeaderDrawer/HeaderDrawer";
import ModalCollection from "../ModalCollection/ModalCollection";
import { headerLinksArray } from "../../Services/constants";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";
import { getNotifications, getUnreadDataCount, modalControl } from "../../Services/commonService";
import { IoNotificationsOutline } from "react-icons/io5";
import { postTReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { selectChat } from "../../Redux/Slices/chatSlice";

const Header = (props) => {
    const dispatch = useDispatch();
    let navigateTo = useNavigate();

    const { personalDetails } = useSelector((state) => state.persist);
    const { chatHistory } = useSelector((state) => state.chatting);
    const { unreadNotifications, unreadNotificationCount } = useSelector((state) => state.nopersist);

    const [messageCount, setMessageCount] = useState(0);

    let context = useContext(AuthContext);
    const isAuthenticated = context.authenticatedStatus;
    const imageContent = new ImageContent();

    // const [click, setClick] = React.useState(false);

    // const [profile, setProfile] = useState("");

    // const handleClick = () => {
    //   setClick(!click);
    // };

    // const Close = () => setClick(false)

    // useEffect(() => {
    //    if (click ) {
    //       document.body.style.overflow = 'hidden';
    //    } else {
    //       document.body.style.overflow = 'auto';
    //    }
    // }, [click])

    const onLogOut = () => {
        localStorage.clear();
        context.setAuthenticationStatus(false);
        navigateTo("");
        // setClick(false);
        dispatch(selectChat({}));
    };

    // const links = [
    //   {
    //     id: 0,
    //     linkUrl: "/",
    //     linkName: "Home",
    //   },
    //   {
    //     id: 1,
    //     linkUrl: "/postJob",
    //     linkName: "Post Jobs",
    //   },
    //   {
    //     id: 2,
    //     linkUrl: "/resumeBuilder",
    //     linkName: "Services",
    //   },
    // ];

    // useEffect(() => {
    //   setProfile(context.profileImage);
    // }, [context]);

    const openingLoginModalWhenUserIsntLoggedIn = () => {
        let element = document.getElementById("loginRegister");
        if (element !== null) {
            element.click();
        }
    };

    // let getImage = profile;
    // let awsUrl = "https://jobsifarish.s3.ap-south-1.amazonaws.com";
    // let persistedProfile = localStorage.getItem("profileUrl");

    // let profileUrl = getImage ? `${awsUrl}/${getImage}` : persistedProfile ? `${awsUrl}/${persistedProfile}` : imageContent.getProfilemage();

    // !getImage
    //    ? imageContent.getProfilemage()
    //    : `https://jobsifarish.s3.ap-south-1.amazonaws.com/${getImage}`

    const goToRoute = (route) => {
        if (isAuthenticated) {
            navigateTo(route);
        } else {
            openingLoginModalWhenUserIsntLoggedIn();
        }
    };

    const goToRouteWithoutLogin = (route) => {
        navigateTo(route);
    };

    const clearNotifications = async () => {
        const res = await postTReq(apiEndpoints.readNotification);
        if (res && !res.error) {
            getNotifications(0, 10);
            getUnreadDataCount();
            dispatch(clearNotificationArray());
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        if (chatHistory) {
            let totalUnread = 0;
            chatHistory.forEach((curElem) => {
                if (curElem.unreadMessages) {
                    totalUnread = totalUnread + curElem.unreadMessages.length;
                }
                if (curElem.un_read_message_count) {
                    totalUnread = totalUnread + curElem.un_read_message_count;
                }
            });
            setMessageCount(totalUnread);
        }
    }, [chatHistory]);

    useEffect(() => {
        if (isAuthenticated) {
            getUnreadDataCount();
        }
    }, [isAuthenticated]);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 850);

    const updateMedia = () => {
        setIsDesktop(window.innerWidth > 850);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <>
            <ModalCollection />
            <HeaderDrawer />
            <div className={styles.container}>
                <div className={styles.logoContainer} onClick={() => navigateTo("/")}>
                    <img src={imageContent.getLogo()} alt="" />
                </div>
                {isDesktop ? (
                    <div className="d-flex align-items-center">
                        {headerLinksArray.map((curElem, index) => {
                            if (curElem.label !== "Profile") {
                                if (curElem.children.length > 0) {
                                    return (
                                        <Dropdown key={`${curElem.label}-${index}`}>
                                            <Dropdown.Toggle className={`${styles.headerDropDown} no-down-arrow`}>
                                                {curElem.label}
                                                <AiFillCaretDown fontSize={12} className="ms-1" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className={styles.dropdownMenu}>
                                                {curElem.children.map((cur) => {
                                                    return (
                                                        <Dropdown.Item key={cur.forKey} className={styles.dropdownLinks} onClick={() => goToRoute(cur.route)}>
                                                            {cur.label}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                } else {
                                    if (curElem.onlyIcon && curElem.onlyIcon.state) {
                                        if (curElem.label === "Admin Panel") {
                                            if (personalDetails && personalDetails.role === "ROLE_ADMIN") {
                                                return (
                                                    <div
                                                        key={`${curElem.label}-${index}`}
                                                        className={`headerIconLink no-down-arrow`}
                                                        onClick={() => {
                                                            if (curElem.withoutLogin) {
                                                                goToRouteWithoutLogin(curElem.route);
                                                            } else {
                                                                goToRoute(curElem.route);
                                                            }
                                                        }}
                                                    >
                                                        <curElem.onlyIcon.icon fontSize={22} />
                                                        {curElem.label === "Message" && messageCount > 0 && <div className={styles.unreadChatCircle}>{messageCount}</div>}
                                                    </div>
                                                );
                                            }
                                        } else {
                                            return (
                                                <div
                                                    key={`${curElem.label}-${index}`}
                                                    className={`headerIconLink no-down-arrow`}
                                                    onClick={() => {
                                                        if (curElem.withoutLogin) {
                                                            goToRouteWithoutLogin(curElem.route);
                                                        } else {
                                                            goToRoute(curElem.route);
                                                        }
                                                    }}
                                                >
                                                    <curElem.onlyIcon.icon fontSize={22} />
                                                    {curElem.label === "Message" && messageCount > 0 && <div className={styles.unreadChatCircle}>{messageCount}</div>}
                                                </div>
                                            );
                                        }
                                    } else {
                                        return (
                                            <div
                                                key={curElem.forKey}
                                                className={styles.headerLink}
                                                onClick={() => {
                                                    if (curElem.withoutLogin) {
                                                        goToRouteWithoutLogin(curElem.route);
                                                    } else {
                                                        goToRoute(curElem.route);
                                                    }
                                                }}
                                            >
                                                {curElem.label}
                                            </div>
                                        );
                                    }
                                }
                            }
                            return null;
                        })}

                        <NotificationDropdown />

                        {isAuthenticated ? (
                            <Dropdown>
                                <Dropdown.Toggle className={`${styles.profileDrop} no-down-arrow ${personalDetails && personalDetails.profileImageUrl ? "overflow-hidden" : ""}`}>
                                    {personalDetails && personalDetails.profileImageUrl ? <img src={personalDetails.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={28} />}
                                    <div className={styles.profileDownArrow}>
                                        <AiFillCaretDown fontSize={10} />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={styles.dropdownMenu}>
                                    <Dropdown.Item onClick={() => navigateTo("/profile")} className={styles.dropdownLinks}>
                                        View My Profile
                                    </Dropdown.Item>
                                    {personalDetails && personalDetails.role === "ROLE_ADMIN" && (
                                        <Dropdown.Item onClick={() => navigateTo("/admin-panel")} className={styles.dropdownLinks}>
                                            Admin Panel
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item onClick={() => modalControl("enterOldPasswordModal", true)} className={styles.dropdownLinks}>
                                        Change Password
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={onLogOut} className={`${styles.dropdownLinks} mb-0`}>
                                        Log Out
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <LoginRegister id="loginRegister" closeModal={() => {}} currentModal="L" onCLoseSideNav={null} />
                        )}
                        {/* {links.map((item) =>
                            !isAuthenticated ? (
                                <Link
                                className={styles.headerLink}
                                key={item.id}
                                to={""}
                                onClick={() => {
                                    openingLoginModalWhenUserIsntLoggedIn();
                                }}
                                >
                                {item.linkName}
                                </Link>
                            ) : item.linkName !== "Services" ? (
                                <Link className={styles.headerLink} key={item.id} to={item.linkUrl}>
                                {item.linkName}
                                </Link>
                            ) : null
                            )}
                            {isAuthenticated ? (
                            <React.Fragment>
                                <Dropdown>
                                <Dropdown.Toggle className={`${styles.headerDropDown} no-down-arrow`}>
                                    Services <BsChevronDown fontSize={12} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => navigateTo("/resumeBuilder")}>Resume Builder</Dropdown.Item>
                                
                                    <Dropdown.Item>Master Video</Dropdown.Item>
                                
                                    <Dropdown.Item>Live Session</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                                
                            </React.Fragment>
                            ) : (
                            <LoginRegister id="loginRegister" closeModal={() => {}} currentModal="L" />
                            )} */}
                    </div>
                ) : isAuthenticated ? (
                    <div className="d-flex align-items-center">
                        <div
                            className="me-2"
                            style={{ position: "relative" }}
                            onClick={() => {
                                clearNotifications();
                                navigateTo("/notification");
                            }}
                        >
                            <IoNotificationsOutline fontSize={20} />
                            {unreadNotificationCount || unreadNotifications
                                ? (unreadNotificationCount || 0) + unreadNotifications.length > 0 && <div className={styles.countCircle}>{unreadNotificationCount + unreadNotifications.length}</div>
                                : null}
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className={styles.hamburgerBox} onClick={() => dispatch(modalController({ modal: "headerDrawer", state: true }))}>
                                {personalDetails && personalDetails.profileImageUrl ? <img src={personalDetails.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={28} />}
                            </div>
                            {messageCount > 0 && <div className={styles.unreadChatCircle}>{messageCount}</div>}
                        </div>
                    </div>
                ) : (
                    <LoginRegister id="loginRegister" closeModal={() => {}} currentModal="L" />
                )}
            </div>
        </>
    );
};

export default Header;
