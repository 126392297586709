import React, { useContext } from "react";
import ImageContent from "../../content/ImageContent";
import "../../style/Home.css";
import Search from "../../component/SearchBar/Search";
import LoginRegister from "../../component/Modal/LoginRegister";
import AuthContext from "../../context/authContext";
import EasySteps from "../../component/EasySteps/EasySteps";
import styles from "./homeContainer.module.css";
import { BsCircleFill, BsPersonCircle } from "react-icons/bs";
import sessionImage from "../../assets/Live Streaming Illustration.png";

let imageContent = new ImageContent();

const HomeContainer = (props) => {
    // let serviceProvider = new ServiceProvider();
    // let headerUtility = new HeaderUtility();
    // let jobService = serviceProvider.returnJobService();
    // let header = headerUtility.returnHeaderContainingToken();

    let context = useContext(AuthContext);

    // const getAllJobPost = () => {
    //    jobService.getAllJobsUser(header).then((res) => {
    //       if (res.status === '200 OK') {
    //          setJobData(res.data)
    //       }
    //    })
    // }

    // const getAllJobPostBySearch = (inputJson) => {
    //    jobService.searchJob(inputJson, header).then((res) => {
    //       if (res.status === '200 OK') {
    //          setJobData(res.data)
    //       }
    //    })
    // }

    const openingLoginModalWhenUserIsntLoggedInRegister = () => {
        let element = document.getElementById("registerUser");
        if (element !== null) {
            element.click();
        }
    };
    // const navigateToMasterVideo = () => {
    //     console.log("here");
    //     if (context.authenticatedStatus) {
    //         navigateTo("/masterVideo");
    //     } else {
    //         openingLoginModalWhenUserIsntLoggedInRegister();
    //     }
    // };
    // const liveSession = () => {
    //     console.log("ok");
    // };

    // useEffect(() => {
    //    getAllJobPost()
    // }, [])

    const testimonials = [
        {
            text: "Job Sifarish has helped me to look for the right job opportunity. The best part was talking to the expert right from the organization which pointed me to the right direction and helped me understand if I can really look forward to working in the organization ",

            image: "",

            name: "Manish Kumar",

            designation: "Software Engineer",
        },

        {
            text: "Job Sifarish is a great place to enroll with.  You get to know the openings right from the employees and the best part- you can talk to them and get the hands-on experience from them which helped me crack the interview.",

            image: "",

            name: "Praveer",

            designation: "React Developer",
        },
    ];

    // const sericesList = [
    //     {
    //         text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis",

    //         image: imageContent.getComingSoon(),

    //         name: "Live Session",

    //         designation: "Developer",

    //         action() {
    //             liveSession();
    //         },
    //     },

    //     {
    //         text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis",

    //         image: imageContent.getMastersVideo(),

    //         name: "Master Video",

    //         designation: "Developer",

    //         action() {
    //             navigateToMasterVideo();
    //         },
    //     },
    // ];

    return (
        <React.Fragment>
            <Search />
            <div style={{ background: "#FBFBFF" }}>
                <div className="container pb-5">
                    <div className="row pt-4">
                        <div className="col-xs-12 col-sm-12 col-md-12 pt-5">
                            <div className="row pb-0 d-flex justify-content-center align-items-center">
                                <h1 className="col-12 d-flex justify-content-center howItWorks">How it Works</h1>
                            </div>
                            <div className="row d-flex justify-content-center align-items-center">
                                <h3 className="col-12 sessionLive d-flex justify-content-center">Follow 5 easy steps</h3>
                            </div>
                        </div>
                        {/* <div className="col-xs-12 col-sm-12 col-md-7 hexagonDiv mx-0 px-0 d-flex justify-content-center align-items-center">
              <div className="hexagon hexagon1 hexagonCreate">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <div>
                      <div className="hexTextInfo">
                        1&nbsp;&nbsp;
                        <img src={imageContent.getHexProfImage()} height="50" width="50" />
                      </div>
                      <span className="hexagonText">Create Account</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hexagon hexagon1 hexagonMake">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <div>
                      <div className="hexTextInfo">
                        2&nbsp;&nbsp;
                        <img src={imageContent.getHexMakeImage()} height="50" width="50" />
                      </div>
                      <span className="hexagonText">Make Profile</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hexagon hexagon1 hexagonSearch">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <div>
                      <div className="hexTextInfo">
                        3&nbsp;&nbsp;
                        <img src={imageContent.getHexSrhImage()} height="50" width="50" />
                      </div>
                      <span className="hexagonText">Search Job Reference</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hexagon hexagon1 hexagonResume">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <div>
                      <div className="hexTextInfo">
                        4&nbsp;&nbsp;
                        <img src={imageContent.getHexUldImage()} height="50" width="50" />
                      </div>
                      <span className="hexagonText">CV/Resume Upload</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hexagon hexagon1 hexagonApply">
                <div className="hexagon-in1">
                  <div className="hexagon-in2">
                    <div>
                      <div className="hexTextInfo">
                        5&nbsp;&nbsp;
                        <img src={imageContent.getHexAppImage()} height="50" width="50" />
                      </div>
                      <span className="hexagonText">Apply for Job</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                        <EasySteps />
                    </div>
                </div>
                <div className="container pt-5 testimonials text-center">
                    <h2>
                        <b>Connect and learn</b> from your industry masters
                    </h2>

                    <h3 className="sessionLive">
                        Request for
                        <button className={styles.liveButton}>
                            <BsCircleFill fontSize={12} style={{ marginRight: "0.25rem" }} color="red" /> Live
                        </button>
                        sessions
                    </h3>
                </div>

                {/* <div className="masters-container">
                    {sericesList.map((item, index) => (
                        <React.Fragment key={`${item.name}-${index}`}>
                            <div className={styles.mastersCard}>
                                <img src={item.image} className="master-img" alt="" />

                                <div
                                    className={`d-flex justify-content-center ${styles.actionButton}`}
                                    onClick={() => {
                                        item.action();
                                    }}
                                >
                                    <button className={styles.connectButton}>{item.name}</button>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div> */}
                <div className={styles.livesessionSection}>
                    <img alt="" src={sessionImage} />
                </div>

                {/* end connect to master  */}

                {/* testimonials  */}

                <div className="container testimonials">
                    <div>What other job holder</div>
                    <div>say about us</div>
                </div>

                <div className="container testimonial-container" style={{ paddingBottom: "1rem" }}>
                    {testimonials.map((item, index) => (
                        <div key={`${item.text}-${index}`} className="testimonial-card">
                            <div className="testimonial-quotes">
                                <img src={imageContent.getQuoteImage()} alt="quotesImg" />
                            </div>
                            <p>{item.text}</p>
                            <div className="profile">
                                {item.image ? <img src={item.image} alt="" style={{ objectFit: "contain" }} height={55} width={60} /> : <BsPersonCircle fontSize={55} className="me-3" />}
                                <div className="profile-details">
                                    <h5>{item.name}</h5>
                                    <p>{item.designation}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* testimonials  */}
            </div>

            {/* <div className="container">
                <div className="row d-flex justify-content-center align-items-center homeHeadings">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="row d-flex justify-content-center text-center">
                            <div>500+ job masters posted videos of their knowledege</div>
                        </div>

                        <div className="row pl-4 justify-content-center ">
                            <Button className="playButton">Play</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 row d-flex justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-6 d-flex justify-content-center">
                    <iframe src={'https://www.youtube.com/embed/gZS6KP48kQc'}></iframe>
                    <iframe
                        src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/promo.mp4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        height={300}
                        width={600}
                    ></iframe>
                </div>
            </div> */}

            {/* Referal Div  */}

            <div className="container-fluid pb-5 pl-md-5 pr-md-5">
                <div className="row ml-5 mr-5 referalsBlack pb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="row d-flex align-items-center" style={{ paddingTop: "20px" }}>
                            <div
                                className={
                                    !context.authenticatedStatus
                                        ? "col-xs-12 col-sm-12 col-md-7 d-flex justify-content-center"
                                        : "col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center align-item-center"
                                }
                            >
                                <div className={styles.referralText}>Find your dream job referals</div>
                                &nbsp;&nbsp;
                            </div>

                            {!context.authenticatedStatus ? (
                                <div className="col-xs-12 col-sm-12 col-md-5 d-flex justify-content-center">
                                    {!context.authenticatedStatus ? (
                                        <button className={styles.createFreeAccount} onClick={openingLoginModalWhenUserIsntLoggedInRegister}>
                                            Create Your Free Account
                                        </button>
                                    ) : null}
                                    <div style={{ display: "none" }}>
                                        <LoginRegister id="registerUser" closeModal={() => {}} currentModal="R" />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HomeContainer;
