import { postReq } from "./api";
import store from "../Redux/store";
import { loadingOnEmailInput } from "../Redux/Slices/noPersistSlice";
import { apiEndpoints } from "../PrEnums/prEnums";
import { toasterController } from "../Redux/Slices/toasterSlice";

export const getToken = () => {
  return `Bearer ${localStorage.getItem("jwtToken")}`;
};

export const checkEmailExistence = async (value) => {
  store.dispatch(loadingOnEmailInput(true));
  const res = await postReq(`${apiEndpoints.validateEmail}`, { emailAddress: value });
  if (res && !res.error) {
    store.dispatch(loadingOnEmailInput(false));
    if (res.data.data.isAvailable === true) {
      store.dispatch(toasterController({ toaster: "emailAlreadyExists", state: true }));
      return true;
    } else {
      return false;
    }
  } else {
    console.log(res.error);
    store.dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
    store.dispatch(loadingOnEmailInput(false));
  }
  return res;
};
