import React, { useContext, useEffect } from "react";
import styles from "./notificationPage.module.css";
import { MdChevronRight } from "react-icons/md";
import { FaBell } from "react-icons/fa6";
import { convertTimeStringToDuration, getNotifications, handleGetProfileAndGoThere, startChatDirectlyWithFriend } from "../../Services/commonService";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import { useSelector } from "react-redux";

const NotificationPage = () => {
    const navigateTo = useNavigate();
    const { notificationArray } = useSelector((state) => state.nopersist);
    const { chatHistory } = useSelector((state) => state.chatting);
    let context = useContext(AuthContext);
    const isAuthenticated = context.authenticatedStatus;

    const doNotificationAction = (curElem) => {
        switch (curElem.notification_type) {
            case "friend_request_sent":
                handleGetProfileAndGoThere({ emailAddress: curElem.sender_email }, navigateTo);
                break;

            default:
                startChatDirectlyWithFriend(chatHistory, curElem.sender_email, navigateTo);
                break;
        }
    };
    useEffect(() => {
        if (isAuthenticated) {
            getNotifications(0, 10);
        }
    }, [isAuthenticated]);
    return (
        <div className="main-content-container">
            <div className="d-flex justify-content-center h-100">
                <div className={styles.container}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className={styles.pageHeading}>Notification</div>
                        {/* <div>Icons</div> */}
                    </div>
                    <div className={styles.notificationsContainer}>
                        {notificationArray && notificationArray.length > 0 ? (
                            notificationArray.map((curElem) => {
                                return (
                                    <div className={styles.eachNotification} onClick={() => doNotificationAction(curElem)}>
                                        <div className={styles.notificationContent}>
                                            <div className={styles.profileImage}>
                                                {curElem.notification_sender_profile_pic && curElem.notification_sender_profile_pic.length > 0 ? (
                                                    <img src={curElem.notification_sender_profile_pic} alt="" height={42} />
                                                ) : (
                                                    <FaBell color="#bbbbbb" />
                                                )}
                                            </div>
                                            <div className={styles.notificationData}>{curElem.notification_message && curElem.notification_message}</div>
                                        </div>
                                        <div>
                                            <MdChevronRight />
                                        </div>
                                        <div className={styles.notificationTime}>{curElem.notification_time && convertTimeStringToDuration(curElem.notification_time)}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="wide-nothing-to-show">No notifications available</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;
