import React, { useEffect } from "react";
import styles from "./appliedJobs.module.css";
import { getJobsAppliedByUser } from "../../../../../Services/commonService";
import { useSelector } from "react-redux";
import { getDurationString } from "../../../../../Services/commons";
import { GoClockFill } from "react-icons/go";

const AppliedJobs = () => {
    const { appliedJobsByUser } = useSelector((state) => state.jobslice);

    useEffect(() => {
        getJobsAppliedByUser(0, 10);
    }, []);
    return (
        <div className={styles.container}>
            {appliedJobsByUser && appliedJobsByUser.length > 0 ? (
                appliedJobsByUser.map((curElem) => {
                    return (
                        <div className={styles.jobCard}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <div className={styles.joiningType}>{curElem.joiningType}</div>
                                    <div className={styles.jobTitle}>{curElem.jobTitle}</div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 flex-wrap">
                                {curElem.job_type_details.map((cur) => {
                                    return <div className={styles.typePill}>{cur.job_type}</div>;
                                })}
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="d-flex me-3">
                                    <div className={`${styles.label} me-1`}>Role:</div>
                                    <div className={styles.dataValue}>{curElem.role}</div>
                                </div>
                                <div className="d-flex me-3">
                                    <div className={`${styles.label} me-1`}>Location:</div>
                                    <div className={styles.dataValue}>{curElem.location}</div>
                                </div>
                                <div className="d-flex me-3">
                                    <div className={`${styles.label} me-1`}>Job Stream:</div>
                                    <div className={styles.dataValue}>{curElem.jobStreams}</div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.label}>Skills Required:</div>
                                <div className={`${styles.dataValue} d-flex flex-wrap`}>
                                    {curElem.skillset.map((cur) => {
                                        return <div className={styles.skillPill}>{cur.skill}</div>;
                                    })}
                                </div>
                            </div>
                            <div className={`d-flex justify-content-end align-items-center ${styles.uploadTime}`}>
                                <GoClockFill color="#888888" fontSize={12} className="me-1" />
                                {getDurationString(curElem.date)}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="colorback-nothing-to-show">No Applied Jobs</div>
            )}
        </div>
    );
};

export default AppliedJobs;
