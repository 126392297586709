import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ImageContent from '../../content/ImageContent';

import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, RedditShareButton, EmailShareButton, TwitterShareButton } from 'react-share';

const imageContent = new ImageContent();

const PlatformSharingModal = (props) => {
    const incCount = () => {
        props.updateAllowed();
    }
    return (
        <React.Fragment>
            <Modal size="lg"
                show={props.isShow} onHide={props.handleClose}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body className="p-0">
                    <div className="container-fluid">
                        <div className="row applyForJobDiv p-3">
                            <div className="col-9">
                                <h5><b>{"Keep Sharing & Keep Supporting"}</b></h5>
                            </div>
                            <div className="col-3">
                                <img className="crossicon" alt="cross" onClick={props.handleClose} src={imageContent.getCrossImg()} />
                            </div>
                        </div>
                        <div className="row pt-4">
                            <h6><b>&nbsp;Please share our portal link with friends to download..</b></h6>
                        </div>
                        <div className="row pt-4">
                            <div className="col-2 d-flex justify-content-center">
                                <WhatsappShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-whatsapp" aria-hidden="true"></i></span>
                                </WhatsappShareButton>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <FacebookShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-facebook" aria-hidden="true"></i></span>
                                </FacebookShareButton>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <LinkedinShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-linkedin" aria-hidden="true"></i></span>
                                </LinkedinShareButton>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <RedditShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-reddit" aria-hidden="true"></i></span>
                                </RedditShareButton>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <EmailShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-envelope" aria-hidden="true"></i></span>
                                </EmailShareButton>
                            </div>
                            <div className="col-2 d-flex justify-content-center">
                                <TwitterShareButton title="JobSifarish.com"
                                    url="https://jobsifarish.com/"
                                    quote="Hey build your professional resume on jobsifarish.com"
                                    hashtag="#jobsifarish"
                                    onClick={incCount}>
                                    <span style={{ fontSize: "50px" }}><i className="fa fa-twitter" aria-hidden="true"></i></span>
                                </TwitterShareButton>
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-12 d-flex justify-content-center">
                                <Button disabled={props.allowed} onClick={()=>{ props.printResume('downloadResume') }} className="connectButton">Print</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default PlatformSharingModal;