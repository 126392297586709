import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import SearchConnection from "../../component/SearchBar/SearchConnection";
import { Button } from "react-bootstrap";
import { AccordionConnection } from "./FilteredJobs";
import ImageContent from "../../content/ImageContent";
import "../../style/PersonalDetails.css";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
import ErrorModal from "../../component/Modal/ErrorModal";
import { Link, useNavigate } from "react-router-dom";

// const data = [
//   {
//     title: "Skills",
//     component: DataFiltersSkills,
//     componentProps: {},
//   },
//   {
//     title: "Location",
//     component: DataFiltersLocation,
//     componentProps: {},
//   },
// ];
// let userData = [
//   {
//     name: "Vikrant Pratap Pandey",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
//   {
//     name: "Kuldeep Sharma",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
//   {
//     name: "Manish Kumar",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
//   {
//     name: "Shivam Deo",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
//   {
//     name: "Ankit Kumar",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
//   {
//     name: "Praveer Srivastav",
//     position: "Web Developer",
//     location: "Noida,U.P",
//   },
// ];

let imageContent = new ImageContent();

const FilteredConnections = () => {
  let [sideNav, setSideNav] = useState(false);
  const [filtersData, setFiltersData] = useState({});
  const [forFilters, setForFilters] = useState({});
  const [mobileView, setMobileView] = useState(false);
  const [userList, setUserList] = useState([]);
  let [show, setShow] = useState(false);
  let [pos, setPos] = useState(0);
  let [totalPage, setTotalPage] = useState(0);
  let [loading, setLoading] = useState(false);
  let [msg, setMsg] = useState(false);
  let [name, setName] = useState("");
  let [iSkillSet, setISkillSet] = useState([]);
  let [iLocation, setILocation] = useState([]);
  const navigateTo = useNavigate();
  const serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  const headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const observer = useRef();

  const lastJobRef = useCallback(
    (node) => {
      const scroll = () => {
        let np = pos + 1;
        console.log(np);
        console.log(totalPage);
        if (np < totalPage) {
          setPos(np);
          console.log("ok");
          getSuggestedUserOnScroll(np);
        }
      };

      const getSuggestedUserOnScroll = (page) => {
        setLoading(true);
        console.log("here");
        let header = headerUtility.returnHeaderContainingToken();
        serviceProvider
          .returnConnectService()
          .getSuggestedConnections(header, {
            page_no: page,
            no_of_records: 10,
            user_search_key: name,
            location: iLocation,
            skillset: iSkillSet,
          })
          .then((res) => {
            if (res.status === "200 OK") {
              let videoList = DataUtility.returnSuggestedUserList(res.data);
              console.log(userList);
              let nVideo = [...userList];
              console.log(nVideo);
              nVideo = nVideo.concat(videoList);
              console.log(nVideo);
              setUserList(nVideo);
              setLoading(false);
            }
          });
      };

      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          console.log("visi");
          console.log(totalPage);
          scroll();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, totalPage, pos, headerUtility, iLocation, iSkillSet, name, serviceProvider, userList]
  );

  const handleForFilters = (data) => {
    if (data.skills !== undefined) {
      setISkillSet(data.skills);
    }
    if (data.location !== undefined) {
      setILocation(data.location);
    }
    getSuggestedConnections();
    setForFilters({ ...forFilters, data });
  };
  const updateDimensions = () => {
    if (window.innerWidth <= 600) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  const navigateToViewProfile = (email) => {
    navigateTo("/viewProfile/" + email);
  };

  const getSuggestedConnections = () => {
    setPos(0);
    setLoading(true);
    let header = headerUtility.returnHeaderContainingToken();
    serviceProvider
      .returnConnectService()
      .getSuggestedConnections(header, {
        page_no: 0,
        no_of_records: 10,
        user_search_key: name,
        location: iLocation,
        skillset: iSkillSet,
      })
      .then((res) => {
        if (res.status === "200 OK") {
          console.log(res.page_details.total_pages);
          setTotalPage(res.page_details.total_pages);
          let users = res.data;
          setUserList(users);
          setLoading(false);
        }
      });
  };

  const sendRequest = (email) => {
    let header = headerUtility.returnHeaderContainingToken();
    let inputJson = {
      reciever_id: email,
    };
    serviceProvider
      .returnConnectService()
      .sendRequest(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          getSuggestedConnections();
        }
        if (res.status === "200 OK") {
          setShow(true);
          setMsg(res.data.message);
        } else {
          setMsg(res.data.message);
        }
        setTimeout(() => {
          setShow(false);
        }, 5000);
      });
  };
  const updateName = (value) => {
    setName(value);
  };
  const onSearch = () => {
    getSuggestedConnections();
  };
  window.addEventListener("resize", updateDimensions);
  const returnBtnGroup = (user) => {
    if (user.user_connection_status.is_friend === undefined) {
      return (
        <Button
          className="connectBtnThree"
          onClick={() => {
            sendRequest(user.emailAddress);
          }}
        >
          Connect
        </Button>
      );
    } else {
      if (user.user_connection_status.is_friend) {
        return (
          <React.Fragment>
            <Link to={{ pathname: "/message", user: user }}>
              <Button className="connectBtnThree">Message</Button>
            </Link>
          </React.Fragment>
        );
      } else {
        return (
          <h5>
            <b>Sent</b>
          </h5>
        );
      }
    }
  };
  const returnUserList = () => {
    return userList.map((user, index) => {
      if (userList.length === index + 1) {
        return (
          <div ref={lastJobRef} className={mobileView || index > 2 ? "col-xs-12 col-sm-12 col-md-4 pt-3" : "col-xs-12 col-sm-12 col-md-4"} id={"connectionProfile" + index}>
            <div className="ant-card">
              <div className="ant-card-bodyMinorTwo">
                <div className="row ml-0 mr-0 pb-0 px-1">
                  <div className="col-3"></div>
                  <div className="col-6 pt-2 pr-0 pl-0">
                    <div
                      className="jobInfoImgTwo"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <img
                        className="jobInfoImgTwo"
                        src={
                          user.profileImageUrl !== undefined && user.profileImageUrl !== "" ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl : imageContent.getProfilemage()
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div
                      className="text-center"
                      onClick={() => {
                        navigateToViewProfile(user.emailAddress);
                      }}
                    >
                      <span className="profileUserName">
                        {user.firstName} {user.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0 pb-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user?.job_profile_name}</span>
                    </div>
                  </div>
                </div>
                <div className="row ml-0 mr-0 pt-0">
                  <div className="col-12">
                    <div className="text-center">
                      <span className="profileUserRole">{user.city}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-5 ml-0 mr-0 d-flex justify-content-center">{returnBtnGroup(user)}</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <div className={mobileView || index > 2 ? "col-xs-12 col-sm-12 col-md-4 pt-3" : "col-xs-12 col-sm-12 col-md-4"} id={"connectionProfile" + index}>
              <div className="ant-card">
                <div className="ant-card-bodyMinorTwo">
                  <div className="row ml-0 mr-0 pb-0 px-1">
                    <div className="col-3"></div>
                    <div className="col-6 pt-2 pr-0 pl-0">
                      <div
                        className="jobInfoImgTwo"
                        onClick={() => {
                          navigateToViewProfile(user.emailAddress);
                        }}
                      >
                        <img
                          className="jobInfoImgTwo"
                          src={
                            user.profileImageUrl !== undefined && user.profileImageUrl !== ""
                              ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + user.profileImageUrl
                              : imageContent.getProfilemage()
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <div className="row ml-0 mr-0 pt-0 pb-0">
                    <div className="col-12">
                      <div
                        className="text-center"
                        onClick={() => {
                          navigateToViewProfile(user.emailAddress);
                        }}
                      >
                        <span className="profileUserName">
                          {user.firstName} {user.lastName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row ml-0 mr-0 pt-0 pb-0">
                    <div className="col-12">
                      <div className="text-center">
                        <span className="profileUserRole">{user?.job_profile_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row ml-0 mr-0 pt-0">
                    <div className="col-12">
                      <div className="text-center">
                        <span className="profileUserRole">{user.city}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row px-5 ml-0 mr-0 d-flex justify-content-center">{returnBtnGroup(user)}</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    });
  };
  useEffect(() => {
    // getFilterList();
    const getSuggestedConnections = () => {
      setPos(0);
      setLoading(true);
      let header = headerUtility.returnHeaderContainingToken();
      serviceProvider
        .returnConnectService()
        .getSuggestedConnections(header, {
          page_no: 0,
          no_of_records: 10,
          user_search_key: name,
          location: iLocation,
          skillset: iSkillSet,
        })
        .then((res) => {
          if (res.status === "200 OK") {
            console.log(res.page_details.total_pages);
            setTotalPage(res.page_details.total_pages);
            let users = res.data;
            setUserList(users);
            setLoading(false);
          }
        });
    };
    getSuggestedConnections();
  }, [forFilters, headerUtility, iLocation, iSkillSet, name, serviceProvider]);
  useEffect(() => {
    const getFilterList = () => {
      let header = headerUtility.returnHeaderContainingToken();
      serviceProvider
        .returnJobService()
        .filterData(header)
        .then((res) => {
          if (res.status === "200 OK") {
            setFiltersData((prev) => {
              return {
                ...prev,
                skillset: res.data.skillset.map((item, index) => ({
                  label: item,
                  value: item,
                  isChecked: false,
                })),
                location: res.data.location.map((item, index) => ({
                  label: item,
                  value: item,
                  isChecked: false,
                })),
              };
            });
          } else console.log("fetched some error");
        });
    };
    getFilterList();
  }, [headerUtility, serviceProvider]);
  return (
    <React.Fragment>
      <SearchConnection mobileView={mobileView} updateName={updateName} onSearch={onSearch} />
      <ErrorModal
        message={msg}
        icon="text-primary"
        title="Sucess"
        isShow={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <span className="sideNavToggle" onClick={() => setSideNav(!sideNav)}>
        &#9776; Apply Filters
      </span>
      <div className="filtered_container">
        <div className="filtered_container_left">
          <div>
            <div className="filters_wrapper">
              <div className="filters_text">
                <h5>
                  <b>Filters</b>
                </h5>
              </div>

              <AccordionConnection handleForFilters={handleForFilters} filtersData={filtersData} />
            </div>
          </div>
        </div>
        <div id="mySidenav" class="sidenav" style={sideNav ? { width: "100%" } : { width: "0px" }}>
          <div className="closebtn" onClick={() => setSideNav(!sideNav)}>
            &times;
          </div>
          <div className="filters_wrapper">
            <div className="filters_text">
              <h5>
                <b>Filters</b>
              </h5>
            </div>

            <AccordionConnection handleForFilters={handleForFilters} filtersData={filtersData} />
          </div>
        </div>
        <div className="filtered_container_right">
          <div className={!mobileView ? "row jobList pt-5 px-2 ml-0 mr-0" : "row jobList pt-5 px-5 ml-0 mr-0"}>
            {userList.length > 0 ? (
              returnUserList()
            ) : (
              <div className="d-flex w-100 justify-content-center align-items-center">
                <h1>No users found !!!</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilteredConnections;
