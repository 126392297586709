import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../../style/PersonalDetails.css";
import statesJson from "../../json/states.json";
import { ValidationUtility } from "../../utility/ValidationUtility";
import { ValidationType } from "../../enum/ValidationType";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
import ImageContent from "../../content/ImageContent";
import Select from "react-select";
import { skillsArray } from "../../json/commonData";
import { languages } from "../../json/language";

const optionsSkills = skillsArray.map((item, index) => ({ value: index, label: item }));

let states = statesJson.states;

// const optionsLanguage = [
//     { value: 'English', label: 'English' },
//     { value: 'Spanish', label: 'Spanish' },
//     { value: 'French', label: 'French' }
// ]

const PersonalDetails = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let [cityList, setCityList] = useState([]);
  let [state, setState] = useState("");
  let [city, setCity] = useState("");
  let [country, setCountry] = useState("India");
  let [addr, setAddr] = useState("");
  let [desc, setdesc] = useState("");
  let [dob, setDOB] = useState("");
  let [gender, setGender] = useState("");
  let [capturedImg, setCapturedImg] = useState("");
  let [capturedString, setCapturedString] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  let [btnLoader, setBtnLoader] = useState(false);
  const [mobile, setMobile] = useState("");
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let imageContent = new ImageContent();

  // let [listOfElasticText, setListOfElasticText] = useState(initElasticItem);

  let [jobProfile, setJobProfile] = useState(false);
  const [language, setLanguage] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [userId, setUserId] = useState("");
  const [achievementDetails, setAchievementDetails] = useState([]);
  const [optionsLanguage, setoptionsLanguage] = useState([]);

  let todayDate = new Date();
  let validDate = DataUtility.getValidDateForDob(todayDate);
  const handleStateInput = (value) => {
    setState(value);
    ValidationUtility.validateElement("state", ValidationType.Select, true, "stateError", "State");
    states.forEach((stateInfo) => {
      if (stateInfo.state === value) {
        setCityList(stateInfo.districts);
      }
    });
  };
  const openFileSelectPromt = () => {
    let fileId = document.getElementById("fileId");
    if (fileId !== null) {
      fileId.click();
    }
  };
  const uploadImage = (list) => {
    let checkSize = list ? (list.length > 0 ? list[0].size / 1000000 > 2 : true) : true;
    if (checkSize) {
      return false;
    }
    if (list.length > 0) {
      capturedString = list[0].name;
      setCapturedString(list[0].name);
      uploadUserImage("fileId");
    } else {
      capturedString = "";
      setCapturedString("");
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCapturedImg(reader.result);
        if (typeof reader.result == "string") {
          // localStorage.setItem('profileImg', reader.result);
        }
      }
    };
    if (list != null) {
      reader.readAsDataURL(list[0]);
    }
  };
  const generateInputJsonForPersonalDetails = () => {
    let inputJson = {
      profileImageUrl: capturedString,
      userId: userId,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      emailAddress: email,
      mobileNumber: mobile,
      dob: DataUtility.dateToEpochValue(dob),
      gender: gender,
      address: addr,
      state: state,
      city: city,
      country: country,
      // "skillSet": DataUtility.generateSkillSetStringFromSkillList(listOfElasticText),
      skillset: skillsList ? skillsList.map((data, index) => ({ skill_id: data.value, skill: data.label })) : [],
      achievement_details: achievementDetails,
      about_me: desc,
      language: language.map((data) => data.value),
      job_profile_name: jobProfile,
    };
    return inputJson;
  };
  const uploadUserImage = (fileId) => {
    props.updateLoader(true);
    let headerForFile = headerUtility.getHeaderForFileUpload();
    profileService.uploadUserProfileImage(headerForFile, fileId).then((res) => {
      if (res.status === "200 OK") {
        setCapturedString(res.file_name);
        props.updateLoader(false);
      }
    });
  };

  const onSubmit = () => {
    validateOnSubmit(capturedImg).then(() => {
      setBtnLoader(true);
      profileService.postPersonalDetails(generateInputJsonForPersonalDetails(), header).then((res) => {
        if (res.status === "200 OK") {
          setBtnLoader(false);
          props.updateScreen(2);
        }
      });
    });
  };
  const handleCityInput = (value) => {
    setCity(value);
    ValidationUtility.validateElement("city", ValidationType.Select, true, "cityError", "City");
  };
  const handleAddrInput = (value) => {
    setAddr(value);
    ValidationUtility.validateElement("addr", ValidationType.Text, true, "addrError", "Address");
  };
  const handleGenderInput = (value) => {
    setGender(value);
    ValidationUtility.validateElement("gender", ValidationType.Select, true, "genderError", "Gender");
  };
  const handleDateOfBirthInput = (value) => {
    setDOB(value);
    ValidationUtility.validateElement("dob", ValidationType.Date, true, "dobError", "DOB");
  };

  // const addMoreElasticSearchText = (text) => {
  //     let index = listOfElasticText.length;
  //     let obj = {
  //         id: index,
  //         text: text
  //     }
  //     setListOfElasticText(oldData => [...oldData, obj]);
  // }

  // const handleKeyPress = (event) => {
  //     if (event.charCode === 13) {
  //         event.preventDefault();
  //         if (productName !== '') {
  //             addMoreElasticSearchText(productName);
  //         }
  //         productName = '';
  //         setProductName('');
  //     }
  // }

  // const removeELasticSearchItem = (index) => (e) => {
  //     let tempListOfElasticSearchItem = [...listOfElasticText];
  //     let subTempListOfElasticSearchItem = tempListOfElasticSearchItem.filter((item, subIndex) => subIndex !== index);
  //     setListOfElasticText([]);
  //     setListOfElasticText(subTempListOfElasticSearchItem);
  // }

  // const returnElasticSearchList = () => {
  //     return listOfElasticText.map((item, index) => {
  //         return (
  //             <div className="ml-2 mt-1 mb-0 elasticSearchItem" id={item.text + 'elasticSearch' + index + 'id' + item.id}>
  //                 <span className="pl-2">{item.text}&nbsp;</span>
  //                 <span className="pr-2 elasticSearchCross" onClick={removeELasticSearchItem(index)}><i className="fa fa-times" aria-hidden="true"></i></span>
  //             </div>
  //         )
  //     });
  // }

  useEffect(() => {
    const getUserInfo = () => {
      props.updateLoader(true);
      profileService
        .getPersonalDetail(header)
        .then((res) => {
          if (res.status === "200 OK") {
            props.updateLoader(false);
            setFirstName(res.data.firstName);
            setMiddleName(res.data.middleName);
            setLastName(res.data.lastName);
            setMobile(res.data.mobileNumber);
            setEmail(res.data.emailAddress);
            setAddr(res?.data?.address);
            setdesc(res?.data?.about_me);
            let dateOfBirth = DataUtility.returnDateInmmDDyyyFormat(new Date(res?.data?.dob));
            setDOB(dateOfBirth);
            handleStateInput(res?.data?.state);
            handleCityInput(res?.data?.city);
            setCountry(res.data.country ? res.data.country : "India");
            setUserId(res?.data?.userId);
            setGender(res?.data?.gender);
            setSkillsList(res?.data?.skills);
            setJobProfile(res?.data?.job_profile_name);
            let lang = res?.data?.language.map((lang) => ({ value: lang, label: lang }));
            setLanguage(lang);
            setAchievementDetails(res?.data?.achievement_details);
            if (res?.data?.profileImageUrl !== "") {
              setCapturedString(res?.data?.profileImageUrl);
              let profileUrl = "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + res?.data?.profileImageUrl;
              setCapturedImg(profileUrl);
            }
            let skills = res?.data?.skillset;
            let newSkills = [];
            for (let i = 0; i < skills.length; ++i) {
              let obj = { value: i, label: skills[i].skill };
              newSkills.push(obj);
            }
            console.log(newSkills);
            setSkillsList(newSkills);
            props.updateLoader(false);
          } else {
            props.updateLoader(false);
          }
        })
        .catch((err) => {
          props.updateLoader(false);
        })
        .finally(() => {
          props.updateLoader(false);
        });
    };

    getUserInfo();
    let finalLanguage = [];
    for (let i in languages) {
      finalLanguage.push({ value: i, label: i });
    }
    setoptionsLanguage(finalLanguage);
  }, [header, profileService, props]);

  const [achieveData, setAchieveData] = useState("");

  const handleAchieve = (e) => {
    e.preventDefault();
    setAchievementDetails([...achievementDetails, { achievement_id: achievementDetails.length, achievement: achieveData }]);
    setAchieveData("");
  };

  const handleDeleteAchievement = (id) => {
    setAchievementDetails(achievementDetails.filter((d) => d.achievement_id !== id));
  };

  return (
    <div className="container-fluid createProfileSideBar">
      <div className="row pt-2 pb-0 completeProfileHeader">
        <div className="col-12">
          <h4>
            <b>Personal Details</b>
          </h4>
        </div>
      </div>
      <div className="row profilePictureRow pr-3 pl-3 pt-3 pb-0">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pr-0 pl-0">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-9">
              <div className="row pl-3">
                <div className="col-12 pb-2">
                  <span>
                    <b>Profile Picture</b>
                  </span>
                </div>
                <div className="col-12">
                  <div className="row">
                    <Button onClick={openFileSelectPromt} className="profileUploadButton d-flex justify-content-center align-item-center">
                      <img alt="" src={imageContent.getUploadImage()} height="25" />
                      &nbsp;&nbsp;Upload Image
                    </Button>
                    <span>{capturedString}</span>
                  </div>
                </div>
              </div>
              <span className="text-danger" id="fileIdError"></span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 text-center d-flex flex-column justify-content-center align-item-center">
              <div className="profileImgDiv">
                {capturedImg === "" ? <img alt="" src={imageContent.getProfilemage()} height="50" /> : <img alt="" src={capturedImg} style={{ height: "80px", width: "80px" }} />}
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          id="fileId"
          accept=".png,.jpg"
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files !== null) {
              uploadImage(e.target.files);
            }
          }}
        />
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-12">
              <label>Address</label>
              <br />
              <Form.Control value={addr} id="addr" className="inputFieldStyle" rows="4" cols="33" as="textarea" onChange={(e) => handleAddrInput(e.target.value)} />
              <span className="text-danger" id="addrError"></span>
            </div>
            <div className="col-12 pt-4">
              <label>Description</label>
              <br />
              <Form.Control value={desc} className="inputFieldStyle" rows="4" cols="33" as="textarea" onChange={(e) => setdesc(e.target.value)} />
            </div>
            <div className="col-md-6 pt-4">
              <label>Country</label>
              <br />
              <select value={country} className="inputFieldStyle" style={{ width: "100%" }} id="country">
                <option selected disabled value="India">
                  India
                </option>
              </select>
              <span className="text-danger" id="countryError"></span>
            </div>
            <div className="col-md-6 pt-4">
              <label>State</label>
              <br />
              <StatesDropDown state={state} returnState={handleStateInput} />
              <span className="text-danger" id="stateError"></span>
            </div>
            <div className="col-md-6 pt-4">
              <label>City</label>
              <br />
              <CityDropDown city={city} value={cityList} returnCity={handleCityInput} />
              <br />
              <span className="text-danger" id="cityError"></span>
            </div>
            <div className="col-md-6 pt-4">
              <label>Gender</label>
              <br />
              <select value={gender} id="gender" className="inputFieldStyle" style={{ width: "100%" }} onChange={(e) => handleGenderInput(e.target.value)}>
                <option value="-1" selected disabled>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <span className="text-danger" id="genderError"></span>
            </div>
            <div className="col-md-6 pt-4">
              <label>Date Of Birth</label>
              <br />
              <Form.Control value={dob} max={validDate} className="inputFieldStyle" id="dob" type="date" onChange={(e) => handleDateOfBirthInput(e.target.value)} />
              <span className="text-danger" id="dobError"></span>
            </div>
            <div className="col-md-6 pt-4">
              <label>Your Job Profile</label>
              <br />
              <Form.Control value={jobProfile} className="inputFieldStyle" id="jobProfile" type="text" onChange={(e) => setJobProfile(e.target.value)} />
            </div>
            <div className="col-md-6 pt-4">
              <label>Language(s)</label>
              <br />
              {/* <Form.Control value={dob} max={validDate} className="inputFieldStyle" id="dob" type="date" onChange={e => handleDateOfBirthInput(e.target.value)} /> */}
              <Select options={optionsLanguage} value={language} isMulti onChange={setLanguage} defaultValue={language[0]} />
              {/* <span className="text-danger" id="language"></span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-4 px-3">
        <label className="px-3">Acheievement(s)</label>
        <br />
        {achievementDetails.map((data, id) => (
          <div className="col-12 d-flex align-items-baseline" key={data.achievement_id}>
            <p>
              {id + 1} - {data.achievement}
            </p>
            <i className="fa fa-times-circle-o ml-3" onClick={() => handleDeleteAchievement(data.achievement_id)} aria-hidden="true"></i>
          </div>
        ))}

        {/* <div className='col-1'>
                    <p onClick={handleAchieve}>Add</p>
                    <Form.Control value={data.achievement} id="achievement" className="inputFieldStyle" rows="2" cols="33" as="textarea" onChange={e => handleAchieveData(e.target.value, data.id)} />
                </div> */}
      </div>
      <form onSubmit={handleAchieve}>
        <div className="row px-3">
          <div className="col-sm-12 col-md-10">
            <Form.Control value={achieveData} id="achievement" required className="inputFieldStyle" rows="2" cols="33" as="textarea" onChange={(e) => setAchieveData(e.target.value)} />
          </div>
          <div className="col-sm-12 col-md-2">
            <Button type="submit" className="connectButton">
              Add
            </Button>
          </div>
        </div>
      </form>
      <div className="row  px-3">
        <div className="col-12 mt-2">
          <label>Skill Set:</label>
          <Select options={optionsSkills} value={skillsList} isMulti onChange={setSkillsList} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6"></div>
        <div className="col-sm-12 col-md-4 col-lg-4"></div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          {!btnLoader ? (
            <Button className="addProjectBtn" onClick={onSubmit}>
              Submit
            </Button>
          ) : (
            <Button className="text-white connectButton">
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const StatesDropDown = (props) => {
  return (
    <React.Fragment>
      <select
        value={props.state}
        className="inputFieldStyle"
        style={{ width: "100%" }}
        id="state"
        onChange={(e) => {
          props.returnState(e.target.value);
        }}
      >
        <option value="-1" selected disabled>
          Select State
        </option>
        {states.map((stateInfo, index) => {
          return (
            <option value={stateInfo.state} id={stateInfo.state + index}>
              {stateInfo.state}
            </option>
          );
        })}
      </select>
    </React.Fragment>
  );
};

export const CityDropDown = (props) => {
  useEffect(() => {}, [props.value]);
  return (
    <React.Fragment>
      <select
        value={props.city}
        className="inputFieldStyle"
        style={{ width: "100%" }}
        id="city"
        onChange={(e) => {
          props.returnCity(e.target.value);
        }}
      >
        <option value="-1" selected disabled>
          Select City
        </option>
        {props.value.map((cityInfo, index) => {
          return (
            <option value={cityInfo} id={cityInfo + index}>
              {cityInfo}
            </option>
          );
        })}
      </select>
    </React.Fragment>
  );
};

const validateOnSubmit = (imgName) => {
  let responseArray = [];
  if (imgName === "") {
    responseArray.push(ValidationUtility.validateElement("fileId", ValidationType.Text, true, "fileIdError", "Profile Image"));
  }
  responseArray.push(ValidationUtility.validateElement("country", ValidationType.Select, true, "countryError", "Country"));
  responseArray.push(ValidationUtility.validateElement("state", ValidationType.Select, true, "stateError", "State"));
  responseArray.push(ValidationUtility.validateElement("city", ValidationType.Select, true, "cityError", "City"));
  responseArray.push(ValidationUtility.validateElement("addr", ValidationType.Text, true, "addrError", "Address"));
  responseArray.push(ValidationUtility.validateElement("gender", ValidationType.Select, true, "genderError", "Gender"));
  responseArray.push(ValidationUtility.validateElement("dob", ValidationType.Date, true, "dobError", "DOB"));
  let errors = responseArray.filter((x) => {
    return !x.isValid;
  });
  return new Promise((resolve, reject) => {
    errors?.length > 0 ? reject(false) : resolve(true);
  });
};

export default PersonalDetails;
