import React from "react";
import styles from "./userProfessionalDetailsCard.module.css";
import { convertDateMMYYYY } from "../../Services/commonService";

const UserProfessionalDetailsCard = ({ data }) => {
    return (
        <div className={styles.headingPart}>
            <div className={styles.line}>
                <div className={styles.companyName}>{data.companyName ? data.companyName : "N/A"}</div>
                <div className={styles.workDuration}>
                    <div>{data.joined_from ? convertDateMMYYYY(data.joined_from) : "N/A"}</div>
                    <div style={{ fontSize: "14px", margin: "0 0.5rem", color: "#000000" }}>to</div>
                    <div>{data.worked_till ? convertDateMMYYYY(data.worked_till) : "N/A"}</div>
                </div>
            </div>
            <div className={styles.line}>
                {data.designation && <div className={styles.designation}>{data.designation ? data.designation : "N/A"}</div>}
                {data.joiningType && <div className={styles.joiningType}>{data.joiningType ? data.joiningType : "N/A"}</div>}
            </div>
            {data.location && (
                <div className={styles.line}>
                    <div className={styles.location}>{data.location ? data.location : "N/A"}</div>
                </div>
            )}
            {data.description && (
                <div className={styles.line}>
                    <div className={styles.description}>{data.description ? data.description : "N/A"}</div>
                </div>
            )}

            {data.project_details.length > 0 && (
                <>
                    <div className={styles.projectsHead}>Projects:</div>
                    <div className={styles.projectsContainer}>
                        {data.project_details &&
                            data.project_details.length > 0 &&
                            data.project_details.map((curElem) => {
                                return (
                                    <div className={styles.eachProject}>
                                        <div className={styles.projectLine}>
                                            <div className={styles.projectName}>{curElem.project_name ? curElem.project_name : "N/A"}</div>
                                            <div className={styles.projectDuration}>
                                                <div>{curElem.started_from ? convertDateMMYYYY(curElem.started_from) : "N/A"}</div>
                                                <div style={{ fontSize: "12px", color: "#000000", margin: "0 0.5rem" }}>to</div>
                                                <div>{curElem.end_on ? convertDateMMYYYY(curElem.end_on) : "N/A"}</div>
                                            </div>
                                        </div>
                                        <div className={styles.projectLine}>
                                            <div className={styles.projectUrl}>
                                                {curElem.project_prod_url ? (
                                                    <a
                                                        href={curElem.project_prod_url.startsWith("http") ? curElem.project_prod_url : `https://${curElem.project_prod_url}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        {curElem.project_prod_url}
                                                    </a>
                                                ) : (
                                                    "No URL"
                                                )}
                                            </div>
                                            <div className={styles.rolePlayed}>{curElem.role_played ? curElem.role_played : "N/A"}</div>
                                        </div>
                                        <div className={styles.projectLine}>
                                            <div className={styles.techUsed}>{curElem.tech_used ? curElem.tech_used : "N/A"}</div>
                                        </div>
                                        <div className={styles.projectLine}>
                                            <div className={styles.projectDescription}>{curElem.description ? curElem.description : "No description given"}</div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
};

export default UserProfessionalDetailsCard;
