import React from "react";
import styles from "./liveSessionSwitch.module.css";
import { Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { postTReq } from "../../../../Services/api";
import { apiEndpoints } from "../../../../PrEnums/prEnums";
import { getPersonalDetails, toasterControl } from "../../../../Services/commonService";

const LiveSessionSwitch = () => {
    const { personalDetails } = useSelector((state) => state.persist);
    const handleStatusChange = async (event) => {
        const res = await postTReq(apiEndpoints.openForSession, {
            is_open_for_live_session: event.target.checked,
        });
        if (res && !res.error) {
            getPersonalDetails();
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "An error occured");
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.label}>Open for live sessions</div>
            <div>
                <Switch size="small" checked={personalDetails && personalDetails.is_open_for_live_session} onChange={handleStatusChange} />
            </div>
        </div>
    );
};

export default LiveSessionSwitch;
