import React, { useEffect, useState } from "react";
import { getEveryUser, toasterControl } from "../../../Services/commonService";
import styles from "./UserListTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import { MdBlockFlipped, MdStarOutline } from "react-icons/md";
import { IoPersonOutline } from "react-icons/io5";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { IoMdCheckmark } from "react-icons/io";
import RemarkPage from "../RemarkPage/RemarkPage";
import ProfileViewData from "../ProfileViewPage/ProfileViewData";
import { BsPersonCircle } from "react-icons/bs";
import { VscVerifiedFilled } from "react-icons/vsc";
import { Pagination, Tooltip } from "@mui/material";
import { savePaginationPageNum } from "../../../Redux/Slices/generalSlice";

const UserListTable = () => {
    const dispatch = useDispatch();
    const [open, setopen] = useState("");
    const { allUserList } = useSelector((state) => state.admin);
    const { paginationPageNum, paginationRecordsNum, paginationTotalPages } = useSelector((state) => state.general);

    const blockUser = async (email, status) => {
        const res = await postTReq(apiEndpoints.blockUser, {
            emailAddress: email,
            is_blocked: status,
        });
        if (res && !res.error) {
            getEveryUser(0, 10);
            toasterControl("successToaster", true, "User Blocked Successfully");
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "An Error Occured");
        }
    };

    const [remarkData, setremarkData] = useState();

    const remarkDetails = async (email) => {
        const res = await postTReq(apiEndpoints.remarks, {
            page_no: 0,
            no_of_records: 10,
            emailAddress: email,
        });
        if (res && !res.error) {
            setremarkData(res.data.data);
            console.log(res.data.data);
        } else {
            console.log(res.error);
        }
    };

    const [profileData, setProfileData] = useState([]);

    const profileDetails = async (email) => {
        const res = await postTReq(apiEndpoints.profile, {
            emailAddress: email,
        });
        if (res && !res.error) {
            if (res.data.data !== undefined) {
                setProfileData(res.data.data);
                console.log(res.data.data);
            }
        } else {
            console.log(res.error);
        }
    };

    const handlePageChange = (pagenum) => {
        dispatch(savePaginationPageNum(pagenum));
    };

    useEffect(() => {
        getEveryUser(paginationPageNum - 1, paginationRecordsNum || 10);
    }, [paginationPageNum, paginationRecordsNum]);

    return (
        <>
            {open === "remark" ? (
                <RemarkPage data={remarkData} openSetter={setopen} />
            ) : open === "profile" ? (
                <ProfileViewData data={profileData} openSetter={setopen} />
            ) : (
                <div className="h-100">
                    <div className={styles.mainHeading}>USER LIST</div>
                    <div className={styles.actualTable}>
                        <div className={styles.onlyTableContainer}>
                            <div className={styles.fittingBox}>
                                <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                                    <div className={styles.firstHead} style={{ paddingLeft: "1rem" }}>
                                        FULL NAME
                                    </div>
                                    <div className={`${styles.headingName} text-center`}>EMAIL VERIFIED</div>
                                    <div className={styles.headingName}>LIVE SESSION</div>
                                    <div className={styles.headingName}>ACTIONS</div>
                                </div>

                                {/* details */}
                                <div className="d-flex flex-column mt-2">
                                    {allUserList.map((curelem) => {
                                        return (
                                            <>
                                                <div
                                                    className="d-flex align-items-center py-2"
                                                    style={curelem.is_blocked ? { backgroundColor: "#f6f6f6", borderBottom: "2px solid #e5e5e6" } : { borderBottom: "2px solid #e5e5e6" }}
                                                >
                                                    <div className={`${styles.firstCell} d-flex align-items-center`}>
                                                        <div className={styles.profileImageContainer}>
                                                            {curelem.profileImageUrl ? <img src={curelem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={32} color="#888888" />}
                                                        </div>
                                                        <div>
                                                            <div>{curelem.fullName}</div>
                                                            <div className={styles.emailPill}>{curelem.emailAddress}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`${styles.detailsText} text-center`}>
                                                        {curelem.is_email_verifed === false ? "Unverified" : <VscVerifiedFilled color="#f1bb46" fontSize={24} />}
                                                    </div>
                                                    <div className={styles.detailsText}>{curelem.is_verified_from_admin_for_live_session ? "Yes" : "No"}</div>
                                                    <div style={{ display: "flex" }}>
                                                        <Tooltip title={curelem.is_blocked ? "Unblock User" : "Block User"}>
                                                            <button className={curelem.is_blocked ? "small-active-button me-1" : "small-delete-button me-1"}>
                                                                {curelem.is_blocked ? (
                                                                    <IoMdCheckmark
                                                                        onClick={() => {
                                                                            blockUser(curelem.emailAddress, curelem.is_blocked ? false : true);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <MdBlockFlipped
                                                                        onClick={() => {
                                                                            blockUser(curelem.emailAddress, curelem.is_blocked ? false : true);
                                                                        }}
                                                                    />
                                                                )}
                                                            </button>
                                                        </Tooltip>
                                                        <Tooltip title="View User Ratings and Remarks">
                                                            <button
                                                                className="small-button me-1"
                                                                onClick={() => {
                                                                    remarkDetails(curelem.emailAddress);
                                                                    setopen("remark");
                                                                }}
                                                            >
                                                                <MdStarOutline />
                                                            </button>
                                                        </Tooltip>

                                                        <Tooltip title="View User Details">
                                                            <button
                                                                className="small-secondary-button"
                                                                onClick={() => {
                                                                    profileDetails(curelem.emailAddress);
                                                                    setopen("profile");
                                                                }}
                                                            >
                                                                <IoPersonOutline />
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <Pagination size="small" color="primary" count={paginationTotalPages} page={paginationPageNum} onChange={(event, value) => handlePageChange(value)} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserListTable;
