import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ValidationUtility } from "../../utility/ValidationUtility";
import "../../style/Home.css";
import { ValidationType } from "../../enum/ValidationType";
import { DataUtility } from "../../utility/DataUtility";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { skillsArray } from "../../json/commonData";
import styles from "./postJobModal.module.css";
import { toasterControl } from "../../Services/commonService";

const optionsSkills = skillsArray.map((item, index) => ({ value: index, label: item }));

const PostJobModal = (props) => {
    let serviceProvider = new ServiceProvider();
    let headerUtility = new HeaderUtility();
    let initElasticItem = [];
    let jobTypeList = [
        {
            name: "Full Time",
            selected: false,
        },
        {
            name: "Part time",
            selected: false,
        },
        {
            name: "Remote",
            selected: false,
        },
        {
            name: "Internship",
            selected: false,
        },
        {
            name: "Contracted",
            selected: false,
        },
        {
            name: "Training",
            selected: false,
        },
    ];
    let [listOfElasticText, setListOfElasticText] = useState(initElasticItem);
    let [jobTitle, setJobTitle] = useState("");
    let [jobDesc, setJobDesc] = useState("");
    let [jobStream, setJobStream] = useState("");
    let [companyName, setCompanyName] = useState("");
    let [exp, setExp] = useState("");
    let [ofrPkg, setOfrPkg] = useState("");
    const [pkgStart, setPkgStart] = useState("");
    const [pkgEnd, setPkgEnd] = useState("");
    let [role, setRole] = useState("");
    let [joiningType, setJoiningType] = useState("");
    let [location, setLocationType] = useState("");
    let [isSkill, setIsSkill] = useState(false);
    let [listOfJobType, setListOfJobType] = useState(jobTypeList);
    let [btnLoader, setBtnLoader] = useState(false);
    let jobService = serviceProvider.returnJobService();
    let header = headerUtility.returnHeaderContainingToken();
    let navigateTo = useNavigate();
    const onJobTitleInput = (value) => {
        setJobTitle(value);
        ValidationUtility.validateElement("jobTitle", ValidationType.Text, true, "jobTitleError", "Job Title");
    };
    const onJobStreamInput = (value) => {
        setJobStream(value);
        ValidationUtility.validateElement("jobStream", ValidationType.Text, true, "jobStreamError", "Job Stream");
    };
    const onJobDescInput = (value) => {
        setJobDesc(value);
        ValidationUtility.validateElement("jobDesc", ValidationType.Text, true, "jobDescError", "Job Description");
    };
    const onCompanyNameInput = (value) => {
        setCompanyName(value);
        ValidationUtility.validateElement("companyName", ValidationType.Text, true, "companyNameError", "Company Name");
    };

    // const addMoreElasticSearchText = (text) => {
    //   let index = listOfElasticText.length;
    //   let obj = {
    //     id: index,
    //     text: text,
    //   };
    //   if (listOfElasticText.length < 7) {
    //     setIsSkill(false);
    //     setListOfElasticText((oldData) => [...oldData, obj]);
    //   }
    // };

    // const handleKeyPress = (event) => {
    //   if (event.charCode === 13) {
    //     event.preventDefault();
    //     if (productName !== "") {
    //       addMoreElasticSearchText(productName);
    //     }
    //     productName = "";
    //     setProductName("");
    //   }
    // };

    // const removeELasticSearchItem = (index) => (e) => {
    //   let tempListOfElasticSearchItem = [...listOfElasticText];
    //   let subTempListOfElasticSearchItem = tempListOfElasticSearchItem.filter((item, subIndex) => subIndex !== index);
    //   setListOfElasticText([]);
    //   setListOfElasticText(subTempListOfElasticSearchItem);
    // };

    // const returnElasticSearchList = () => {
    //   return listOfElasticText.map((item, index) => {
    //     return (
    //       <div className="ml-2 mt-0 mb-0 elasticSearchItem" id={item.text + "elasticSearch" + index + "id" + item.id}>
    //         <span className="pl-2">{item.text}&nbsp;</span>
    //         <span className="pr-2 elasticSearchCross" onClick={removeELasticSearchItem(index)}>
    //           <i className="fa fa-times" aria-hidden="true"></i>
    //         </span>
    //       </div>
    //     );
    //   });
    // };

    const onJobTypeSelect = (index, value) => {
        let list = [...listOfJobType];
        let newList = [];
        list.forEach((item, subindex) => {
            if (subindex === index) {
                item.selected = value;
            }
            newList.push(item);
        });
        console.log(list);
        setListOfJobType([]);
        setListOfJobType(newList);
    };
    const getJobTypeForInputJson = () => {
        let newList = [...listOfJobType];
        console.log(newList);
        let list = [];
        newList.forEach((item) => {
            if (item.selected) {
                let obj = {
                    job_type: item.name,
                };
                list.push(obj);
            }
        });
        return list;
    };
    const generateInputJson = () => {
        let inputJson = {
            jobTitle: jobTitle,
            roleAndResposibility: role,
            companyName: companyName,
            description: jobDesc,
            location: location,
            skillset: DataUtility.generateSkillSetListFromSkillList(listOfElasticText),
            joiningType: joiningType,
            offerPackage: ofrPkg,
            expRequired: exp,
            job_type_details: getJobTypeForInputJson(),
            jobStreams: jobStream,
        };
        return inputJson;
    };
    const onSubmit = () => {
        if (listOfElasticText.length > 0) {
            setIsSkill(false);
        } else {
            setIsSkill(true);
        }
        validatePostJobs().then(() => {
            if (!isSkill) {
                setBtnLoader(true);
                jobService.postJobDetails(generateInputJson(), header).then((res) => {
                    if (res.status === "200 OK") {
                        // onClose();
                        setBtnLoader(false);
                        navigateTo("/profile");
                        // postJobMail(res.data.job_id);
                    } else {
                        setBtnLoader(false);
                        toasterControl("errorToaster", true, "Only verified users can post jobs.");
                        navigateTo("/profile");
                    }
                });
            }
        });
    };

    // const onClose = () => {
    //   setListOfElasticText([]);
    //   props.handleClose();
    // };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    // const imageContent = new ImageContent();

    useEffect(() => {
        setOfrPkg(`${pkgStart} - ${pkgEnd}`);
    }, [pkgEnd, pkgStart]);

    return (
        <div className="">
            <div className="container-fluid pl-0 pr-0">
                <div className="row pl-0 pr-0">
                    <div className="col-12">
                        <label>
                            <strong>Job Title</strong>
                        </label>
                        <Form.Control
                            placeholder="What are you looking for"
                            className="inputFieldStyle"
                            onKeyDown={ValidationUtility.onKeyUpText}
                            id="jobTitle"
                            type="text"
                            onChange={(e) => onJobTitleInput(e.target.value)}
                        />
                        <span className="text-danger float-right" id="jobTitleError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Job Description</strong>
                        </label>
                        <Form.Control placeholder="What they have to do" className="inputFieldStyle" rows="4" cols="33" id="jobDesc" as="textarea" onChange={(e) => onJobDescInput(e.target.value)} />
                        <span className="text-danger float-right" id="jobDescError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>{"Roles & Responsibility"}</strong>
                        </label>
                        <Form.Control placeholder={"Roles & Responsibility"} id="role" rows="4" cols="33" className="inputFieldStyle" as="textarea" onChange={(e) => setRole(e.target.value)} />
                        <span className="text-danger float-right" id="roleError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Location</strong>
                        </label>
                        <Form.Control placeholder="Location ex : Delhi" id="loc" className="inputFieldStyle" type="text" onChange={(e) => setLocationType(e.target.value)} />
                        <span className="text-danger float-right" id="locError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Experienced (in yr)</strong>
                        </label>
                        <Form.Control
                            placeholder="Choose experience"
                            onInput={maxLengthCheck}
                            onKeyDown={ValidationUtility.onkeyUpNumber}
                            pattern="\d*"
                            maxLength={2}
                            className="inputFieldStyle"
                            rows="5"
                            cols="33"
                            id="expRqd"
                            type="text"
                            onChange={(e) => setExp(e.target.value)}
                        />
                        <span className="text-danger float-right" id="expRqdError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Offer Package (in lacs)</strong>
                        </label>
                        <div className="row">
                            <div className="col-6">
                                <Form.Control
                                    placeholder="From"
                                    onInput={maxLengthCheck}
                                    onKeyDown={ValidationUtility.onkeyUpNumber}
                                    pattern="\d*"
                                    maxLength={10}
                                    className="inputFieldStyle"
                                    id="pkgStart"
                                    type="text"
                                    onChange={(e) => setPkgStart(e.target.value)}
                                />
                            </div>
                            <div className="col-6">
                                <Form.Control
                                    placeholder="Upto"
                                    onInput={maxLengthCheck}
                                    onKeyDown={ValidationUtility.onkeyUpNumber}
                                    pattern="\d*"
                                    maxLength={10}
                                    className="inputFieldStyle"
                                    id="pkgEnd"
                                    type="text"
                                    onChange={(e) => setPkgEnd(e.target.value)}
                                />
                            </div>
                        </div>
                        <span className="text-danger float-right" id="ofrPkgError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Joining Type</strong>
                        </label>
                        {/* <Form.Control placeholder="Choose joining Type" id="joint" className="inputFieldStyle" type="text" onChange={e => setJoiningType(e.target.value)} /> */}
                        <select style={{ width: "100%" }} id="jobStream" className="inputFieldStyle" onChange={(e) => setJoiningType(e.target.value)}>
                            <option value="-1" selected disabled>
                                Choose Joining Type
                            </option>
                            <option value="IMMEDIATE">IMMEDIATE</option>
                            <option value="LESS THAN 1 MONTH">LESS THAN 1 MONTH</option>
                            <option value="LESS THAN 2 MONTH">LESS THAN 2 MONTH</option>
                        </select>
                        <br />
                        <span className="text-danger float-right" id="jointError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Company Name (Optional)</strong>
                        </label>
                        <Form.Control
                            placeholder="Company Name"
                            className="inputFieldStyle"
                            onKeyDown={ValidationUtility.onKeyUpText}
                            id="companyName"
                            type="text"
                            onChange={(e) => onCompanyNameInput(e.target.value)}
                        />
                        <span className="text-danger float-right" id="companyNameError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Job Stream</strong>
                        </label>
                        <br />
                        {/* <select style={{ width: "100%" }} id="jobStream" className="inputFieldStyle" onChange={e => onJobStreamInput(e.target.value)}>
                            <option value="-1" selected disabled>Choose job stream</option>
                            <option value="IT Services">IT Services</option>
                            <option value="BBA">BBA</option>
                            <option value="ARTS">ARTS</option>
                        </select><br /> */}
                        <Form.Control placeholder="Job Stream" className="inputFieldStyle" id="jobStream" type="text" onChange={(e) => onJobStreamInput(e.target.value)} />
                        <span className="text-danger float-right" id="jobStreamError"></span>
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Skills</strong>
                        </label>
                        <Select options={optionsSkills} isMulti onChange={setListOfElasticText} />
                        {/* {listOfElasticText.length > 0 ? <div className="container mt-2">
                            <div className="row ml-0 mr-0">
                                {returnElasticSearchList()}
                            </div>
                        </div> : null}
                        <Form.Control onKeyDown={ValidationUtility.onKeyUpText} className="inputFieldStyle" placeholder="Skills" id="skillSet" value={productName} type="text" onKeyPress={handleKeyPress} onChange={e => { setProductName(e.target.value) }} /> */}
                        {isSkill ? (
                            <span className="text-danger float-right" id="skillSetError">
                                Please define one or more required skill set
                            </span>
                        ) : null}
                    </div>
                    <div className="col-12 pt-4">
                        <label>
                            <strong>Job Type</strong>
                        </label>
                        <div className="row">
                            {listOfJobType &&
                                listOfJobType.map((item, index) => {
                                    return (
                                        <React.Fragment>
                                            <div id={"jobTYpe" + index} className="col-xs-6 col-sm-6 col-md-3">
                                                {item.selected ? (
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            onJobTypeSelect(index, false);
                                                        }}
                                                        checked
                                                    />
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            onJobTypeSelect(index, true);
                                                        }}
                                                    />
                                                )}
                                                &nbsp;&nbsp;{item.name}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="col-12 pt-4">
                        <div className="row pt-3 pb-0">
                            <div className="col-12 d-flex justify-content-end">
                                {!btnLoader ? (
                                    <button className={styles.publishButton} onClick={onSubmit}>
                                        Publish Job
                                    </button>
                                ) : (
                                    <button className={styles.publishButton}>
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const validatePostJobs = () => {
    let responseArray = [];
    responseArray.push(ValidationUtility.validateElement("jobTitle", ValidationType.Text, true, "jobTitleError", "Job Title"));
    responseArray.push(ValidationUtility.validateElement("companyName", ValidationType.Text, true, "companyNameError", "Company Name"));
    responseArray.push(ValidationUtility.validateElement("jobDesc", ValidationType.Text, true, "jobDescError", "Job Description"));
    responseArray.push(ValidationUtility.validateElement("jobStream", ValidationType.Text, true, "jobStreamError", "Job Stream"));
    let errors = responseArray.filter((x) => {
        return !x.isValid;
    });
    return new Promise((resolve, reject) => {
        errors?.length > 0 ? reject(false) : resolve(true);
    });
};

export default PostJobModal;
