import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import ImageContent from "../../content/ImageContent";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";
import { DataUtility } from "../../utility/DataUtility";

const ResumeNew4 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let imageContent = new ImageContent();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          let skills = res.data.skillList ? res.data.skillList.map((d) => d.skills) : [];
          setSkillList(skills);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  return (
    <React.Fragment>
      <div className="container_wrapper">
        <div className="row pt-5 d-flex align-items-center">
          {/* <div className="col-4"></div> */}
          <div className="col-8">
            <div className="resume6_name pl-4 d-flex flex-column">
              <span>
                {personalData && personalData.firstName} <span> {personalData && personalData.lastName}</span>
              </span>
              <span>{professionalData.length > 0 ? professionalData[0].designation : ""}</span>
            </div>
          </div>
          <div className="col-4">
            <div className="resume6_contact">
              <div className="row minimal_bottom_padding pt-1">
                <div className="col-1">
                  <img src={imageContent.getResume6_phone()} alt="" height="18" />
                </div>
                <div className="col-9">{personalData && personalData.mobileNumber ? "Phone:  " + personalData.mobileNumber : null}</div>
              </div>
              <div className="row minimal_bottom_padding_last">
                <div className="col-1">
                  <img src={imageContent.getResume6_location()} alt="" height="18" />
                </div>
                <div className="col-9">
                  {personalData && personalData.address ? (
                    <p>
                      Address: {personalData.address}, {personalData.city}, {personalData.country}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row minimal_bottom_padding_last">
                <div className="col-1">
                  <img src={imageContent.getResume6_email()} alt="" height="18" />
                </div>
                <div className="col-9">{personalData && personalData.emailAddress ? <p>Email: {personalData.emailAddress}</p> : null}</div>
              </div>

              {/* <p>Web: www.companyname.com </p> */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-8">
            <div className="right_border">
              <div className="resume6_experience">
                <h5 className="resume6_heading">EXPERIENCE</h5>
                <hr className="devider" />
                {professionalData.length > 0
                  ? professionalData.map((res, index) => (
                      <div className="row nogap" key={index}>
                        <div className="col-md-2 pt-3  no-right-padding">
                          {DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                          {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}
                        </div>
                        <div className="col-md-10">
                          <div className="description_title pt-3">{res.designation}</div>
                          <div className="description_sub_title">
                            {res.companyName} / {res.location}
                          </div>
                          <span className="description_desc">
                            Projects :- <br />{" "}
                            {res.projects_details.map((response, key) => (
                              <Fragment key={response.project_id}>
                                {key + 1}. {response.project_name} - {response.role_played} <br />
                                Tech Used - {response.tech_used} <br />
                                Project Url - {response.project_prod_url} <br />
                                {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                                <br />
                              </Fragment>
                            ))}{" "}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <div className="resume6_education mt-4">
                <h5 className="resume6_heading">EDUCATION</h5>
                <hr className="devider" />

                {educationData.length > 0
                  ? educationData.map((res, index) => (
                      <div className="row nogap" key={index}>
                        <div className="col-md-2 pt-3  no-right-padding">
                          {res.session_start} - {res.session_end}
                        </div>
                        <div className="col-md-10">
                          <div className="description_title pt-3">
                            {res.educationType} ({res.boardName})
                          </div>
                          <div className="description_sub_title">
                            {res.schoolName}, {res.location}
                          </div>

                          <span className="description_desc">with {getPercentage(res.scoredMarks, res.totalMarks)} %</span>
                        </div>
                      </div>
                    ))
                  : null}

                {/* <div className='row nogap'>
                                <div className='col-md-2 pt-3  no-right-padding'>
                                2011-2019
                                </div>
                                <div className='col-md-10'>
                                     <div className='description_title pt-3'>
                                        Position Title Here
                                        </div>
                                        <div className='description_sub_title'>
                                        Company Name / Location
                                        </div>
                                    

                                        <span className='description_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sita amete nisri siat amete nibh dignissim lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.</span>
                                    
                                   
                                </div>
                            </div> */}
              </div>
              <div className="resume6_organization mt-4">
                <h5 className="resume6_heading">ORGANIZATION</h5>
                <hr className="devider" />
                <div className="row pt-3">
                  {professionalData.length > 0
                    ? professionalData.map((res, index) => (
                        <div className="col-6" key={index}>
                          <div className="description_title">{res.companyName}</div>
                          <p>
                            {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                          </p>
                          <p>{res.designation}</p>
                        </div>
                      ))
                    : null}
                  {/* <div className='col-6'>
                                    <div className='description_title'>
                                        American Management Association
                                    </div>
                                    <p > 2011 - Present</p>
                                </div> */}
                  {/* <div className='col-6'>
                                    <div className='description_title'>
                                            American Management Association
                                        </div>
                                        <p > 2011 - Present</p>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h5 className="resume6_heading">PROFILE</h5>
            <hr className="devider" />
            <p className="pt-3"> {personalData && personalData.about_me}</p>

            <h5 className="resume6_heading">SKILLS</h5>
            <hr className="devider" />

            {skillList.length > 0
              ? skillList.map((res, index) => (
                  <div className="skills_bar mt-3" key={index}>
                    <div className="skill_label">{res}</div>
                    <div className="container_skill">
                      <div className="skill html"></div>
                    </div>
                  </div>
                ))
              : null}

            <div className="mt-4">
              <h5 className="resume6_heading">AWARDS</h5>
              <hr className="devider" />
              <div className="description_title pt-3">Best Award Name 2019</div>
              <div className="description_sub_title">Name Of Achievement</div>
              <span className="description_desc">Lorem lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.</span>
              <div className="description_title pt-3">Best Award Name 2019</div>
              <div className="description_sub_title">Name Of Achievement</div>
              <span className="description_desc">Lorem lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor sit amet.</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeNew4;
