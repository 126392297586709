import { ValidationType } from "../enum/ValidationType";
import config from "../config.json";
export class ValidationUtility {
  static emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static mobileRegex = /^(?:(?:\+|0{0,2})91(\s*[- ]\s*)?|[0]?)?[6789]\d{9}|(\d[- ]?){10}\d$/;
  static passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  static panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  static adharRegex = /^(\d{12})$/;
  static maxFileSize = config.maxFileSize;
  static isEmail(input) {
    return this.emailRegex.test(input);
  }
  static isNumeric(input) {
    return !isNaN(parseFloat(input));
  }
  static isInt(input) {
    return !isNaN(parseInt(input));
  }
  static isFloat(input) {
    return !isNaN(parseFloat(input));
  }
  static isNullOrEmpty(input) {
    return input === "" || input === null;
  }
  static isValidPan(input) {
    return this.panRegex.test(input);
  }
  static isValidAdhar(input) {
    return this.adharRegex.test(input);
  }
  static isValidInputByRegex(input, regex) {
    return regex.test(input);
  }
  static isValidDate(input) {
    return true;
  }
  static isValidPassword(input) {
    return this.passwordRegex.test(input);
  }
  static isValidDateRange(startDate, endDate) {
    if (this.isValidDate(startDate) && this.isValidDate(endDate)) {
      return {
        isValid: false,
        msg: "Invalid date",
      };
    } else {
      return {
        isValid: false,
        msg: "Invalid date",
      };
    }
  }
  static isValidFileSize(fileSize) {
    const size = Math.round(fileSize / 1024);
    if (size > this.maxFileSize * 1024) {
      return false;
    }
    return true;
  }
  static isValidMobileNumber(input) {
    return this.mobileRegex.test(input.toString());
  }
  static validateInputFileElement(event) {
    const element = event.target;
    const selectedFile = element.files?.item(0);
    if (!this.isValidFileSize(selectedFile.size)) {
      event.preventDefault();
      alert(`Size of file is not valid, please upload file less than ${this.maxFileSize} mb !`);
    }
  }
  static onkeyUpNumber(event) {
    var key = event.key;
    var convertedKey = isNaN(parseInt(key));
    if (convertedKey === true && key.length <= 1) {
      event.preventDefault();
    }
  }
  static onkeyUpFloat(event) {
    var key = event.key;
    var convertedKey = isNaN(parseFloat(key));
    if (convertedKey === true && key.length <= 1 && key !== ".") {
      event.preventDefault();
    }
  }
  static onKeyUpText(event) {
    var key = event.key;
    var convertedKey = isNaN(parseInt(key));
    if (convertedKey !== true && key.length <= 1 && key !== ".") {
      event.preventDefault();
    }
  }
  static isValidSelectBoxValue(input) {
    if (input !== undefined && input !== "" && input !== null && input !== "-1") {
      return true;
    } else {
      return false;
    }
  }
  static validateInput(input, type, isRequired) {
    let response = {
      msg: "",
      isValid: true,
    };
    if (!this.isNullOrEmpty(input)) {
      switch (type) {
        case ValidationType.Email:
          response.isValid = this.isEmail(input);
          response.msg = response.isValid ? "" : "Invalid Email Id !";
          break;
        case ValidationType.Mobile:
          response.isValid = this.isValidMobileNumber(input);
          response.msg = response.isValid ? "" : "Invalid Mobile Number !";
          break;
        case ValidationType.Integer:
          response.isValid = this.isInt(input);
          response.msg = response.isValid ? "" : "Invalid Integer Number !";
          break;
        case ValidationType.Float:
          response.isValid = this.isFloat(input);
          response.msg = response.isValid ? "" : "Invalid Float Number !";
          break;
        case ValidationType.Pan:
          response.isValid = this.isValidPan(input);
          response.msg = response.isValid ? "" : "Invalid Pan Card !";
          break;
        case ValidationType.Adhar:
          response.isValid = this.isValidAdhar(input);
          response.msg = response.isValid ? "" : "Invalid Adhar Card !";
          break;
        case ValidationType.Password:
          response.isValid = this.isValidPassword(input);
          response.msg = response.isValid ? "" : "Invalid Adhar Card !";
          break;
        case ValidationType.Date:
          response.isValid = this.isValidDate(input);
          response.msg = response.isValid ? "" : "Invalid Date !";
          break;
        case ValidationType.Select:
          response.isValid = this.isValidSelectBoxValue(input);
          response.msg = response.isValid ? "" : "Text is mandatory field !";
          break;
        default:
          break;
      }
    } else {
      if (isRequired) {
        response.msg = `${ValidationType[type]} is mandatory field !`;
        response.isValid = false;
      }
    }
    return response;
  }
  static validateInputElement(elementId, type, isRequired) {
    const element = document.getElementById(elementId);
    let value = "";
    if (element !== null) {
      value = element.value;
    }
    return this.validateInput(value, type, isRequired);
  }
  static validateSelectBox(elementId, isRequired) {
    const element = document.getElementById(elementId);
    const value = element.options[element.selectedIndex].value;
    return this.validateInput(value, ValidationType.Select, isRequired);
  }
  static handleValidation(response, errorSpanId, elementName) {
    const element = document.getElementById(errorSpanId);
    if (element != null) {
      if (response.isValid) {
        element.innerText = "";
      } else {
        element.innerText = elementName !== undefined ? response.msg.replace("Text", elementName) : response.msg;
      }
    }
    return response;
  }
  static validateElement(elementId, type, isRequired, errorSpanId, elementName) {
    let response;
    response = this.validateInputElement(elementId, type, isRequired);
    return this.handleValidation(response, errorSpanId, elementName);
  }
  static validateCommonDropDownValue = (index, value, data, elementIdString, errorMsg, objectKey) => {
    let indexValue = -1;
    let element = document.getElementById(index + elementIdString);
    if (value === "-2") {
      return (indexValue = -2);
    }
    for (let i = 0; i < data.length; ++i) {
      if (value === data[i][objectKey]) {
        indexValue = i;
        break;
      }
    }
    if (indexValue !== -1) {
      if (element !== null) {
        data[index][objectKey] = "";
        element.innerText = errorMsg;
      }
    } else {
      if (element !== null) {
        element.innerText = "";
      }
    }
    return indexValue;
  };
  static validateDynamicDropDownValue = (index, value, data, elementIdString, errorMsg, objectKey) => {
    let indexValue = -1;
    let element = document.getElementById(elementIdString);
    if (value === "-2") {
      return (indexValue = -2);
    }
    for (let i = 0; i < data.length; ++i) {
      if (i !== index) {
        if (value === data[i][objectKey]) {
          indexValue = i;
          break;
        }
      }
    }
    if (indexValue !== -1) {
      if (element !== null) {
        data[index][objectKey] = "-2";
        element.innerText = errorMsg;
      }
    } else {
      if (element !== null) {
        element.innerText = "";
      }
    }
    return indexValue;
  };
  static validateListForDuplicateValue = (data, objectKey) => {
    for (let i = 0; i < data.length - 1; ++i) {
      for (let j = i + 1; j < data.length; ++j) {
        if (data[i][objectKey] === data[j][objectKey]) {
          data[j][objectKey] = "";
        }
      }
    }
    return data;
  };
}
