import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";
import { DataUtility } from "../../utility/DataUtility";

const Resume420 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          let skills = res.data.skillList ? res.data.skillList.map((d) => d.skills) : [];
          setSkillList(skills);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  return (
    <React.Fragment>
      <div className="container_fluidelisona-resume" style={{ display: "flex", flexDirection: "column", width: "100%", fontFamily: "'Poppins', sans-serif", fontSize: "13px" }}>
        <div className="pt_5" style={{ display: "flex", flexDirection: "row", paddingTop: "1.25rem" }}>
          <div className="col_4" style={{ width: "33.33%" }}></div>
          <div className="col_8" style={{ width: "66.67%" }}>
            <div style={{ paddingLeft: "20px" }}>
              <div className="pb_0" style={{ paddingBottom: "0" }}>
                <h1
                  className="elisona_Name"
                  style={{ fontFamily: "'Poppins', sans-serif", fontSize: "55px", color: "#c6ceb8", maxWidth: "100%", wordWrap: "break-word", position: "relative", top: "20px" }}
                >
                  {personalData && personalData.firstName.toUpperCase()} {personalData && personalData.lastName.toUpperCase()}
                </h1>
              </div>
              <div className="elisonaDesignation_Conatiner pt_0 pl_3" style={{ backgroundColor: "#dee2d4", paddingTop: "0", paddingLeft: "0.8rem" }}>
                <span className="elisona_Designation" style={{ fontSize: "35px" }}>
                  {professionalData.length > 0 ? professionalData[0].designation.toUpperCase() : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="ro_w" style={{ display: "flex" }}>
          <div className="col_4" style={{ width: "33.33%" }}>
            {/* Skills Div Start */}
            {skillList.length > 0 ? (
              <div className="ro_w" style={{ display: "flex" }}>
                <div
                  className="col_12 d_flex justify_content_center align_item_center pb_3"
                  style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", paddingBottom: "0.8px" }}
                >
                  <h5>SKILLS</h5>
                </div>
                <div style={{ width: "60%", margin: "auto" }}>
                  {skillList.length > 0
                    ? skillList.map((res, index) => (
                        <div
                          className="resume-alisona-skills px_2 pt_2"
                          key={index}
                          style={{ textAlign: "center", paddingLeft: "0.6rem", paddingRight: "0.6rem", paddingTop: "0.6rem", width: "100%", backgroundColor: "#c6ceb8" }}
                        >
                          <span>{res.toUpperCase()}</span>
                          <div class="skills_Container" style={{ width: "100%", backgroundColor: "#c6ceb8" }}>
                            <div class="skill_s htm_l" style={{ textAlign: "left", paddingBottom: "10px", color: "white", width: "70%", backgroundColor: "#46483e" }}></div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ) : null}
            {/* Skills Div End */}
            {/* Interest Div Start */}
            {/* <div className="row">
                            <div className="col-12 d-flex justify-content-center align-item-center pb-3">
                                <h5>INTEREST</h5>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-item-center">
                                <div className="row pb-1">
                                    <div className="col-12 elisonaSkill">ART</div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-item-center">
                                <div className="row pb-1">
                                    <div className="col-12 elisonaSkill">MUSIC</div>
                                </div>
                            </div>
                        </div> */}
            {/* Interest Div End */}
            {/* Lang Div Start */}
            {personalData && personalData.language ? (
              <div className="ro_w" style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="col_12 d_flex justify_content_center align_item_center pb_3"
                  style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "0.8rem", fontWeight: "800" }}
                >
                  <h5>LANGUAGE</h5>
                </div>
                <div className="col_12 d_flex justify_content_center align_item_center" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div className="ro_w pb_1" style={{ display: "flex", paddingBottom: "0.3rem" }}>
                    <div className="col_12 pl_0 elisona_Skill text_center" style={{ fontSize: "12px", width: "100%", paddingLeft: "0", textAlign: "center" }}>
                      <ul>{personalData && personalData.language && personalData.language.map((data, index) => <li key={index}>{data.toUpperCase()}</li>)}</ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Lang Div End */}
            {/* Detail Div Start */}
            <div
              className="ro_w elisona_DesignationConatiner d_flex justify_content-center align_item-center"
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#dee2d4" }}
            >
              <div className="elisona_Skill text_right pt_3 pr_2" style={{ fontSize: "12px", textAlign: "right", paddingTop: "0.8rem", paddingRight: "0.6rem", paddingBottom: "0.8rem" }}>
                {/* www.vikipro.com<br /> */}
                {personalData && personalData.emailAddress && personalData.emailAddress.toUpperCase()}
                <br />
                {personalData && personalData.address && personalData.address.toUpperCase()}, {personalData && personalData.city && personalData.city.toUpperCase()},{" "}
                {personalData && personalData.country && personalData.country.toUpperCase()}
                <br />
                {personalData && personalData.mobileNumber && personalData.mobileNumber.toUpperCase()}
              </div>
            </div>
            {/* Detail Div End */}
          </div>
          <div className="col_8" style={{ width: "66.67%" }}>
            <div style={{ paddingLeft: "20px" }}>
              {personalData && personalData.about_me ? (
                <div className="row">
                  <div className="col_12" style={{ width: "100%", marginTop: "1rem", fontWeight: "800" }}>
                    <h5 style={{ fontSize: "21px" }}>PROFILE</h5>
                  </div>
                  <div className="col_12" style={{ width: "100%", marginLeft: "1rem" }}>
                    <span>{personalData && personalData.about_me}</span>
                  </div>
                </div>
              ) : null}
              {educationData.length > 0 ? (
                <div className="ro_w" style={{ display: "flex", flexDirection: "column", fontWeight: "800" }}>
                  <div className="col_12" style={{ width: "100%" }}>
                    <h5 style={{ fontSize: "21px" }}>EDUCATION</h5>
                  </div>
                  {educationData.length > 0
                    ? educationData.map((res, index) => (
                        <div className="col_12 mb_2" key={index} style={{ width: "100%", marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
                          <span style={{ fontSize: "16px", fontWeight: "600", width: "70%" }}>
                            {res.schoolName} - {res.boardName}
                          </span>
                          <br />
                          <div style={{ display: "flex", flexDirection: "column", marginRight: "1rem", width: "30%" }}>
                            <div style={{ fontSize: "12px", fontWeight: "400", justifyContent: "left" }}>
                              {res.educationType} ({res.session_start} - {res.session_end})
                            </div>
                            <div className="d_escription_desc" style={{ fontSize: "13.2px", fontWeight: "200", justifyContent: "left" }}>
                              with {getPercentage(res.scoredMarks, res.totalMarks)} %
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              ) : null}

              {professionalData.length > 0 ? (
                <div className="ro_w" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="col_12" style={{ width: "100%", fontWeight: "800" }}>
                    <h5 style={{ fontSize: "21px" }}>EXPERIENCE</h5>
                  </div>
                  {professionalData.length > 0
                    ? professionalData.map((res, index) => (
                        <div className="col_12" key={index} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                              <b>
                                {res.designation} - {DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                                {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}
                              </b>
                            </span>
                            <br />
                            <span>
                              {res.companyName} / {res.location}
                            </span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", marginRight: "1rem" }}>
                            <span className="description_desc">
                              Projects :- <br />{" "}
                              {res.projects_details.map((response, key) => (
                                <Fragment key={response.project_id}>
                                  {key + 1}. {response.project_name} - {response.role_played} <br />
                                  Tech Used - {response.tech_used} <br />
                                  Project Url - {response.project_prod_url} <br />
                                  {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                                  <br />
                                </Fragment>
                              ))}{" "}
                            </span>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              ) : null}

              {personalData && personalData.achievement_details.length > 0 ? (
                <div className="ro_w" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="col_12" style={{ width: "100%", fontWeight: "800" }}>
                    <h5 style={{ fontSize: "21px" }}>AWARDS / ACHIEVEMENTS</h5>
                  </div>
                  <div className="col_12" style={{ width: "100%" }}>
                    {personalData.achievement_details.map((item, id) => (
                      <>
                        <span key={item.achievement_id}>
                          {id + 1}- {item.achievement}
                        </span>
                        <br />
                      </>
                    ))}
                  </div>
                </div>
              ) : null}

              {professionalData && professionalData.length > 0 ? (
                <div className="ro_w" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="col_12" style={{ width: "100%", fontWeight: "800", marginTop: "1rem" }}>
                    <h5 style={{ fontSize: "21px" }}>ORGANIZATION</h5>
                  </div>
                  {professionalData.length > 0
                    ? professionalData.map((res, index) => (
                        <div className="col_12" style={{ width: "100%", marginBottom: "1rem" }}>
                          <span>
                            <b>
                              {" "}
                              {res.companyName} - {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                            </b>
                          </span>
                          <br />
                          <span>{res.designation}</span>
                        </div>
                      ))
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Resume420;
