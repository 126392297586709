import React, { useEffect, useState } from "react";
import styles from "./scheduleSessionModal.module.css";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { socket } from "../../../Services/socket";
import { getToken } from "../../../Services/authService";
import DatePicker from "react-datepicker";
import { commonValidateField, validationErrorChecker } from "../../../Services/commons";
import { liveSessionValidationObj } from "../../../Services/validationObjects";

const ScheduleSessionModal = () => {
    const dispatch = useDispatch();
    const { scheduleSessionModal } = useSelector((state) => state.nopersist);
    const { selectedChat } = useSelector((state) => state.chatting);
    const { personalDetails } = useSelector((state) => state.persist);

    const initialData = {
        meet_start_time: new Date(),
        meet_end_time: new Date(new Date().setHours(23, 15, 0, 0)),
        message: "",
        meeting_url: "",
        comment: "",
        meetDate: new Date(),
    };
    const [inputData, setInputData] = useState(initialData);
    const [sendingData, setSendingData] = useState(inputData);
    const [errorData, setErrorData] = useState({});
    const handleInput = (event) => {
        const { name, value } = event.target;
        if (name === "meet_start_time" || name === "meet_end_time") {
            const year = inputData.meetDate.getFullYear();
            const month = inputData.meetDate.getMonth();
            const day = inputData.meetDate.getDate();
            const hours = value.getHours();
            const minutes = value.getMinutes();
            const combinedDateTime = new Date(year, month, day, hours, minutes);
            setInputData((prev) => {
                return {
                    ...prev,
                    [name]: combinedDateTime,
                };
            });
            let valText = commonValidateField(liveSessionValidationObj[name], value);
            setErrorData((prev) => {
                return {
                    ...prev,
                    [name]: valText,
                };
            });
        } else {
            setInputData((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
            let valText = commonValidateField(liveSessionValidationObj[name], value);
            setErrorData((prev) => {
                return {
                    ...prev,
                    [name]: valText,
                };
            });
        }
    };

    const handleSubmit = async () => {
        let formFields = Object.keys(liveSessionValidationObj);
        if (validationErrorChecker(inputData, errorData, liveSessionValidationObj)) {
            formFields.forEach((curElem) => {
                let valText = commonValidateField(liveSessionValidationObj[curElem], inputData[curElem]);
                setErrorData((prev) => {
                    return {
                        ...prev,
                        [curElem]: valText,
                    };
                });
            });
        } else {
            // const res = await postTReq(apiEndpoints.scheduleMeeting, { reciever_email: selectedChat.emailAddress, ...newInputData });
            // if (res && !res.error) {
            //     getChatByUserEmail(selectedChat.emailAddress, 0, 10);
            //     setInputData(initialData);
            //     dispatch(modalController({ modal: "scheduleSessionModal", state: false }));
            // } else {
            //     console.log(res.error);
            // }
            socket.emit("messageSent", {
                requestBody: { reciever_email: selectedChat.emailAddress, ...sendingData },
                authTok: getToken(),
                receiverUserId: selectedChat.userId,
                senderUserId: personalDetails.userId,
                type: "meeting",
            });
            setInputData(initialData);
            dispatch(modalController({ modal: "scheduleSessionModal", state: false }));
            // scheduleMeetingMail(personalDetails.fullName, selectedChat.fullName, selectedChat.emailAddress, sendingData.meetDate, sendingData.meet_start_time);
        }
    };

    useEffect(() => {
        setSendingData((prev) => {
            return {
                ...prev,
                ...inputData,
                meetDate: inputData.meetDate.getTime(),
                meet_end_time: inputData.meet_end_time.getTime(),
                meet_start_time: inputData.meet_start_time.getTime(),
            };
        });
    }, [inputData]);

    return (
        <Modal
            className="transparentModalSession"
            dialogClassName="d-flex justify-content-center"
            centered
            show={scheduleSessionModal}
            onHide={() => dispatch(modalController({ modal: "scheduleSessionModal", state: false }))}
            size="lg"
        >
            <Modal.Body className="p-0">
                <div className={styles.container}>
                    <div className={styles.modalHeading}>
                        <div>Schedule Live Session</div>
                        <div className={styles.closeButtonContainer} onClick={() => dispatch(modalController({ modal: "scheduleSessionModal", state: false }))}>
                            <AiOutlineClose fontSize={20} />
                        </div>
                    </div>
                    <div className={styles.recepientSection}>
                        <div className={styles.sectionTitle}>To:</div>
                        <div className={styles.recepientPill}>{selectedChat && selectedChat.fullName ? selectedChat.fullName : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Title</div>
                            <div className={styles.inputContainer}>
                                <input type="text" placeholder="e.g. Live session with John Doe" name="message" value={inputData.message} onChange={handleInput} />
                            </div>
                            <div className="input-error-text">{errorData.message && errorData.message}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-49`}>
                            <div className={styles.inputHeading}>Live Session Date</div>
                            <div className={styles.inputContainer}>
                                <DatePicker showIcon selected={inputData.meetDate} onChange={(date) => handleInput({ target: { name: "meetDate", value: date } })} />
                                <div className="input-error-text">{errorData.meetDate && errorData.meetDate}</div>
                            </div>
                        </div>
                        <div className={`${styles.eachInputSection} w-49`}>
                            <div className={styles.inputHeading}>Session Time</div>
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className={styles.durationInputContainer}>
                                    {/* <input type="time" name="meetTimeStart" value={inputData.meetTimeStart} onChange={handleInput} /> */}
                                    <DatePicker
                                        selected={inputData.meet_start_time}
                                        onChange={(date) => handleInput({ target: { name: "meet_start_time", value: date } })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        minTime={new Date()}
                                        maxTime={inputData.meet_end_time}
                                    />
                                    <div className="input-error-text">{errorData.meet_start_time && errorData.meet_start_time}</div>
                                </div>
                                <div style={{ width: "2rem" }} className="d-flex justify-content-center">
                                    to
                                </div>
                                <div className={styles.durationInputContainer}>
                                    {/* <input type="time" name="meetTimeEnd" value={inputData.meetTimeEnd} onChange={handleInput} /> */}
                                    <DatePicker
                                        selected={inputData.meet_end_time}
                                        onChange={(date) => handleInput({ target: { name: "meet_end_time", value: date } })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        minTime={inputData.meet_start_time}
                                        maxTime={new Date(inputData.meetDate.setHours(23, 45, 0, 0))}
                                    />
                                    <div className="input-error-text">{errorData.meet_end_time && errorData.meet_end_time}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Share Meet Link</div>
                            <div className={styles.inputContainer}>
                                <input type="text" placeholder="e.g. https://meet.google.com/abc-def-ghi" name="meeting_url" value={inputData.meeting_url} onChange={handleInput} />
                                <div className="input-error-text">{errorData.meeting_url && errorData.meeting_url}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Add Description</div>
                            <div className={styles.inputContainer}>
                                <textarea rows={6} type="text" placeholder="Add Description" name="comment" value={inputData.comment} onChange={handleInput} />
                            </div>
                        </div>
                    </div>
                    <div className="js-submit-button-container">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ScheduleSessionModal;
