import React from 'react';
import ResumeBuilder from '../../component/ResumeBuilder/ResumeBuilder';
import '../../style/CompleteProfile.css';

const ResumeBuilderContainer = (props) => {
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row pl-5">
                    <div className='pl-5 pt-5 pb-0'>
                        <p className='pl-1 screenTitle font-3'>Create Your Resume</p>
                    </div>
                </div>
                <div className='row ml-5 mr-5'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='createProfileSideBar col-sm-12 col-md-12 col-lg-12 '>
                            <ResumeBuilder/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default ResumeBuilderContainer;