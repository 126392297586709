export class DocumentUtility {
    static getFormData(fileElementId) {
        let formData = new FormData();
        const element = document.getElementById(fileElementId);
        const selectedFile = element ? element.files?.item(0) : '';
        formData.append('file', selectedFile);
        return formData;
    }

    static getFormDataWithJson(fileElementId, inputJson) {
        const formData = this.getFormData(fileElementId);
        formData.append('json', JSON.stringify(inputJson));
        return formData;
    }

    static getFormDataWithJsonVideo(inputJson) {
        const formData = inputJson.formInfo;
        // formData.append('file', JSON.stringify(inputJson.name));
        formData.append('title', JSON.stringify(inputJson.title));
        formData.append('category', JSON.stringify(inputJson.category));
        formData.append('subCategory', JSON.stringify(inputJson.subCategory));
        return formData;
    }

    static getFormDataWithResume(inputJson) {
        const formData = inputJson.file;
        formData.append('jobid', JSON.stringify(inputJson.id));
        formData.append('message', JSON.stringify(inputJson.message));
        return formData;
    }
}

