import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import "../../style/Modal.css";
import "../../style/PersonalDetails.css";
import ImageContent from "../../content/ImageContent";
import { Button, Form } from "react-bootstrap";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";

const ResumeUploadModal = (props) => {
  let dummyData = {
    id: props.job_id,
    file: "",
    message: "",
    fileName: "",
  };
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let [data, setData] = useState(dummyData);
  let imageContent = new ImageContent();
  const onClickToChooseFiles = () => {
    let fileEle = document.getElementById("fileId");
    if (fileEle !== null) {
      fileEle.click();
    }
  };
  const onUpload = () => {
    serviceProvider
      .returnJobService()
      .applyJob(headerUtility.getHeaderForFileUpload(), data)
      .then((res) => {
        if (res.status === "200 OK") {
          props.handleClose();
          props.setIsApplied(true);
        }
      });
  };
  const addResume = (url, file, fileid) => {
    let newData = { ...dummyData };
    let formData = new FormData();
    const element = document.getElementById(fileid);
    const selectedFile = element ? element.files?.item(0) : "";
    formData.append("file", selectedFile);
    newData.file = formData;
    newData.fileName = file.name;
    setData(newData);
  };
  const addMessage = (value) => {
    let newData = { ...data };
    newData.message = value;
    setData(newData);
  };
  const onFileSelect = (list) => {
    if (list.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(list[0]);
      reader.onload = () => {
        if (reader.readyState === 2) {
          addResume(reader.result, list[0], "fileId");
        }
      };
    }
  };
  return (
    <React.Fragment>
      <Modal size="lg" show={props.isShow} onHide={props.handleClose} aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className="p-0">
          <div className="container-fluid">
            <div className="row applyForJobDiv p-3">
              <div className="col-9">
                <h5>
                  <b>Apply for job</b>
                </h5>
              </div>
              <div className="col-3">
                <img className="crossicon" alt="cross" onClick={props.handleClose} src={imageContent.getCrossImg()} />
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <div className="resumeUploadInputField" onClick={onClickToChooseFiles}>
                  <Form.Control
                    type="file"
                    style={{ display: "none" }}
                    id="fileId"
                    onChange={(e) => {
                      if (e.target.files !== null) {
                        onFileSelect(e.target.files);
                      }
                    }}
                  />
                  <div className="row pt-3">
                    <div className="col-12 d-flex justify-content-center">
                      <img src={imageContent.getUploadImage()} alt="" />
                    </div>
                    <div className="col-12 d-flex justify-content-center pt-2">
                      <h6>
                        <strong>UPLOAD RESUME</strong>
                      </h6>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <span className="contactSpan">{data.fileName === "" ? "Click to choose files" : data.fileName}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <h6>
                  <strong>Message(Optional)</strong>
                </h6>
              </div>
              <div className="col-12">
                <div className="messageOptional">
                  <Form.Control
                    onChange={(e) => {
                      addMessage(e.target.value);
                    }}
                    placeholder="Do you wanna say something"
                    rows="5"
                    cols="33"
                    as="textarea"
                  />
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <Button onClick={onUpload} className="connectButtonTwo">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ResumeUploadModal;
