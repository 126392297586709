import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import ImageContent from "../../content/ImageContent";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";
import { DataUtility } from "../../utility/DataUtility";

const ResumeNew2 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let imageContent = new ImageContent();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          let skills = res.data.skillList ? res.data.skillList.map((d) => d.skills) : [];
          setSkillList(skills);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  let profileUrl = !personalData?.profileImageUrl ? imageContent.getProfilemage() : `https://jobsifarish.s3.ap-south-1.amazonaws.com/${personalData?.profileImageUrl}`;

  return (
    <React.Fragment>
      <div
        className="c_ontaine_new_resume_2"
        style={{
          backgroundImage: "url('https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Background.svg')",
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
          fontSize: "14px",
          fontFamily: "'Poppins', sans-serif",
          border: "1px solid grey",
        }}
      >
        <div className="r_ow pt_5" style={{ display: "flex", paddingTop: "1.5rem" }}>
          <div className="col_4" style={{ width: "33.33%" }}>
            <div className="p_rofile_image_resume_new_2 mt_4 pt_4 pl_5" style={{ marginTop: "1.2rem", paddingTop: "1.2", paddingLeft: "1.5rem" }}>
              <img
                className="ml_3"
                src={profileUrl}
                alt="profileimage"
                style={{ marginLeft: "0.8rem", width: "200px", height: "200px", borderRadius: "50%", objectFit: "cover", border: "5px solid black" }}
              />
            </div>
          </div>
          {personalData && personalData.about_me && (
            <div className="col_8 pt_5" style={{ width: "66.67%", paddingTop: "1.5rem" }}>
              <div className="r_esume_2_new_content mt_5 px_4" style={{ lineHeight: "1.2", marginTop: "2rem", paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
                <div className="r_esume_new_2_heading" style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}>
                  <h6 style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}> &#9632; &nbsp; ABOUT ME</h6>
                </div>
                <p className="pl_4" style={{ paddingLeft: "1.6rem", fontSize: "15px", marginBottom: "5px" }}>
                  {personalData.about_me}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="r_ow" style={{ display: "flex" }}>
          <div className="col_4" style={{ width: "33.33%" }}>
            <div className="pl_4 pt_3" style={{ paddingLeft: "1.2rem", paddingTop: "0.8rem" }}>
              <div className="c_ontact_info_resume2_new" style={{ lineHeight: "1.1" }}>
                <h3 style={{ fontSize: "42px", lineHeight: "0.9" }}>{personalData && personalData.firstName}</h3>
                <h3 style={{ fontSize: "42px", lineHeight: "0.9" }}>{personalData && personalData.lastName}</h3>
                <span style={{ color: "#aa8b57", fontSize: "18px" }}>{professionalData.length > 0 ? professionalData[0].designation.toUpperCase() : ""}</span>
              </div>
              <div className="c_ontact_links_resume2_new mt_4" style={{ wordBreak: "break-all", marginTop: "1.2rem" }}>
                <span className="d_flex" style={{ display: "flex", marginTop: "10px" }}>
                  <img
                    className="mr_2"
                    src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+25.svg"
                    alt="mobile"
                    height="16"
                    style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem" }}
                  />
                  <span>{personalData && personalData.emailAddress}</span>
                </span>
                <span className="d_flex" style={{ display: "flex", marginTop: "10px" }}>
                  <img
                    className="mr_2"
                    src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+23.svg"
                    alt="mobile"
                    height="16"
                    style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem" }}
                  />
                  <span>{personalData && personalData.address}</span>
                </span>
                <span className="d_flex" style={{ display: "flex", marginTop: "10px" }}>
                  <img
                    className="mr_2"
                    src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+22.svg"
                    alt="mobile"
                    height="16"
                    style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem" }}
                  />
                  <span>{personalData && personalData.mobileNumber}</span>
                </span>
              </div>
              <hr style={{ height: "1px", backgroundColor: "lightgray", marginRight: "85px" }} />
              <div className="r_esume2_new_skills pt_3" style={{ paddingTop: "0.8rem" }}>
                <h5 style={{ fontSize: "22px" }}>SKILLS</h5>
                <div className="b_ullets_border" style={{ borderLeft: "3px solid lightgray", margin_eft: "7px" }}>
                  <ul style={{ marginLeft: "-46px", listStyle: "none" }}>
                    {skillList.length > 0
                      ? skillList.map((res, index) => (
                          <li key={index} className="pb_1" style={{ paddingBottom: "0.3rem", fontFamily: "Arial", fontSize: "18px" }}>
                            {res}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className="r_esume2_new_skills pt-3" style={{ paddingTop: "0.8rem" }}>
                <h5 style={{ fontSize: "22px" }}>LANGUAGE</h5>
                <div className="b_ullets_border" style={{ borderLeft: "3px solid lightgray", marginLeft: "7px" }}>
                  <ul style={{ marginLeft: "-46px", listStyle: "none" }}>
                    {personalData &&
                      personalData.language &&
                      personalData.language.map((data, index) => (
                        <li key={index} className="pb_1" style={{ paddingBottom: "0.3rem", fontFamily: "Arial", fontSize: "18px", paddingLeft: "1rem" }}>
                          {data}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col_8" style={{ borderLeft: "3px solid lightgray", width: "66.66%" }}>
            <div className="r_esume_2_new_content mt_1 px_4" style={{ marginTop: "0.3rem", paddingLeft: "1.2rem", paddingRight: "1.2rem", lineHeight: "1.2" }}>
              <div className="r_esume_new_2_heading" style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}>
                <h6 style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}> &#9632; &nbsp; EDUCATION</h6>
              </div>
              {educationData.length > 0
                ? educationData.map((res, index) => (
                    <div className="r_ow" key={index} style={{ display: "flex" }}>
                      <div className="col_4" style={{ width: "33.33%" }}>
                        <p className="pl_4" style={{ fontSize: "15px", marginBottom: "5px", paddingLeft: "1.2rem" }}>
                          {res.session_start} - {res.session_end}
                        </p>
                      </div>
                      <div className="col_8" style={{ width: "66.67%" }}>
                        <p style={{ fontSize: "15px", marginBottom: "5px" }}>
                          {res.educationType} | {res.schoolName}
                        </p>
                        <p className="t_ext_secondary" style={{ fontSize: "15px", marginBottom: "5px", color: "grey" }}>
                          with {getPercentage(res.scoredMarks, res.totalMarks)} %
                        </p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div className="r_esume_2_new_content mt_1 px_4" style={{ lineHeight: "1.2", marginTop: "0.3rem", paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
              <div className="r_esume_new_2_heading" style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}>
                <h6 style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}> &#9632; &nbsp; WORK EXPERIENCE</h6>
              </div>
              {professionalData.length > 0
                ? professionalData.map((res, index) => (
                    <div className="r_ow" key={index} style={{ display: "flex" }}>
                      <div className="col_4" style={{ width: "33.33%" }}>
                        <p className="pl_4" style={{ paddingLeft: "1.2rem", fontSize: "15px", marginBottom: "5px" }}>
                          {DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                          {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}
                        </p>
                      </div>
                      <div className="col_8" style={{ width: "66.67%" }}>
                        <p style={{ fontSize: "15px", marginBottom: "5px" }}>{res.designation}</p>
                        <p className="t_ext_secondary" style={{ fontSize: "15px", marginBottom: "5px", color: "grey" }}>
                          {res.companyName}{" "}
                        </p>
                        <p className="mt_1" style={{ lineHeight: 1.5, fontSize: "15px", marginBottom: "5px", marginTop: "0.3rem" }}>
                          Projects :- <br />{" "}
                          {res.projects_details.map((response, key) => (
                            <Fragment key={response.project_id}>
                              {key + 1}. {response.project_name} - {response.role_played} <br />
                              Tech Used - {response.tech_used} <br />
                              Project Url - {response.project_prod_url} <br />
                              {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                              <br />
                            </Fragment>
                          ))}{" "}
                        </p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div className="r_esume_2_new_content mt_1 px_4" style={{ lineHeight: "1.2", marginTop: "0.3rem", paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
              <div className="resume_new_2_heading" style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}>
                <h6 style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}> &#9632; &nbsp; ORGANIZATION</h6>
              </div>
              {professionalData.length > 0
                ? professionalData.map((res, index) => (
                    <div className="r_ow" key={index} style={{ display: "flex" }}>
                      <div className="col_4" style={{ width: "33.33%" }}>
                        <span className="pl_4" style={{ paddingLeft: "1.2rem", fontSize: "15px", marginBottom: "5px" }}>
                          {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                        </span>
                      </div>
                      <div className="col_8" style={{ width: "66.67%" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>{res.companyName}</span>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            {personalData && personalData.achievement_details.length > 0 && (
              <div className="resume_2_new_content mt-1 px-4">
                <div className="r_esume_new_2_heading" style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}>
                  <h6 style={{ letterSpacing: "1.6px", color: "#aa8b57", fontSize: "16px", fontWeight: "900" }}> &#9632; &nbsp; HONOURS/AWARDS</h6>
                </div>
                {personalData.achievement_details.map((item, id) => (
                  <>
                    <p className="pl_4" key={item.achievement_id} style={{ paddingLeft: "1.2rem" }}>
                      {id + 1}- {item.achievement}
                    </p>
                    <br />
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeNew2;
