import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    singleJobData: {},
    jobsPostedByUser: [],
    appliedJobsByUser: [],
    jobApplicants: [],
    editingJobObj: {},
    jobsBookmarkedByUser: {},
};

export const jobSlice = createSlice({
    name: "jobSlice",
    initialState,
    reducers: {
        saveSingleJobData: (state, action) => {
            state.singleJobData = action.payload;
        },
        saveJobsPostedByUser: (state, action) => {
            state.jobsPostedByUser = action.payload;
        },
        saveAppliedJobsByUser: (state, action) => {
            state.appliedJobsByUser = action.payload;
        },
        saveJobApplicants: (state, action) => {
            state.jobApplicants = action.payload;
        },
        saveEditingJobObj: (state, action) => {
            state.editingJobObj = action.payload;
        },
        saveJobsBookmarkedByUser: (state, action) => {
            state.jobsBookmarkedByUser = action.payload;
        },
    },
});

export const { saveSingleJobData, saveJobsPostedByUser, saveAppliedJobsByUser, saveJobApplicants, saveEditingJobObj, saveJobsBookmarkedByUser } = jobSlice.actions;

export default jobSlice.reducer;
