import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    postJobSubmitToggler: false,
    openedAccordion: "",

    //loadings
    onInputEmailLoading: false,

    //modals and drawers here
    headerDrawer: false,
    loginModal: false,
    registrationModal: false,
    jobsifarishChatDrawer: true,
    addWorkExperienceModal: false,
    addEducationalDetailModal: false,
    scheduleSessionModal: false,
    scheduleSessionDrawer: false,
    rescheduleModal: false,
    editProfessionModal: false,
    professionDeleteConfirmationModal: false,
    editEducationModal: false,
    jobApplicantsModal: false,
    addProjectModal: false,
    editProjectModal: false,
    editJobModal: false,
    editRatingModal: false,
    enterOtpModal: false,
    forgotPasswordEnterEmailModal: false,
    forgotPasswordOtpModal: false,
    enterNewPasswordModal: false,
    enterOldPasswordModal: false,
    changeEnterOtpModal: false,
    enterNewPasswordChangeModal: false,

    forgotPasswordEmail: "",
    forgotPasswordOtp: "",
    changePasswordOtp: "",

    unreadNotificationCount: 0,
    unreadNotifications: [],

    //registration form handling
    registrationData: {},
    registrationDataError: {},
    //login form handling
    loginData: {},
    loginDataError: {},

    //connection page tabs controls
    selectedTab: "connection",
    connectionSearchText: "",

    //notifications
    notificationArray: [],

    //coming from homepage
    searchTextFromHome: "",

    //scheduled meet list
    scheduledMeetList: [],
    meetToReschedule: {},

    //search results on search results page
    peopleArray: [],
    jobArray: [],

    //loading
    generalLoading: false,
    emailSavedForOtp: "",
};

export const noPersistSlice = createSlice({
    name: "noPersistSlice",
    initialState,
    reducers: {
        togglePostJobSubmit: (state, action) => {
            state.postJobSubmitToggler = action.payload;
        },

        modalController: (state, action) => {
            state[action.payload.modal] = action.payload.state;
        },

        accordionToggler: (state, action) => {
            if (state.openedAccordion === action.payload) {
                state.openedAccordion = "";
            } else {
                state.openedAccordion = action.payload;
            }
        },

        registrationDataSetter: (state, action) => {
            state.registrationData[action.payload.fieldName] = action.payload.value;
        },
        registrationDataErrorSetter: (state, action) => {
            state.registrationDataError[action.payload.fieldName] = action.payload.value;
        },

        loginDataSetter: (state, action) => {
            state.loginData[action.payload.fieldName] = action.payload.value;
        },
        loginDataErrorSetter: (state, action) => {
            state.loginDataError[action.payload.fieldName] = action.payload.value;
        },

        loadingOnEmailInput: (state, action) => {
            state.onInputEmailLoading = action.payload;
        },

        selectConnectionsTab: (state, action) => {
            state.selectedTab = action.payload;
        },

        setConnectionSearchText: (state, action) => {
            state.connectionSearchText = action.payload;
        },

        saveNotifications: (state, action) => {
            state.notificationArray = action.payload;
        },
        saveMoreNotifications: (state, action) => {
            state.notificationArray = [action.payload, ...state.notificationArray];
        },
        saveApiMoreNotifications: (state, action) => {
            state.notificationArray = [...state.notificationArray, ...action.payload];
        },

        saveSearchTextFromHome: (state, action) => {
            state.searchTextFromHome = action.payload;
        },

        saveScheduledMeetList: (state, action) => {
            state.scheduledMeetList = action.payload;
        },

        saveMeetToReschedule: (state, action) => {
            state.meetToReschedule = action.payload;
        },

        savePeopleArray: (state, action) => {
            state.peopleArray = action.payload;
        },
        saveJobArray: (state, action) => {
            state.jobArray = action.payload;
        },

        makeJobApplied: (state, action) => {
            let data = state.jobArray;
            let changingIndex = data.findIndex((curElem) => {
                return curElem.job_id === action.payload;
            });
            data[changingIndex]["job_applied_status"] = {
                userId: "I am interested",
            };
            state.jobArray = data;
        },
        makeJobBookmarked: (state, action) => {
            let data = state.jobArray;
            let changingIndex = data.findIndex((curElem) => {
                return curElem.job_id === action.payload.jobId;
            });
            data[changingIndex]["job_bookmarked_id"] = action.payload.bookmarkNum;
            state.jobArray = data;
        },
        makeJobNotBookmarked: (state, action) => {
            let data = state.jobArray;
            let changingIndex = data.findIndex((curElem) => {
                return curElem.job_id === action.payload;
            });
            delete data[changingIndex]["job_bookmarked_id"];
            state.jobArray = data;
        },

        changeGeneralLoading: (state, action) => {
            state.generalLoading = action.payload;
        },

        saveUnreadDataCount: (state, action) => {
            state.unreadNotificationCount = action.payload;
        },

        increaseNotificationUnreadCount: (state, action) => {
            const check = state.unreadNotifications.some((curElem) => {
                return curElem.notification_time === action.payload.notification_time;
            });
            if (!check) {
                state.unreadNotifications = [...state.unreadNotifications, action.payload];
            }
        },
        clearNotificationArray: (state) => {
            state.unreadNotifications = [];
        },

        saveEmailForOtp: (state, action) => {
            state.emailSavedForOtp = action.payload;
        },

        saveForgotPasswordEmail: (state, action) => {
            state.forgotPasswordEmail = action.payload;
        },
        saveForgotPasswordOtp: (state, action) => {
            state.forgotPasswordOtp = action.payload;
        },
        saveChangePasswordOtp: (state, action) => {
            state.changePasswordOtp = action.payload;
        },
    },
});

export const {
    togglePostJobSubmit,
    modalController,
    accordionToggler,
    registrationDataErrorSetter,
    registrationDataSetter,
    loadingOnEmailInput,
    loginDataSetter,
    loginDataErrorSetter,
    selectConnectionsTab,
    setConnectionSearchText,
    saveNotifications,
    saveSearchTextFromHome,
    saveScheduledMeetList,
    saveMeetToReschedule,
    saveJobArray,
    savePeopleArray,
    makeJobApplied,
    makeJobBookmarked,
    makeJobNotBookmarked,
    changeGeneralLoading,
    saveUnreadDataCount,
    saveMoreNotifications,
    saveEmailForOtp,
    increaseNotificationUnreadCount,
    clearNotificationArray,
    saveApiMoreNotifications,
    saveForgotPasswordEmail,
    saveForgotPasswordOtp,
    saveChangePasswordOtp,
} = noPersistSlice.actions;

export default noPersistSlice.reducer;
