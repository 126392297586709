import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import noPersistSlice from "./Slices/noPersistSlice";
import persistSlice from "./Slices/persistSlice";
import toasterSlice from "./Slices/toasterSlice";
import socketSlice from "./Slices/socketSlice";
import chatSlice from "./Slices/chatSlice";
import adminSlice from "./Slices/adminSlice";
import profileSlice from "./Slices/profileSlice";
import firebaseSlice from "./Slices/firebaseSlice";
import jobSlice from "./Slices/jobSlice";
import notificationSlice from "./Slices/notificationSlice";
import generalSlice from "./Slices/generalSlice";

const reducers = combineReducers({
    persist: persistSlice,
    nopersist: noPersistSlice,
    toaster: toasterSlice,
    socketing: socketSlice,
    chatting: chatSlice,
    admin: adminSlice,
    profile: profileSlice,
    fireslice: firebaseSlice,
    jobslice: jobSlice,
    notifications: notificationSlice,
    general: generalSlice,
});
const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["persist"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export default store;
