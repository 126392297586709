import React from "react";
import PostJobModal from "../../component/Modal/PostJobModal";
import "../../style/PersonalDetails.css";
import styles from "./postJobContainer.module.css";

const PostJobContainer = () => {
  return (
    <React.Fragment>
      <div className="container-fluid pageBody" style={{ marginTop: "5.5rem" }}>
        <div className="row d-flex justify-content-center pt-2 pl-md-5">
          <div className="col-12 col-md-8 ant-card" style={{ boxShadow : "0 4px 10px #eeeeee",border: "1px solid #eeeeee"}}>
            <div className="ant-card-head">
              <div className={styles.heading}>Post Job</div>
            </div>
            <div className="ant-card-bodyMinor">
              <PostJobModal />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostJobContainer;
