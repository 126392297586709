import { HttpService } from '../common/httpService'

class JobService extends HttpService {
   urls = {
      postJobUrl: '/postJob',
      getAllJobsUrl: 'getAllJobs',
      // searchJobByLocationTitleUrl: 'searchJobByLocationTitle',
      searchJobByLocationTitleUrl: 'getAdvJobSearchData',
      getPostedJobByUserUrl: 'getPostedJobByUser',
      getFilterList: 'getFilterList',
      viewJob:'viewJob',
      applyJob:'applyJob',
      getAppliedJobUserList:'getAppliedJobUserList',
      getAppliedJobList:'getAppliedJobList',
      getUserPostedJob:"getUserPostedJob",
      deleteSkillFromJob:'deleteSkillFromJob',
      deleteJobTypeFromJob:'deleteJobTypeFromJob'
   }
   postJobDetails = (inputJson, header) => {
      return this.postQuery(this.urls.postJobUrl, header, inputJson);
   }
   getAllJobsUser = (header) => {
      return this.postQuery(this.urls.getAllJobsUrl, undefined, {});
   }
   searchJob = (inputJson, header) => {
      return this.postQuery(this.urls.searchJobByLocationTitleUrl, header, inputJson);
   }
   getPostedJobByUser = (inputJson, header) => {
      return this.postQuery(this.urls.getPostedJobByUserUrl, header, inputJson)
   }
   filterData = (header) => {
      return this.getQuery(this.urls.getFilterList)
   }
   viewJob=(header,inputJson)=>{
      return this.postQuery(this.urls.viewJob,header,inputJson);
   }
   applyJob=(header,inputJson)=>{
      return this.uploadReume(this.urls.applyJob,header,inputJson);
   }
   getAppliedJobUserList=(header,inputJson)=>{
      return this.postQuery(this.urls.getAppliedJobUserList,header,inputJson);
   }
   getAppliedJobList=(header,inputJson)=>{
      return this.postQuery(this.urls.getAppliedJobList,header,inputJson);
   }
   getUserPostedJob(inputJson,header){
      return this.postQuery(this.urls.getUserPostedJob, header, inputJson)
   }
   deleteSkillFromJob(inputJson,header){
      return this.postQuery(this.urls.deleteSkillFromJob,header,inputJson);
   }
   deleteJobTypeFromJob(inputJson,header){
      return this.postQuery(this.urls.deleteJobTypeFromJob,header,inputJson);
   }
}

export default JobService
