import React, { useState } from "react";
import styles from "./searchResults.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    cancelFriendRequest,
    getChatByUserEmail,
    getConnectionsList,
    getSearchedUsers,
    handleGetProfileAndGoThere,
    readMessages,
    sendFriendRequest,
    sendRequestMail,
    unfriendConnection,
} from "../../Services/commonService";
import { BsChat, BsFillPersonXFill, BsPersonCircle } from "react-icons/bs";
import { AiFillStar, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdOutlineWork } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";
import { CircularProgress, Tooltip, Dialog, DialogContent } from "@mui/material";
import { socket } from "../../Services/socket";
import { selectChat } from "../../Redux/Slices/chatSlice";
import { modalController } from "../../Redux/Slices/noPersistSlice";
import { VscVerifiedFilled } from "react-icons/vsc";

const SearchResults = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { searchedConnections } = useSelector((state) => state.persist);
    const { connectionSearchText } = useSelector((state) => state.nopersist);
    const { personalDetails } = useSelector((state) => state.persist);
    const { chatHistory } = useSelector((state) => state.chatting);

    const [confirmation, setConfirmation] = useState(false);
    const [savedFriendListId, setSavedFriendListId] = useState("");

    const [loading, setLoading] = useState([]);

    const handleFriendRequest = async (event, email, name) => {
        event.stopPropagation();
        let newLoading = loading.filter((curElem) => {
            return curElem !== email;
        });
        const res = await sendFriendRequest(email);
        if (res === "200 OK") {
            getSearchedUsers(0, 10, connectionSearchText);
            socket.emit("sendConnectionNotification", {
                receiverEmail: email,
                addData: {
                    notification_message: `${personalDetails?.fullName}  sent you a friend request`,
                    notification_sender_profile_pic: personalDetails?.profileImageUrl,
                    notification_type: "friend_request_sent",
                    notification_time: new Date().getTime(),
                    sender_email: personalDetails.emailAddress,
                },
            });
            sendRequestMail(personalDetails.fullName, name, email);
            setLoading(newLoading);
        } else {
            setLoading(newLoading);
        }
    };

    const handleCancelingRequest = async (event, email, friendListId) => {
        event.stopPropagation();
        let newLoading = loading.filter((curElem) => {
            return curElem !== email;
        });
        const res = await cancelFriendRequest(friendListId);
        if (res === "200 OK") {
            setLoading(newLoading);
            getSearchedUsers(0, 10, connectionSearchText);
        } else {
            setLoading(newLoading);
        }
    };

    const unfriendUser = async (friendListId) => {
        let newLoading = loading.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await unfriendConnection(friendListId);
        if (res === "200 OK") {
            setLoading(newLoading);
            closeConfirmation();
            getConnectionsList(0, 10);
        } else {
            setLoading(newLoading);
        }
    };

    const startChatWithFriend = (event, elem) => {
        event.stopPropagation();
        let x = chatHistory.filter((curElem) => {
            return curElem?.emailAddress === elem.emailAddress;
        });
        if (x.length > 0) {
            dispatch(selectChat(x[0]));
            getChatByUserEmail(x[0]?.emailAddress, 0, 10);
            dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
            readMessages(elem.emailAddress);
            navigateTo("/message");
        } else {
            dispatch(selectChat(elem));
            getChatByUserEmail(elem?.emailAddress, 0, 10);
            dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }));
            readMessages(elem.emailAddress);
            navigateTo("/message");
        }
    };

    const closeConfirmation = () => {
        setSavedFriendListId("");
        setConfirmation(false);
    };

    const submitUnfriending = () => {
        setLoading((prev) => {
            return [...prev, savedFriendListId];
        });
        unfriendUser(savedFriendListId);
    };

    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-3">Are you sure you want to remove this connection?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={submitUnfriending}>
                            Yes
                        </button>
                        <button className="normal-button" onClick={closeConfirmation}>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.container}>
                {searchedConnections &&
                    searchedConnections.map((curElem) => {
                        return (
                            <div className={styles.eachCard} onClick={() => handleGetProfileAndGoThere(curElem, navigateTo)}>
                                <div className={styles.coverImage}>
                                    <img src={curElem.cover_image_url} alt="" />
                                </div>
                                <div className={styles.profileImage}>{curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                                <div className={styles.cardContent}>
                                    <div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className={styles.profileName}>{curElem.fullName ? curElem.fullName : "-"}</div>
                                            {curElem.is_verified_from_admin_for_live_session && (
                                                <div className="ms-1">
                                                    <VscVerifiedFilled color="#f1bb46" fontSize={18} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className={styles.ratingPill}>
                                                <AiFillStar fontSize={14} color="#f1bb46" className="me-1" />
                                                {curElem.over_all_rating}
                                            </div>
                                        </div>
                                        {curElem.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                                        {curElem.designation && (
                                            <div className={styles.designationName}>
                                                <MdOutlineWork color="#f1bb46" className="me-1" />
                                                {`${curElem.designation ? curElem.designation : "-"}`}
                                            </div>
                                        )}
                                        {curElem.companyName && (
                                            <div className={styles.companyName}>
                                                <GoOrganization color="#f1bb46" className="me-1" />
                                                {`${curElem.companyName ? curElem.companyName : "-"}`}
                                            </div>
                                        )}
                                        {curElem.location && (
                                            <div className={`${styles.sectionContainer} mt-3`}>
                                                <div className={styles.sectionName}>Location:</div>
                                                <div className={styles.sectionValue}>{curElem.location ? curElem.location : "-"}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="action-button-container">
                                        {curElem.user_connection_status && Object.keys(curElem.user_connection_status).length > 0 && curElem.user_connection_status.is_friend ? (
                                            <div>
                                                <Tooltip title="Remove Connection">
                                                    <button
                                                        className="action-button-disabled me-2"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setSavedFriendListId(curElem.friendListId);
                                                            setConfirmation(true);
                                                        }}
                                                    >
                                                        <BsFillPersonXFill />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Chat with connection">
                                                    <button className="action-button-disabled" onClick={(event) => startChatWithFriend(event, curElem)}>
                                                        <BsChat />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            <button
                                                className={
                                                    curElem.user_connection_status && Object.keys(curElem.user_connection_status).length > 0 ? "action-button-disabled" : "action-button-connect"
                                                }
                                                disabled={loading.includes(curElem.emailAddress)}
                                                onClick={(event) => {
                                                    setLoading((prev) => {
                                                        return [...prev, curElem.emailAddress];
                                                    });
                                                    if (curElem.user_connection_status && Object.keys(curElem.user_connection_status).length > 0) {
                                                        handleCancelingRequest(event, curElem.emailAddress, curElem.user_connection_status.friendListId);
                                                    } else {
                                                        handleFriendRequest(event, curElem.emailAddress, curElem.fullName);
                                                    }
                                                }}
                                            >
                                                {loading.includes(curElem.emailAddress) ? (
                                                    <CircularProgress size={12} style={{ color: "#ffffff" }} />
                                                ) : curElem.user_connection_status && Object.keys(curElem.user_connection_status).length > 0 ? (
                                                    <FaCheck />
                                                ) : (
                                                    <AiOutlinePlus />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </React.Fragment>
    );
};

export default SearchResults;
