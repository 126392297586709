import React from "react";
import styles from "./headerDrawer.module.css";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../Redux/Slices/noPersistSlice";
import { useContext } from "react";
import AuthContext from "../../context/authContext";
import { headerLinksArray } from "../../Services/constants";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderMenuItem from "../HeaderMenuItem/HeaderMenuItem";
import LoginRegister from "../Modal/LoginRegister";

const HeaderDrawer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigateTo = useNavigate();
    let context = useContext(AuthContext);
    const { headerDrawer } = useSelector((state) => state.nopersist);
    const { personalDetails } = useSelector((state) => state.persist);

    const isAuthenticated = context.authenticatedStatus;
    const onLogOut = () => {
        localStorage.clear();
        context.setAuthenticationStatus(false);
        dispatch(modalController({ modal: "headerDrawer", state: false }));
        navigateTo("/");
    };

    return (
        <Offcanvas style={{ width: "60%", backgroundColor: "#ffffff" }} show={headerDrawer} onHide={() => dispatch(modalController({ modal: "headerDrawer", state: false }))} placement="end">
            <Offcanvas.Body className={styles.drawerContent}>
                <div>
                    {headerLinksArray.map((curElem) => {
                        if (curElem.label === "Admin Panel") {
                            if (personalDetails && personalDetails.role === "ROLE_ADMIN") {
                                return (
                                    <HeaderMenuItem
                                        key={curElem.label}
                                        data={curElem}
                                        location={location}
                                        closeDrawer={() => {
                                            if (!curElem.children) {
                                                dispatch(modalController({ modal: "headerDrawer", state: false }));
                                            }
                                        }}
                                    />
                                );
                            } else {
                                return null;
                            }
                        } else {
                            return (
                                <HeaderMenuItem
                                    key={curElem.label}
                                    data={curElem}
                                    location={location}
                                    closeDrawer={() => {
                                        if (!curElem.children) {
                                            dispatch(modalController({ modal: "headerDrawer", state: false }));
                                        }
                                    }}
                                />
                            );
                        }
                    })}
                </div>
                {isAuthenticated ? (
                    <div className={styles.logoutButton} onClick={onLogOut}>
                        Logout
                    </div>
                ) : (
                    <LoginRegister id="loginRegister" closeModal={() => {}} currentModal="L" />
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default HeaderDrawer;
