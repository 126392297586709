import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { DataUtility } from "../../utility/DataUtility";
import { ValidationUtility } from "../../utility/ValidationUtility";
import { ValidationType } from "../../enum/ValidationType";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import ImageContent from "../../content/ImageContent";
import { useNavigate } from "react-router-dom";

let imageContent = new ImageContent();

function ProfessionalDetails(props) {
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  const dummyData = {
    id: 0,
    professional_id: null,
    apprenticeType: "",
    companyName: "",
    role: "",
    fromDate: "",
    toDate: "",
    currentlyWorking: true,
    location: "",
    projects: [
      {
        project_id: null,
        pid: 0,
        name: "",
        pRole: "",
        pFromDate: "",
        pToDate: "",
        pDescription: "",
        pTechUsed: "",
        pProdUrl: "",
      },
    ],
  };
  let profileService = serviceProvider.returnProfileService();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let navigateTo = useNavigate();
  const initValue = [];
  initValue.push(dummyData);
  let [expInfo, setExpInfo] = useState(initValue);
  let [btnLoader, setBtnLoader] = useState(false);
  let [isExp, setIsExp] = useState(false);
  const addMoreExp = () => {
    let index = expInfo.length;
    let expObj = { ...dummyData };
    expObj.id = index;
    setExpInfo([...expInfo, expObj]);
  };
  const addMoreProjects = (index) => {
    let expList = [...expInfo];
    let indexOfProject = expList[index].projects.length;
    let projectObj = {
      pid: indexOfProject,
      name: "",
      pRole: "",
      pFromDate: "",
      pToDate: "",
      pDescription: "",
      pTechUsed: "",
      pProdUrl: "",
    };
    expList[index].projects.push(projectObj);
    setExpInfo(expList);
  };
  const onCompanyNameInput = (e, index) => {
    let expDummyList = [...expInfo];
    if (e.target.name !== "currentlyWorking") {
      expDummyList[index] = {
        ...expInfo[index],
        [e.target.name]: e.target.value,
      };
    } else {
      if (expDummyList[index]["currentlyWorking"] === false) {
        expDummyList[index] = {
          ...expInfo[index],
          [e.target.name]: true,
        };
      } else {
        expDummyList[index] = {
          ...expInfo[index],
          [e.target.name]: false,
        };
      }
    }
    expDummyList.forEach((exp, index) => {
      if (isExp !== "Experienced") {
        ValidationUtility.validateElement("aprType" + index, ValidationType.Select, true, "aprTypeError" + index, "Apprentice Type");
      }
      ValidationUtility.validateElement("company" + index, ValidationType.Text, true, "companyError" + index, "Company Name");
      ValidationUtility.validateElement("role" + index, ValidationType.Text, true, "roleError" + index, "Role");
      ValidationUtility.validateElement("fromDate" + index, ValidationType.Date, true, "fromDateError" + index, "From Date");
      if (!exp.currentlyWorking) {
        ValidationUtility.validateElement("toDate" + index, ValidationType.Date, true, "toDateError" + index, "To Date");
      }
      ValidationUtility.validateElement("loc" + index, ValidationType.Text, true, "locError" + index, "Location");
    });
    setExpInfo(expDummyList);
  };
  const onProjectDetailInput = (e, index, projectIndex) => {
    let expDummyList = [...expInfo];
    expDummyList[index]["projects"][projectIndex] = {
      ...expInfo[index]["projects"][projectIndex],
      [e.target.name]: e.target.value,
    };
    setExpInfo(expDummyList);
  };
  const setUserExperienceType = (value) => {
    setIsExp(value);
    ValidationUtility.validateElement("expType", ValidationType.Select, true, "expTypeError", "Experience Type");
  };
  const onSubmit = () => {
    setBtnLoader(true);
    validateOnSubmit(expInfo, isExp).then(() => {
      let expInfoList = DataUtility.generateProfessionalJsonFromList(expInfo, isExp);
      profileService.postProfessionalDetails(expInfoList, header).then((res) => {
        if (res.status === "200 OK") {
          if (props.updateEdit !== undefined) {
            props.updateEdit(false);
          } else {
            navigateTo("/profile");
          }
          setBtnLoader(false);
        }
      });
    });
  };
  const onDelete = (delIndex, profId) => {
    let newList = [...expInfo];
    let filterList = [];
    let id = profId;
    newList.forEach((item, index) => {
      if (index !== delIndex) {
        filterList.push(item);
      }
    });
    setExpInfo([]);
    setExpInfo(filterList);
    if (id !== null) {
      serviceProvider.returnProfileService().deletingProfessionalDataCorrespondingToId(header, { professional_id: id });
    }
  };
  const onDeleteProject = (delIndex, delPIndex) => {
    let newList = [...expInfo];
    let filterList = [];
    let projectFilterList = [];
    newList.forEach((item, index) => {
      if (index === delIndex) {
        item.projects.forEach((pItem, pIndex) => {
          if (pIndex !== delPIndex) {
            projectFilterList.push(pItem);
          }
        });
        item.projects = projectFilterList;
      }
      filterList.push(item);
    });
    setExpInfo([]);
    setExpInfo(filterList);
  };

  useEffect(() => {
    const getProfessionalInfo = () => {
      serviceProvider
        .returnProfileService()
        .getProfessionalDetais(header)
        .then((res) => {
          if (res.status === "200 OK") {
            if (res?.data.length > 0) {
              let outPutJson = DataUtility.generateProfessionalListForEdit(res?.data);
              setExpInfo(outPutJson.professionalList);
              setIsExp(outPutJson.isExp);
              console.log(outPutJson.isExp);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {});
    };
    getProfessionalInfo();
  }, [header, serviceProvider]);
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row createProfileSideBar completeProfileHeader pt-2 pb-0">
          <div className="col-12">
            <h4>
              <b>Professional Details</b>
            </h4>
          </div>
        </div>
        <div className="row pt-3 mt-2 createProfileSideBar">
          <div className="col-12">
            <Form.Label>
              <b>Work Experience</b>
            </Form.Label>
            <select className="inputFieldStyle" value={isExp} style={{ width: "100%" }} id="expType" onChange={(e) => setUserExperienceType(e.target.value)}>
              <option value="-1">Select You Work Experience</option>
              <option value="Experienced">Experienced</option>
              <option value="Fresher">Fresher</option>
            </select>
            <br />
            <span className="text-danger" id="expTypeError"></span>
          </div>
        </div>
        <div className="row pt-2 pb-0">
          <div className="col-12 mt-2">
            {expInfo &&
              expInfo.map((exp, index) => {
                if (index === 0) {
                  return (
                    <div className="row createProfileSideBar pb-0" id={"newExp" + index}>
                      <TrainingInternship
                        onDeleteProj={onDeleteProject}
                        onDelete={onDelete}
                        onProjectDetailInput={onProjectDetailInput}
                        isExp={isExp}
                        onCompanyNameInput={onCompanyNameInput}
                        expInfo={exp}
                        projects={exp.projects}
                        index={index}
                        addProject={addMoreProjects}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="row mt-3 createProfileSideBar pb-0" id={"newExp" + index}>
                      <TrainingInternship
                        onDeleteProj={onDeleteProject}
                        onDelete={onDelete}
                        onProjectDetailInput={onProjectDetailInput}
                        isExp={isExp}
                        onCompanyNameInput={onCompanyNameInput}
                        expInfo={exp}
                        projects={exp.projects}
                        index={index}
                        addProject={addMoreProjects}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="row pt-2">
          <Button className="educationalDetailPlusBtn" onClick={addMoreExp}>
            <i className="fa fa-plus"></i> ADD FIELD
          </Button>
        </div>
        <div className="row">
          <div className="col-9"></div>
          <div className="col-sm-12 col-md-3">
            {!btnLoader ? (
              <Button className="addProjectBtn" style={{ width: "100%" }} onClick={() => onSubmit()}>
                Submit
              </Button>
            ) : (
              <Button className="text-white connectButton">
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export const TrainingInternship = (props) => {
  let todayDate = DataUtility.getTodayDate(new Date());
  useEffect(() => {}, [props.projects]);
  return (
    <React.Fragment>
      <div className="row col-12 mt-2">
        <div className="col-12 pt-2">
          <Button
            onClick={() => {
              props.onDelete(props.index, props.expInfo?.professional_id);
            }}
            className="educationDelBtn d-flex justify-content-center align-item-center"
          >
            <img src={imageContent.getDelImage()} alt="" height="20" />
            &nbsp;&nbsp;Delete
          </Button>
        </div>
        <div className="col-12 pt-3">
          <Form.Label>Organization</Form.Label>
          <Form.Control
            className="inputFieldStyle"
            value={props.expInfo?.companyName}
            type="text"
            id={"company" + props.index}
            name="companyName"
            placeholder="Company Name"
            onChange={(e) => props.onCompanyNameInput(e, props.index)}
          />
          <span id={"companyError" + props.index} className="text-danger "></span>
        </div>
        <div className="col-12 pt-3">
          <Form.Label>Company Location</Form.Label>
          <Form.Control
            value={props.expInfo?.location}
            className="inputFieldStyle"
            type="text"
            name="location"
            id={"loc" + props.index}
            placeholder="Company Location"
            onChange={(e) => props.onCompanyNameInput(e, props.index)}
          />
          <span className="text-danger " id={"locError" + props.index}></span>
        </div>
        {props.isExp !== "Experienced" ? (
          <React.Fragment>
            <div className="col-6 pt-3">
              <Form.Label>Apprentice Type</Form.Label>
              <select
                value={props.expInfo?.apprenticeType}
                className="inputFieldStyle"
                style={{ width: "100%" }}
                id={"aprType" + props.index}
                name="apprenticeType"
                onChange={(e) => props.onCompanyNameInput(e, props.index)}
              >
                <option value="-1" selected disabled>
                  Apprentice Type
                </option>
                <option value="Training">Training</option>
                <option value="Internship">Internship</option>
              </select>
              <span id={"aprTypeError" + props.index} className="text-danger "></span>
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-6 pt-3">
          <Form.Label>Role</Form.Label>
          <Form.Control
            value={props.expInfo?.role}
            className="inputFieldStyle"
            type="text"
            id={"role" + props.index}
            name="role"
            placeholder="Role"
            onChange={(e) => props.onCompanyNameInput(e, props.index)}
          />
          <span id={"roleError" + props.index} className="text-danger "></span>
        </div>
        <div className="col-6 pt-3">
          <Form.Label>From Date</Form.Label>
          <Form.Control
            value={props.expInfo?.fromDate}
            className="inputFieldStyle"
            type="date"
            id={"fromDate" + props.index}
            name="fromDate"
            onChange={(e) => props.onCompanyNameInput(e, props.index)}
          />
          <span id={"fromDateError" + props.index} className="text-danger "></span>
        </div>
        <React.Fragment>
          <div className="col-6 pt-3">
            <div className="row">
              <div className="col-12">
                <Form.Label>
                  {props.expInfo?.currentlyWorking ? (
                    <input type="checkbox" checked name="currentlyWorking" onChange={(e) => props.onCompanyNameInput(e, props.index)} />
                  ) : (
                    <input type="checkbox" name="currentlyWorking" onChange={(e) => props.onCompanyNameInput(e, props.index)} />
                  )}
                  &nbsp;&nbsp;Currently Working
                </Form.Label>
                <Form.Control
                  value={props.expInfo?.toDate}
                  className="inputFieldStyle"
                  disabled={props.expInfo.currentlyWorking}
                  max={todayDate}
                  type="date"
                  id={"toDate" + props.index}
                  name="toDate"
                  onChange={(e) => props.onCompanyNameInput(e, props.index)}
                />
                {props.expInfo?.currentlyWorking ? <span id={"toDateError" + props.index} className="text-danger "></span> : null}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
      <div className="col-12 pt-2">
        {/* <div className="row pt-3">
                    <div className="col-12">
                        {props.expInfo.companyName === '' ? <Form.Label><b>Project Related To Your Company Name</b></Form.Label> : <Form.Label><b>Project Related To {props.expInfo.companyName}</b></Form.Label>}
                    </div>
                </div> */}
        <div className="row px-3">
          {props.projects.map((project, index) => {
            return (
              <div className="col-12 pb-3" style={{ backgroundColor: "#FCFCFC" }} id={"project" + index}>
                <Projects onDeleteProj={props.onDeleteProj} projectInfo={project} projectIndex={index} expIndex={props.index} onProjectDetailInput={props.onProjectDetailInput} />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              className="addProjectBtn"
              onClick={() => {
                props.addProject(props.index);
              }}
            >
              Add Projects
            </Button>
          </div>
          {/* <div className="col-3"><b onClick={() => { props.addProject(props.index) }}>Add More Projects</b></div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export const Projects = (props) => {
  let todayDate = DataUtility.getTodayDate(new Date());
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 d-flex justify-content-end align-item-end pt-3">
          <img
            onClick={() => {
              props.onDeleteProj(props.expIndex, props.projectIndex);
            }}
            src={imageContent.getDelBlackImage()}
            alt=""
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            value={props.projectInfo?.name}
            className="inputFieldStyle"
            name="name"
            type="text"
            placeholder="Project Name"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>Your Role</Form.Label>
          <Form.Control
            className="inputFieldStyle"
            value={props.projectInfo?.pRole}
            name="pRole"
            type="text"
            placeholder="Your Role"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>From Date</Form.Label>
          <Form.Control
            value={props.projectInfo?.pFromDate}
            className="inputFieldStyle"
            name="pFromDate"
            type="date"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>To Date</Form.Label>
          <Form.Control
            value={props.projectInfo?.pToDate}
            className="inputFieldStyle"
            name="pToDate"
            max={todayDate}
            type="date"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>Technology Used</Form.Label>
          <Form.Control
            value={props.projectInfo?.pTechUsed}
            className="inputFieldStyle"
            name="pTechUsed"
            type="text"
            placeholder="Technology Used"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-6 pt-2">
          <Form.Label>Prod Url</Form.Label>
          <Form.Control
            value={props.projectInfo?.pProdUrl}
            className="inputFieldStyle"
            name="pProdUrl"
            type="text"
            placeholder="Production Url"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
        <div className="col-12 pt-2">
          <Form.Label>Project Description</Form.Label>
          <Form.Control
            value={props.projectInfo?.pDescription}
            className="inputFieldStyle"
            name="pDescription"
            as="textarea"
            placeholder="Project Description"
            onChange={(e) => props.onProjectDetailInput(e, props.expIndex, props.projectIndex)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const validateOnSubmit = (expList, isExp) => {
  let responseArray = [];
  responseArray.push(ValidationUtility.validateElement("expType", ValidationType.Select, true, "expTypeError", "Experience Type"));
  expList.forEach((exp, index) => {
    if (!isExp) {
      responseArray.push(ValidationUtility.validateElement("aprType" + index, ValidationType.Select, true, "aprTypeError" + index, "Apprentice Type"));
    }
    responseArray.push(ValidationUtility.validateElement("company" + index, ValidationType.Text, true, "companyError" + index, "Company Name"));
    responseArray.push(ValidationUtility.validateElement("role" + index, ValidationType.Text, true, "roleError" + index, "Role"));
    responseArray.push(ValidationUtility.validateElement("fromDate" + index, ValidationType.Date, true, "fromDateError" + index, "From Date"));
    if (!exp.currentlyWorking) {
      responseArray.push(ValidationUtility.validateElement("toDate" + index, ValidationType.Date, true, "toDateError" + index, "To Date"));
    }
    responseArray.push(ValidationUtility.validateElement("loc" + index, ValidationType.Text, true, "locError" + index, "Location"));
  });
  let errors = responseArray.filter((x) => {
    return !x.isValid;
  });
  return new Promise((resolve, reject) => {
    errors?.length > 0 ? reject(false) : resolve(true);
  });
};
export default ProfessionalDetails;
