import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageContent from "../../content/ImageContent";
import ServiceProvider from "../../provider/serviceProvider";
import "../../style/PersonalDetails.css";
import { DataUtility } from "../../utility/DataUtility";
import HeaderUtility from "../../utility/HeaderUtility";
// import ConnectionModal from '../Modal/ConnectionModal';
import EducationalDetails from "../Educational/EducationalDetails";
import ProfessionalDetails from "../Professional/ProfessionalDetails";
import statesJson from "../../json/states.json";
import Loader from "../Loader/Loader";
import Select from "react-select";
import { skillsArray } from "../../json/commonData";
import { CityDropDown, StatesDropDown } from "../PersonalDetails/PersonalDetails";
import styles from "./profile.module.css";
import { BsBriefcaseFill, BsPersonCircle, BsPlus } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import { IoLocationSharp } from "react-icons/io5";

const optionsSkills = skillsArray.map((item, index) => ({ value: index, label: item }));

let imageContent = new ImageContent();

let states = statesJson.states;

const optionsLanguage = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "French", label: "French" },
];
const Profile = () => {
  const activityList = [
    {
      text: "Created",
      active: true,
    },
    {
      text: "Applied",
      active: false,
    },
  ];
  const navigateTo = useNavigate();
  const [edit, setEdit] = useState(false);
  const [editEdu, setEditEdu] = useState(false);
  const [pedit, setPEdit] = useState(false);
  const [isCre, setIsCre] = useState(true);
  let [activityArr, setActivityArr] = useState(activityList);
  let [userInfo, setUserInfo] = useState({});
  let [profInfo, setProfInfo] = useState([]);
  let [jobInfo, setJobInfo] = useState([]);
  let [jobInfoApp, setJobInfoApp] = useState([]);
  let [numberOfQualification, setNumberOfQualification] = useState([]);
  let [skills, setSkills] = useState([]);
  let [skillsref, setSkillsRef] = useState([]);
  let [cityList, setCityList] = useState([]);
  let [capturedImg, setCapturedImg] = useState("");
  let [pos, setPos] = useState(0);
  let [totalPage, setTotalPage] = useState(0);
  let [totalPageApp, setTotalPageApp] = useState(0);
  let [posApp, setPosApp] = useState(0);
  let [loading, setLoading] = useState(false);
  let [loader] = useState(false);
  let [capturedString, setCapturedString] = useState("");
  const [language, setLanguage] = useState([]);
  const [achieveData, setAchieveData] = useState("");
  const [achievementDetails, setAchievementDetails] = useState([]);
  let [btnLoader, setBtnLoader] = useState(false);
  const observer = useRef();

  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  let header = headerUtility.returnHeaderContainingToken();

  const handleStateInput = useCallback(
    (value) => {
      states.forEach((stateInfo) => {
        if (stateInfo.state === value) {
          setCityList(stateInfo.districts);
        }
      });
    },
    [setCityList]
  );

  const getActivityInfo = useCallback(() => {
    setPos(0);
    serviceProvider
      .returnJobService()
      .getPostedJobByUser({ page_no: 0, no_of_records: 10 }, header)
      .then((res) => {
        if (res.status === "200 OK") {
          setJobInfo(res?.data);
          setTotalPage(res?.page_details?.total_pages);
        }
      });
  }, [setPos, serviceProvider, header, setJobInfo, setTotalPage]);

  const getActivityInfoAppliedScroll = useCallback(
    (page) => {
      setLoading(true);
      serviceProvider
        .returnJobService()
        .getAppliedJobList(header, { page_no: page, no_of_records: 10 })
        .then((res) => {
          if (res.status === "200 OK") {
            let videoList = res.data;
            let nVideo = [...jobInfo];
            nVideo = nVideo.concat(videoList);
            setJobInfoApp(nVideo);
            setLoading(false);
          }
        });
    },
    [setLoading, serviceProvider, header, jobInfo, setJobInfoApp]
  );

  const getActivityInfoOnScroll = useCallback(
    (page) => {
      setLoading(true);
      let header = headerUtility.returnHeaderContainingToken();
      serviceProvider
        .returnJobService()
        .getPostedJobByUser(
          {
            page_no: page,
            no_of_records: 10,
          },
          header
        )
        .then((res) => {
          if (res.status === "200 OK") {
            let videoList = res.data;
            let nVideo = [...jobInfo];
            nVideo = nVideo.concat(videoList);
            setJobInfo(nVideo);
            setLoading(false);
          }
        });
    },
    [setLoading, headerUtility, serviceProvider, jobInfo, setJobInfo]
  );

  const scroll = useCallback(() => {
    let np = pos + 1;
    setPos(np);
    if (np < totalPage) {
      getActivityInfoOnScroll(np);
    }
  }, [pos, totalPage, getActivityInfoOnScroll]);

  const lastJobRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          scroll();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, scroll]
  );
  const lastJobRefApp = useCallback(
    (node) => {
      const scrollApp = () => {
        let np = posApp + 1;
        setPosApp(np);
        if (np < totalPageApp) {
          getActivityInfoAppliedScroll(np);
        }
      };

      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          scrollApp();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, totalPageApp, getActivityInfoAppliedScroll, posApp]
  );

  const getActivityInfoApplied = useCallback(() => {
    setPosApp(0);
    serviceProvider
      .returnJobService()
      .getAppliedJobList(header, { page_no: 0, no_of_records: 10 })
      .then((res) => {
        if (res.status === "200 OK") {
          setJobInfoApp(res?.data);
          setTotalPageApp(res?.page_details?.total_pages);
        }
      });
  }, [setPosApp, serviceProvider, header, setJobInfoApp, setTotalPageApp]);

  const getUserInfo = useCallback(() => {
    serviceProvider
      .returnProfileService()
      .getPersonalDetail(header)
      .then((res) => {
        if (res.status === "200 OK") {
          let skillArray = res.data?.skillset.map((skill, index) => ({ value: index, label: skill.skill }));
          console.log(skillArray);
          setSkillsRef(res.data.skillset);
          setUserInfo(res.data);
          if (res?.data?.profileImageUrl !== "") {
            setCapturedString(res?.data?.profileImageUrl);
            let profileUrl = res?.data?.profileImageUrl;
            setCapturedImg(profileUrl);
          }
          setSkills(skillArray);
          let lang = res?.data?.language.map((lang) => ({ value: lang, label: lang }));
          setLanguage(lang);
          setAchievementDetails(res?.data?.achievement_details);
          handleStateInput(res?.data?.state);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, [serviceProvider, header, setSkillsRef, setUserInfo, setCapturedString, setCapturedImg, setSkills, setLanguage, setAchievementDetails, handleStateInput]);

  const getProfessionalInfo = useCallback(() => {
    serviceProvider
      .returnProfileService()
      .getProfessionalDetais(header)
      .then((res) => {
        if (res.status === "200 OK") {
          setProfInfo(DataUtility.getProfessionalData(res?.data));
        }
      })
      .catch((err) => {})
      .finally(() => {});
  }, [serviceProvider, header, setProfInfo]);

  const getUserInfoEducation = useCallback(() => {
    serviceProvider
      .returnProfileService()
      .getEducationDetail(header)
      .then((res) => {
        if (res.status === "200 OK") {
          setNumberOfQualification(res.data);
        }
      });
  }, [serviceProvider, header, setNumberOfQualification]);

  const returnWorkComp = () => {
    return profInfo.map((work, index) => {
      if (index !== profInfo.length - 1) {
        return <WorkExpComponent work={work} workId={index + "work"} bodyClassName="ant-card-bodySmall" />;
      } else {
        return <WorkExpComponent work={work} workId={index + "work"} bodyClassName="ant-card-bodySmallWithoutBorder" />;
      }
    });
  };
  const returnEduComp = () => {
    return numberOfQualification.map((edu, index) => {
      if (index !== numberOfQualification.length - 1) {
        return <EduComponent edu={edu} eduId={index + "education"} bodyClassName="ant-card-bodySmall" />;
      } else {
        return <EduComponent edu={edu} eduId={index + "education"} bodyClassName="ant-card-bodySmallWithoutBorder" />;
      }
    });
  };
  const updateEditValue = (value) => {
    setEdit(value);
    getProfessionalInfo();
  };
  const updateEditValueEdu = (value) => {
    setEditEdu(value);
    getUserInfoEducation();
  };
  const returnSkillList = () => {
    if (skills.length > 0) {
      return skills.map((skill, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
              <span>{skill.label}</span>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return "Nothing to show";
    }
  };
  const returnLanguageList = () => {
    if (language.length > 0) {
      return language.map((skill, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
              <span>{skill.label}</span>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return "Nothing to show";
    }
  };
  const returnAchivementsList = () => {
    if (achievementDetails.length > 0) {
      return achievementDetails.map((skill, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
              <span>{skill.achievement}</span>
            </div>
          </React.Fragment>
        );
      });
    } else {
      return "Nothing to show";
    }
  };
  const handleAchieve = (e) => {
    e.preventDefault();
    setAchievementDetails([...achievementDetails, { achievement_id: achievementDetails.length, achievement: achieveData }]);
    setAchieveData("");
  };
  const handleDeleteAchievement = (id) => {
    setAchievementDetails(achievementDetails.filter((d) => d.achievement_id !== id));
  };
  const getObjectForDeleteSkill = () => {
    let inputJson = {};
    let skillsRefList = [];
    let isSkillAva = false;
    let newSkills = [...skillsref];
    let skillInfoList = skills.map((data, index) => ({ skill_id: data.value, skill: data.label, skill_reference_id: null }));
    for (let j = 0; j < newSkills.length; ++j) {
      for (let i = 0; i < skillInfoList.length; ++i) {
        if (newSkills[j].skill === skillInfoList[i].skill) {
          isSkillAva = false;
        } else {
          isSkillAva = true;
        }
      }
      if (isSkillAva) {
        skillsRefList.push(newSkills[j]);
        isSkillAva = false;
      }
    }
    inputJson.skillset = skillsRefList;
    return inputJson;
  };
  const getObjectForUpdate = () => {
    let obj = {};
    for (const property in userInfo) {
      if (property === "profileImageUrl") {
        obj["profileImageUrl"] = capturedString;
      } else if (property === "skillset") {
        let newSkills = [...skillsref];
        let skillInfoList = skills.map((data, index) => ({ skill_id: data.value, skill: data.label, skill_reference_id: null }));
        for (let i = 0; i < skillInfoList.length; ++i) {
          for (let j = 0; j < newSkills.length; ++j) {
            if (newSkills[j].skill === skillInfoList[i].skill) {
              if (skillInfoList[i].skill_reference_id === null) {
                skillInfoList[i].skill_reference_id = newSkills[j].skill_reference_id;
              }
            }
          }
        }
        obj["skillset"] = skillInfoList;
      } else if (property === "language") {
        obj["language"] = language.map((data) => data.value);
      } else if (property === "achievement_details") {
        obj["achievement_details"] = achievementDetails;
      } else {
        obj[property] = userInfo[property];
      }
    }
    return obj;
  };

  // const addMoreElasticSearchText = (text) => {
  //   let obj = [...skills];
  //   obj.push(text);
  //   let newArray = [...obj];
  //   let newObj = { ...userInfo };
  //   newObj.skillset = newArray.toString();
  //   setUserInfo(newObj);
  //   setSkills([]);
  //   setSkills(obj);
  // };

  // const handleKeyPress = (event) => {
  //   if (event.charCode === 13) {
  //     event.preventDefault();
  //     if (productName !== "") {
  //       addMoreElasticSearchText(productName);
  //     }
  //     productName = "";
  //     setProductName("");
  //   }
  // };

  const updateUserPersonalDetails = () => {
    setBtnLoader(true);
    serviceProvider
      .returnProfileService()
      .deleteSkillFromUser(headerUtility.returnHeaderContainingToken(), getObjectForDeleteSkill())
      .then((response) => {
        if (response.status === "200 OK") {
          serviceProvider
            .returnProfileService()
            .postPersonalDetails(getObjectForUpdate(), header)
            .then((res) => {
              if (res.status === "200 OK") {
                setBtnLoader(false);
                setPEdit(false);
                getUserInfo();
                getProfessionalInfo();
                getUserInfoEducation();
                getActivityInfo();
              }
            });
        }
      });
  };
  const updateUserInfo = (value, key) => {
    let obj = { ...userInfo };
    obj[key] = value;
    setUserInfo(obj);
  };
  const openFileSelect = () => {
    const elem = document.getElementById("fileIdProfile");
    if (elem !== null) {
      elem.click();
    }
  };
  const uploadImage = (list) => {
    let checkSize = list ? (list.length > 0 ? list[0].size / 1000000 > 2 : true) : true;
    if (checkSize) {
      return false;
    }
    if (list.length > 0) {
      setCapturedString(list[0].name);
      uploadUserImage("fileIdProfile");
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCapturedImg(reader.result);
        if (typeof reader.result == "string") {
          // localStorage.setItem('profileImg', reader.result);
        }
      }
    };
    if (list != null) {
      reader.readAsDataURL(list[0]);
    }
  };
  const uploadUserImage = (fileId) => {
    let headerForFile = headerUtility.getHeaderForFileUpload();
    serviceProvider
      .returnProfileService()
      .uploadUserProfileImage(headerForFile, fileId)
      .then((res) => {
        if (res.status === "200 OK") {
          setCapturedImg(res.file_name);
          setCapturedString(res.file_name);
        }
      });
  };

  // const removeELasticSearchItem = (index) => (e) => {
  //   let tempListOfElasticSearchItem = [...skills];
  //   let subTempListOfElasticSearchItem = tempListOfElasticSearchItem.filter((item, subIndex) => subIndex !== index);
  //   setSkills([]);
  //   setSkills(subTempListOfElasticSearchItem);
  // };

  // const returnElasticSearchList = () => {
  //   return skills.map((item, index) => {
  //     return (
  //       <div className="ml-2 mt-1 mb-0 elasticSearchItem" id={item + "elasticSearch" + index + "id"}>
  //         <span className="pl-2">{item}&nbsp;</span>
  //         <span className="pr-2 elasticSearchCross" onClick={removeELasticSearchItem(index)}>
  //           <i className="fa fa-times" aria-hidden="true"></i>
  //         </span>
  //       </div>
  //     );
  //   });
  // };

  const onTabSelction = (index) => {
    let dummyArray = [...activityArr];
    dummyArray.forEach((tab, ind) => {
      if (ind === index) {
        tab.active = true;
        if (tab.text === "Applied") {
          getActivityInfo();
          setIsCre(false);
        }
        if (tab.text === "Created") {
          getActivityInfoApplied();
          setIsCre(true);
        }
      } else {
        tab.active = false;
      }
    });
    setActivityArr(dummyArray);
  };
  const returnTabs = () => {
    return activityArr.map((tab, index) => {
      return (
        <span id={index + "tabs" + tab.id}>
          <span
            onClick={() => {
              onTabSelction(index);
            }}
            className={tab.active ? "activeTab" : "inActiveTab"}
          >
            {tab.text}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      );
    });
  };
  const returnActivity = () => {
    if (isCre) {
      return (
        jobInfo &&
        jobInfo.map((job, index) => {
          if (jobInfo.length === index + 1) {
            return (
              <div className="col-12 pt-3" ref={lastJobRef}>
                <JobCard isEdit={true} name={userInfo?.firstName} jobInfo={job} index={index} />
              </div>
            );
          } else {
            return (
              <div className="col-12 pt-3">
                <JobCard isEdit={true} name={userInfo?.firstName} jobInfo={job} index={index} />
              </div>
            );
          }
        })
      );
    } else {
      return (
        jobInfoApp &&
        jobInfoApp.map((job, index) => {
          if (jobInfo.length === index + 1) {
            return (
              <div className="col-12 pt-3" ref={lastJobRefApp}>
                <AppliedJobCard name={userInfo?.firstName} jobInfo={job} index={index} />
              </div>
            );
          } else {
            return (
              <div className="col-12 pt-3">
                <AppliedJobCard name={userInfo?.firstName} jobInfo={job} index={index} />
              </div>
            );
          }
        })
      );
    }
  };
  const onStateValueChange = (value) => {
    updateUserInfo(value, "state");
    handleStateInput(value);
  };
  const onCityValueChange = (value) => {
    updateUserInfo(value, "city");
  };
  useEffect(() => {
    getUserInfo();
    getProfessionalInfo();
    getUserInfoEducation();
    getActivityInfo();
    getActivityInfoApplied();
  }, [getActivityInfo, getActivityInfoApplied, getProfessionalInfo, getUserInfo, getUserInfoEducation]);
  return (
    <div className="container-fluid" id="profilePage">
      <Loader isVisible={loader} />
      {/* <ConnectionModal isShow={show} handleClose={() => { setShow(false) }} /> */}
      <div className="row pt-4"></div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 h-100">
          <div className="row">
            <div className="col-12">
              <div className="ant-card">
                <div className="ant-card-bodyMinor">
                  <div className="w-100 d-flex justify-content-end">
                    {!pedit ? (
                      <div className={styles.editAndCloseIcon} onClick={() => setPEdit(true)}>
                        <FiEdit />
                      </div>
                    ) : (
                      <div className={styles.editAndCloseIcon} onClick={() => setPEdit(false)}>
                        <AiOutlineClose />
                      </div>
                    )}
                  </div>
                  <div className="row pl-0 pr-0 ml-0 mr-0">
                    <div className="col-12 d-flex justify-content-center pt-2 pr-0 pl-0">
                      {pedit ? (
                        <div className={styles.profileImageContainer} onClick={openFileSelect}>
                          {capturedImg && capturedImg !== "" ? <img alt="" src={capturedImg} /> : <BsPersonCircle fontSize={120} color="#dfdfdf" />}
                          <div className={styles.editProfileImageOverlay}>
                            <BsPlus />
                            Add Image
                          </div>
                        </div>
                      ) : (
                        <div className={styles.profileImageContainer}>{capturedImg && capturedImg !== "" ? <img alt="" src={capturedImg} /> : <BsPersonCircle fontSize={120} color="#dfdfdf" />}</div>
                      )}
                      <input
                        type="file"
                        id="fileIdProfile"
                        accept=".png,.jpg,.jpeg"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target.files !== null) {
                            uploadImage(e.target.files);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row pt-0 pb-0">
                    <div className="col-12">
                      {!pedit ? (
                        <div className="text-center">
                          <span className="profileUserName">{`${userInfo?.firstName ? userInfo?.firstName : "-"} ${userInfo?.middleName ? userInfo?.middleName : "-"} ${
                            userInfo?.lastName ? userInfo?.lastName : "-"
                          }`}</span>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                updateUserInfo(e.target.value, "firstName");
                              }}
                              value={userInfo?.firstName}
                              placeholder="First Name"
                              className="inputFieldStyle"
                            />
                          </div>
                          <div className="col-12 pt-2">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                updateUserInfo(e.target.value, "middleName");
                              }}
                              value={userInfo?.middleName}
                              placeholder="Middle Name"
                              className="inputFieldStyle"
                            />
                          </div>
                          <div className="col-12 pt-2">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                updateUserInfo(e.target.value, "lastName");
                              }}
                              value={userInfo?.lastName}
                              placeholder="Last Name"
                              className="inputFieldStyle"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row pt-0">
                    <div className="col-12">
                      <div className="text-center">
                        {!pedit ? (
                          <span className="profileUserRole">{userInfo?.job_profile_name}</span>
                        ) : (
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              updateUserInfo(e.target.value, "job_profile_name");
                            }}
                            value={userInfo?.job_profile_name}
                            placeholder="Job Profile"
                            className="inputFieldStyle"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className={styles.connectButton} onClick={() => navigateTo("/searchConnection")}>
                      Connect with colleague
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="ant-card">
                <div className="ant-card-bodyMinor">
                  <div className="row px-3 pb-0">
                    <h6 className={styles.aboutHeading}>About</h6>
                  </div>
                  {!pedit ? (
                    <div className="row px-3 pb-0">{userInfo?.about_me ? <span>{userInfo?.about_me}</span> : <span className={styles.nothingToShow}>Nothing to show</span>}</div>
                  ) : (
                    <div className="row">
                      <Form.Control
                        value={userInfo?.about_me}
                        className="inputFieldStyle"
                        rows="4"
                        cols="33"
                        as="textarea"
                        onChange={(e) => {
                          updateUserInfo(e.target.value, "about_me");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="ant-card">
                <div className="ant-card-bodyMinor">
                  <div className="px-3 d-flex align-items-center mb-2">
                    <GoMail color="#f1bb46" />
                    <div className="d-flex ms-1">
                      <div style={{ fontWeight: "500", marginRight: "0.25rem" }}>Email:</div>
                      <div>{userInfo?.emailAddress ? userInfo?.emailAddress : "-"}</div>
                    </div>
                  </div>
                  <div className="px-3 d-flex align-items-center mb-2">
                    <BsBriefcaseFill color="#f1bb46" />
                    <div className="d-flex ms-1">
                      <div style={{ fontWeight: "500", marginRight: "0.25rem" }}>Experience:</div>
                      <div>0-2 Years</div>
                    </div>
                  </div>
                  <div className="px-3 d-flex align-items-center mb-2">
                    {!pedit ? <IoLocationSharp color="#f1bb46" /> : null}
                    {!pedit ? (
                      <div className="d-flex ms-1">
                        <div style={{ fontWeight: "500", marginRight: "0.25rem" }}>City:</div>
                        <div>{userInfo?.city ? userInfo?.city : "-"}</div>
                      </div>
                    ) : (
                      <CityDropDown value={cityList} city={userInfo?.city} returnCity={onCityValueChange} />
                    )}
                  </div>
                  <div className="px-3 d-flex align-items-center mb-2">
                    {!pedit ? <IoLocationSharp color="#f1bb46" /> : null}
                    {!pedit ? (
                      <div className="d-flex ms-1">
                        <div style={{ fontWeight: "500", marginRight: "0.25rem" }}>State:</div>
                        <div>{userInfo?.state ? userInfo?.state : "-"}</div>
                      </div>
                    ) : (
                      <StatesDropDown state={userInfo?.state} returnState={onStateValueChange} />
                    )}
                  </div>
                  <div className="row px-3 pb-2">
                    <div className="col-12">
                      <b>Achivements :</b>
                    </div>
                    {!pedit ? (
                      achievementDetails.length > 0 ? (
                        returnAchivementsList()
                      ) : (
                        <div className={styles.nothingToShow}>{returnAchivementsList()}</div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          {achievementDetails.map((data, id) => (
                            <div className="col-12 d-flex align-items-baseline" key={data.achievement_id}>
                              <p>
                                {id + 1} - {data.achievement}
                              </p>
                              <i className="fa fa-times-circle-o ml-3" onClick={() => handleDeleteAchievement(data.achievement_id)} aria-hidden="true"></i>
                            </div>
                          ))}
                          <form onSubmit={handleAchieve}>
                            <div className="row px-3">
                              <div className="col-sm-12 col-md-10">
                                <Form.Control
                                  value={achieveData}
                                  id="achievement"
                                  required
                                  className="inputFieldStyle"
                                  rows="2"
                                  cols="33"
                                  as="textarea"
                                  onChange={(e) => setAchieveData(e.target.value)}
                                />
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <Button type="submit" style={{ borderRadius: "0.25rem" }} className="connectButton">
                                  Add
                                </Button>
                              </div>
                            </div>
                          </form>
                          {/* <Select options={optionsLanguage} value={language} isMulti onChange={setLanguage} /> */}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="row px-3 pb-2">
                    <div className="col-12">
                      <b>Language :</b>
                    </div>
                    {!pedit ? (
                      achievementDetails.length > 0 ? (
                        returnLanguageList()
                      ) : (
                        <div className={styles.nothingToShow}>{returnLanguageList()}</div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          <Select options={optionsLanguage} value={language} isMulti onChange={setLanguage} />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="row px-3 pb-0">
                    <div className="col-12">
                      <b>Skills :</b>
                    </div>
                    {!pedit ? (
                      achievementDetails.length > 0 ? (
                        returnSkillList()
                      ) : (
                        <div className={styles.nothingToShow}>{returnSkillList()}</div>
                      )
                    ) : (
                      <React.Fragment>
                        <div className="col-12">
                          <Select options={optionsSkills} value={skills} isMulti onChange={setSkills} />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  {pedit ? (
                    <div className="row px-5 py-5 d-flex justify-content-center">
                      {!btnLoader ? (
                        <Button
                          className="connectButton"
                          onClick={() => {
                            updateUserPersonalDetails();
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button className="text-white connectButton">
                          <div className="spinner-border text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className="col-12"
              onClick={() => {
                navigateTo("/connectedUser");
              }}
            >
              <div className="ant-card">
                <div className="ant-card-bodyMinor">
                  <div className="row px-3 pb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center" style={{ paddingLeft: "1.50rem" }}>
                      <div className="parentContainerOfConnections">
                        <img alt="" src={imageContent.getCounslingImg()} className="image1Connection" />
                        <img alt="" src={imageContent.getCounslingImg()} className="image2Connection" />
                        <img alt="" src={imageContent.getCounslingImg()} className="image3Connection" />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center px-4">
                      <span className="connectionText">
                        <b>Your Connections</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12"
              onClick={() => {
                navigateTo("/resumeBuilder");
              }}
            >
              <div className={`${styles.resumeBox} ant-card`}>
                <div className="ant-card-bodyMinor">
                  <div className="row px-3 pb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                      <h4>
                        <b>Create Your Resume</b>
                      </h4>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                      <img alt="" className="imageContainerForResume" src={imageContent.getResumeImgFive()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="col-xs-12 col-sm-12 col-md-8">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="ant-card">
                <div className="ant-card-bodySmall">
                  <div className="pb-0 pr-2 d-flex justify-content-end">
                    {!edit ? (
                      <div className={styles.editAndCloseIcon} onClick={() => setEdit(true)}>
                        <FiEdit />
                      </div>
                    ) : (
                      <div className={styles.editAndCloseIcon} onClick={() => setEdit(false)}>
                        <AiOutlineClose />
                      </div>
                    )}
                  </div>
                  <div className="row pl-3 pb-0">
                    {!edit ? (
                      <h5>
                        <b>Work experince</b>
                      </h5>
                    ) : null}
                  </div>
                  {!edit ? returnWorkComp() : <ProfessionalDetails updateEdit={updateEditValue} updateLoader={() => {}} />}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="ant-card">
                <div className="ant-card-bodySmall">
                  <div className="pb-0 pr-2 d-flex justify-content-end">
                    {!editEdu ? (
                      <div className={styles.editAndCloseIcon} onClick={() => setEditEdu(true)}>
                        <FiEdit />
                      </div>
                    ) : (
                      <div className={styles.editAndCloseIcon} onClick={() => setEditEdu(false)}>
                        <AiOutlineClose />
                      </div>
                    )}
                  </div>
                  <div className="row pl-3 pb-0">
                    {!editEdu ? (
                      <h5>
                        <b>Education</b>
                      </h5>
                    ) : null}
                  </div>
                  {!editEdu ? returnEduComp() : <EducationalDetails updateEdit={updateEditValueEdu} updateLoader={() => {}} />}
                </div>
              </div>
            </div>
            <div className="col-12 pt-3">
              <div className="ant-card">
                <div className="ant-card-bodySmall">
                  <div className="row pl-3 pb-0">
                    <h5>
                      <b>Activity</b>
                    </h5>
                    <br />
                  </div>
                  <div className="row pl-3 pb-0">{returnTabs()}</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row jobList">{returnActivity()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WorkExpComponent = (props) => {
  return (
    <React.Fragment>
      <div className="row pb-0" id={props.professional_id}>
        <div className={props.bodyClassName}>
          <div className="col-12 pb-2">
            <span className="workAndEduInternalInfoText">{props.work.designation}</span>
          </div>
          <div className="col-12">
            <div className="row pb-0">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <img alt="" src={imageContent.getOrgImage()} />
                <span>&nbsp;&nbsp;Org : {props.work.companyName}</span>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <img alt="" src={imageContent.getLocImage()} />
                <span>&nbsp;&nbsp;Location : {props.work.location}</span>
              </div>
              {/* <div className="col-xs-12 col-sm-12 col-md-3">
                                <img alt="" src={imageContent.getExpImage()} />
                                <span>&nbsp;&nbsp;Job Type:{props.work.jobType}</span>
                            </div> */}
            </div>
          </div>
          <div className="col-12">
            <div className="row pb-0 pt-2">
              <div className="col-12">
                <span>{props.work?.projects_details[0]?.discription}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row pb-0 pt-2">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="row pb-0">
                  <div className="col-12">
                    <img alt="" src={imageContent.getWorkImage()} />
                    <span>
                      &nbsp;&nbsp;{props.work?.joined_from !== 0 ? DataUtility.epochToDateValue(props.work?.joined_from) : ""} --{" "}
                      {props.work?.worked_till !== 0 ? DataUtility.epochToDateValue(props.work?.worked_till) : "Currently Working"}
                    </span>
                  </div>
                  <div className="col-12 pl-4">
                    <span className="viewExpText pl-2">&nbsp;&nbsp;{props.work?.userExp?.diff + " " + props.work?.userExp?.duration}</span>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="row pb-0">
                  <div className="col-12">
                    <img alt="" src={imageContent.getProjectImage()} />
                    <span>&nbsp;&nbsp;{props.work?.projects_details.length} projects</span>
                  </div>
                  <div className="col-12 pl-4">{/* <span className="viewProjectText pl-2">&nbsp;&nbsp;View projects</span> */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const EduComponent = (props) => {
  return (
    <React.Fragment>
      <div className="row pb-0" id={props.eduId}>
        <div className={props.bodyClassName}>
          <div className="col-12 pb-2">
            <span className="workAndEduInternalInfoText">{props.edu?.educationType}</span>
          </div>
          <div className="col-12">
            <div className="row pb-0">
              <div className="col-xs-12 col-sm-12 col-md-3">
                <img alt="" src={imageContent.getWorkImage()} />
                <span>&nbsp;&nbsp;Passing Year&nbsp;:&nbsp;{props.edu?.session_end}</span>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                {/* <img alt="" src={imageContent.getWorkImage()} />
                                <span>&nbsp;&nbsp;To:{props.edu.workedFor}</span> */}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <img alt="" src={imageContent.getLocImage()} />
                <span>&nbsp;&nbsp;Location&nbsp;:&nbsp;{props.edu?.location}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const JobCard = (props) => {
  let navigateTo = useNavigate();
  const onEdit = (id) => {
    navigateTo("/editJob/" + id);
  };
  const navigateToJobInfo = (id) => {
    navigateTo("/jobInfo/" + id);
  };
  return (
    <React.Fragment>
      <div className="ant-card" key={"jobCard" + props.index}>
        <div className="ant-card-bodySmall">
          <div className="row pl-3 pb-0">
            <div className="col-8">
              <div className="row pb-0">
                <div className="col-12">
                  <span style={{ color: "orange" }}>{props.jobInfo?.joiningType}</span>
                  <h4
                    onClick={() => {
                      navigateToJobInfo(props.jobInfo?.job_id);
                    }}
                  >
                    <b>{props.jobInfo?.jobTitle}</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">
              {props.isEdit ? (
                <img
                  alt=""
                  src={imageContent.getEditImage()}
                  height="15"
                  width="15"
                  onClick={() => {
                    onEdit(props.jobInfo?.job_id);
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="row pl-3 pb-2">
            {props.jobInfo?.skillset?.map((item, index) => {
              return (
                <React.Fragment>
                  <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
                    <span>{item.skill}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="row pl-3 pb-2">
            <div className="col-12">{props.jobInfo?.description}</div>
          </div>
          <div className="row pl-3 pb-0">
            <div className="col-xs-12 col-sm-12 col-md-3" title={props.jobInfo?.companyName}>
              <img alt="" src={imageContent.getOrgImage()} />
              <span>&nbsp;&nbsp;Org : {props.jobInfo?.companyName.length > 15 ? props.jobInfo?.companyName.substring(0, 15) + "..." : props.jobInfo?.companyName}</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">
              <img alt="" src={imageContent.getExpImage()} />
              <span>&nbsp;&nbsp;Experience : {props.jobInfo?.expRequired} Yr</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">
              <img alt="" src={imageContent.getLocImage()} />
              <span>&nbsp;&nbsp;Location : {props.jobInfo?.location}</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">Uploaded By : {props.jobInfo?.posted_by}</div>
          </div>
          <div className="row pl-3 pb-0">
            <div className="col-12">{/* <span className="profileUserRole">Posted on: 1 day ago</span> */}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const AppliedJobCard = (props) => {
  return (
    <React.Fragment>
      <div className="ant-card" key={"appliedjobCard" + props.index}>
        <div className="ant-card-bodySmall">
          <div className="row pl-3 pb-0">
            <div className="col-8">
              <div className="row pb-0">
                <div className="col-12">
                  <span style={{ color: "orange" }}>{props.jobInfo?.joiningType}</span>
                  <h4>
                    <b>{props.jobInfo?.jobTitle}</b>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end">{/* <img alt="" src={imageContent.getEditImage()} height="15" width="15" onClick={() => { onEdit(props.jobInfo?.job_id) }} /> */}</div>
          </div>
          <div className="row pl-3 pb-2">
            {props.jobInfo?.skillset?.map((item, index) => {
              return (
                <React.Fragment>
                  <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
                    <span>{item.skill}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="row pl-3 pb-2">
            <div className="col-12">{props.jobInfo?.description}</div>
          </div>
          <div className="row pl-3 pb-0">
            <div className="col-xs-12 col-sm-12 col-md-3" title={props.jobInfo?.companyName}>
              <img alt="" src={imageContent.getOrgImage()} />
              <span>&nbsp;&nbsp;Org : {props.jobInfo?.companyName}</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">
              <img alt="" src={imageContent.getExpImage()} />
              <span>&nbsp;&nbsp;Experience : {props.jobInfo?.expRequired} Yr</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">
              <img alt="" src={imageContent.getLocImage()} />
              <span>&nbsp;&nbsp;Location : {props.jobInfo?.location}</span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3">Uploaded By : {props.jobInfo?.posted_by}</div>
          </div>
          <div className="row pl-3 pb-0">
            <div className="col-12">{/* <span className="profileUserRole">Posted on: 1 day ago</span> */}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
