import React from "react";
import EmailAlreadyExists from "./EmailAlreadyExists/EmailAlreadyExists";
import SomethingWentWrong from "./SomethingWentWrong/SomethingWentWrong";
import RequestSentSuccessfully from "./RequestSentSuccessfully/RequestSentSuccessfully";
import PleaseSelectChat from "./PleaseSelectChat/PleaseSelectChat";
import SuccessToaster from "./SuccessToaster/SuccessToaster";
import ErrorToaster from "./ErrorToaster/ErrorToaster";

const ToasterCollection = () => {
  return (
    <React.Fragment>
      <EmailAlreadyExists />
      <SomethingWentWrong />
      <RequestSentSuccessfully />
      <PleaseSelectChat />
      <SuccessToaster />
      <ErrorToaster />
    </React.Fragment>
  );
};

export default ToasterCollection;
