import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sessionRequestList: [],
    allUserList: [],
    allReportedList: [],
    allPaymentsList: [],
};

export const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        saveSessionRequestList: (state, action) => {
            state.sessionRequestList = action.payload;
        },
        saveAllUserList: (state, action) => {
            state.allUserList = action.payload;
        },

        saveAllReportedList: (state, action) => {
            state.allReportedList = action.payload;
        },

        saveAllPaymentsList: (state, action) => {
            state.allPaymentsList = action.payload;
        },
    },
});

export const { saveSessionRequestList, saveAllUserList, saveAllReportedList, saveAllPaymentsList } = adminSlice.actions;

export default adminSlice.reducer;
