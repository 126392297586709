import React, { useEffect, useState } from "react";
import styles from "./jsSearchResult.module.css";
import countryStates from "../../json/states.json";
import { useDispatch, useSelector } from "react-redux";
import { saveSearchTextFromHome } from "../../Redux/Slices/noPersistSlice";
import { Typeahead } from "react-bootstrap-typeahead";
import { skillsArray } from "../../json/commonData";
import { getJobSearch, getPeopleSearch } from "../../Services/commonService";
import { Offcanvas } from "react-bootstrap";
import { FcFilledFilter } from "react-icons/fc";
import EachJobCard from "./EachJobCard/EachJobCard";
import EachPeopleCard from "./EachPeopleCard/EachPeopleCard";
import { Switch } from "@mui/material";

const JsSearchResult = () => {
    const dispatch = useDispatch();
    const { searchTextFromHome, peopleArray, jobArray } = useSelector((state) => state.nopersist);

    const [activeSwitch, setActiveSwitch] = useState("people");
    const [allLocations, setAllLocations] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const [liveSession, setLiveSession] = useState(true);

    const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);

    const [locationsList, setLocationsList] = useState([]);
    const [sendingLocationsList, setSendingLocationsList] = useState([]);
    const handleSelectLocation = (selected) => {
        let x = JSON.parse(JSON.stringify(locationsList));
        let check = x.some((curElem) => {
            return curElem.value === selected[0].value;
        });
        if (!check) {
            setLocationsList((prev) => {
                return [...prev, selected[0]];
            });
        }
    };
    const handleLocationRemove = (locationValue) => {
        let x = JSON.parse(JSON.stringify(locationsList));
        let y = x.filter((curElem) => {
            return curElem.value !== locationValue;
        });
        setLocationsList(y);
    };

    const [skillsList, setSkillsList] = useState([]);
    const [sendingSkillsList, setSendingSkillsList] = useState([]);
    const handleSelectSkill = (selected) => {
        let x = JSON.parse(JSON.stringify(skillsList));
        let check = x.some((curElem) => {
            return curElem.value === selected[0].value;
        });
        if (!check) {
            setSkillsList((prev) => {
                return [...prev, selected[0]];
            });
        }
    };
    const handleSkillRemove = (locationValue) => {
        let x = JSON.parse(JSON.stringify(skillsList));
        let y = x.filter((curElem) => {
            return curElem.value !== locationValue;
        });
        setSkillsList(y);
    };

    const resetFilters = () => {
        setLiveSession(false);
        dispatch(saveSearchTextFromHome(""));
        setLocationsList([]);
        setSkillsList([]);
    };

    useEffect(() => {
        let x = countryStates.states;
        let stateNames = x.map((curElem) => {
            return { value: curElem.state, label: curElem.state };
        });
        let y = x.map((curElem) => {
            return curElem.districts;
        });
        let z = y.flat();
        let districtNames = z.map((curElem) => {
            return { value: curElem, label: curElem };
        });
        let allNames = [...stateNames, ...districtNames];
        setAllLocations(allNames);
    }, []);
    useEffect(() => {
        let x = skillsArray.map((curElem) => {
            return { value: curElem, label: curElem };
        });
        setAllSkills(x);
    }, []);

    useEffect(() => {
        let newSkillsList = skillsList.map((curElem) => {
            return curElem.value;
        });
        let newLocationsList = locationsList.map((curElem) => {
            return curElem.value;
        });
        setSendingSkillsList(newSkillsList);
        setSendingLocationsList(newLocationsList);
    }, [locationsList, skillsList]);

    // useEffect(() => {
    //     getGlobalSearchData(searchTextFromHome);
    // }, [searchTextFromHome]);

    useEffect(() => {
        if (activeSwitch === "people") {
            getPeopleSearch(0, 10, sendingLocationsList, sendingSkillsList, searchTextFromHome, liveSession);
        } else {
            getJobSearch(0, 10, sendingLocationsList, sendingSkillsList, searchTextFromHome);
        }
    }, [searchTextFromHome, activeSwitch, liveSession, sendingLocationsList, sendingSkillsList]);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

    const updateMedia = () => {
        setIsDesktop(window.innerWidth > 767);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <React.Fragment>
            <Offcanvas style={{ height: "70vh" }} placement="bottom" show={showFilterOffcanvas} onHide={() => setShowFilterOffcanvas(false)}>
                <Offcanvas.Header closeButton style={{ fontSize: "16px", fontWeight: "600" }}>
                    Filters
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0">
                    <div className={styles.filterControlsContainerMob}>
                        {/* <div className="mb-2">
                            <div className={`${styles.inputTitle}`}>Search:</div>
                            <input type="text" value={searchTextFromHome} onChange={(event) => dispatch(saveSearchTextFromHome(event.target.value))} className="jobsifarish-input2" />
                        </div> */}
                        {activeSwitch === "people" && (
                            <div className={`d-flex align-items-center justify-content-between ${styles.sessionSwitchContainer}`}>
                                <div className={styles.inputTitle}>Live Session Providers</div>
                                <Switch size="small" color="warning" checked={liveSession} onChange={(event) => setLiveSession(event.target.checked)} />
                            </div>
                        )}
                        <div>
                            <div className={`${styles.inputTitle}`}>Location:</div>
                            <Typeahead id="location-typeahead" options={[...allLocations]} value={locationsList} onChange={handleSelectLocation} selected={[]} />
                            <div className={styles.pillsContainer}>
                                {locationsList &&
                                    locationsList.map((curElem) => {
                                        return (
                                            <div className={styles.eachPill} onClick={() => handleLocationRemove(curElem.value)}>
                                                {curElem.value}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div>
                            <div className={`${styles.inputTitle}`}>Skills:</div>
                            <Typeahead id="skills-typeahead" options={[...allSkills]} value={skillsList} onChange={handleSelectSkill} selected={[]} />
                            <div className={styles.pillsContainer}>
                                {skillsList &&
                                    skillsList.map((curElem) => {
                                        return (
                                            <div className={styles.eachPill} onClick={() => handleSkillRemove(curElem.value)}>
                                                {curElem.value}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-end">
                        <button className={styles.resetButton} onClick={resetFilters}>
                            Reset
                        </button>
                        <button className={styles.submitButton} onClick={() => setShowFilterOffcanvas(false)}>
                            Submit
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div className="main-content-container-flexible">
                <div className={styles.container}>
                    {!isDesktop && (
                        <div className={styles.searchContainer}>
                            <input
                                type="text"
                                className="jobsifarish-input2"
                                placeholder="Search Users"
                                value={searchTextFromHome}
                                onChange={(event) => dispatch(saveSearchTextFromHome(event.target.value))}
                            />
                        </div>
                    )}
                    <div className={styles.contentContainer}>
                        {isDesktop ? (
                            <div className={styles.filterBox}>
                                <div className={styles.filterHead}>Filters</div>
                                <div className={styles.filterControlsContainer}>
                                    <div className="mb-2">
                                        <div className={`${styles.inputTitle}`}>Search:</div>
                                        <input type="text" value={searchTextFromHome} onChange={(event) => dispatch(saveSearchTextFromHome(event.target.value))} className="jobsifarish-input2" />
                                    </div>
                                    <div className="mb-2">
                                        <div className={`${styles.inputTitle}`}>Search Category:</div>
                                        <div className={styles.switchContainer}>
                                            <div className={`${styles.eachSwitch} ${activeSwitch === "people" ? styles.active : ""}`} onClick={() => setActiveSwitch("people")}>
                                                People
                                            </div>
                                            <div className={`${styles.eachSwitch} ${activeSwitch === "job" ? styles.active : ""}`} onClick={() => setActiveSwitch("job")}>
                                                Job
                                            </div>
                                        </div>
                                    </div>
                                    {activeSwitch === "people" && (
                                        <div className={`d-flex align-items-center justify-content-between ${styles.sessionSwitchContainer}`}>
                                            <div className={styles.inputTitle}>Live Session Providers</div>
                                            <Switch size="small" color="warning" checked={liveSession} onChange={(event) => setLiveSession(event.target.checked)} />
                                        </div>
                                    )}
                                    <div>
                                        <div className={`${styles.inputTitle}`}>Location:</div>
                                        <Typeahead id="location-typeahead" options={[...allLocations]} value={locationsList} onChange={handleSelectLocation} selected={[]} />
                                        <div className={styles.pillsContainer}>
                                            {locationsList &&
                                                locationsList.map((curElem) => {
                                                    return (
                                                        <div className={styles.eachPill} onClick={() => handleLocationRemove(curElem.value)}>
                                                            {curElem.value}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`${styles.inputTitle}`}>Skills:</div>
                                        <Typeahead id="skills-typeahead" options={[...allSkills]} value={skillsList} onChange={handleSelectSkill} selected={[]} />
                                        <div className={styles.pillsContainer}>
                                            {skillsList &&
                                                skillsList.map((curElem) => {
                                                    return (
                                                        <div className={styles.eachPill} onClick={() => handleSkillRemove(curElem.value)}>
                                                            {curElem.value}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="normal-disabled-button" onClick={resetFilters}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className={styles.filterButton} onClick={() => setShowFilterOffcanvas(true)}>
                                    <FcFilledFilter fontSize={24} />
                                </div>
                                <div style={{ width: "calc(100% - 4rem)" }}>
                                    <div className={styles.switchContainer}>
                                        <div className={`${styles.eachSwitch} ${activeSwitch === "people" ? styles.active : ""}`} onClick={() => setActiveSwitch("people")}>
                                            People
                                        </div>
                                        <div className={`${styles.eachSwitch} ${activeSwitch === "job" ? styles.active : ""}`} onClick={() => setActiveSwitch("job")}>
                                            Job
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={styles.resultsContainer}>
                            <div className={styles.fitBox}>
                                {activeSwitch === "people" && (
                                    <React.Fragment>
                                        <div className={styles.sectionHeading}>People</div>
                                        <div className={styles.peopleOrJobBox}>
                                            {peopleArray &&
                                                peopleArray.map((curElem) => {
                                                    return (
                                                        <EachPeopleCard
                                                            cardData={curElem}
                                                            sendingLocationsList={sendingLocationsList}
                                                            sendingSkillsList={sendingSkillsList}
                                                            searchTextFromHome={searchTextFromHome}
                                                            liveSession={liveSession}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </React.Fragment>
                                )}
                                {activeSwitch === "job" && (
                                    <React.Fragment>
                                        <div className={styles.sectionHeading}>Jobs</div>
                                        <div className={styles.peopleOrJobBox}>
                                            {jobArray &&
                                                jobArray.map((curElem) => {
                                                    return <EachJobCard cardData={curElem} />;
                                                })}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default JsSearchResult;
