import React, { useEffect, useState } from "react";
import styles from "./scheduledMeet.module.css";
import { getDateFromTimeString, getScheduledMeetList, getTimeFromTimeString, toasterControl } from "../../../../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { modalController, saveMeetToReschedule } from "../../../../../Redux/Slices/noPersistSlice";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { postTReq } from "../../../../../Services/api";
import { apiEndpoints } from "../../../../../PrEnums/prEnums";
import axios from "axios";
import config from "../../../../../config.json";

const ScheduledMeet = () => {
    const dispatch = useDispatch();
    const { scheduledMeetList } = useSelector((state) => state.nopersist);
    const { personalDetails } = useSelector((state) => state.persist);

    const [meetingUrl, setMeetingUrl] = useState("");
    const [linkModal, setLinkModal] = useState(false);
    const [savedMessageId, setSavedMessageId] = useState("");
    const closeModal = () => {
        setLinkModal(false);
    };

    const handleRescheduling = (obj) => {
        dispatch(saveMeetToReschedule(obj));
        dispatch(modalController({ modal: "rescheduleModal", state: true }));
    };

    const [loading, setLoading] = useState([]);
    const sessionAction = async (message_id, status, from) => {
        const newLoading = loading.filter((curElem) => {
            return curElem !== message_id;
        });
        const res = await postTReq(apiEndpoints.acceptMeetingRequest, {
            message_id: message_id,
            request_status: status,
        });
        if (res && !res.error) {
            if (from && from === "modal") {
                const meetRes = await postTReq(apiEndpoints.saveMeetLink, {
                    message_id: savedMessageId,
                    meeting_url: meetingUrl,
                });
                if (meetRes && !meetRes.error) {
                    toasterControl("successToaster", true, "Meeting link added");
                    setMeetingUrl("");
                    setSavedMessageId("");
                    setLinkModal(false);
                } else {
                    console.log(meetRes.error);
                    toasterControl("errorToaster", true, "Something went wrong");
                }
            }
            getScheduledMeetList(0, 10);
            setLoading(newLoading);
        } else {
            console.log(res.error);
            setLoading(newLoading);
        }
    };

    const modalSubmit = () => {
        setLoading((prev) => {
            return [...prev, savedMessageId];
        });
        sessionAction(savedMessageId, "accept", "modal");
    };

    const goToLink = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handlePayment = async (e, url) => {
        e.preventDefault();
        const amount = 100000;

        try {
            const response = await axios.post(
                `${config.apiConfig.apiEndPoint}/payment/createOrder`,
                { amount: amount },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = response.data;

            const options = {
                key: "rzp_test_qhFSUTm3tovZal", // Replace with your Razorpay key
                amount: amount,
                currency: "INR",
                order_id: data.id,
                name: "Your Company Name",
                description: "Payment for your order",
                image: "https://your-company-logo.png", // Replace with your company logo URL
                handler: function (response) {
                    // alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
                    goToLink(url);
                },
                prefill: {
                    name: "John Doe",
                    email: "john.doe@example.com",
                    contact: "+919876543210",
                },
                theme: {
                    color: "#F37254",
                },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        getScheduledMeetList(0, 10);
    }, []);
    return (
        <React.Fragment>
            <Dialog open={linkModal}>
                <DialogTitle>Add meeting link</DialogTitle>
                <DialogContent>
                    <div className="mb-2">
                        <input className="jobsifarish-input2" type="text" style={{ width: "20rem" }} value={meetingUrl} onChange={(event) => setMeetingUrl(event.target.value)} />
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="normal-button" onClick={modalSubmit}>
                            Add
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.container}>
                {scheduledMeetList && scheduledMeetList.length > 0 ? (
                    scheduledMeetList.map((curElem) => {
                        return (
                            <div className={styles.eachMeet}>
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                                    <div className={styles.meetHeading}>{curElem.message_type && curElem.message_type.message ? curElem.message_type.message : "Untitled"}</div>
                                    {curElem.message_type.sender_email === personalDetails.emailAddress ? (
                                        <div className={styles.rescheduleButtonContainer}>
                                            <button onClick={() => handleRescheduling(curElem)}>Reschedule</button>
                                        </div>
                                    ) : loading.includes(curElem.message_id) ? (
                                        <button className="action-button-disabled">
                                            <CircularProgress size={12} style={{ color: "#000000" }} />
                                        </button>
                                    ) : (
                                        // <button className="normal-button" onClick={() => goToLink(curElem.message_type.meeting_url)}>
                                        <button className="normal-button" onClick={(event) => handlePayment(event, curElem.message_type.meeting_url)}>
                                            Join Session
                                        </button>
                                    )}
                                </div>
                                <div className={`${styles.dateAndTime} flex-wrap`}>
                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className={`me-2 ${styles.label}`}>Meet Date:</div>
                                        <div className={styles.value}>{getDateFromTimeString(curElem.message_type.meet_start_time)}</div>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className={`me-2 ${styles.label}`}>Meet Time:</div>
                                        <div className={styles.value}>
                                            {getTimeFromTimeString(curElem.message_type.meet_start_time)} to {getTimeFromTimeString(curElem.message_type.meet_end_time)}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className={`me-2 ${styles.label}`}>{curElem.message_type.sender_email === personalDetails.emailAddress ? "Meeting With:" : "Created By:"}</div>
                                        <div className={styles.value}>
                                            {curElem.message_type.sender_email === personalDetails.emailAddress ? curElem.message_type.reciever_email : curElem.message_type.sender_email}
                                        </div>
                                    </div>
                                    {/* <div className="d-flex align-items-center flex-wrap">
                                        <div className={`me-2 ${styles.label}`}>Meeting URL:</div>
                                        <a href={curElem.message_type.meeting_url} target="_blank" rel="noreferrer" className={styles.meetingUrl}>
                                            {curElem.message_type.meeting_url}
                                        </a>
                                    </div> */}
                                </div>
                                {curElem.message_type.comment && (
                                    <div className={`d-flex flex-column ${styles.descriptionBox}`}>
                                        <div className={`me-2 ${styles.label}`}>Description:</div>
                                        <div className={styles.value}>{curElem.message_type.comment}</div>
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className="colorback-nothing-to-show">No Scheduled Meets</div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ScheduledMeet;
