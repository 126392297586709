import React, { useEffect } from "react";
import { getAllPayments, getEveryUser, toasterControl } from "../../../Services/commonService";
import styles from "./paymentsView.module.css";
import { useDispatch, useSelector } from "react-redux";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { BsPersonCircle } from "react-icons/bs";
import { Pagination } from "@mui/material";
import { savePaginationPageNum } from "../../../Redux/Slices/generalSlice";

const PaymentsView = () => {
    const dispatch = useDispatch();
    const { allPaymentsList } = useSelector((state) => state.admin);
    const { paginationPageNum, paginationRecordsNum, paginationTotalPages } = useSelector((state) => state.general);

    const statusObj = {
        success: {
            label: "Success",
        },
        processing: {
            label: "Processing",
        },
    };

    const blockUser = async (email, status) => {
        const res = await postTReq(apiEndpoints.blockUser, {
            emailAddress: email,
            is_blocked: status,
        });
        if (res && !res.error) {
            getEveryUser(0, 10);
            toasterControl("successToaster", true, "User Blocked Successfully");
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "An Error Occured");
        }
    };

    const handlePageChange = (pagenum) => {
        dispatch(savePaginationPageNum(pagenum));
    };

    useEffect(() => {
        getAllPayments(paginationPageNum - 1, paginationRecordsNum || 10);
    }, [paginationPageNum, paginationRecordsNum]);

    return (
        <>
            <div className="h-100">
                <div className={styles.mainHeading}>USER PAYMENTS</div>
                <div className={styles.actualTable}>
                    <div className={styles.onlyTableContainer}>
                        <div className={styles.fittingBox}>
                            <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                                <div className={styles.firstHead} style={{ paddingLeft: "1rem" }}>
                                    PAID BY
                                </div>
                                <div className={`${styles.firstHead}`}>PAID TO</div>
                                <div className={styles.headingName}>STATUS</div>
                                <div className={styles.headingName}>ACTIONS</div>
                            </div>

                            {/* details */}
                            <div className="d-flex flex-column mt-2">
                                {allPaymentsList &&
                                    allPaymentsList.map((curelem) => {
                                        return (
                                            <>
                                                <div
                                                    className="d-flex align-items-center py-2"
                                                    style={curelem.is_blocked ? { backgroundColor: "#f6f6f6", borderBottom: "2px solid #e5e5e6" } : { borderBottom: "2px solid #e5e5e6" }}
                                                >
                                                    <div className={`${styles.firstCell} d-flex align-items-center`}>
                                                        <div className={styles.profileImageContainer}>
                                                            {curelem.payment_done_by_user_details.profileImageUrl ? (
                                                                <img src={curelem.payment_done_by_user_details.profileImageUrl} alt="" />
                                                            ) : (
                                                                <BsPersonCircle fontSize={32} color="#888888" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div>{curelem.payment_done_by_user_details.fullName}</div>
                                                            <div className={styles.emailPill}>{curelem.payment_done_by_user_details.emailAddress}</div>
                                                        </div>
                                                    </div>
                                                    <div className={`${styles.firstCell} d-flex align-items-center`}>
                                                        <div className={styles.profileImageContainer}>
                                                            {curelem.payment_receiver_user_details.profileImageUrl ? (
                                                                <img src={curelem.payment_receiver_user_details.profileImageUrl} alt="" />
                                                            ) : (
                                                                <BsPersonCircle fontSize={32} color="#888888" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div>{curelem.payment_receiver_user_details.fullName}</div>
                                                            <div className={styles.emailPill}>{curelem.payment_receiver_user_details.emailAddress}</div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.detailsText}>{statusObj[curelem.payment_status].label}</div>
                                                    <div className={styles.detailsText} style={{ display: "flex" }}>
                                                        <select className={styles.statusSelect} onChange={() => blockUser(curelem)}>
                                                            <option value="success">Successful</option>
                                                            <option value="processing">Processing</option>
                                                            <option value="reject">Rejected</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                        <Pagination size="small" color="primary" count={paginationTotalPages} page={paginationPageNum} onChange={(event, value) => handlePageChange(value)} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentsView;
