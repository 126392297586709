import React, { useEffect, useState } from "react";
import JobsifarishChat from "../JobsifarishChat";
import JobsifarishChatMob from "../JobsifarishChatMob/JobsifarishChatMob";

const JobsifarishChatCombine = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 767);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return <React.Fragment>{isDesktop ? <JobsifarishChat /> : <JobsifarishChatMob />}</React.Fragment>;
};

export default JobsifarishChatCombine;
