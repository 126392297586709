import imgOfCross from "../assets/crossSvg.svg";
import gifOfLoader from "../assets/loader.gif";
import imgOfLogo from "../assets/final_logo.png";
import searchBackGroundImg from "../assets/img.jpg";
import searchBackGroundImgOne from "../assets/imgOne.jpg";
import searchBackGroundImgTwo from "../assets/imgTwo.jpg";
import resumeOne from "../assets/resume1.png";
import resumeTwo from "../assets/resume2.png";
import resumeThree from "../assets/resume3.png";
import resumeFour from "../assets/resume4.png";
import imgOfCounsling from "../assets/counsling.jpg";
import jobSearch from "../assets/Handshake illustration.svg";
import imgQuotes from "../assets/quotes.svg";
import imageEmail from "../assets/email.svg";
import imageExp from "../assets/exp.svg";
import imageLoc from "../assets/loc.svg";
import imageOrg from "../assets/org.svg";
import imageWork from "../assets/work.svg";
import experienceImg from "../assets/case.svg";
import location from "../assets/location.svg";
import imageUpload from "../assets/upload.svg";
import imageProject from "../assets/project.svg";
import imageEdit from "../assets/edit.svg";
import imageProfile from "../assets/profile.svg";
import deleteImg from "../assets/delete.svg";
import deleteBlackImg from "../assets/delBlack.svg";
import resumeSeven from "../assets/Resume7.svg";
import resumeFive from "../assets/Resume5.svg";
import resumeSix from "../assets/resume6.svg";
import resumeEight from "../assets/resume8.svg";
import resumeNine from "../assets/resume9.svg";
import resume6_phone from "../assets/resume6_phone.svg";
import resume6_website from "../assets/resume6_website.svg";
import resume6_email from "../assets/resume6_email.svg";
import resume6_location from "../assets/resume6_location.svg";

import resume_new_1 from "../assets/resume_new_1.svg";
import resume_new_2 from "../assets/resume_new_2.svg";
import resume_new_3 from "../assets/resume_new_3.svg";
import resume_new_4 from "../assets/resume_new_4.svg";

import imageOfMakeHex from "../assets/hexMake.svg";
import imageOfMakeProf from "../assets/hexProf.svg";
import imageOfSrhHex from "../assets/hexSrh.svg";
import imageOfUldHex from "../assets/hexUld.svg";
import imageOfAppHex from "../assets/hexApp.svg";

import sessions from "../assets/sessions.svg";
import coming_soon from "../assets/coming_soon.svg";
import masters_video from "../assets/masters_video.svg";

import welcome from "../assets/welcomepage.jpeg";
class ImageContent {
    images = {
        crossImg: imgOfCross,
        loaderImg: gifOfLoader,
        logoImg: imgOfLogo,
        seachBgImg: searchBackGroundImg,
        seachBgImgOne: searchBackGroundImgOne,
        seachBgImgTwo: searchBackGroundImgTwo,
        resumeImgOne: resumeOne,
        resumeImgTwo: resumeTwo,
        resumeImgThree: resumeThree,
        resumeImgFour: resumeFour,
        counImg: imgOfCounsling,
        jobSearchImage: jobSearch,
        imageQuote: imgQuotes,
        emailImage: imageEmail,
        expImage: imageExp,
        locImage: imageLoc,
        orgImage: imageOrg,
        workImage: imageWork,
        imgExperience: experienceImg,
        imgLocation: location,
        imgUpload: imageUpload,
        imgProject: imageProject,
        imgEdit: imageEdit,
        profileImg: imageProfile,
        imgDel: deleteImg,
        imgDelBlack: deleteBlackImg,
        resumeImgSeven: resumeSeven,
        resumeImgFive: resumeFive,
        imgHexMake: imageOfMakeHex,
        imgHexProf: imageOfMakeProf,
        imgHexSrh: imageOfSrhHex,
        imgHexUld: imageOfUldHex,
        imgHexApp: imageOfAppHex,
        resumeSix: resumeSix,
        resume6_phone: resume6_phone,
        resume6_website: resume6_website,
        resume6_email: resume6_email,
        resume6_location: resume6_location,
        resumeEight: resumeEight,
        resumeNine: resumeNine,
        resume_new_1,
        resume_new_2,
        resume_new_3,
        resume_new_4,
        welcome,
        sessions,
        coming_soon,
        masters_video,
    };

    getComingSoon() {
        return this.images.coming_soon;
    }

    getSessions() {
        return this.images.sessions;
    }

    getMastersVideo() {
        return this.images.masters_video;
    }

    getWelcome() {
        return this.images.welcome;
    }

    getResume1_new() {
        return "https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/resume_new_1.svg";
    }

    getResume2_new() {
        return "https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/resume_new_2.svg";
    }
    getResume3_new() {
        return "https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/resume_new_1.svg";
    }
    getResume4_new() {
        return this.images.resume_new_4;
    }

    getResume6_phone() {
        return this.images.resume6_phone;
    }
    getResume6_website() {
        return this.images.resume6_website;
    }
    getResume6_email() {
        return this.images.resume6_email;
    }
    getResume6_location() {
        return this.images.resume6_location;
    }

    getResumeImgSix() {
        return this.images.resumeSix;
    }
    getResumeImgEight() {
        return this.images.resumeEight;
    }
    getResumeImgNine() {
        return this.images.resumeNine;
    }
    getHexAppImage() {
        return this.images.imgHexApp;
    }
    getHexUldImage() {
        return this.images.imgHexUld;
    }
    getHexSrhImage() {
        return this.images.imgHexSrh;
    }
    getHexProfImage() {
        return this.images.imgHexProf;
    }
    getHexMakeImage() {
        return this.images.imgHexMake;
    }
    getResumeImgFive() {
        return this.images.resumeImgFive;
    }
    getResumeImgSeven() {
        return this.images.resumeImgSeven;
    }
    getDelBlackImage() {
        return this.images.imgDelBlack;
    }
    getDelImage() {
        return this.images.imgDel;
    }
    getProfilemage() {
        return this.images.profileImg;
    }
    getEditImage() {
        return this.images.imgEdit;
    }
    getProjectImage() {
        return this.images.imgProject;
    }
    getUploadImage() {
        return this.images.imgUpload;
    }
    getExperienceicon() {
        return this.images.imgExperience;
    }
    getLocationImg() {
        return this.images.imgLocation;
    }
    getOrgImage() {
        return this.images.orgImage;
    }
    getWorkImage() {
        return this.images.workImage;
    }
    getEmailImage() {
        return this.images.emailImage;
    }
    getExpImage() {
        return this.images.expImage;
    }
    getLocImage() {
        return this.images.locImage;
    }
    getQuoteImage() {
        return this.images.imageQuote;
    }
    getJobSearchImage() {
        return this.images.jobSearchImage;
    }
    getCounslingImg() {
        return this.images.counImg;
    }
    getResumeImgOne() {
        return this.images.resumeImgOne;
    }
    getResumeImgTwo() {
        return this.images.resumeImgTwo;
    }
    getResumeImgThree() {
        return this.images.resumeImgThree;
    }
    getResumeImgFour() {
        return this.images.resumeImgFour;
    }
    getCrossImg() {
        return this.images.crossImg;
    }
    getLoader() {
        return this.images.loaderImg;
    }
    getLogo() {
        return this.images.logoImg;
    }
    getSeachBgImg() {
        return this.images.seachBgImg;
    }
    getSeachBgImgOne() {
        return this.images.seachBgImgOne;
    }
    getSeachBgImgTwo() {
        return this.images.seachBgImgTwo;
    }
}

export default ImageContent;
