import { Checkbox, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getJobsPostedByUser, getSkillsArray, modalControl, toasterControl } from "../../../Services/commonService";
import styles from "./editJobModal.module.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineClose } from "react-icons/ai";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const EditJobModal = () => {
    const { editingJobObj } = useSelector((state) => state.jobslice);
    const { editJobModal } = useSelector((state) => state.nopersist);
    const { skillsList } = useSelector((state) => state.persist);

    const initialJob = {
        jobTitle: "",
        role: "",
        companyName: "",
        description: "",
        location: "",
        joiningType: "",
        offerPackage: "",
        expRequired: 0,
        jobStreams: "",
    };

    const [jobData, setJobData] = useState(initialJob);

    const handleJobInput = (event) => {
        const { name, value } = event.target;
        setJobData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const handleSelectJobType = (jobType) => {
        let x = JSON.parse(JSON.stringify(selectedJobTypes));
        let check = x.some((curElem) => {
            return curElem.job_type === jobType;
        });
        if (!check) {
            let temp = { job_type: jobType };
            setSelectedJobTypes((prev) => {
                return [...prev, temp];
            });
        } else {
            let temp = selectedJobTypes.filter((curElem) => {
                return curElem.job_type !== jobType;
            });
            setSelectedJobTypes(temp);
        }
    };
    const checkIfItemExists = (jobType) => {
        let check =
            selectedJobTypes &&
            selectedJobTypes.some((curElem) => {
                return curElem.job_type === jobType;
            });
        return check;
    };

    const [selectedSkills, setSelectedSkills] = useState([]);
    const handleSelectSkill = (selected) => {
        let x = JSON.parse(JSON.stringify(selectedSkills));
        let check = x.some((curElem) => {
            return curElem.skill === selected[0];
        });
        if (!check) {
            x = { skill: selected[0] };
            setSelectedSkills((prev) => {
                return [...prev, x];
            });
        }
    };
    const deleteSkill = (index) => {
        let x = selectedSkills.filter((curElem, ind) => {
            return ind !== index;
        });
        setSelectedSkills(x);
    };

    const submitJob = async () => {
        const res = await postTReq(apiEndpoints.postJob, {
            ...jobData,
            skillset: selectedSkills,
            job_type_details: selectedJobTypes,
        });
        if (res && !res.error) {
            getJobsPostedByUser(0, 10);
            setJobData(initialJob);
            setSelectedJobTypes([]);
            setSelectedSkills([]);
            toasterControl("successToaster", true, "Job updated successfully");
            modalControl("editJobModal", false);
        } else {
            console.log(res.error);
        }
    };

    const [arrayOfSkills, setArrayOfSkills] = useState([]);
    useEffect(() => {
        if (skillsList) {
            let arr = skillsList.map((curElem) => {
                return curElem.skill_name;
            });
            setArrayOfSkills(arr);
        }
    }, [skillsList]);

    useEffect(() => {
        if (editingJobObj) {
            setJobData(editingJobObj);
            setSelectedJobTypes(editingJobObj.job_type_details);
            setSelectedSkills(editingJobObj.skillset);
        }
    }, [editingJobObj]);
    useEffect(() => {
        getSkillsArray();
    }, []);
    return (
        <Dialog open={editJobModal} onClose={() => modalControl("editJobModal", false)}>
            <DialogTitle>Edit Job</DialogTitle>
            <DialogContent>
                <div className={styles.container}>
                    <div className={styles.formContainer}>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Job Title</div>
                            <input type="text" className="jobsifarish-input2" name="jobTitle" value={jobData.jobTitle} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Description</div>
                            <textarea rows={3} className="jobsifarish-textarea" name="description" value={jobData.description} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Roles & Responsibilities</div>
                            <textarea rows={3} className="jobsifarish-textarea" name="role" value={jobData.role} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Location</div>
                            <input type="text" className="jobsifarish-input2" name="location" value={jobData.location} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Experience (in Yrs)</div>
                            <input type="number" className="jobsifarish-input2" name="expRequired" value={jobData.expRequired} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Offer Package (in lacs)</div>
                        </div>
                        <div className={`w-50 px-1`}>
                            <input type="text" className="jobsifarish-input2" />
                        </div>
                        <div className={`w-50 px-1`}>
                            <input type="text" className="jobsifarish-input2" />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Joining Type</div>
                            <select className="jobsifarish-input2" name="joiningType" value={jobData.joiningType} onChange={handleJobInput}>
                                <option value="">Select</option>
                                <option value="Immediate">Immediate</option>
                                <option value="Less than 1 month">Less than 1 month</option>
                                <option value="Less than 2 months">Less than 2 months</option>
                            </select>
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Company Name</div>
                            <input type="text" className="jobsifarish-input2" name="companyName" value={jobData.companyName} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Job Stream</div>
                            <input type="text" className="jobsifarish-input2" name="jobStreams" value={jobData.jobStreams} onChange={handleJobInput} />
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Skills</div>
                            <Typeahead options={arrayOfSkills} placeholder="Select" onChange={handleSelectSkill} selected={[]} />
                            <div className="mt-2 d-flex flex-wrap align-items-center">
                                {selectedSkills &&
                                    selectedSkills.map((curElem, index) => {
                                        return (
                                            <div className={styles.skillPill} onClick={() => deleteSkill(index)}>
                                                {curElem.skill}
                                                <div className={styles.deletePill}>
                                                    <AiOutlineClose />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className={`w-100 px-1`}>
                            <div className={styles.inputHead}>Job Type</div>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="fulltime" size="small" checked={checkIfItemExists("Full Time Job")} onChange={() => handleSelectJobType("Full Time Job")} />
                            <label htmlFor="fulltime" className={styles.checkBoxLabel}>
                                Full Time
                            </label>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="parttime" size="small" checked={checkIfItemExists("Part Time Job")} onChange={() => handleSelectJobType("Part Time Job")} />
                            <label htmlFor="parttime" className={styles.checkBoxLabel}>
                                Part Time
                            </label>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="remote" size="small" checked={checkIfItemExists("Remote Job")} onChange={() => handleSelectJobType("Remote Job")} />
                            <label htmlFor="remote" className={styles.checkBoxLabel}>
                                Remote
                            </label>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="internship" size="small" checked={checkIfItemExists("Internship")} onChange={() => handleSelectJobType("Internship")} />
                            <label htmlFor="internship" className={styles.checkBoxLabel}>
                                Internship
                            </label>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="contract" size="small" checked={checkIfItemExists("Contract Job")} onChange={() => handleSelectJobType("Contract Job")} />
                            <label htmlFor="contract" className={styles.checkBoxLabel}>
                                Contract
                            </label>
                        </div>
                        <div className={`w-24 px-1 d-flex align-items-center`}>
                            <Checkbox id="training" size="small" checked={checkIfItemExists("Training")} onChange={() => handleSelectJobType("Training")} />
                            <label htmlFor="training" className={styles.checkBoxLabel}>
                                Training
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="normal-button" onClick={submitJob}>
                            Submit
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditJobModal;
