import React, { useState } from "react";
import styles from "./reportUserBox.module.css";
import { MdCheck, MdReport } from "react-icons/md";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { getOtherUserProfileData, reportUserByEmail } from "../../../../Services/commonService";

const ReportUserBox = () => {
    const { userPersonalDetails } = useSelector((state) => state.persist);
    const [reasonModal, setReasonModal] = useState(false);
    const [comment, setComment] = useState("");

    const closeModal = () => {
        setComment("");
        setReasonModal(false);
    };
    const submitReport = async () => {
        const res = await reportUserByEmail(comment, userPersonalDetails.emailAddress);
        if (res && res === "200 OK") {
            closeModal();
            getOtherUserProfileData(userPersonalDetails);
        }
    };
    return (
        <React.Fragment>
            <Dialog open={reasonModal} onClose={closeModal}>
                <DialogTitle>Provide Feedback</DialogTitle>
                <DialogContent>
                    <div style={{ width: "20rem" }}>
                        <textarea className="jobsifarish-textarea" rows="4" value={comment} onChange={(event) => setComment(event.target.value)}></textarea>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="normal-button" onClick={submitReport}>
                            Submit
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div
                className={styles.container}
                onClick={() => {
                    if (userPersonalDetails && !userPersonalDetails.is_user_reported) {
                        setReasonModal(true);
                    }
                }}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div className={styles.reportText}>{userPersonalDetails && userPersonalDetails.is_user_reported ? "User Reported" : "Report User"}</div>
                    <div>{userPersonalDetails && userPersonalDetails.is_user_reported ? <MdCheck color="#ff0000" /> : <MdReport color="#ff0000" />}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReportUserBox;
