import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import ImageContent from "../../content/ImageContent";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";
import { DataUtility } from "../../utility/DataUtility";

const ResumeNew1 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let imageContent = new ImageContent();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          let skills = res.data.skillList ? res.data.skillList.map((d) => d.skills) : [];
          // .skillset !== undefined ? res.data.skillset.split(',') : [];
          setSkillList(skills);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  let profileUrl = !personalData?.profileImageUrl ? imageContent.getProfilemage() : `https://jobsifarish.s3.ap-south-1.amazonaws.com/${personalData?.profileImageUrl}`;

  return (
    <div className="px_3" style={{ fontSize: "14px", paddingLeft: "0.8rem", paddingRight: "0.8rem", fontFamily: "'Open Sans', sans-serif", paddingTop: "1rem", paddingBottom: "1.rem" }}>
      <div className="ro_w" style={{ display: "flex" }}>
        <div
          className="col_4 pt_3 py_2 r_esume_new_1_left_container"
          style={{ width: "33.33%", paddingTop: "0.6rem", paddingBottom: "0.6rem", backgroundColor: "#161e28", color: "#fff", textAlign: "center" }}
        >
          <div className="p_rofile_image_resume_new_1 mt_4" style={{ margin: "auto", marginTop: "2rem", borderRadius: "50%", height: "150px", width: "150px", border: "1px solid wheat" }}>
            <img src={profileUrl} alt="profileimage" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
          </div>
          <div className="i_nfo_resume_new_1 mt-3" style={{ marginTop: "0.8rem", lineHeight: "1.1" }}>
            <p style={{ fontSize: "40px" }}>
              {personalData && personalData.firstName} {personalData && personalData.lastName}
            </p>
            <span style={{ color: "#f5740e", fontSize: "20px" }}>{professionalData.length > 0 ? professionalData[0].designation.toUpperCase() : ""}</span>
          </div>
          {personalData && personalData.about_me ? (
            <div className="r_esume_new_1_heading mt_5" style={{ marginTop: "3rem" }}>
              <h3 style={{ borderBottom: "1px solid white", height: "40px" }}>ABOUT ME</h3>
              <p>{personalData.about_me}</p>
            </div>
          ) : null}
          {skillList.length > 0 ? (
            <div className="r_esume_new_1_heading mt_5" style={{ marginTop: "1.5rem" }}>
              <h3 style={{ borderBottom: "1px solid white", height: "40px" }}>SKILL</h3>
              {skillList.length > 0
                ? skillList.map((res, index) => (
                    <div
                      className="r_esume_new_1_skills px_2 pt_4"
                      key={index}
                      style={{ textAlign: "left", paddingLeft: "0.6rem", paddingRight: "0.6rem", paddingTop: "1rem", width: "70%", backgroundColor: "#f5740e" }}
                    >
                      <span>{res.toUpperCase()}</span>
                      <div class="s_killsContainer" style={{ width: "100%", backgroundColor: "#ddd" }}>
                        <div class="s_kills h_tml" style={{ textAlign: "left", paddingBottom: "10px", color: "white", width: "60%" }}></div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
          <div className="r_esume_new_1_heading mt_5" style={{ marginTop: "1.5rem" }}>
            <h4 style={{ borderBottom: "1px solid white", height: "40px" }}>CONNECT WITH ME</h4>
            <span className="mt_2" style={{ marginTop: "0.6rem" }}>
              <p>Gmail:</p>
              <p>{personalData && personalData.emailAddress}</p>
            </span>
            <span className="mt_2" style={{ marginTop: "0.6rem" }}>
              <p>Mobile:</p>
              <p>{personalData && personalData.mobileNumber}</p>
            </span>
          </div>
        </div>
        <div className="col_8 r_esume_new_1_right_container" style={{ width: "66.66%", padding: "0px 30px" }}>
          <div className="r_esume_new_1_heading_right mt_5 d_flex" style={{ borderBottom: "1px solid black", marginTop: "1.5rem", display: "flex" }}>
            <img
              className="mr_2"
              src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+81.svg"
              alt="experince"
              height="16"
              style={{ marginTop: "3px", marginBottom: "3px", wordWrap: "break-word", marginRight: "0.6rem", height: "30px" }}
            />
            <span className="ml_2" style={{ marginLeft: "0.6rem", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", fontSize: "25px" }}>
              EXPERIENCE
            </span>
          </div>
          {professionalData.length > 0
            ? professionalData.map((res, index) => (
                <div className="r_ow" key={index} style={{ display: "flex" }}>
                  <div className="col_5" style={{ width: "41.67%" }}>
                    <div className="h_eading_item_resume1_new mt_3" style={{ marginTop: "0.8rem", lineHeight: "1.6" }}>
                      <span style={{ fontSize: "18px" }}>
                        {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                      </span>
                      <br />
                      <span className="mt_1" style={{ fontSize: "18px", marginTop: "0.3rem" }}>
                        {res.companyName}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col_7" style={{ width: "58.33%" }}>
                    <div className="h_eading_item_resume1_new mt_3" style={{ lineHeight: "1.6", marginTop: "0.8rem" }}>
                      <span style={{ fontSize: "18px" }}>{res.designation}</span>
                      <p className="mt-1">
                        Projects :- <br />{" "}
                        {res.projects_details.map((response, key) => (
                          <Fragment key={response.project_id}>
                            {key + 1}. {response.project_name} - {response.role_played} <br />
                            Tech Used - {response.tech_used} <br />
                            Project Url - {response.project_prod_url} <br />
                            {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                            <br />
                          </Fragment>
                        ))}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : null}
          <div
            className="r_esume_new_1_heading_right mt_5 d_flex"
            style={{ marginTop: "1.5rem", display: "flex", borderBottom: "1px solid black", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", lineHeight: "1.2" }}
          >
            <img
              className="mr_2"
              src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+99.svg"
              alt="experince"
              height="16"
              style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem", height: "30px" }}
            />
            <span className="ml_2" style={{ marginLeft: "0.6rem", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", fontSize: "25px" }}>
              EDUCATION
            </span>
          </div>
          {educationData.length > 0
            ? educationData.map((res, index) => (
                <div className="r_ow" key={index} style={{ display: "flex" }}>
                  <div className="col_5" style={{ width: "41.67%" }}>
                    <div className="h_eading_item_resume1_new mt_3" style={{ lineHeight: "1.6", marginTop: "0.8rem" }}>
                      <span style={{ fontSize: "18px" }}>
                        {res.session_start} - {res.session_end}
                      </span>
                      <br />
                      <span className="mt_1" style={{ marginTop: "0.3rem", fontSize: "18px" }}>
                        {res.schoolName}, {res.location}
                      </span>
                    </div>
                  </div>
                  <div className="col_7" style={{ width: "58.33%" }}>
                    <div className="h_eading_item_resume1_new mt_3" style={{ marginTop: "0.8rem", lineHeight: "1.6" }}>
                      <span style={{ fontSize: "18px" }}>{res.educationType}</span>
                      <p className="mt_1" style={{ marginTop: "0.3rem" }}></p>
                      {res.boardName} <br />
                      with {getPercentage(res.scoredMarks, res.totalMarks)} %
                    </div>
                  </div>
                </div>
              ))
            : null}
          <div
            className="r_esume_new_1_heading_right mt_5 d_flex"
            style={{ borderBottom: "1px solid black", marginTop: "1.5rem", display: "flex", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", lineHeight: "1.2" }}
          >
            <img
              className="mr_2"
              src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+100.svg"
              alt="experince"
              height="16"
              style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem", height: "30px" }}
            />
            <span className="ml_2" style={{ marginLeft: "0.6rem", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", fontSize: "25px" }}>
              ORGANIZATION
            </span>
          </div>
          {professionalData.length > 0
            ? professionalData.map((res, index) => (
                <h5 className="mt_3" key={index} style={{ marginTop: "0.8rem" }}>
                  {res.companyName}{" "}
                  <span className="text-secondary">
                    ({DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                    {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()})
                  </span>
                </h5>
              ))
            : null}

          {personalData && personalData.achievement_details.length > 0 && (
            <div className="resume_2_new_content mt-1 ">
              <div
                className="r_esume_new_1_heading_right mt_5 d_flex mb_2"
                style={{
                  borderBottom: "1px solid black",
                  marginTop: "1.5rem",
                  display: "flex",
                  marginBottom: "0.6rem",
                  fontFamily: 'Open Sans", sans-serif',
                  fontWeight: "500",
                  lineHeight: "1.2",
                }}
              >
                <img
                  className="mr_2"
                  src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+85.svg"
                  alt="experince"
                  height="16"
                  style={{ marginTop: "3px", wordWrap: "break-word", marginRight: "0.6rem", height: "30px" }}
                />
                <span className="ml_2" style={{ marginLeft: "0.6rem", fontFamily: 'Open Sans", sans-serif', marginBottom: "0.5rem", fontWeight: "500", fontSize: "25px" }}>
                  HONOURS/AWARDS
                </span>
              </div>
              {personalData.achievement_details.map((item, id) => (
                <>
                  <p key={item.achievement_id}>
                    {id + 1}- {item.achievement}
                  </p>
                  <br />
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeNew1;
