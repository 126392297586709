import React, { useEffect } from "react";
import styles from "./viewJob.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { convertDateDDMMYYYY, getJobDataWithJobId, handleGetProfileAndGoThere, toasterControl } from "../../Services/commonService";
import { useSelector } from "react-redux";
import { PiWarningCircleFill, PiWarningCircleLight } from "react-icons/pi";
import { BsBookmarkPlus, BsFillBookmarkCheckFill } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { delReq, postTReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { TbClockFilled } from "react-icons/tb";

const ViewJob = () => {
    const { singleJobData } = useSelector((state) => state.jobslice);
    const { personalDetails } = useSelector((state) => state.persist);
    const { jobId } = useParams();

    const navigateTo = useNavigate();

    const getFileUploadDurationString = (timeString) => {
        let currentTimeString = new Date().getTime();
        let timeDifferenceUnix = currentTimeString - timeString;
        let timeDifferenceHours = timeDifferenceUnix / 1000 / 60 / 60;
        let timeDifferenceDays = Math.floor(timeDifferenceHours / 24);
        let dateString = convertDateDDMMYYYY(timeString);
        if (timeDifferenceHours < 24) {
            return "Today";
        } else if (timeDifferenceHours > 24 && timeDifferenceHours < 48) {
            return "1 day ago";
        } else if (timeDifferenceDays < 30) {
            return `${timeDifferenceDays} days ago`;
        } else {
            return `${dateString}`;
        }
    };

    const applyToThisJob = async () => {
        const res = await postTReq(apiEndpoints.applyJob, {
            job_id: jobId,
            message: "I am interested.",
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Applied to the job");
            getJobDataWithJobId(jobId);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const bookmarkThisJob = async () => {
        const res = await postTReq(apiEndpoints.bookmarkJob, {
            job_id: jobId,
        });
        if (res && !res.error) {
            getJobDataWithJobId(jobId);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };
    const removeThisBookmark = async () => {
        const res = await delReq(apiEndpoints.removeBookmark, {
            job_bookmarked_id: singleJobData.job_bookmarked_id,
        });
        if (res && !res.error) {
            getJobDataWithJobId(jobId);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    const reportJob = async () => {
        const res = await postTReq(apiEndpoints.reportJob, {
            job_id: singleJobData.job_id,
        });
        if (res && !res.error) {
            console.log(res);
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        getJobDataWithJobId(jobId);
    }, [jobId]);
    return (
        <div className="main-content-container-flexible p-3">
            {personalDetails?.emailAddress !== singleJobData?.user_details?.emailAddress && (
                <div className="d-flex justify-content-end mb-3">
                    {singleJobData && singleJobData.job_applied_status && Object.keys(singleJobData.job_applied_status).length > 0 ? (
                        <button className="normal-done-button">Already Applied</button>
                    ) : (
                        <button className="normal-button" onClick={() => applyToThisJob()}>
                            Apply For Job
                        </button>
                    )}
                </div>
            )}
            <div className={styles.jobContainer}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                        <div className={styles.jobTitle}>
                            <div>{singleJobData && singleJobData.jobTitle && singleJobData.jobTitle.toUpperCase()}</div>
                            <div className={styles.joiningType}>{singleJobData && singleJobData.joiningType && singleJobData.joiningType}</div>
                        </div>
                        <div className={styles.companyName}>{singleJobData && singleJobData.companyName && singleJobData.companyName}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Tooltip title="Report Job">
                            <div className={`${styles.reportButton} me-2`} onClick={reportJob}>
                                {true ? <PiWarningCircleLight fontSize={24} /> : <PiWarningCircleFill fontSize={24} />}
                            </div>
                        </Tooltip>

                        {singleJobData && singleJobData.job_bookmarked_id ? (
                            <Tooltip title="Remove Bookmark">
                                <div className={styles.actionButton} onClick={removeThisBookmark}>
                                    <BsFillBookmarkCheckFill fontSize={20} />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Bookmark This Job">
                                <div className={styles.actionButton} onClick={bookmarkThisJob}>
                                    <BsBookmarkPlus fontSize={20} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-wrap mb-3">
                    {singleJobData &&
                        singleJobData.skillset &&
                        singleJobData.skillset.length > 0 &&
                        singleJobData.skillset.map((curElem) => {
                            return <div className={styles.skillPill}>{curElem.skill}</div>;
                        })}
                </div>
                <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex align-items-center" style={{ width: "calc(100% / 3)" }}>
                        <div className={styles.dataLabel}>Experience Required:</div>
                        <div className={styles.dataValue}>{singleJobData && singleJobData.expRequired && `${singleJobData.expRequired} Years`}</div>
                    </div>
                    <div className="d-flex align-items-center" style={{ width: "calc(100% / 3)" }}>
                        <div className={styles.dataLabel}>Category:</div>
                        <div className={styles.dataValue}>{singleJobData && singleJobData.jobStreams && singleJobData.jobStreams}</div>
                    </div>
                    <div className="d-flex align-items-center" style={{ width: "calc(100% / 3)" }}>
                        <div className={styles.dataLabel}>Location:</div>
                        <div className={styles.dataValue}>{singleJobData && singleJobData.location && singleJobData.location}</div>
                    </div>
                    <div className="d-flex align-items-center" style={{ width: "calc(100% / 3)" }}>
                        <div className={styles.dataLabel}>Package Offered:</div>
                        <div className={styles.dataValue}>{singleJobData && singleJobData.offerPackage && singleJobData.offerPackage} Lakhs</div>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <div className={styles.dataLabel}>Job Category:</div>
                    <div className="d-flex align-items-center">
                        {singleJobData &&
                            singleJobData.job_type_details &&
                            singleJobData.job_type_details.length > 0 &&
                            singleJobData.job_type_details.map((curElem) => {
                                return (
                                    <div key={curElem.job_type} className={styles.jobTypePill}>
                                        {curElem.job_type}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className={styles.uploadTime}>
                        <TbClockFilled fontSize={16} className="me-1" />
                        {singleJobData && singleJobData.date && getFileUploadDurationString(singleJobData.date)}
                    </div>
                </div>
            </div>
            <div className={styles.jobContainer}>
                <div className="mb-2">
                    <div className={styles.boxDataLabel}>Description:</div>
                    <div className={styles.boxDataValue}>{singleJobData && singleJobData.description && singleJobData.description}</div>
                </div>
                <div>
                    <div className={styles.boxDataLabel}>Role:</div>
                    <div className={styles.boxDataValue}>{singleJobData && singleJobData.role && singleJobData.role}</div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-end">
                <div>
                    <div className={styles.boxDataLabel}>Uploaded By:</div>
                    <div className={styles.extraDataBox} onClick={() => handleGetProfileAndGoThere(singleJobData.user_details, navigateTo)}>
                        <div className={styles.uploaderImage}>
                            {singleJobData && singleJobData.user_details && singleJobData.user_details.profileImageUrl && <img src={singleJobData.user_details.profileImageUrl} alt="" />}
                        </div>
                        <div>
                            <div className={styles.uploaderName}>{singleJobData && singleJobData.user_details && singleJobData.user_details.fullName}</div>
                            <div className={styles.uploaderDesignation}>{singleJobData && singleJobData.user_details && singleJobData.user_details.job_profile_name}</div>
                        </div>
                    </div>
                </div>
                {personalDetails?.emailAddress !== singleJobData?.user_details?.emailAddress && (
                    <div>
                        {singleJobData && singleJobData.job_applied_status && Object.keys(singleJobData.job_applied_status).length > 0 ? (
                            <button className="normal-done-button">Already Applied</button>
                        ) : (
                            <button className="normal-button" onClick={() => applyToThisJob()}>
                                Apply For Job
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewJob;
