import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toasterController } from "../../Redux/Slices/toasterSlice";

const PleaseSelectChat = () => {
    const dispatch = useDispatch();
    const { pleaseSelectChat } = useSelector((state) => state.toaster);
    return (
        <ToastContainer className="toaster-top-right">
            <Toast show={pleaseSelectChat === true} bg="warning" onClose={() => dispatch(toasterController({ toaster: "pleaseSelectChat", state: false }))} autohide delay={4000}>
                <Toast.Body style={{ color: "#ffffff" }}>Please select someone to chat to.</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default PleaseSelectChat;
