import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toasterController } from "../../Redux/Slices/toasterSlice";

const SomethingWentWrong = () => {
  const dispatch = useDispatch();
  const { somethingWentWrong } = useSelector((state) => state.toaster);
  return (
    <ToastContainer className="toaster-top-right">
      <Toast show={somethingWentWrong === true} bg="danger" onClose={() => dispatch(toasterController({ toaster: "somethingWentWrong", state: false }))} autohide delay={4000}>
        <Toast.Body style={{ color: "#ffffff" }}>Oops! Something went wrong.</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default SomethingWentWrong;
