import React, { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import AuthContext from "../../context/authContext";
import Footer from "../Footer/Footer";
import { noFooterRoutes } from "../../Services/commons";
import { routingArray } from "../../Services/routing";

const Navigation = () => {
  const path = useLocation();
  const context = useContext(AuthContext);
  return (
    <React.Fragment>
      <Header />
      <Routes>
        {routingArray.map((curElem) => {
          if (!curElem.always && context.authenticatedStatus) {
            return <Route exact path={curElem.route} element={curElem.element} />;
          }
          return <Route exact path={curElem.route} element={curElem.element} />;
        })}
      </Routes>
      {!noFooterRoutes.includes(path.pathname) && <Footer />}
    </React.Fragment>
  );
};

export default Navigation;
