import React, { useCallback, useEffect, useState } from "react";
import styles from "./adminPanel.module.css";
import { BsPeopleFill, BsPersonFillExclamation } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import VerificationTable from "./VerificationTable/VerificationTable";
import UserListTable from "./UserTable/UserListTable";
import ReportedUser from "./ReportedUser/ReportedUser";
import { PiChatsCircleFill } from "react-icons/pi";
import UserChats from "./UserChats/UserChats";
import { MdAttachMoney } from "react-icons/md";
import { Tooltip } from "@mui/material";
import PaymentsView from "./PaymentsView/PaymentsView";
import { getReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { useNavigate } from "react-router-dom";
import { toasterControl } from "../../Services/commonService";

const AdminPanel = () => {
    const navigateTo = useNavigate();
    const componentObj = {
        verification: <VerificationTable />,
        userList: <UserListTable />,
        reportedUser: <ReportedUser />,
        userChat: <UserChats />,
        payments: <PaymentsView />,
    };
    const [activeItem, setActiveItem] = useState("verification");

    const checkRole = useCallback(async () => {
        const res = await getReq(apiEndpoints.checkUserRole);
        if (res && !res.error) {
            console.log(res.data);
            switch (res.data.data.role) {
                case "ROLE_USER":
                    toasterControl("errorToaster", true, "You are unauthorized to view this page");
                    navigateTo("/profile");
                    break;

                default:
                    break;
            }
        } else {
            console.log(res.error);
        }
    }, [navigateTo]);
    useEffect(() => {
        checkRole();
    }, [checkRole]);
    return (
        <div className="main-content-container">
            <div className={styles.container}>
                <div className={styles.leftSidebar}>
                    <div className={`${styles.eachIconContainer} ${activeItem === "verification" ? styles.active : ""}`} onClick={() => setActiveItem("verification")}>
                        <Tooltip title="Liver Session User Verification">
                            <div className={styles.eachIconBox}>
                                <BsPeopleFill />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`${styles.eachIconContainer} ${activeItem === "userList" ? styles.active : ""}`} onClick={() => setActiveItem("userList")}>
                        <Tooltip title="User List">
                            <div className={styles.eachIconBox}>
                                <AiOutlineUser />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`${styles.eachIconContainer} ${activeItem === "reportedUser" ? styles.active : ""}`} onClick={() => setActiveItem("reportedUser")}>
                        <Tooltip title="Reported Users">
                            <div className={styles.eachIconBox}>
                                <BsPersonFillExclamation />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`${styles.eachIconContainer} ${activeItem === "userChat" ? styles.active : ""}`} onClick={() => setActiveItem("userChat")}>
                        <Tooltip title="User Chats">
                            <div className={styles.eachIconBox}>
                                <PiChatsCircleFill />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`${styles.eachIconContainer} ${activeItem === "payments" ? styles.active : ""}`} onClick={() => setActiveItem("payments")}>
                        <Tooltip title="Payments">
                            <div className={styles.eachIconBox}>
                                <MdAttachMoney />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.contentContainer}>{componentObj[activeItem]}</div>
            </div>
        </div>
    );
};

export default AdminPanel;
