import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageContent from "../../content/ImageContent";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import ErrorModal from "../Modal/ErrorModal";

const ResumeSelector = (props) => {
    let navigateTo = useNavigate();
    let imageContent = new ImageContent();
    let serviceProvider = new ServiceProvider();
    let headerUtility = new HeaderUtility();
    let header = headerUtility.returnHeaderContainingToken();
    let [show, setShow] = useState(false);
    const resumeList = [
        // {
        //     id: 1,
        //     img: imageContent.getResumeImgOne()
        // },
        // {
        //     id: 2,
        //     img: imageContent.getResumeImgTwo()
        // },
        // {
        //     id: 3,
        //     img: imageContent.getResumeImgThree()
        // },
        // {
        //     id: 4,
        //     img: imageContent.getResumeImgFour()
        // },
        // {
        //     id: 5,
        //     img: imageContent.getResumeImgSeven()
        // },
        // {
        //     id: 6,
        //     img: imageContent.getResumeImgFive()
        // },
        // {
        //     id: 7,
        //     img: imageContent.getResumeImgSix()
        // },
        // {
        //     id: 8,
        //     img: imageContent.getResumeImgEight()
        // },
        // {
        //     id: 9,
        //     img: imageContent.getResumeImgNine()
        // },
        {
            id: 10,
            img: imageContent.getResume1_new(),
        },
        {
            id: 11,
            img: imageContent.getResume2_new(),
        },
        {
            id: 12,
            img: imageContent.getResume3_new(),
        },
        // {
        //     id: 13,
        //     img: imageContent.getResumeImgFour()
        // },
        {
            id: 16,
            img: imageContent.getResumeImgNine(),
        },
        {
            id: 17,
            img: imageContent.getResumeImgSix(),
        },
        // {

        //     id: 14,
        //     img: imageContent.getResumeImgFive()
        // },
        {
            id: 15,
            img: imageContent.getResumeImgSeven(),
        },
    ];
    const isUserProfileValidated = (url) => {
        serviceProvider
            .returnProfileService()
            .validateUserProfile(header)
            .then((res) => {
                if (res.status === "200 OK") {
                    setShow(!res.data.is_profile_completed);
                    if (res.data.is_profile_completed) {
                        navigateTo(url);
                    }
                    setTimeout(() => {
                        setShow(false);
                    }, 5000);
                }
            });
    };
    const returnResumeList = () => {
        return resumeList.map((resume, index) => {
            return (
                <div className="col-xs-12 col-sm-12 col-md-4" key={index}>
                    <div
                        className="ml-4 mr-4 mb-4 jobCards"
                        onClick={() => {
                            isUserProfileValidated("/resumeBuilder/" + resume.id);
                        }}
                    >
                        <img src={resume.img} style={{ height: "60vmin", width: "100%" }} alt="" />
                    </div>
                </div>
            );
        });
    };
    return (
        <React.Fragment>
            <ErrorModal
                icon="text-danger"
                title="Warning"
                message={`This is a required part for creating and accessing your Resume.So fill all details of your Educational background.`}
                isShow={show}
                handleClose={() => {
                    setShow(false);
                }}
            />
            <div className="container-fluid" style={{ background: "#FBFBFF", marginTop: "4rem" }}>
                <div className="row pl-5">
                    <div className="pl-5 pt-5 pb-0">
                        <p className="pl-1 screenTitle font-3">Select Your Resume</p>
                        <p>We have already made it only for you</p>
                    </div>
                </div>
                <div className="row ml-5 mr-5">{returnResumeList()}</div>
            </div>
        </React.Fragment>
    );
};

export default ResumeSelector;
