import React, { useContext, useEffect, useMemo, useState } from "react";
import "../../style/Welcome.css";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import AuthContext from "../../context/authContext";

const Welcome = (props) => {
  let context = useContext(AuthContext);
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    const getUserFirstAndLastName = () => {
      let header = headerUtility.returnHeaderContainingToken();
      let profileService = serviceProvider.returnProfileService();
      profileService
        .getPersonalDetail(header)
        .then((res) => {
          if (res.status === "200 OK") {
            setFirstName(res.data.firstName);
            setLastName(res.data.lastName);
            localStorage.setItem("fname", res.data.firstName);
            localStorage.setItem("lname", res.data.lastName);
            localStorage.setItem("profileUrl", res.data.profileImageUrl);
            context.setFirstName(res.data.firstName);
            context.setLastName(res.data.lastName);
            context.setprofilePic(res.data.profileImageUrl);
            props.updateLoader(false);
          }
        })
        .catch((err) => {
          props.updateLoader(false);
        })
        .finally(() => {
          props.updateLoader(false);
        });
    };
    getUserFirstAndLastName();
  }, [context, headerUtility, props, serviceProvider]);

  return (
    <div className="row createProfileSideBar pb-0" id="createProfileSideBar">
      <div className="container text-center">
        <div className="row welcomeContainer pb-0">
          <div className="col-6 mx-0 px-0">
            <div className="orangeOuterDiv">
              <div className="orangeTriangleLeftTop"></div>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 d-flex justify-content-end">
            <div className="orangeOuterDiv">
              <div className="orangeTriangleRightTop"></div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
            <h2 style={{ color: "rgb(241, 187, 70)" }}>Welcome To</h2>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
            <h1 style={{ color: "rgb(241, 187, 70)" }}>JobSifarish.com</h1>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
            <h4>
              {firstName} {lastName}
            </h4>
          </div>
          <div className="col-6 mx-0 px-0">
            <div className="orangeOuterDiv">
              <div className="orangeTriangleLeftBottom"></div>
            </div>
          </div>
          <div className="col-6 mx-0 px-0 d-flex justify-content-end">
            <div className="orangeOuterDiv">
              <div className="orangeTriangleRightBottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
