import { Button } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import ImageContent from "../../content/ImageContent";
// import ApplicantsModal from '../../component/Modal/ApplicantsModal'
import "../../style/PersonalDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";

let imageContent = new ImageContent();
const JobInfo = () => {
  const [jsonData, setJsonData] = useState({});
  const [jobId, setJobId] = useState("");
  const [users, setUsers] = useState([]);
  const params = useParams();
  const serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  const headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const navigateTo = useNavigate();
  const returnSkillList = () => {
    if (jsonData?.skillset !== undefined) {
      return jsonData?.skillset.map((item, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"} key={item.skill_id}>
              <span>{item.skill}</span>
            </div>
          </React.Fragment>
        );
      });
    }
  };

  useEffect(() => {
    setJobId(params.id);
    serviceProvider
      .returnJobService()
      .viewJob(headerUtility.returnHeaderContainingToken(), { job_id: params.id })
      .then((res) => {
        if (res.status === "200 OK") {
          let json = { ...res.data };
          if (json.user_details !== undefined) {
            if (json.user_details.profileImageUrl !== undefined) {
              let profileUrl = "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + json.user_details.profileImageUrl;
              json.user_details.profileImageUrl = profileUrl;
            }
          }
          setJsonData(json);
        }
      });
    const getUserList = (id) => {
      serviceProvider
        .returnJobService()
        .getAppliedJobUserList(headerUtility.returnHeaderContainingToken(), {
          job_id: id,
          page_no: 0,
          no_of_records: 5,
        })
        .then((res) => {
          if (res.status === "200 OK") {
            setUsers(res.data);
          }
        });
    };
    getUserList(params.id);
  }, [headerUtility, params.id, serviceProvider]);

  return (
    <div className="container-fluid pageBody">
      {/* <ApplicantsModal
            job_id={jsonData.job_id}
            users={users}
            isShow={toggle}
            handleClose={() => setToggle(false)}
         /> */}
      <div className="row pl-md-3">
        <div className="col-12 pl-md-4 pt-3">
          <span
            onClick={() => {
              navigateTo(-1);
            }}
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            &nbsp;&nbsp;Back
          </span>
        </div>
      </div>
      <div className="row pt-2 pl-md-4">
        <div className="col-xs-12 col-sm-12 col-md-8">
          <div className="ant-card">
            <div className="ant-card-bodyMinor">
              <div className="row pb-0">
                <div className="col-12">
                  <span className="immediateJoiningTiltle">{jsonData?.joiningType}</span>
                </div>
                <div className="col-12 pt-2">
                  <h5>
                    <b>{jsonData?.jobTitle}</b>
                  </h5>
                </div>
                <div className="col-12 pt-2">
                  <div className="row px-1 pb-2">{returnSkillList()}</div>
                </div>
                <div className="col-12 pt-2">
                  <div className="row px-1 pb-0">
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img src={imageContent.getOrgImage()} alt="" />
                      <span>&nbsp;&nbsp;Org:{jsonData?.companyName}</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img src={imageContent.getExpImage()} alt="" />
                      <span>&nbsp;&nbsp;Exp:{jsonData?.expRequired}Yr</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img src={imageContent.getLocImage()} alt="" />
                      <span>&nbsp;&nbsp;Place:{jsonData?.location}</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <span className="dollarIcon">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                      </span>
                      <span>&nbsp;&nbsp;Offer Package:{jsonData?.offerPackage} lacs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-card-bodyMinor">
              <div className="row">
                <div className="col-12">
                  <h5>
                    <b>Job Overview</b>
                  </h5>
                </div>
                <div className="col-12 pt-3">
                  <span>{jsonData?.description}</span>
                </div>
              </div>
            </div>
            <div className="ant-card-bodyMinor">
              <div className="row">
                <div className="col-12">
                  <h5>
                    <b>Roles And Responsibility</b>
                  </h5>
                </div>
                <div className="col-12">{jsonData?.role}</div>
                {/* {returnTextList()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 pl-md-3">
          <div className="ant-card">
            <div className="ant-card-bodyMinor">
              <div className="row pb-0">
                <div className="col-12">
                  <h6>
                    <b>{users.length} Applicants applied</b>
                  </h6>
                </div>
                <div className="col-12 pt-3">
                  <Button className="viewApplicants" onClick={() => navigateTo("/applicants/" + jobId)}>
                    View applicants
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfo;
