import React, { useEffect } from "react";
import styles from "./peopleYouMayKnow.module.css";
import { useSelector } from "react-redux";
import { getPersonalDetails, getSuggestedUsers, handleGetProfileAndGoThere } from "../../Services/commonService";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const PeopleYouMayKnow = () => {
    const navigateTo = useNavigate();
    const { suggestedConnections, personalDetails } = useSelector((state) => state.persist);

    useEffect(() => {
        getPersonalDetails();
    }, []);
    useEffect(() => {
        getSuggestedUsers(0, 10, [personalDetails.city]);
    }, [personalDetails]);

    return (
        <div className={styles.container}>
            {suggestedConnections &&
                suggestedConnections.map((curElem) => {
                    return (
                        <div className={styles.eachCard} onClick={() => handleGetProfileAndGoThere(curElem, navigateTo)}>
                            <div className={styles.coverImage}>
                                <div className={styles.profileImage}>{curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                            </div>
                            <div className={styles.cardContent}>
                                <div>
                                    <div className={styles.profileName}>{curElem.fullName ? curElem.fullName : "-"}</div>
                                    <div className={styles.designationName}>{`${curElem.designation ? curElem.designation : "-"} ${curElem.companyName ? "at" : ""} ${
                                        curElem.companyName ? curElem.companyName : ""
                                    }`}</div>
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.sectionName}>Location:</div>
                                        <div className={styles.sectionValue}>{curElem.location ? curElem.location : "-"}</div>
                                    </div>
                                    <div className={styles.sectionContainer}>
                                        <div className={styles.sectionName}>Joining type:</div>
                                        <div className={styles.sectionValue}>{curElem.joiningType ? curElem.joiningType : "-"}</div>
                                    </div>
                                </div>
                                <div className="action-button-container">
                                    <button className="action-button-reject">
                                        <AiOutlineClose />
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PeopleYouMayKnow;
