import React, { useEffect, useRef, useState } from "react";
import styles from "./headerMenuItem.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { accordionToggler } from "../../Redux/Slices/noPersistSlice";

const HeaderMenuItem = ({ data, location, closeDrawer }) => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { openedAccordion } = useSelector((state) => state.nopersist);
    const { chatHistory } = useSelector((state) => state.chatting);

    const subItemsRef = useRef(null);

    const [messageCount, setMessageCount] = useState(0);

    const goToPage = (path, label) => {
        closeDrawer();
        if (path) {
            navigateTo(path);
        } else {
            if (data.route) {
                navigateTo(data.route);
            } else {
                // toggleAccordion();
                dispatch(accordionToggler(label));
            }
        }
    };

    useEffect(() => {
        if (chatHistory) {
            let totalUnread = 0;
            chatHistory.forEach((curElem) => {
                if (curElem.unreadMessages) {
                    totalUnread = totalUnread + curElem.unreadMessages.length;
                }
            });
            setMessageCount(totalUnread);
        }
    }, [chatHistory]);

    return (
        <>
            <div className={location.pathname === data.route ? styles.eachSidebarLinkActive : styles.eachSidebarLink} onClick={() => goToPage(false, data.label)}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div className={styles.iconContainer} style={{ color: location.pathname === data.route ? "#353535" : "#ffffff" }}>
                        {data.icon}
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className={styles.labelContainer}>{data.label}</div>
                        {data.label === "Message" && messageCount > 0 && <div className={styles.unreadMessagesCount}>{messageCount}</div>}
                    </div>
                </div>
                {data.children && data.children.length > 0 && (
                    <>
                        <BsChevronRight fontSize={12} className={`${styles.arrow} ${openedAccordion === data.label ? styles.arrowDown : styles.arrowRight}`} />
                    </>
                )}
            </div>
            <div
                ref={subItemsRef}
                className={`${styles.subItemsContainer} ${openedAccordion === data.label ? styles.open : styles.close}`}
                style={{ maxHeight: openedAccordion === data.label ? `${subItemsRef.current && subItemsRef.current.scrollHeight}px` : "0px" }}
            >
                {data.children &&
                    data.children.map((cur) => {
                        return (
                            <div key={cur.label} className={location.pathname === cur.route ? styles.eachSidebarSubLinkActive : styles.eachSidebarSubLink} onClick={() => goToPage(cur.route, false)}>
                                <div className={styles.labelContainer}>{cur.label}</div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default HeaderMenuItem;
