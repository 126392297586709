import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../../style/Resume.css';

const ShiviResume = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [educationData, setEducationData] = useState([]);
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };

    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          setEducationData(res.data);
          getProfessionalDetails();
        }
      });
    };

    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          var skillsset = res.data.skillset ? res.data.skillset : [];
          setSkillList(skillsset);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  const getPercentage = (a, b) => {
    return ((100 * a) / b).toFixed(2);
  };

  return (
    <React.Fragment>
      <div
        className="container_fluid pt_4 resume8_Container"
        style={{ display: "flex", flexDirection: "column", fontSize: "14px", paddingTop: "2.25rem", width: "100%", fontFamily: "'Lato', sans-serif" }}
      >
        <div
          className="name_Wrapper p_5"
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            textAlign: "center",
            border: "5.25px solid #9c99c9",
            textTransform: "uppercase",
            paddingTop: "1.25rem",
            paddingBottom: "2rem",
            marginLeft: "2rem",
            marginRight: "2rem",
          }}
        >
          {personalData && personalData.firstName} {personalData && personalData.lastName}
        </div>
        <div
          className="resume8_Designtion"
          style={{ marginTop: "-24px", marginRight: "auto", marginBottom: "0px", marginLeft: "auto", width: "30%", fontSize: "25px", fontWeight: "600", backgroundColor: "white", textAlign: "center" }}
        >
          {professionalData.length > 0 ? professionalData[0].designation : ""}
        </div>
        <div className="ro_w" style={{ display: "flex" }}>
          <div className="col_4" style={{ width: "33.33%", marginLeft: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.5rem" }}>
            {skillList.length > 0 ? (
              <>
                <h4 className="resume8_Heading mt_3" style={{ marginTop: "0.8rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
                  Skills
                </h4>
                <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "25px" }} />
                <ul className="resume8_Skills" style={{ listStyleType: "square", lineHeight: "28px" }}>
                  {skillList.length > 0 ? skillList.map((res, index) => <li key={index}>{res.skill}</li>) : null}
                </ul>
              </>
            ) : null}
            {personalData && personalData.language && personalData.language.length > 0 ? (
              <>
                <h4 className="resume8_Heading " style={{ marginTop: "0.50rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
                  Languages
                </h4>
                <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "25px" }} />
              </>
            ) : null}

            {personalData &&
              personalData.language &&
              personalData.language.map((data, index) => (
                <div className="skills_Bar_8 mt_3" style={{ marginTop: "0.50rem" }} key={index}>
                  <div className="skill_Label_8" style={{ fontSize: "16px", fontWeight: "600" }}>
                    {data}
                  </div>
                  <div class="container_Skill_8 mt_2" style={{ backgroundColor: "transparent", border: "1px solid #9c99c9", height: ".50rem", width: "90%", marginTop: ".60rem" }}>
                    <div class="Skill_8 Html_8" style={{ backgroundColor: "rgb(156, 153, 201)", border: "1px solid #9c99c9", height: ".50rem", width: "60%" }}></div>
                  </div>
                </div>
              ))}
            {/* <div className='skills_bar_8 mt-3'>
                            <div className='skill_label_8'>Spanish Language</div>
                            <div class="container_skill_8 mt-2">
                                <div class="skill_8 html_8"></div>
                            </div>
                        </div>
                        <div className='skills_bar_8 mt-3'>
                            <div className='skill_label_8'>German Language</div>
                            <div class="container_skill_8 mt-2">
                                <div class="skill_8 html_8"></div>
                            </div>
                        </div> */}
            <h4 className="resume8_Heading mt_4" style={{ marginTop: "1rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
              Contact
            </h4>
            <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "25px" }} />
            <div className="resume8_Contact" style={{ lineHeight: "0.8", fontSize: "15px", wordBreak: "break-all" }}>
              <h6 style={{ fontSize: "1rem" }}>{personalData && personalData.mobileNumber}</h6>
              <p>{personalData && personalData.emailAddress}</p>
              <p style={{ lineHeight: 1 }}>
                {" "}
                {personalData && personalData.address}, {personalData && personalData.city}, {personalData && personalData.country}
              </p>
            </div>
            <h4 className="resume8_heading mt-4" style={{ fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
              Organization
            </h4>
            <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "25px" }} />
            {professionalData.length > 0
              ? professionalData.map((res, index) => (
                  <div className="resume8_Org" style={{ lineHeight: "0.8", fontSize: "15px", wordBreak: "break-all" }}>
                    <p>{res.companyName}</p>
                    <p>
                      {DataUtility.getMonth(new Date(res.joined_from).getMonth()) + "-" + new Date(res.joined_from).getFullYear()}-
                      {res.worked_till === 0 || !res.worked_till ? "Present" : DataUtility.getMonth(new Date(res.worked_till).getMonth()) + "-" + new Date(res.worked_till).getFullYear()}
                    </p>
                  </div>
                ))
              : null}
            {/* <div className='resume8_org mt-3'>
                            <p>American Management Association</p>
                            <p>2012 - Present</p>
                        </div> */}
          </div>

          <div className="col_8" style={{ width: "66.67%", paddingRight: "1.25rem" }}>
            {personalData && personalData.about_me ? (
              <>
                <h4 className="resume8_Heading mt_3" style={{ marginTop: ".75rem", marginLeft: "2.75rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
                  Profile
                </h4>
                <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "20px", marginLeft: "2.75rem" }} />
                <p style={{ marginLeft: "2.75rem" }}>{personalData && personalData.about_me}</p>
              </>
            ) : null}
            <h4 className="resume8_Heading mt_4" style={{ marginTop: "1rem", marginLeft: "2.75rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }}>
              Experience
            </h4>
            <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginLeft: "2.75rem" }} />
            <div className="left_Border" style={{ borderLeft: "4px solid #9c99c9" }}>
              <ul className="resume8_Bullets" style={{ paddingTop: "0.50rem", listStyleType: "square" }}>
                {professionalData.length > 0
                  ? professionalData.map((res, index) => (
                      <li>
                        <h5 style={{ marginTop: "-5px", fontSize: "1.25rem" }}>
                          {res.designation}, {res.companyName} <br />
                          <span style={{ fontSize: "1.0rem" }}> {res.location}</span>
                        </h5>
                        <span>
                          {new Date(res.joined_from).getFullYear()}-{res.worked_till === 0 || !res.worked_till ? "Present" : new Date(res.worked_till).getFullYear()}
                        </span>
                        <p style={{ marginBottom: "1.75rem" }}>
                          Projects :- <br />{" "}
                          {res.projects_details.map((response, key) => (
                            <Fragment key={response.project_id}>
                              {key + 1}. {response.project_name} - {response.role_played} <br />
                              Tech Used - {response.tech_used} <br />
                              Project Url - {response.project_prod_url} <br />
                              {new Date(response.started_from).getFullYear()} - {new Date(response.end_on).getFullYear()}
                              <br />
                            </Fragment>
                          ))}
                        </p>
                      </li>
                    ))
                  : null}

                <li className="mt_4" style={{ marginTop: "0.50rem" }}>
                  <h4 style={{ marginTop: "-25px", paddingTop: "0.75rem", fontSize: "1.5rem", fontFamily: "'Lato', sans-serif" }} className="resume8_Heading">
                    Education
                  </h4>
                  <hr className="Devider_8" style={{ height: "3px", backgroundColor: "#9c99c9", marginRight: "20px", marginTop: ".55rem" }} />
                </li>
                {educationData.length > 0
                  ? educationData.map((res, index) => (
                      <li key={index}>
                        <h5 style={{ marginTop: "0.25rem", paddingTop: "0.25rem", fontSize: "1.25rem" }}>
                          {res.educationType}, {res.boardName}
                        </h5>
                        <span>
                          {res.session_start} - {res.session_end}
                        </span>
                        <p>
                          {res.schoolName}, {res.location} <br />
                          with {getPercentage(res.scoredMarks, res.totalMarks)} %
                        </p>
                      </li>
                    ))
                  : null}

                {/* <li className='mt-4'>
                            <h4  style={{ marginTop: '-25px' }} className='resume8_heading '>Awards</h4> 
                        < hr className='devider_8' />
                            </li>
                            <li>
                                
                                <h5 style={{ marginTop: '-25px' }}>
                                Best Award Name 2019 
                                </h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                                
                            </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShiviResume;
