import React, { useState } from "react";
import styles from "./requestLiveSessionBox.module.css";
import { VscVerifiedFilled } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { TbClockCheck } from "react-icons/tb";
import { getPersonalDetails, toasterControl } from "../../../../Services/commonService";
import { apiEndpoints } from "../../../../PrEnums/prEnums";
import { postTReq } from "../../../../Services/api";
import { Dialog, DialogContent } from "@mui/material";

const RequestLiveSessionBox = () => {
    const { personalDetails } = useSelector((state) => state.persist);

    const [alertModal, setAlertModal] = useState(false);

    const requestLiveSessionApproval = async () => {
        const res = await postTReq(apiEndpoints.requestLiveSessionApproval);
        if (res && !res.error) {
            getPersonalDetails();
            setAlertModal(false);
            toasterControl("successToaster", true, "Request sent successfully");
        } else {
            console.log(res.error);
            toasterControl("somethingWentWrong", true);
        }
    };
    return (
        <React.Fragment>
            <Dialog open={alertModal}>
                <DialogContent>
                    <div className="mb-2">Do you wish to send a request to Admin to get your account approved to take Live Sessions?</div>
                    <div className={styles.pointHead}>Prerequisites:</div>
                    <div className={styles.points}>1. Must have minimum of 5 Years of experience in relevant field.</div>
                    <div className={styles.points}>2. Must validate official Email via OTP.</div>
                    <div className={styles.points}>3. Must do a video verification of documents.</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-button me-2" onClick={requestLiveSessionApproval}>
                            Yes
                        </button>
                        <button className="normal-disabled-button" onClick={() => setAlertModal(false)}>
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            {personalDetails && personalDetails.request_for_live_session_approval_from_admin && Object.keys(personalDetails.request_for_live_session_approval_from_admin).length > 0 ? (
                personalDetails.request_for_live_session_approval_from_admin.is_verified_from_admin_for_live_session ? null : (
                    <div className={styles.waitingContainer}>
                        <div className="me-1">
                            <TbClockCheck fontSize={22} />
                        </div>
                        <div className={styles.boxText}>Verification Pending</div>
                        <div className={styles.shine}></div>
                        <div className={styles.shine2}></div>
                    </div>
                )
            ) : (
                <div className={styles.container} onClick={() => setAlertModal(true)}>
                    <div className="me-1">
                        <VscVerifiedFilled fontSize={22} />
                    </div>
                    <div className={styles.boxText}>Request for verification</div>
                    <div className={styles.shine}></div>
                </div>
            )}
        </React.Fragment>
    );
};

export default RequestLiveSessionBox;
