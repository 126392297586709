import { Checkbox, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalDetails, modalControl, toasterControl } from "../../../Services/commonService";
import styles from "./editProfessionModal.module.css";
import { convertDateStringForMonthInput } from "../../../Services/commons";
import { inputEditingProfessionObject } from "../../../Redux/Slices/profileSlice";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const EditProfessionModal = () => {
    const dispatch = useDispatch();
    const { editProfessionModal } = useSelector((state) => state.nopersist);
    const { editingProfessionObject } = useSelector((state) => state.profile);

    const currentMonth = new Date().toISOString().slice(0, 7);

    const [currentWorking, setCurrentWorking] = useState(false);

    const inputHeadObj = {
        professional_id: "Professional ID",
        companyName: "Company Name",
        joiningType: "Joining Type",
        designation: "Designation",
        location: "Location",
        joined_from: "Joined From",
        worked_till: "Worked Till",
        project_details: "Project Details",
        description: "Description",
    };
    const doNotShow = ["professional_id", "project_details", "description"];
    const dateInput = ["joined_from", "worked_till", "started_from", "end_on"];

    const handleInput = (event) => {
        const { name, value } = event.target;
        if (dateInput.includes(name)) {
            dispatch(inputEditingProfessionObject({ name, value: new Date(value).getTime() }));
        } else {
            dispatch(inputEditingProfessionObject({ name, value }));
        }
    };

    const handleSubmit = async () => {
        let payload = JSON.parse(JSON.stringify(editingProfessionObject));
        const res = await postTReq(apiEndpoints.updateProfessionalDetails, [payload]);
        if (res && !res.error) {
            getProfessionalDetails();
            modalControl("editProfessionModal", false);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    useEffect(() => {
        if (currentWorking) {
            dispatch(inputEditingProfessionObject({ name: "worked_till", value: 0 }));
        }
    }, [currentWorking, dispatch]);
    return (
        <Dialog open={editProfessionModal} onClose={() => modalControl("editProfessionModal", false)}>
            <DialogTitle>Edit Experience</DialogTitle>
            <DialogContent>
                <div className={`d-flex flex-wrap ${styles.professionInputsContainer}`}>
                    {editingProfessionObject &&
                        Object.keys(editingProfessionObject).map((curElem) => {
                            if (!doNotShow.includes(curElem)) {
                                if (dateInput.includes(curElem)) {
                                    return (
                                        <div className={`w-50 px-1 mb-2`}>
                                            <div className={styles.inputHead}>{inputHeadObj[curElem]}</div>
                                            <input
                                                type="month"
                                                className="jobsifarish-input2"
                                                name={curElem}
                                                disabled={curElem === "worked_till" && currentWorking}
                                                value={convertDateStringForMonthInput(editingProfessionObject[curElem])}
                                                onChange={handleInput}
                                                min={curElem === "worked_till" ? convertDateStringForMonthInput(editingProfessionObject["joined_from"]) || currentMonth : ""}
                                                max={
                                                    curElem === "joined_from"
                                                        ? convertDateStringForMonthInput(editingProfessionObject["worked_till"]) || currentMonth
                                                        : curElem === "worked_till"
                                                        ? currentMonth
                                                        : ""
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className={`w-50 px-1 mb-2`}>
                                            <div className={styles.inputHead}>{inputHeadObj[curElem]}</div>
                                            <input type="text" className="jobsifarish-input2" name={curElem} value={editingProfessionObject[curElem]} onChange={handleInput} />
                                        </div>
                                    );
                                }
                            } else {
                                return null;
                            }
                        })}
                    <div className="d-flex align-items-center">
                        <Checkbox id="currentlyWorking" checked={currentWorking} onChange={(event) => setCurrentWorking(event.target.checked)} />
                        <label htmlFor="currentlyWorking" className={styles.currentlyWorking}>
                            Currently working
                        </label>
                    </div>
                    {editingProfessionObject && (
                        <div className="w-100 px-1">
                            <div className={styles.inputHead}>Description</div>
                            <textarea rows={3} className="jobsifarish-textarea" name="description" value={editingProfessionObject["description"]} onChange={handleInput}></textarea>
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button className="normal-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditProfessionModal;
