import React from "react";
import styles from "./easySteps.module.css";
import { easyStepsArray } from "../../Services/constants";

const EasySteps = () => {
  return (
    <div className={styles.container}>
      <div className={styles.fitBox}>
        {easyStepsArray.map((curElem, index) => {
          return (
            <div key={`${curElem.label}-${index}`} className={styles.cardContainer} style={{ backgroundColor: `hsla(41, 86%, 61%, calc(${0.2 * (index + 1)}))` }}>
              <div className={styles.stepNum}>{curElem.step}</div>
              <div className={styles.boxIcon}>
                <curElem.icon />
              </div>
              <div className={styles.contentContainer}>
                <div className={styles.cardText}>{curElem.label}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EasySteps;
