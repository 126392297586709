import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageContent from "../../content/ImageContent";
import { Button } from "react-bootstrap";
import ServiceProvider from "../../provider/serviceProvider";
import "../../style/PersonalDetails.css";
import { DataUtility } from "../../utility/DataUtility";
import HeaderUtility from "../../utility/HeaderUtility";
import { EduComponent, WorkExpComponent, JobCard } from "./Profile";
let imageContent = new ImageContent();

const ViewProfile = () => {
  let [profInfo, setProfInfo] = useState([]);

  let [numberOfQualification, setNumberOfQualification] = useState([]);
  let [jobInfo, setJobInfo] = useState([]);
  let [totalPage, setTotalPage] = useState(0);
  let [loading, setLoading] = useState(false);
  let [pos, setPos] = useState(0);
  let [userInfo, setUserInfo] = useState({});
  let [email, setEmail] = useState("");
  let params = useParams();
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  let navigateTo = useNavigate();
  const observer = useRef();
  const lastJobRef = useCallback(
    (node) => {
      const getActivityInfoOnScroll = (page) => {
        setLoading(true);
        let header = headerUtility.returnHeaderContainingToken();
        serviceProvider
          .returnJobService()
          .getUserPostedJob(
            {
              page_no: page,
              no_of_records: 10,
              emailAddress: email,
            },
            header
          )
          .then((res) => {
            if (res.status === "200 OK") {
              let videoList = res.data;
              let nVideo = [...jobInfo];
              nVideo = nVideo.concat(videoList);
              setJobInfo(nVideo);
              setLoading(false);
            }
          });
      };

      const scroll = () => {
        let np = pos + 1;
        setPos(np);
        if (np < totalPage) {
          getActivityInfoOnScroll(np);
        }
      };

      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          scroll();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, totalPage, pos, email, headerUtility, jobInfo, serviceProvider]
  );

  const getActivityInfo = useCallback(
    (inputJson) => {
      setPos(0);
      serviceProvider
        .returnJobService()
        .getUserPostedJob(inputJson, headerUtility.returnHeaderContainingToken())
        .then((res) => {
          if (res.status === "200 OK") {
            setJobInfo(res?.data);
            setTotalPage(res?.page_details?.total_pages);
          }
        });
    },
    [setPos, serviceProvider, setJobInfo, setTotalPage, headerUtility]
  );

  const getUserInfo = useCallback(
    (inputJson) => {
      serviceProvider
        .returnProfileService()
        .getViewUserProfile(headerUtility.returnHeaderContainingToken(), inputJson)
        .then((res) => {
          if (res.status === "200 OK") {
            let data = res.data;
            let skills = res.data?.skillset.map((skill, index) => ({ value: index, label: skill.skill }));
            data.skillset = skills;
            setUserInfo(data);
            setNumberOfQualification(res.data?.educational_details);
            setProfInfo(DataUtility.getProfessionalData(res.data?.professional_details));
          }
        });
    },
    [serviceProvider, setUserInfo, setNumberOfQualification, setProfInfo, headerUtility]
  );
  
  const returnWorkComp = () => {
    return profInfo.map((work, index) => {
      if (index !== profInfo.length - 1) {
        return <WorkExpComponent work={work} workId={index + "work"} bodyClassName="ant-card-bodySmall" />;
      } else {
        return <WorkExpComponent work={work} workId={index + "work"} bodyClassName="ant-card-bodySmallWithoutBorder" />;
      }
    });
  };
  const returnEduComp = () => {
    return numberOfQualification.map((edu, index) => {
      if (index !== numberOfQualification.length - 1) {
        return <EduComponent edu={edu} eduId={index + "education"} bodyClassName="ant-card-bodySmall" />;
      } else {
        return <EduComponent edu={edu} eduId={index + "education"} bodyClassName="ant-card-bodySmallWithoutBorder" />;
      }
    });
  };
  const returnSkillList = () => {
    return (
      userInfo?.skillset &&
      userInfo?.skillset.map((skill, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"}>
              <span>{skill.label}</span>
            </div>
          </React.Fragment>
        );
      })
    );
  };
  const returnActivity = () => {
    return (
      jobInfo &&
      jobInfo.map((job, index) => {
        if (jobInfo.length === index + 1) {
          return (
            <div className="col-12 pt-3" ref={lastJobRef}>
              <JobCard name={userInfo?.firstName} jobInfo={job} index={index} />
            </div>
          );
        } else {
          return (
            <div className="col-12 pt-3">
              <JobCard name={userInfo?.firstName} jobInfo={job} index={index} />
            </div>
          );
        }
      })
    );
  };
  const sendRequest = () => {
    let header = headerUtility.returnHeaderContainingToken();
    let inputJson = {
      reciever_id: email,
    };
    serviceProvider
      .returnConnectService()
      .sendRequest(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          getUserInfo({ emailAddress: email });
          getActivityInfo({ page_no: 0, no_of_records: 10, emailAddress: email });
        }
      });
  };
  const returnBtnGroup = () => {
    if (userInfo?.user_connection_status?.is_friend === undefined) {
      return (
        <Button className="connectButton" onClick={sendRequest}>
          Connect
        </Button>
      );
    } else {
      if (userInfo?.user_connection_status?.is_friend) {
        return (
          <React.Fragment>
            <Link to={{ pathname: "/message", user: userInfo.user_connection_status }}>
              <Button className="connectButton">Message</Button>
            </Link>
          </React.Fragment>
        );
      } else {
        return (
          <h5>
            <b>Sent</b>
          </h5>
        );
      }
    }
  };
  useEffect(() => {
    setEmail(params.email);
    getUserInfo({ emailAddress: params.email });
    getActivityInfo({ page_no: 0, no_of_records: 10, emailAddress: params.email });
  }, [getUserInfo, getActivityInfo, params.email]);
  return (
    <React.Fragment>
      <div className="container-fluid pageBody" id="profilePage">
        <div className="row pt-4"></div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="row">
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodyMinor">
                    <div className="row pl-0 pr-0 ml-0 mr-0">
                      {/* <div className="col-2"></div> */}
                      <div className="col-12 d-flex justify-content-center pt-2 pr-0 pl-0">
                        <div className="imageContainerForProfile">
                          <img
                            alt=""
                            className="imageContainerForProfile"
                            src={
                              userInfo?.profileImageUrl && userInfo?.profileImageUrl !== ""
                                ? "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + userInfo?.profileImageUrl
                                : imageContent.getProfilemage()
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-2"></div> */}
                    </div>
                    <div className="row pt-0 pb-0">
                      <div className="col-12">
                        <div className="text-center">
                          <span className="profileUserName">{userInfo?.firstName + " " + userInfo?.middleName + " " + userInfo?.lastName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-0">
                      <div className="col-12">
                        <div className="text-center">
                          <span className="profileUserRole">{userInfo?.job_profile_name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row px-5 d-flex justify-content-center">
                      {/* {!userInfo?.user_connection_status?.is_friend ? <Button className="connectButton" onClick={sendRequest}>Connect</Button> : <Link to={{
                                                pathname: "/message",
                                                user: userInfo?.user_connection_status,
                                            }}><Button className="connectButton">Message</Button></Link>} */}
                      {returnBtnGroup()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodyMinor">
                    <div className="row px-3 pb-0">
                      <h6>
                        <strong>
                          <b>About</b>
                        </strong>
                      </h6>
                    </div>
                    <div className="row px-3 pb-0">
                      <span>{userInfo?.about_me}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodyMinor">
                    <div className="row px-3">
                      <img alt="" src={imageContent.getEmailImage()} />
                      <span>
                        &nbsp;&nbsp;<b>Email :</b> {userInfo?.emailAddress}
                      </span>
                    </div>
                    {/* <div className="row px-3">
                                        <img alt="" src={imageContent.getExpImage()} />
                                        <span>&nbsp;&nbsp;Experience : 0-2 Yr</span>
                                    </div> */}
                    <div className="row px-3">
                      <img alt="" src={imageContent.getLocImage()} />
                      <span>
                        &nbsp;&nbsp;<b>Location :</b> {userInfo?.city}
                      </span>
                    </div>
                    <div className="row px-3 pb-0">{returnSkillList()}</div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodyMinor">
                    <div className="row px-3 pb-0">
                      <div className="col-xs-12 col-sm-12 col-md-3 d-flex justify-content-center">
                        <div className="parentContainerOfConnections">
                          <img alt="" src={imageContent.getCounslingImg()} className="image1Connection" />
                          <img alt="" src={imageContent.getCounslingImg()} className="image2Connection" />
                          <img alt="" src={imageContent.getCounslingImg()} className="image3Connection" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-8 d-flex justify-content-start px-4">
                        <span className="connectionText">
                          <b>400+ Connection</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12"
                onClick={() => {
                  navigateTo("/resumeBuilder");
                }}
              >
                <div className="ant-card">
                  <div className="ant-card-bodyMinor">
                    <div className="row px-3 pb-0">
                      <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                        <h4>
                          <b>Create Your Resume</b>
                        </h4>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                        <img alt="" className="imageContainerForResume" src={imageContent.getResumeImgFive()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="row">
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodySmall">
                    <div className="row pl-3 pb-0">
                      <h5>
                        <b>Work experince</b>
                      </h5>
                    </div>
                    {returnWorkComp()}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ant-card">
                  <div className="ant-card-bodySmall">
                    <div className="row pl-3 pb-0">
                      <h5>
                        <b>Education</b>
                      </h5>
                    </div>
                    {returnEduComp()}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-3">
                <div className="ant-card">
                  <div className="ant-card-bodySmall">
                    <div className="row pl-3 pb-0">
                      <h5>
                        <b>Activity</b>
                      </h5>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row jobList">{returnActivity()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewProfile;
