import { Button } from "react-bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import ImageContent from "../../content/ImageContent";
import "../../style/PersonalDetails.css";
import ResumeUploadModal from "../../component/Modal/ResumeUploadModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";

// let skillList = [
//   { id: 0, name: "React JS" },
//   { id: 1, name: "JavaScript" },
//   { id: 2, name: "Node JS" },
// ];
// let textList = [
//   {
//     id: 0,
//     text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
//   },
//   {
//     id: 1,
//     text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
//   },
//   {
//     id: 2,
//     text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
//   },
// ];

let imageContent = new ImageContent();

const JobInfoApply = () => {
  let [show, setShow] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [isAppled, setIsApplied] = useState(false);
  let params = useParams();
  const serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  const headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const navigateTo = useNavigate();
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const returnBtnGroup = (user) => {
    if (user?.user_connection_status?.is_friend === undefined) {
      return (
        <Button
          className="viewApplicants"
          onClick={() => {
            sendRequest(user?.user_details?.emailAddress);
          }}
        >
          Connect with colleague
        </Button>
      );
    } else {
      if (user?.user_connection_status?.is_friend) {
        return (
          <React.Fragment>
            <Link to={{ pathname: "/message", user: user }}>
              <Button className="viewApplicants">Message</Button>
            </Link>
          </React.Fragment>
        );
      } else {
        return (
          <h5 className="text-center">
            <b>Sent</b>
          </h5>
        );
      }
    }
  };
  const returnSkillList = () => {
    if (jsonData?.skillset !== undefined) {
      return jsonData?.skillset.map((item, index) => {
        return (
          <React.Fragment>
            <div className="mx-1 px-2 pt-1 pb-1 elasticSearchItem" id={index + "skill"} key={item.skill_id}>
              <span>{item.skill}</span>
            </div>
          </React.Fragment>
        );
      });
    }
  };
  //   const returnTextList = () => {
  //     return textList.map((textInfo, index) => {
  //       return (
  //         <React.Fragment>
  //           <div className="col-12 pt-3" id={index + "rolesAndResText"}>
  //             <span className="dotIconOrange">
  //               <i className="fa fa-circle" aria-hidden="true"></i>
  //             </span>
  //             <span>&nbsp;&nbsp;{textInfo.text}</span>
  //           </div>
  //         </React.Fragment>
  //       );
  //     });
  //   };
  const sendRequest = (email) => {
    let header = headerUtility.returnHeaderContainingToken();
    let inputJson = {
      reciever_id: email,
    };
    serviceProvider
      .returnConnectService()
      .sendRequest(header, inputJson)
      .then((res) => {
        if (res.status === "200 OK") {
          console.log("Connected");
        }
      });
  };
  useEffect(() => {
    serviceProvider
      .returnJobService()
      .viewJob(headerUtility.returnHeaderContainingToken(), { job_id: params.id })
      .then((res) => {
        if (res.status === "200 OK") {
          let json = { ...res.data };
          if (json.user_details !== undefined) {
            if (json.user_details.profileImageUrl !== undefined) {
              let profileUrl = "https://jobsifarish.s3.ap-south-1.amazonaws.com/" + json.user_details.profileImageUrl;
              json.user_details.profileImageUrl = profileUrl;
            }
          }
          setJsonData(json);
        }
      });
  }, [headerUtility, params.id, serviceProvider]);
  return (
    <div className="container-fluid pageBody">
      <ResumeUploadModal job_id={jsonData.job_id} isShow={show} handleClose={handleClose} isAppled={isAppled} setIsApplied={setIsApplied} />
      <div className="row pl-md-3">
        <div className="col-12 pl-md-4 pt-3">
          <span
            onClick={() => {
              navigateTo(-1);
            }}
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            &nbsp;&nbsp;Back
          </span>
        </div>
      </div>
      <div className="row pt-2 pl-md-4">
        <div className="col-xs-12 col-sm-12 col-md-8">
          <div className="ant-card">
            <div className="ant-card-bodyMinor">
              <div className="row pb-0">
                <div className="col-12 pl-0">
                  <div className="row pl-0 pb-0">
                    <div className="col-xs-12 col-sm-12 col-md-9">
                      <div className="col-12">
                        <span className="immediateJoiningTiltle">{jsonData?.joiningType}</span>
                      </div>
                      <div className="col-12 pt-2">
                        <h5>
                          <b>{jsonData?.jobTitle}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3">
                      {!isAppled ? (
                        <Button className="connectButton" onClick={handleShow}>
                          Apply Now
                        </Button>
                      ) : (
                        <Button className="connectButton">Applied</Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-2">
                  <div className="row px-1 pb-2">{returnSkillList()}</div>
                </div>
                <div className="col-12 pt-2">
                  <div className="row px-1 pb-0">
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img alt="" src={imageContent.getOrgImage()} />
                      <span>&nbsp;&nbsp;Org :{jsonData?.companyName}</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img alt="" src={imageContent.getExpImage()} />
                      <span>&nbsp;&nbsp;Exp :{jsonData?.expRequired}Yr</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <img alt="" src={imageContent.getLocImage()} />
                      <span>&nbsp;&nbsp;Place :{jsonData?.location}</span>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <span className="dollarIcon">
                        <i className="fa fa-usd" aria-hidden="true"></i>
                      </span>
                      <span>&nbsp;&nbsp;Offer :{jsonData?.offerPackage} lacs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-card-bodyMinor">
              <div className="row">
                <div className="col-12">
                  <h5>
                    <b>Job Overview</b>
                  </h5>
                </div>
                <div className="col-12 pt-3">
                  <span>{jsonData?.description}</span>
                </div>
              </div>
            </div>
            <div className="ant-card-bodyMinor">
              <div className="row">
                <div className="col-12">
                  <h5>
                    <b>Roles And Responsibility</b>
                  </h5>
                </div>
                <div className="col-12">{jsonData?.role}</div>
                {/* {returnTextList()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 pl-md-3">
          <div className="ant-card">
            <div className="ant-card-bodyMinor">
              <div className="row pb-0">
                <div className="col-12">
                  <h6>
                    <b>Interested in making new friends?</b>
                  </h6>
                </div>
                <div className="col-12">
                  <span className="contactSpan">CONTACT PERSON</span>
                </div>
                <div className="col-12 pt-2">
                  <div className="row pb-0">
                    <div className="col-3">
                      <div
                        className="jobInfoImg"
                        onClick={() => {
                          navigateTo("/viewProfile/" + jsonData.user_details?.emailAddress);
                        }}
                      >
                        <img
                          alt=""
                          className="jobInfoImg"
                          src={jsonData.user_details !== undefined ? (jsonData.user_details.profileImageUrl !== undefined ? jsonData.user_details.profileImageUrl : "") : ""}
                        />
                      </div>
                    </div>
                    <div className="col-9 pl-0">
                      <div className="row pb-0 pl-0">
                        <div
                          className="col-12 pb-0"
                          onClick={() => {
                            navigateTo("/viewProfile/" + jsonData.user_details?.emailAddress);
                          }}
                        >
                          <h5>
                            <b>
                              {jsonData?.user_details?.firstName} {jsonData?.user_details?.lastName}
                            </b>
                          </h5>
                        </div>
                        <div className="col-12">
                          <span className="contactSpan">{jsonData?.user_details?.job_profile_name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-3">{returnBtnGroup(jsonData)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfoApply;
