import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalControl } from "../../../Services/commonService";
import styles from "./changeEnterOtpModal.module.css";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { saveChangePasswordOtp } from "../../../Redux/Slices/noPersistSlice";

const ChangeEnterOtpModal = () => {
    const dispatch = useDispatch();
    const { changeEnterOtpModal } = useSelector((state) => state.nopersist);

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputRefs = useRef([]);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Focus the next input
        if (index < 5 && element.value) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace") {
            let newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
            // Focus the previous input
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const closeModalAndOpenEnterEmailModal = () => {
        setOtp(new Array(6).fill(""));
        dispatch(saveChangePasswordOtp(""));
        modalControl("changeEnterOtpModal", false);
        modalControl("enterOldPasswordModal", true);
    };

    const handleOtpSubmit = async () => {
        const res = await postTReq(apiEndpoints.changeVerifyOtp, {
            otp: otp.join(""),
        });
        if (res && !res.error) {
            dispatch(saveChangePasswordOtp(otp.join("")));
            setOtp(new Array(6).fill(""));
            modalControl("changeEnterOtpModal", false);
            modalControl("enterNewPasswordChangeModal", true);
        } else {
            console.log(res.error);
        }
    };

    return (
        <Dialog open={changeEnterOtpModal} onClose={closeModalAndOpenEnterEmailModal}>
            <DialogTitle>Enter OTP</DialogTitle>
            <DialogContent>
                {otp.map((data, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className={styles.otpInput}
                    />
                ))}
                <div className="d-flex justify-content-end mt-2">
                    <button className="normal-disabled-button me-1" onClick={closeModalAndOpenEnterEmailModal}>
                        Cancel
                    </button>
                    <button className="normal-button" onClick={handleOtpSubmit}>
                        Submit
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeEnterOtpModal;
