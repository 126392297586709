import React, { useEffect, useState } from "react";
import styles from "./aboutBox.module.css";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { postTReq } from "../../../../Services/api";
import { apiEndpoints } from "../../../../PrEnums/prEnums";
import { addNewSkill, getPersonalDetails, getSkillsArray, toasterControl } from "../../../../Services/commonService";
// import { skillsArray } from "../../../../json/commonData";
import { languages } from "../../../../json/language";
import { Typeahead } from "react-bootstrap-typeahead";

const AboutBox = () => {
    const { personalDetails, skillsList } = useSelector((state) => state.persist);
    const [editMode, setEditMode] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const howToShowEdit = () => {
        if (!editMode) {
            setShowButtons(true);
        }
    };
    const howToUnshowEdit = () => {
        if (!editMode) {
            setShowButtons(false);
        }
    };

    const [aboutData, setAboutData] = useState(personalDetails);
    const handleInput = (event) => {
        const { name, value } = event.target;
        setAboutData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleSelectSkill = async (selected) => {
        if (selected.length && selected[selected.length - 1].customOption) {
            await addNewSkill(selected[selected.length - 1].label);
            let x = JSON.parse(JSON.stringify(aboutData.skillset));
            let check = x.some((curElem) => {
                return curElem.skill === selected[selected.length - 1].label;
            });
            if (!check) {
                x = [...x, { skill: selected[selected.length - 1].label }];
                setAboutData((prev) => {
                    return {
                        ...prev,
                        skillset: [...x],
                    };
                });
            }
        } else {
            let x = JSON.parse(JSON.stringify(aboutData.skillset));
            let check = x.some((curElem) => {
                return curElem.skill === selected[0];
            });
            if (!check) {
                x = [...x, { skill: selected[0] }];
                setAboutData((prev) => {
                    return {
                        ...prev,
                        skillset: [...x],
                    };
                });
            }
        }

        // let y= skillsList.map((elem)=>{
        //   return elem.skill_name;
        // })
    };
    const handleSelectLanguage = (selected) => {
        let x = JSON.parse(JSON.stringify(aboutData.language));
        let check = x.some((curElem) => {
            return curElem === selected[0];
        });
        if (!check) {
            x = [...x, selected[0]];
            setAboutData((prev) => {
                return {
                    ...prev,
                    language: [...x],
                };
            });
        }
    };

    const handleSubmit = async () => {
        const res = await postTReq(`${apiEndpoints.updatePersonalDetails}`, aboutData);
        if (res && !res.error) {
            setEditMode(false);
            getPersonalDetails();
        } else {
            console.log(res.error);
        }
    };

    const deleteSkill = async (refid, id) => {
        let payload = {
            skillset: [
                {
                    skill_reference_id: refid,
                    skill_id: id,
                },
            ],
        };

        const res = await postTReq(apiEndpoints.deleteSkill, payload);
        if (res && !res.error) {
            getPersonalDetails();
        } else {
            toasterControl("errorToaster", true, "Could not delete skill. Some error occured.");
        }
    };

    const deleteLanguage = async (language) => {
        const payload = {
            language: [language],
        };
        const res = await postTReq(apiEndpoints.deleteLanguage, payload);
        if (res && !res.error) {
            getPersonalDetails();
        } else {
            toasterControl("errorToaster", true, "Could not delete language. Some error occured.");
        }
    };

    useEffect(() => {
        getPersonalDetails();
        if (editMode) {
            getSkillsArray();
        }
    }, [editMode]);

    const [arrayOfSkills, setArrayOfSkills] = useState([]);
    useEffect(() => {
        if (skillsList) {
            let arr = skillsList.map((curElem) => {
                return curElem.skill_name;
            });
            setArrayOfSkills(arr);
        }
    }, [skillsList]);

    useEffect(() => {
        if (personalDetails && Object.keys(personalDetails).length > 0) {
            setAboutData(personalDetails);
        }
    }, [personalDetails]);

    return (
        <div className={`content-card`} onMouseOver={howToShowEdit} onMouseOut={howToUnshowEdit}>
            {!editMode && showButtons && (
                <div className={`content-card-edit`} onClick={() => setEditMode(true)}>
                    <FiEdit2 />
                </div>
            )}
            {editMode && (
                <div className={`content-card-confirm`} onClick={handleSubmit}>
                    <AiOutlineCheck />
                </div>
            )}
            <div className="content-card-heading">About</div>
            <div className={styles.aboutSection}>
                {editMode && <textarea rows="8" name="about_me" value={aboutData.about_me} placeholder="Write about yourself..." onChange={handleInput}></textarea>}
                {!editMode && (aboutData && aboutData.about_me ? <div className={styles.aboutContent}>{aboutData.about_me}</div> : <div className="nothing-to-show">Nothing to show</div>)}
            </div>
            <div className={styles.aboutSection}>
                <div className={styles.subHeadings}>Skills</div>
                {aboutData && aboutData.skillset && aboutData.skillset.length > 0 ? (
                    <div className={`${styles.aboutSection} ${styles.skillPillsContainer}`}>
                        {aboutData.skillset &&
                            aboutData.skillset.map((curElem) => {
                                return (
                                    <div className={styles.skillPill} onClick={() => deleteSkill(curElem.skill_reference_id, curElem.skill_id)}>
                                        {curElem.skill}
                                        <div className={styles.deletePill}>
                                            <AiOutlineClose />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                ) : (
                    !editMode && <div className="nothing-to-show">Nothing to show</div>
                )}
                {editMode && skillsList && <Typeahead allowNew newSelectionPrefix="Create " options={arrayOfSkills} placeholder="Select" onChange={handleSelectSkill} selected={[]} />}
            </div>

            <div className={styles.aboutSection}>
                <div className={styles.subHeadings}>Languages</div>
                {aboutData && aboutData.language && aboutData.language.length > 0 ? (
                    <div className={`${styles.aboutSection} ${styles.skillPillsContainer}`}>
                        {aboutData.language &&
                            aboutData.language.map((curElem) => {
                                return (
                                    <div className={styles.skillPill}>
                                        {curElem}
                                        <div className={styles.deletePill} onClick={() => deleteLanguage(curElem)}>
                                            <AiOutlineClose />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                ) : (
                    !editMode && <div className="nothing-to-show">Nothing to show</div>
                )}
                {editMode && <Typeahead options={Object.keys(languages)} placeholder="Select" onChange={handleSelectLanguage} selected={[]} />}
            </div>
        </div>
    );
};

export default AboutBox;
