import React, { useState } from "react";
import styles from "./professionalDetailsCard.module.css";
import { convertDateDDMMYYYY, convertDateMMYYYY, getProfessionalDetails, modalControl, toasterControl } from "../../Services/commonService";
import { useDispatch } from "react-redux";
import { saveEditingProfessionObject, saveEditingProjectObject, saveSelectedWorkExperienceForEdit } from "../../Redux/Slices/profileSlice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidEdit, BiSolidTrashAlt } from "react-icons/bi";
import { delReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { Dialog, DialogContent } from "@mui/material";

const ProfessionalDetailsCard = ({ data }) => {
    const dispatch = useDispatch();

    const [projectConfirmation, setProjectConfirmation] = useState(false);
    const [savedProjectId, setSavedProjectId] = useState("");

    const handleEdit = () => {
        dispatch(saveEditingProfessionObject(data));
        modalControl("editProfessionModal", true);
    };

    const handleDelete = () => {
        dispatch(saveEditingProfessionObject(data));
        modalControl("professionDeleteConfirmationModal", true);
    };

    const deleteProject = async (projectId) => {
        const res = await delReq(apiEndpoints.deleteProject, {
            project_id: projectId,
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Project Deleted Successfully");
            closeConfirmation();
            getProfessionalDetails();
        } else {
            toasterControl("errorToaster", true, "An error occured");
            console.log(res.error);
        }
    };

    const handleEditProject = (project) => {
        dispatch(saveEditingProjectObject(project));
        modalControl("editProjectModal", true);
    };

    const closeConfirmation = () => {
        setSavedProjectId("");
        setProjectConfirmation(false);
    };
    return (
        <React.Fragment>
            <Dialog open={projectConfirmation}>
                <DialogContent>
                    <div className="mb-2">Are you sure you want to delete this project?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className="normal-disabled-button me-2" onClick={closeConfirmation}>
                            No
                        </button>
                        <button className="normal-button" onClick={() => deleteProject(savedProjectId)}>
                            Yes
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={styles.headingPart}>
                <div className="d-flex justify-content-end align-items-center mb-2">
                    <button className="small-button me-2" onClick={handleEdit}>
                        <BiSolidEdit />
                    </button>
                    <button className="small-delete-button" onClick={handleDelete}>
                        <BiSolidTrashAlt />
                    </button>
                </div>
                <div className={styles.line}>
                    <div className={styles.companyName}>{data.companyName ? data.companyName : "N/A"}</div>
                    <div className={styles.workDuration}>
                        <div>{data.joined_from ? convertDateMMYYYY(data.joined_from) : "N/A"}</div>
                        <div style={{ fontSize: "14px", margin: "0 0.5rem", color: "#000000" }}>to</div>
                        <div>{data.worked_till ? convertDateMMYYYY(data.worked_till) : "Currently Working"}</div>
                    </div>
                </div>
                <div className={styles.line}>
                    {data.designation && <div className={styles.designation}>{data.designation ? data.designation : "N/A"}</div>}
                    {data.joiningType && <div className={styles.joiningType}>{data.joiningType ? data.joiningType : "N/A"}</div>}
                </div>
                {data.location && (
                    <div className={styles.line}>
                        <div className={styles.location}>{data.location ? data.location : "N/A"}</div>
                    </div>
                )}
                {data.description && (
                    <div className={styles.line}>
                        <div className={styles.description}>{data.description ? data.description : "N/A"}</div>
                    </div>
                )}

                {data.project_details.length > 0 && (
                    <>
                        <div className={styles.projectsHead}>Projects:</div>
                        <div className={styles.projectsContainer}>
                            {data.project_details &&
                                data.project_details.length > 0 &&
                                data.project_details.map((curElem) => {
                                    return (
                                        <div className={styles.eachProject}>
                                            <div className={styles.projectLine}>
                                                <div className={styles.projectName}>{curElem.project_name ? curElem.project_name : "N/A"}</div>
                                                <div className={styles.projectDuration}>
                                                    <div>{curElem.started_from ? convertDateDDMMYYYY(curElem.started_from) : "N/A"}</div>
                                                    <div style={{ fontSize: "12px", color: "#000000", margin: "0 0.5rem" }}>to</div>
                                                    <div>{curElem.end_on ? convertDateDDMMYYYY(curElem.end_on) : "N/A"}</div>
                                                </div>
                                            </div>
                                            <div className={styles.projectLine}>
                                                {curElem.project_prod_url && (
                                                    <div className={styles.projectUrl}>
                                                        {curElem.project_prod_url ? (
                                                            <a
                                                                href={curElem.project_prod_url.startsWith("http") ? curElem.project_prod_url : `https://${curElem.project_prod_url}`}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                            >
                                                                {curElem.project_prod_url}
                                                            </a>
                                                        ) : (
                                                            "No URL"
                                                        )}
                                                    </div>
                                                )}
                                                {curElem.role_played && <div className={styles.rolePlayed}>{curElem.role_played ? curElem.role_played : "N/A"}</div>}
                                            </div>
                                            {curElem.tech_used && (
                                                <div className={styles.projectLine}>
                                                    <div className={styles.techUsed}>{curElem.tech_used ? curElem.tech_used : "N/A"}</div>
                                                </div>
                                            )}
                                            {curElem.description && (
                                                <div className={styles.projectLine}>
                                                    <div className={styles.projectDescription}>{curElem.description ? curElem.description : "No description given"}</div>
                                                </div>
                                            )}
                                            <div className="d-flex justify-content-end">
                                                <div className={styles.projectActions}>
                                                    <button className="small-button me-2" onClick={() => handleEditProject(curElem)}>
                                                        <BiSolidEdit />
                                                    </button>
                                                    <button
                                                        className="small-delete-button"
                                                        onClick={() => {
                                                            setSavedProjectId(curElem.project_id);
                                                            setProjectConfirmation(true);
                                                        }}
                                                    >
                                                        <BiSolidTrashAlt />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </>
                )}
                <div className="d-flex justify-content-end">
                    <div
                        className={styles.addProject}
                        onClick={() => {
                            dispatch(saveSelectedWorkExperienceForEdit(data));
                            modalControl("addProjectModal", true);
                        }}
                    >
                        <AiOutlinePlusCircle className="me-2" fontSize={14} />
                        Add a project
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProfessionalDetailsCard;
