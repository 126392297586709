import React, { useEffect, useRef, useState } from "react";
import styles from "./jobsifarishChat.module.css";
import { BsPersonCircle, BsSearch } from "react-icons/bs";
import ChatUsersContainer from "./ChatUsersContainer/ChatUsersContainer";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { Dropdown } from "react-bootstrap";
import ChatBubble from "./ChatBubble/ChatBubble";
import { IoMdSend } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { getChatHistory, getConnectionsList, getMoreChatByUserEmail, handleGetProfileAndGoThere } from "../../Services/commonService";
import { useDispatch, useSelector } from "react-redux";
import { toasterController } from "../../Redux/Slices/toasterSlice";
import { navigationRoutes } from "../../PrEnums/prEnums";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { RiLiveLine } from "react-icons/ri";
import { BiImageAlt } from "react-icons/bi";
import { modalController } from "../../Redux/Slices/noPersistSlice";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../Services/authService";
import { socket } from "../../Services/socket";
import InfiniteScroll from "react-infinite-scroll-component";

const JobsifarishChat = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { personalDetails, connectionsList } = useSelector((state) => state.persist);
    const { selectedChat, messagesArray, chatHistory, messageDetails } = useSelector((state) => state.chatting);

    const [searchText, setSearchText] = useState("");
    const [newChatHistory, setNewChatHistory] = useState(chatHistory);
    const [messageText, setMessageText] = useState("");

    const handleChatSubmit = async () => {
        if (selectedChat && selectedChat.fullName && messageText) {
            socket.emit("messageSent", {
                requestBody: { reciever_id: selectedChat.emailAddress, message: messageText },
                authTok: getToken(),
                receiverUserId: selectedChat.userId,
                senderUserId: personalDetails.userId,
                type: "message",
            });
            // socket.emit("messageSentNotification", {
            //     receiverEmail: selectedChat.emailAddress,
            //     senderEmail: personalDetails.emailAddress,
            // });
            setMessageText("");
        } else {
            dispatch(toasterController({ toaster: "pleaseSelectChat", state: true }));
        }
    };
    const sendMessageOnEnter = (event) => {
        if (event.key === "Enter") {
            handleChatSubmit();
        }
    };

    const chatDivRef = useRef(null);
    const scrollChatToBottom = () => {
        if (chatDivRef.current) {
            const chatDiv = chatDivRef.current;
            chatDiv.scrollTop = chatDiv.scrollHeight;
        }
    };

    const [pageNum, setPageNum] = useState(1);
    const fetchData = async () => {
        await getMoreChatByUserEmail(selectedChat.emailAddress, pageNum, 10);
        setPageNum(pageNum + 1);
    };

    useEffect(() => {
        if (searchText) {
            setNewChatHistory((prev) => {
                return prev.filter((curElem) => {
                    return (
                        curElem?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                        curElem?.companyName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                        curElem?.emailAddress?.toLowerCase().includes(searchText?.toLowerCase())
                    );
                });
            });
        } else {
            setNewChatHistory(chatHistory);
        }
    }, [searchText, chatHistory]);

    useEffect(() => {
        getChatHistory(0, 10);
        getConnectionsList(0, 10);
        scrollChatToBottom();
    }, []);

    const actions = [
        { icon: <FaRegFilePdf fontSize={20} />, name: "Upload CV" },
        { icon: <BiImageAlt fontSize={20} />, name: "Upload Image" },
        { icon: <RiLiveLine fontSize={20} />, name: "Schedule Live Session", func: () => dispatch(modalController({ modal: "scheduleSessionModal", state: true })) },
    ];
    return (
        <div className="main-content-container">
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <div className={styles.leftPanelHead}>
                        <div className={styles.profileName} onClick={() => navigateTo(navigationRoutes.profile)}>
                            <div className={styles.profileImageContainer}>
                                {personalDetails && personalDetails.profileImageUrl ? <img src={personalDetails.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={36} color="#dfdfdf" />}
                            </div>
                            <div className={styles.profileNameText}>{personalDetails && personalDetails.fullName}</div>
                        </div>
                        {/* <div className={styles.hamburgerContainer}>
                              <RxHamburgerMenu />
                        </div> */}
                    </div>
                    <div className={styles.leftPanelContent}>
                        <div className={styles.searchContainer}>
                            <input type="text" value={searchText} onChange={(event) => setSearchText(event.target.value)} />
                            <BsSearch className={styles.searchIcon} />
                        </div>
                        <div className={styles.chatUsersContainer}>
                            <div className={styles.fitBox}>
                                {newChatHistory.length > 0
                                    ? newChatHistory.map((curElem) => {
                                          return <ChatUsersContainer userData={curElem} setPageNum={setPageNum} scrollChatToBottom={scrollChatToBottom} />;
                                      })
                                    : connectionsList &&
                                      connectionsList.map((curElem) => {
                                          return <ChatUsersContainer userData={curElem} setPageNum={setPageNum} scrollChatToBottom={scrollChatToBottom} />;
                                      })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightPanel}>
                    <div className={styles.rightPanelHead}>
                        <div className={styles.profileName}>
                            {selectedChat && selectedChat.fullName && selectedChat.fullName && (
                                <div className="d-flex align-items-center" onClick={() => handleGetProfileAndGoThere(selectedChat, navigateTo)}>
                                    <div className={styles.profileImageContainer}>
                                        {selectedChat && selectedChat.profileImageUrl ? <img src={selectedChat.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={36} color="#dfdfdf" />}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className={styles.profileNameText}>{selectedChat && selectedChat.fullName && `${selectedChat.fullName}`}</div>
                                        <div className={styles.rightPanelCompany}>{selectedChat && selectedChat.companyName ? selectedChat.companyName : ""}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle className="no-down-arrow p-0" style={{ backgroundColor: "#00000000", border: "none" }}>
                                <div className={styles.ellipsisContainer}>
                                    <HiOutlineEllipsisVertical />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>Block</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {selectedChat && selectedChat.fullName && personalDetails ? (
                        <div ref={chatDivRef} id="scrollableDiv" className={styles.rightPanelContent}>
                            {/* <ChatBubble type="receiver" /> */}
                            {/* {messagesArray &&
                                messagesArray.map((curElem) => {
                                    return <ChatBubble type={personalDetails.emailAddress === curElem.message_type.sender_email ? "sender" : "receiver"} messageData={curElem} />;
                                })} */}
                            {messagesArray && messageDetails && messageDetails.total_records && (
                                <InfiniteScroll
                                    dataLength={messagesArray.length} //This is important field to render the next data
                                    next={fetchData}
                                    style={{ display: "flex", flexDirection: "column-reverse" }}
                                    inverse
                                    hasMore={messagesArray.length < messageDetails.total_records}
                                    scrollableTarget="scrollableDiv"
                                    loader={<div className="d-flex justify-content-center">Loading...</div>}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    // below props only if you need pull down functionality
                                    // refreshFunction={this.refresh}
                                    // pullDownToRefresh
                                    // pullDownToRefreshThreshold={50}
                                    // pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                                    // releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                                >
                                    {messagesArray.map((curElem) => {
                                        return <ChatBubble type={personalDetails.emailAddress === curElem.message_type.sender_email ? "sender" : "receiver"} messageData={curElem} />;
                                    })}
                                </InfiniteScroll>
                            )}
                        </div>
                    ) : (
                        <div className={styles.rightPanelNothing}>
                            <div className={styles.noChatSelected}>No chat selected</div>
                        </div>
                    )}

                    <div className={styles.inputSection}>
                        <div className={styles.messageInputContainer}>
                            <input
                                type="text"
                                className={styles.jobsifarishInput}
                                placeholder="Write a message..."
                                value={messageText}
                                onChange={(event) => setMessageText(event.target.value)}
                                onKeyDown={sendMessageOnEnter}
                            />
                            <div className={styles.attachmentIconContainer}>
                                {/* <AiFillPlusCircle fontSize={22} /> */}
                                <SpeedDial ariaLabel="SpeedDial basic example" sx={{ bottom: 16, right: 16 }} icon={<AiOutlinePlus />}>
                                    {actions.map((action) => (
                                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={() => action.func()} />
                                    ))}
                                </SpeedDial>
                            </div>
                        </div>
                        <div className={styles.sendButtonContainer} onClick={handleChatSubmit}>
                            <IoMdSend fontSize={26} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobsifarishChat;
