import React, { useState } from "react";
import styles from "./addEducationalDetailModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { Modal } from "react-bootstrap";
import { getEducationalDetails } from "../../../Services/commonService";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";

const AddEducationalDetailModal = () => {
  const dispatch = useDispatch();
  const { addEducationalDetailModal } = useSelector((state) => state.nopersist);

  const initialData = {
    educationType: "",
    schoolName: "",
    session_start: "",
    session_end: "",
    scoredMarks: "",
    totalMarks: "",
    boardName: "",
    location: "",
  };
  const [inputData, setInputData] = useState(initialData);

  const handleInput = async (event, field) => {
    const { name, value } = event.target;
    setInputData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    let x = JSON.parse(JSON.stringify(inputData));
    [x].forEach((curElem) => {
      curElem["session_start"] = new Date(curElem["session_start"]).getTime();
      curElem["session_end"] = new Date(curElem["session_end"]).getTime();
    });
    const res = await postTReq(apiEndpoints.updateEducationalDetails, [x]);
    if (res && !res.error) {
      setInputData(initialData);
      dispatch(modalController({ modal: "addEducationalDetailModal", state: false }));
      getEducationalDetails();
    } else {
      console.log(res.error);
    }
  };

  return (
    <Modal
      centered
      className="transparentModal"
      dialogClassName="d-flex justify-content-center"
      show={addEducationalDetailModal}
      size="lg"
      onHide={() => dispatch(modalController({ modal: "addEducationalDetailModal", state: false }))}
    >
      <Modal.Body className="p-0">
        <div className={styles.container}>
          <div className={styles.headingContainer}>Add Educational Detail</div>
          <div className="px-3 w-100 d-flex flex-wrap justify-content-between">
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Education type</div>
              <select className="jobsifarish-input2" name="educationType" value={inputData.educationType} onChange={handleInput}>
                <option value="">Select</option>
                <option value="10th">10th</option>
                <option value="Diploma">Diploma</option>
                <option value="12th">12th</option>
                <option value="Graduation">Graduation</option>
                <option value="Masters">Masters</option>
                <option value="Post-Grad">Post-Grad</option>
                <option value="PhD">PhD</option>
              </select>
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">School/College Name</div>
              <input type="text" className="jobsifarish-input2" name="schoolName" value={inputData.schoolName} onChange={handleInput} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Board/University Name</div>
              <input type="text" className="jobsifarish-input2" name="boardName" value={inputData.boardName} onChange={handleInput} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Started at</div>
              <input type="month" className="jobsifarish-input2" name="session_start" value={inputData.session_start} onChange={handleInput} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Ended at</div>
              <input type="month" className="jobsifarish-input2" name="session_end" value={inputData.session_end} onChange={handleInput} min={inputData.session_start} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Scored marks</div>
              <input type="text" className="jobsifarish-input2" name="scoredMarks" value={inputData.scoredMarks} onChange={handleInput} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Total marks</div>
              <input type="text" className="jobsifarish-input2" name="totalMarks" value={inputData.totalMarks} onChange={handleInput} />
            </div>
            <div className="w-49 mb-2">
              <div className="jobsifarish-input2-head">Location</div>
              <input type="text" className="jobsifarish-input2" name="location" value={inputData.location} onChange={handleInput} />
            </div>
          </div>
          <div className={styles.submitButtonContainer}>
            <button className={styles.submitButton} onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEducationalDetailModal;
