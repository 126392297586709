import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notificationObj: {},
    notificationState: false,
};

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState,
    reducers: {
        notificationController: (state, action) => {
            state.notificationState = action.payload.state;
            state.notificationObj = action.payload.obj;
        },
    },
});

export const { notificationController } = notificationSlice.actions;

export default notificationSlice.reducer;
