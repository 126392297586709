import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import ImageContent from "../../content/ImageContent";
import "../../style/Modal.css";
import { useNavigate } from "react-router-dom";
import { ValidationUtility } from "../../utility/ValidationUtility";
import { ValidationType } from "../../enum/ValidationType";
import ServiceProvider from "../../provider/serviceProvider";
import AuthContext from "../../context/authContext";
import ErrorModal from "./ErrorModal";
import styles from "./loginForm.module.css";

const LoginForm = (props) => {
  let context = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [isDisabled] = useState(false);
  const [isDisabledDiv] = useState(false);
  const [spinner] = useState(false);
  const imageContent = new ImageContent();
  const serviceProvider = new ServiceProvider();
  let [show, setShow] = useState({
    show: false,
    message: "",
  });
  const navigateTo = useNavigate();
  const onEmailInput = (value) => {
    setEmail(value);
    ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email");
  };
  const onPasswordInput = (value) => {
    setPwd(value);
    ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password");
  };
  const generateInputJsonForLogin = () => {
    let inputJson = {
      emailAddress: email,
      password: pwd,
    };
    return inputJson;
  };
  const isUserProfileValidated = (header) => {
    serviceProvider
      .returnProfileService()
      .validateUserProfile(header)
      .then((res) => {
        if (res.status === "200 OK") {
          if (res.data.is_profile_completed) {
            navigateTo("/");
          } else {
            navigateTo("/createProfile");
          }
        }
      });
  };

  const onLoginRequest = () => {
    validateOnLOgin().then(() => {
      let authService = serviceProvider.returnAuthenticationService();
      authService.onLogin(generateInputJsonForLogin()).then((res) => {
        if (res.status === "200 OK" && res.data) {
          let token = res.data["Authorization-Token"];
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("auth", "true");
          context.setAuthenticationStatus(true);
          let header = {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          };
          getUserFirstAndLastName(header);
          isUserProfileValidated(header);
          props.handleClose();
        } else {
          setShow({ ...show, show: !show.show, message: res["error message"] });
        }
      });
    });
    typeof props.onCLoseSideNav === "function" && props.onCLoseSideNav();
  };
  const getUserFirstAndLastName = (header) => {
    let profileService = serviceProvider.returnProfileService();
    profileService
      .getPersonalDetail(header)
      .then((res) => {
        if (res.status === "200 OK") {
          localStorage.setItem("fname", res.data.firstName);
          localStorage.setItem("lname", res.data.lastName);
          localStorage.setItem("email", res.data.emailAddress);
          context.setprofilePic(res.data.profileImageUrl);
          localStorage.setItem("profileUrl", res.data.profileImageUrl);
          context.setFirstName(res.data.firstName);
          context.setLastName(res.data.lastName);
          context.setEmail(res.data.emailAddress);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };
  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      if (!isDisabled) {
        event.preventDefault();
        onLoginRequest();
      }
    }
  };

  return (
    <div className={isDisabledDiv ? "fadeOut" : "fadeIn"}>
      <img className="crossicon" alt="cross" onClick={props.handleClose} src={imageContent.getCrossImg()} />
      <h2 className="login">Login with Email</h2>
      <Form>
        <div className="pt-3">
          <div className="col-sm-12">
            <Form.Control
              type="email"
              id="email"
              placeholder="Email"
              onChange={(e) => {
                onEmailInput(e.target.value);
              }}
            />
            <span className="text-danger" id="emailError"></span>
          </div>
        </div>
        <div className="pt-3">
          <div className="col-sm-12">
            <Form.Control
              id="pwd"
              type="password"
              placeholder="Password"
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                onPasswordInput(e.target.value);
              }}
            />
            <span className="text-danger" id="pwdError"></span>
          </div>
        </div>
        <div className="pt-3">
          <div className="col-sm-12 d-flex justify-content-center">
            <button className={styles.loginButton} onClick={onLoginRequest} disabled={isDisabled} variant="primary" block>
              {!spinner ? (
                "Submit"
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-6 border-right">
            <h6 className="newuser text-right">
              <b>If you are New User?</b>
            </h6>
            <h6 className="newuserReg text-right">
              <div
                onClick={() => {
                  props.manageModal("R");
                }}
                className="link"
              >
                <b>Register</b>
              </div>
              <b> Here</b>
            </h6>
          </div>
          <div className="col-6">
            <h6 className="newuser text-left">
              <b>Forgot Password ?</b>
            </h6>
            <h6 className="newuserReg text-left">
              <div
                onClick={() => {
                  props.manageModal("F");
                }}
                className="link"
              >
                <b>Click Here!</b>
              </div>
            </h6>
          </div>
        </div>
      </Form>
      <ErrorModal
        icon="text-danger"
        title="Warning"
        message={show.message}
        isShow={show.show}
        handleClose={() => {
          setShow({ ...show, show: !show.show });
        }}
      />
    </div>
  );
};

const validateOnLOgin = () => {
  let responseArray = [];
  responseArray.push(ValidationUtility.validateElement("email", ValidationType.Email, true, "emailError", "Email"));
  responseArray.push(ValidationUtility.validateElement("pwd", ValidationType.Text, true, "pwdError", "Password"));
  let errors = responseArray.filter((x) => {
    return !x.isValid;
  });
  return new Promise((resolve, reject) => {
    errors?.length > 0 ? reject(false) : resolve(true);
  });
};

export default LoginForm;
