import { HttpService } from '../common/httpService';

class ProfileService extends HttpService {
    urls = {
        getPersonalDetailsUrl: 'getPersonalDetails',
        updatePersonalDetails: 'updatePersonalDetails',
        educationUrl: 'updateEducationDetails',
        getEducationUrl:'getEducationDetails',
        professionalUrl:'updateProfessionalDetails',
        getProfessionalUrl:'getProfessionalDetails',
        deleteProfessional:'deleteProfessionalDetail',
        uploadProfile:'uploadProfileImage',
        deleteEducational:'deleteEducationDetail',
        validateProfile:'validateProfile',
        uploadVideo:'uploadVideo',
        getVideos:'getVideos',
        viewUserProfile:'viewUserProfile',
        deleteSkillFromUser:'deleteSkillFromUser'
    }
    getPersonalDetail = (header) => {
        return this.postQuery(this.urls.getPersonalDetailsUrl, header, {});
    }
    postPersonalDetails = (inputJson, header) => {
        return this.postQuery(this.urls.updatePersonalDetails, header, inputJson);
    }
    postEducationDetails = (inputJson, header) => {
        return this.postQuery(this.urls.educationUrl, header, inputJson);
    }
    getEducationDetail = (header) => {
        return this.postQuery(this.urls.getEducationUrl, header, {});
    }
    postProfessionalDetails = (inputJson, header) => {
        return this.postQuery(this.urls.professionalUrl, header, inputJson);
    }
    getProfessionalDetais = (header) => {
        return this.postQuery(this.urls.getProfessionalUrl, header, {});
    }
    deletingProfessionalDataCorrespondingToId = (header,inputJson) =>{
        return this.postQuery(this.urls.deleteProfessional,header,inputJson);
    }
    deletingEducationalDataCorrespondingToId = (header,inputJson) =>{
        return this.postQuery(this.urls.deleteEducational,header,inputJson);
    }
    uploadUserProfileImage = (header,fileId) =>{
        return this.uploadFile(this.urls.uploadProfile,header,fileId);
    }
    uploadOnAws(uploadUrl,file){
        return this.putQuery(uploadUrl,undefined,file);
    }
    validateUserProfile(header){
        return this.getQuery(this.urls.validateProfile,header);
    }
    uploadMasterVideo(header,inputJson){
        return this.uploadFileUsingJosn(this.urls.uploadVideo,header,inputJson);
    }
    getMasterVideos(header,inputJson){
        return this.postQuery(this.urls.getVideos,header,inputJson);
    }
    getViewUserProfile(header,inputJson){
        return this.postQuery(this.urls.viewUserProfile,header,inputJson);
    }
    createPdf(header,inputJson){
        return this.downloadPdf(header,inputJson);
    }
    deleteSkillFromUser(header,inputJson){
        return this.postQuery(this.urls.deleteSkillFromUser,header,inputJson);
    }
}

export default ProfileService;