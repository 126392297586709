import React, { createContext } from "react";
let intialValue ={
    authenticatedStatus:false,
    firstName:'',
    lastName:'',
    email:'',
    profileImg:'',
    setFirstName:(value)=>{},
    setLastName:(value)=>{},
    setEmail:(value)=>{},
    setprofilePic:(value)=>{},
    setAuthenticationStatus:(value)=>{},
}
export const AuthContext=createContext(intialValue);
export const AuthUserProvider = (props) => {
    return (
        <AuthContext.Provider value={{...props}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;