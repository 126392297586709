import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./component/Navigation/Navigation";
import { Route, Routes, useLocation } from "react-router-dom";
import AuthContext, { AuthUserProvider } from "./context/authContext";
import AdminLogin from "./container/Admin/AdminLogin";
import ToasterCollection from "./Toasters/ToasterCollection";
import { useDispatch, useSelector } from "react-redux";
import { saveMoreMessages, saveUnreadChatInConnection, updateNewChatInConnection } from "./Redux/Slices/chatSlice";
// import { increaseUnreadChatCount } from "./Redux/Slices/persistSlice";
import { socket } from "./Services/socket";
import GeneralLoadingBackdrop from "./component/GeneralLoadingBackdrop/GeneralLoadingBackdrop";
import NotificationAnimBox from "./component/NotificationAnimBox/NotificationAnimBox";
import { notificationController } from "./Redux/Slices/notificationSlice";
import { increaseNotificationUnreadCount, saveMoreNotifications } from "./Redux/Slices/noPersistSlice";
import "react-datepicker/dist/react-datepicker.css";
import { getChatHistory, getPersonalDetails } from "./Services/commonService";
import notificationSound from "./assets/Audios/tap-notification-180637.mp3";
import messageSound from "./assets/Audios/short-success-sound-glockenspiel-treasure-video-game-6346.mp3";

function App() {
    const context = useContext(AuthContext);
    const dispatch = useDispatch();
    const location = useLocation();
    const { personalDetails } = useSelector((state) => state.persist);
    const { selectedChat } = useSelector((state) => state.chatting);

    const isUserAuthenticated = localStorage.getItem("auth");
    const initVallue = isUserAuthenticated === "true" ? true : false;
    const [isAuthenticated, setIsAuthentionStatus] = useState(initVallue);
    let firstName = localStorage.getItem("fname");
    const [fname, setFname] = useState(firstName);
    let lasstName = localStorage.getItem("lname");
    const [lname, setLname] = useState(lasstName);
    // const [isAdmin, setIsAdmin] = useState(false);
    const [isAdmin] = useState(false);

    useEffect(() => {
        // Replace with your server's address and port
        socket.on("connect", () => {
            console.log("Connected to server");
        });

        socket.emit("joinNotificationRoom", { userEmail: personalDetails?.emailAddress });

        socket.on("receiveConnectionNotification", (data) => {
            dispatch(notificationController({ state: true, obj: data }));
            dispatch(saveMoreNotifications(data));
            dispatch(increaseNotificationUnreadCount(data));
            let audio = new Audio(messageSound);
            audio.play();
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from server");
        });

        socket.on("messageReceived", (data) => {
            dispatch(saveMoreMessages(data));
            dispatch(updateNewChatInConnection(data.message_type));
        });
        socket.on("receiveMessageNotification", (data) => {
            dispatch(saveUnreadChatInConnection(data.message_type));
            let audio = new Audio(notificationSound);
            audio.play();
        });

        // Cleanup the socket connection on unmount
        // return () => socket.disconnect();
    }, [dispatch, personalDetails, selectedChat]);

    useEffect(() => {
        if (context.authenticatedStatus) {
            getChatHistory(0, 10);
            getPersonalDetails();
        }
    }, [context.authenticatedStatus]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        // Load Razorpay script
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <React.Fragment>
            <NotificationAnimBox />
            <GeneralLoadingBackdrop />
            <ToasterCollection />
            <AuthUserProvider authenticatedStatus={isAuthenticated} firstName={fname} lastName={lname} setFirstName={setFname} setLastName={setLname} setAuthenticationStatus={setIsAuthentionStatus}>
                <div id="app-root">
                    {isAdmin ? (
                        <Routes>
                            <Route exact path="/adminLogin" element={<AdminLogin />} />
                        </Routes>
                    ) : (
                        // <AdminNavigation/>
                        <Navigation />
                    )}
                </div>
            </AuthUserProvider>
        </React.Fragment>
    );
}
export default App;
