import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA2CRyecqObwmpn8VpWGGHldHi7WbMdYis",
  authDomain: "jobsifarish-1a197.firebaseapp.com",
  projectId: "jobsifarish-1a197",
  storageBucket: "jobsifarish-1a197.appspot.com",
  messagingSenderId: "172619119637",
  appId: "1:172619119637:web:574ac04848bdf2a4b6e21c",
  measurementId: "G-6MRWX0LCGX",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
