import React from "react";
import styles from "./refundCancellationPage.module.css";
import { refundCancellationData } from "../../Services/constants";

const RefundCancellationPage = () => {
    return (
        <div className="main-content-container">
            <div className={styles.container}>
                <div className={styles.dataBox}>
                    {refundCancellationData.map((curElem) => {
                        return (
                            <div className="mb-3">
                                <div style={curElem.headingStyle}>{curElem.heading}</div>
                                <div style={curElem.contentStyle}>{curElem.content}</div>
                                {curElem.points &&
                                    curElem.points.map((cur) => {
                                        if (cur.label === "Sessions Shorter Than 30 Minutes") {
                                            return (
                                                <div className="d-flex flex-column mt-2">
                                                    <div style={cur.textStyling}>
                                                        <b style={cur.labelStyling}>Sessions Shorter Than 30 Minutes:</b>
                                                        If a session is shorter than 30 minutes, the circumstances will be reviewed on a case-by-case basis. Both the user and the professional involved
                                                        have the option to:
                                                    </div>
                                                    <div className="px-2">
                                                        <div style={cur.textStyling}>
                                                            <b style={cur.labelStyling}>Reconnect:</b>
                                                            Opt for a new session to complete the learning experience at no additional cost.
                                                        </div>
                                                        <div style={cur.textStyling}>
                                                            <b style={cur.labelStyling}>Refund:</b>
                                                            Request a refund. To ensure fairness and accuracy, the decision for a refund will be based on the details surrounding the session's early
                                                            completion, including but not limited to technical difficulties or unfulfilled session objectives.
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="d-flex mt-2">
                                                    <div style={cur.textStyling}>
                                                        <b style={cur.labelStyling}>{cur.label}:</b>
                                                        {cur.text}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        );
                    })}
                    <div className={styles.contentText}>Thank you for choosing JobSifarish to advance your professional journey.</div>
                </div>
            </div>
        </div>
    );
};

export default RefundCancellationPage;
