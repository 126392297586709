import React, { useEffect } from "react";
// import styles from "./workExperienceBox.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../../Redux/Slices/noPersistSlice";
import { getProfessionalDetails } from "../../../../Services/commonService";
import ProfessionalDetailsCard from "../../../../component/ProfessionalDetailsCard/ProfessionalDetailsCard";
import styles from "./workExperienceBox.module.css";

const WorkExperienceBox = () => {
  const dispatch = useDispatch();
  const { professionalDetails } = useSelector((state) => state.persist);

  useEffect(() => {
    getProfessionalDetails();
  }, []);

  return (
    <div className={`content-cardd`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="content-card-heading">Work Experience</div>
        <div className={`content-card-add-more`} onClick={() => dispatch(modalController({ modal: "addWorkExperienceModal", state: true }))}>
          <AiOutlinePlus /> Add
        </div>
      </div>

      <div className={styles.experienceContainer}>
        {professionalDetails && professionalDetails.length > 0 ? (
          professionalDetails.map((curElem) => {
            return <ProfessionalDetailsCard data={curElem} />;
          })
        ) : (
          <div style={{ padding: "1.5rem", display: "flex", justifyContent: "center", alignItems: "center", background: "#f6f6f6", borderRadius: "8px" }}>
            <div className="nothing-to-show">Nothing to show</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkExperienceBox;
