import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ImageContent from "../../content/ImageContent";
import { Button, Form } from "react-bootstrap";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { DataUtility } from "../../utility/DataUtility";
import ErrorModal from "../../component/Modal/ErrorModal";
import "../../style/Master.css";

let imageContent = new ImageContent();
const MasterVideo = () => {
  let dummyData = {
    id: 0,
    name: "",
    url: "",
    title: "",
    category: "",
    subCategory: "",
    formInfo: "",
  };
  const serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  const headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  const [videoUrlList, setVideoUrlList] = useState([]);
  let [show, setShow] = useState(false);
  let [msg, setMsg] = useState(false);
  let [pos, setPos] = useState(0);
  let [totalPage, setTotalPage] = useState(0);
  let [loading, setLoading] = useState(false);

  const [recommendedList, setRecommendedList] = useState([]);

  const observer = useRef();
  const lastVideoRef = useCallback(
    (node) => {
      const scroll = () => {
        let np = pos + 1;
        console.log(np);
        setPos(np);
        console.log(totalPage);
        if (np <= totalPage) {
          getMasterVideosOnScroll(np);
        }
      };

      const getMasterVideosOnScroll = (page) => {
        setLoading(true);
        let header = headerUtility.returnHeaderContainingToken();
        serviceProvider
          .returnProfileService()
          .getMasterVideos(header, {
            page_no: page,
            no_of_records: 10,
          })
          .then((res) => {
            if (res.status === "200 OK") {
              let videoList = DataUtility.returnVideoList(res.data);
              setTotalPage(res.page_details.total_pages);
              let nVideo = [...recommendedList];
              nVideo = nVideo.concat(videoList);
              setRecommendedList(nVideo);
              setLoading(false);
            }
          });
      };

      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          console.log("visi");
          // let np = pos + 1;
          console.log(totalPage);
          scroll();
        }
      });
      if (node) {
        if (observer && observer.current) {
          observer.current.observe(node);
        }
      }
    },
    [loading, totalPage, pos, headerUtility, recommendedList, serviceProvider]
  );

  const onFileClick = () => {
    let ele = document.getElementById("videoId");
    if (ele !== null) {
      ele.click();
    }
  };
  const addNewVideo = (url, file, fileid) => {
    let newData = { ...dummyData };
    let formData = new FormData();
    const element = document.getElementById(fileid);
    const selectedFile = element ? element.files?.item(0) : "";
    formData.append("file", selectedFile);
    newData.id = videoUrlList.length;
    newData.url = url;
    newData.name = file.name;
    newData.formInfo = formData;
    let newList = [...videoUrlList];
    newList.push(newData);
    setVideoUrlList(newList);
  };
  const onDeleteVideo = (delIndex) => {
    let newList = [...videoUrlList];
    let filterList = [];
    newList.forEach((item, index) => {
      if (index !== delIndex) {
        filterList.push(item);
      }
    });
    setTimeout(() => {
      setVideoUrlList([]);
      setVideoUrlList(filterList);
    });
  };
  const onFileSelect = (list) => {
    if (list.length > 0) {
      console.log(list[0]);
      const reader = new FileReader();
      reader.readAsDataURL(list[0]);
      reader.onload = () => {
        if (reader.readyState === 2) {
          addNewVideo(reader.result, list[0], "videoId");
        }
      };
    }
  };
  const onFieldUpdate = (index, columnName, value) => {
    let newList = [...videoUrlList];
    newList[index][columnName] = value;
    setVideoUrlList(newList);
  };
  const onSubmit = () => {
    let header = headerUtility.getHeaderForFileUpload();
    videoUrlList.forEach((item) => {
      serviceProvider
        .returnProfileService()
        .uploadMasterVideo(header, item)
        .then((res) => {
          if (res.status === "200 OK") {
            setShow(true);
            setMsg(res.message);
          } else {
            setMsg(res.message);
          }
          setTimeout(() => {
            setShow(false);
          }, 5000);
        });
    });
  };

  const returnVideoList = () => {
    return videoUrlList.map((item, index) => {
      return (
        <div key={"video" + index + "master" + item.name + "item" + item.url} className="mt-4 col-xs-12 col-sm-12 col-md-3 px-2 shadow">
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <img
                onClick={() => {
                  onDeleteVideo(index);
                }}
                src={imageContent.getCrossImg()}
                alt=""
                height="10"
                width="10"
              />
            </div>
            <div className="mx-0 pt-0 mt-0 px-0 col-12 d-flex justify-content-center align-items-center">
              <video width="96%" height="170" controls controlsList="nodownload">
                <source src={item.url} type="video/mp4" />
                <source src={item.url} type="video/ogg" />
                <source src={item.url} type="video/webm" />
                <source src={item.url} type="video/mkv" />
              </video>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <p style={{ cursor: "pointer" }} title={item.name}>
                {item.name.substring(0, 20) + "..."}
              </p>
            </div>
            <div className="col-12 d-flex pb-3 justify-content-center align-items-center">
              <Form.Control
                type="text"
                placeholder="Title"
                onChange={(e) => {
                  onFieldUpdate(index, "title", e.target.value);
                }}
              />
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <Form.Control
                type="text"
                placeholder="Category"
                onChange={(e) => {
                  onFieldUpdate(index, "category", e.target.value);
                }}
              />
            </div>
            <div className="col-12 mt-3 d-flex justify-content-center align-items-center">
              <Form.Control
                type="text"
                placeholder="Sub-category"
                onChange={(e) => {
                  onFieldUpdate(index, "subCategory", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      );
    });
  };
  const returnRecommendedList = () => {
    return recommendedList.map((item, index) => {
      if (recommendedList.length === index + 1) {
        return (
          <div
            ref={lastVideoRef}
            key={"recommended" + index + "master" + item.file_name + "item" + item.file_category !== undefined ? item.file_category : ""}
            className="mt-4 col-xs-12 col-sm-12 col-md-3 px-2 shadow"
          >
            <div className="row">
              <div className="mx-0 pt-0 mt-0 px-0 col-12 d-flex justify-content-center align-items-center">
                <video width="96%" height="170" controls controlsList="nodownload">
                  <source src={item.file_name} type="video/mp4" />
                  <source src={item.file_name} type="video/ogg" />
                  <source src={item.file_name} type="video/webm" />
                  <source src={item.file_name} type="video/mkv" />
                </video>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <p style={{ cursor: "pointer" }} title={item.title !== undefined ? item.title : "No Title"}>
                  {item.title !== undefined ? item.title : "No Title"}
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={"recommended" + index + "master" + item.file_name + "item" + item.file_category !== undefined ? item.file_category : ""} className="mt-4 col-xs-12 col-sm-12 col-md-3 px-2 shadow">
            <div className="row">
              <div className="mx-0 pt-0 mt-0 px-0 col-12 d-flex justify-content-center align-items-center">
                <video width="96%" height="170" controls controlsList="nodownload">
                  <source src={item.file_name} type="video/mp4" />
                  <source src={item.file_name} type="video/ogg" />
                  <source src={item.file_name} type="video/webm" />
                  <source src={item.file_name} type="video/mkv" />
                </video>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <p style={{ cursor: "pointer" }} title={item.title !== undefined ? item.title : "No Title"}>
                  {item.title !== undefined ? item.title : "No Title"}
                </p>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    const getMasterVideos = () => {
      setPos(0);
      let header = headerUtility.returnHeaderContainingToken();
      serviceProvider
        .returnProfileService()
        .getMasterVideos(header, {
          page_no: pos,
          no_of_records: 10,
        })
        .then((res) => {
          if (res.status === "200 OK") {
            let videoList = DataUtility.returnVideoList(res.data);
            setRecommendedList(videoList);
            setTotalPage(res.page_details.total_pages);
          }
        });
    };

    getMasterVideos();
  }, [headerUtility, pos, serviceProvider]);
  return (
    <div className="container-fluid" style={{ background: "#FBFBFF" }}>
      <ErrorModal
        message={msg}
        icon="text-primary"
        title="Sucess"
        isShow={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <div className="row px-0 mx-0">
        {videoUrlList.length > 0 ? returnVideoList() : null}
        <div onClick={onFileClick} className="mt-4 col-xs-12 col-sm-12 col-md-3 px-2 masterVideoCard shadow d-flex justify-content-center align-items-center">
          Add Video
          <input
            style={{ display: "none" }}
            id="videoId"
            type="file"
            onChange={(e) => {
              if (e.target.files !== null) {
                onFileSelect(e.target.files);
              }
            }}
            accept="video/*"
          />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-md-9"></div>
        <div className="col-xs-12 col-sm-12 col-md-3">
          <Button
            className="addProjectBtn"
            style={{ width: "100%" }}
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
      <hr />
      {recommendedList.length > 0 ? (
        <div className="row recommendedList mb-5">
          <div className="col-12">
            <h3>Recommended Videos</h3>
          </div>
          {returnRecommendedList()}
        </div>
      ) : null}
    </div>
  );
};

export default MasterVideo;
