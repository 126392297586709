import React from "react";
import styles from "./profileview.module.css";
import { getDateFromTimeString } from "../../../Services/commonService";
import { IoMdArrowRoundBack } from "react-icons/io";

const ProfileViewData = ({ data, openSetter }) => {
    console.log(data);
    return (
        <div style={{ overflowY: "scroll", height: "100%" }}>
            <div className={styles.mainHeading}>PROFILE DETAILS</div>
            <div className="d-flex justify-content-start">
                <div className={styles.backButton} onClick={() => openSetter("")}>
                    <IoMdArrowRoundBack /> Back
                </div>
            </div>
            <div className={styles.actualTable}>
                <div className={styles.fittingBox}>
                    <div className={styles.boxHeading}>PERSONAL DETAILS</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ height: "6rem", width: "6rem", borderRadius: "50%", overflow: "hidden" }}>
                            <img src={data.profileImageUrl} alt="" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                        </div>
                        <div style={{ marginLeft: "3rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <div className={styles.detailsTextName}>{data.fullName}</div>
                            <div className={styles.detailsText}>
                                <span style={{ fontSize: "14x", fontWeight: "600", color: "#1a1919c5" }}>Email Address :</span> {data.emailAddress}
                            </div>
                            <div className={styles.detailsText}>
                                <span style={{ fontSize: "14x", fontWeight: "600", color: "#1a1919c5" }}>Contact Number :</span> {data.mobileNumber}
                            </div>

                            <div style={{ display: "flex", gap: "1rem" }}>
                                <div className={styles.detailsText}>
                                    <span style={{ fontSize: "14x", fontWeight: "600", color: "#1a1919c5" }}>Country :</span> {data.country}
                                </div>
                                <div className={styles.detailsText}>
                                    <span style={{ fontSize: "14x", fontWeight: "600", color: "#1a1919c5" }}>City :</span>
                                    {data.city}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* second container */}
            <div className={styles.actualTable} style={{ height: "18rem" }}>
                <div className={styles.fittingBox}>
                    <div className={styles.boxHeading}>PROFESSIONAL DETAILS</div>
                    <div style={{ width: "100%" }}>
                        {data?.professional_details?.map((curelem) => {
                            return (
                                <>
                                    <div style={{ background: "#f6f6f6", width: "100%", borderRadius: "6px", padding: "1rem", marginBottom: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                            <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>{curelem.companyName}</div>
                                            <div style={{ color: "grey", fontWeight: "600", fontSize: "12px" }}>
                                                <div style={{ display: "flex", gap: "0.5rem" }}>
                                                    <div>{getDateFromTimeString(curelem.joined_from)}</div>
                                                    To
                                                    <div>{getDateFromTimeString(curelem.worked_till)}</div>
                                                </div>
                                                <div>
                                                    <div style={{ color: "black", fontWeight: 600, marginTop: "0.3rem" }}>{curelem.joiningType}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ color: "grey", fontWeight: "500", fontSize: "14px" }}>{curelem.designation}</div>
                                        <div style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>{curelem.location}</div>
                                        <div style={{ marginTop: "0.5rem" }}>
                                            <div style={{ color: "black", fontWeight: "600", fontSize: "13px" }}>Description :</div>
                                            <div style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>{curelem.description}</div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* third container */}
            <div className={styles.actualTable}>
                <div className={styles.fittingBox}>
                    <div className={styles.boxHeading}>OTHER DETAILS</div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div>
                            <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>ABOUT</div>
                            <div style={{ color: "grey", fontWeight: "500", fontSize: "14px" }}>{data.about_me}</div>
                        </div>

                        <div>
                            <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>SKILLS</div>
                            <div style={{ color: "grey", fontWeight: "500", fontSize: "14px" }}>
                                {data?.skillset?.length > 0 ? (
                                    <div>
                                        {data.skillset.map((elem) => {
                                            return <>{elem.skill ? elem.skill : "-"}</>;
                                        })}
                                    </div>
                                ) : (
                                    "-"
                                )}
                            </div>
                        </div>

                        <div>
                            <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>RATINGS</div>
                            <div style={{ color: "grey", fontWeight: "500", fontSize: "14px" }}>{data.over_all_rating}</div>
                        </div>

                        <div>
                            <div style={{ color: "black", fontWeight: "600", fontSize: "16px" }}>LANGUAGES</div>
                            <div style={{ color: "grey", fontWeight: "500", fontSize: "14px", display: "flex", gap: "0.4rem" }}>
                                {data?.language?.map((elem) => {
                                    return (
                                        <>
                                            <div>{elem},</div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileViewData;
