import React, { useEffect, useRef, useState } from "react";
import styles from "./jobsifarishChatMob.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import ChatBubble from "../ChatBubble/ChatBubble";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import JobsifarishChatDrawer from "../../../component/Modal/JobsifarishChatDrawer/JobsifarishChatDrawer";
import { toasterController } from "../../../Redux/Slices/toasterSlice";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { BiImageAlt } from "react-icons/bi";
import { RiLiveLine } from "react-icons/ri";
import { getToken } from "../../../Services/authService";
import { socket } from "../../../Services/socket";
import { getMoreChatByUserEmail, handleGetProfileAndGoThere } from "../../../Services/commonService";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const JobsifarishChatMob = () => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const chatDivRef = useRef();

    const { personalDetails } = useSelector((state) => state.persist);
    const { selectedChat, messagesArray, messageDetails } = useSelector((state) => state.chatting);

    const [messageText, setMessageText] = useState("");
    // const handleChatSubmit = async () => {
    //     if (selectedChat && selectedChat.fullName && messageText) {
    //         const res = await postTReq(apiEndpoints.sendMessage, { reciever_id: selectedChat.emailAddress, message: messageText });
    //         if (res && !res.error) {
    //             setMessageText("");
    //             getChatByUserEmail(selectedChat.emailAddress, 0, 10);
    //         } else {
    //             console.log(res.error);
    //             dispatch(toasterController({ toaster: "somethingWentWrong", state: true }));
    //         }
    //     } else {
    //         dispatch(toasterController({ toaster: "pleaseSelectChat", state: true }));
    //     }
    // };

    const handleChatSubmit = async () => {
        if (selectedChat && selectedChat.fullName && messageText) {
            socket.emit("messageSent", {
                requestBody: { reciever_id: selectedChat.emailAddress, message: messageText },
                authTok: getToken(),
                receiverUserId: selectedChat.userId,
                senderUserId: personalDetails.userId,
                type: "message",
            });
            setMessageText("");
            // socket.emit("messageSentNotification", {
            //     receiverEmail: selectedChat.emailAddress,
            // });
        } else {
            dispatch(toasterController({ toaster: "pleaseSelectChat", state: true }));
        }
    };
    const sendMessageOnEnter = (event) => {
        if (event.key === "Enter") {
            handleChatSubmit();
        }
    };

    const actions = [
        { icon: <FaRegFilePdf fontSize={20} />, name: "Upload CV" },
        { icon: <BiImageAlt fontSize={20} />, name: "Upload Image" },
        { icon: <RiLiveLine fontSize={20} />, name: "Schedule Live Session", func: () => dispatch(modalController({ modal: "scheduleSessionDrawer", state: true })) },
    ];

    const [pageNum, setPageNum] = useState(1);
    const fetchData = async () => {
        await getMoreChatByUserEmail(selectedChat.emailAddress, pageNum, 10);
        setPageNum(pageNum + 1);
    };

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

    const updateMedia = () => {
        setIsDesktop(window.innerWidth > 767);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <>
            <JobsifarishChatDrawer setPageNum={setPageNum} />
            <div className="main-content-container">
                <div className={styles.container}>
                    <div className={styles.rightPanel}>
                        <div className={styles.rightPanelHead}>
                            <div className={styles.profileName}>
                                <div className={styles.hamburgerContainer} onClick={() => dispatch(modalController({ modal: "jobsifarishChatDrawer", state: true }))}>
                                    <RxHamburgerMenu />
                                </div>
                                <div className={styles.profileImageContainer} onClick={() => handleGetProfileAndGoThere(selectedChat, navigateTo)}>
                                    {selectedChat && selectedChat.profileImageUrl ? <img src={selectedChat.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={36} color="#dfdfdf" />}
                                </div>
                                <div className="d-flex align-items-center" onClick={() => handleGetProfileAndGoThere(selectedChat, navigateTo)}>
                                    <div className={styles.profileNameText}>{selectedChat && selectedChat.fullName}</div>
                                    <div className={styles.rightPanelCompany}>{selectedChat && selectedChat.companyName ? selectedChat.companyName : ""}</div>
                                </div>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle className="no-down-arrow p-0" style={{ backgroundColor: "#00000000", border: "none" }}>
                                    <div className={styles.ellipsisContainer}>
                                        <HiOutlineEllipsisVertical />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Block</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {selectedChat && selectedChat.fullName && personalDetails ? (
                            <div ref={chatDivRef} id="scrollableDiv" className={styles.rightPanelContent}>
                                {/* {messagesArray &&
                                    messagesArray.map((curElem) => {
                                        return (
                                            <ChatBubble type={personalDetails.emailAddress === curElem.message_type.sender_email ? "sender" : "receiver"} messageData={curElem} isDesktop={isDesktop} />
                                        );
                                    })} */}
                                {messagesArray && messageDetails && messageDetails.total_records && (
                                    <InfiniteScroll
                                        dataLength={messagesArray.length} //This is important field to render the next data
                                        next={fetchData}
                                        style={{ display: "flex", flexDirection: "column-reverse" }}
                                        inverse
                                        hasMore={messagesArray.length < messageDetails.total_records}
                                        scrollableTarget="scrollableDiv"
                                        loader={<div className="d-flex justify-content-center">Loading...</div>}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                        // below props only if you need pull down functionality
                                        // refreshFunction={this.refresh}
                                        // pullDownToRefresh
                                        // pullDownToRefreshThreshold={50}
                                        // pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
                                        // releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}
                                    >
                                        {messagesArray.map((curElem) => {
                                            return (
                                                <ChatBubble
                                                    type={personalDetails.emailAddress === curElem.message_type.sender_email ? "sender" : "receiver"}
                                                    messageData={curElem}
                                                    isDesktop={isDesktop}
                                                />
                                            );
                                        })}
                                    </InfiniteScroll>
                                )}
                            </div>
                        ) : (
                            <div className={styles.rightPanelNothing}>
                                <div className={styles.noChatSelected}>No chat selected</div>
                            </div>
                        )}
                        <div className={styles.inputSection}>
                            <div className={styles.messageInputContainer}>
                                <input
                                    type="text"
                                    className={styles.jobsifarishInput}
                                    placeholder="Write a message..."
                                    value={messageText}
                                    onChange={(event) => setMessageText(event.target.value)}
                                    onKeyDown={sendMessageOnEnter}
                                />
                                <div className={styles.attachmentIconContainer}>
                                    <SpeedDial ariaLabel="SpeedDial basic example" sx={{ bottom: 16, right: 16 }} icon={<AiOutlinePlus />}>
                                        {actions.map((action) => (
                                            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={() => action.func()} />
                                        ))}
                                    </SpeedDial>
                                </div>
                            </div>
                            <div className={styles.sendButtonContainer} onClick={handleChatSubmit}>
                                <IoMdSend fontSize={26} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobsifarishChatMob;
