import React, { useState } from "react";
import styles from "./pendingList.module.css";
import { useSelector } from "react-redux";
import { getReceivedRequest, handleFriendRequestAction, handleGetProfileAndGoThere, respondRequestMail } from "../../Services/commonService";
import { BsPersonCircle } from "react-icons/bs";
import { AiFillStar, AiOutlineClose } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineWork } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { Tooltip } from "@mui/material";
import { socket } from "../../Services/socket";
import { VscVerifiedFilled } from "react-icons/vsc";

const PendingList = () => {
    const navigateTo = useNavigate();
    const { pendingList } = useSelector((state) => state.persist);
    const { personalDetails } = useSelector((state) => state.persist);

    const [loading, setLoading] = useState([]);

    const handleRequest = async (event, friendListId, request_type, email, name) => {
        event.stopPropagation();
        const newLoading = loading.filter((curElem) => {
            return curElem !== friendListId;
        });
        const res = await handleFriendRequestAction(friendListId, request_type);
        if (res === "200 OK") {
            setLoading(newLoading);
            getReceivedRequest(0, 10);
            socket.emit("approveRequestNotification", {
                receiverEmail: email,
                addData: {
                    notification_message: `${personalDetails?.fullName} has accepted your connection request`,
                    notification_sender_profile_pic: personalDetails?.profileImageUrl,
                    notification_type: "friend_request_approved",
                    notification_time: new Date().getTime(),
                    sender_email: personalDetails.emailAddress,
                },
            });
            respondRequestMail(name, email);
        } else {
            setLoading(newLoading);
        }
    };

    return (
        <div className={styles.container}>
            {pendingList &&
                pendingList.map((curElem) => {
                    return (
                        <div className={styles.eachCard} onClick={() => handleGetProfileAndGoThere(curElem, navigateTo)}>
                            <div className={styles.coverImage}>
                                <div className={styles.profileImage}>{curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={88} color="#888888" />}</div>
                            </div>
                            <div className={styles.cardContent}>
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className={styles.profileName}>{curElem.fullName ? curElem.fullName : "-"}</div>
                                        {curElem.is_verified_from_admin_for_live_session && (
                                            <div className="ms-1">
                                                <VscVerifiedFilled color="#f1bb46" fontSize={18} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className={styles.ratingPill}>
                                            <AiFillStar fontSize={14} color="#f1bb46" className="me-1" />
                                            {curElem.over_all_rating}
                                        </div>
                                    </div>
                                    {curElem.is_verified_from_admin_for_live_session && <div className="price-text">₹20/min</div>}
                                    {curElem.job_profile_name && (
                                        <div className={styles.designationName}>
                                            <MdOutlineWork color="#f1bb46" className="me-1" />
                                            {`${curElem.job_profile_name ? curElem.job_profile_name : "-"}`}
                                        </div>
                                    )}
                                    {curElem.companyName && (
                                        <div className={styles.companyName}>
                                            <GoOrganization color="#f1bb46" className="me-1" />
                                            {`${curElem.companyName ? curElem.companyName : "-"}`}
                                        </div>
                                    )}
                                    {curElem.location && (
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionName}>Location:</div>
                                            <div className={styles.sectionValue}>{curElem.location ? curElem.location : "-"}</div>
                                        </div>
                                    )}
                                    {curElem.joiningType && (
                                        <div className={styles.sectionContainer}>
                                            <div className={styles.sectionName}>Joining type:</div>
                                            <div className={styles.sectionValue}>{curElem.joiningType ? curElem.joiningType : "-"}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="action-button-container">
                                    {loading.includes(curElem.friendListId) ? (
                                        <button className="action-button-disabled">
                                            <Spinner animation="border" size="sm" />
                                        </button>
                                    ) : (
                                        <>
                                            <Tooltip title="Accept connection request">
                                                <button
                                                    className="action-button-accept me-2"
                                                    onClick={(event) => {
                                                        setLoading((prev) => {
                                                            return [...prev, curElem.friendListId];
                                                        });
                                                        handleRequest(event, curElem.friendListId, "accept", curElem.emailAddress, curElem.fullName);
                                                    }}
                                                >
                                                    <FaCheck />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Reject connection request">
                                                <button
                                                    className="action-button-reject"
                                                    onClick={(event) => {
                                                        setLoading((prev) => {
                                                            return [...prev, curElem.friendListId];
                                                        });
                                                        handleRequest(event, curElem.friendListId, "reject", curElem.emailAddress, curElem.fullName);
                                                    }}
                                                >
                                                    <AiOutlineClose />
                                                </button>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PendingList;
