import React, { useEffect, useState } from "react";
import styles from "./jobsifarishChatDrawer.module.css";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import ChatUsersContainer from "../../../Pages/JobsifarishChat/ChatUsersContainer/ChatUsersContainer";
import { BsPersonCircle, BsSearch } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { getChatHistory, getConnectionsList } from "../../../Services/commonService";

const JobsifarishChatDrawer = ({ setPageNum }) => {
    const dispatch = useDispatch();
    const { jobsifarishChatDrawer } = useSelector((state) => state.nopersist);
    const { connectionsList, personalDetails } = useSelector((state) => state.persist);
    const { chatHistory } = useSelector((state) => state.chatting);

    useEffect(() => {
        dispatch(modalController({ modal: "jobsifarishChatDrawer", state: true }));
    }, [dispatch]);

    const [searchText, setSearchText] = useState("");
    const [newChatHistory, setNewChatHistory] = useState(chatHistory);

    useEffect(() => {
        if (searchText) {
            setNewChatHistory((prev) => {
                return prev.filter((curElem) => {
                    return (
                        curElem?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                        curElem?.companyName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                        curElem?.emailAddress?.toLowerCase().includes(searchText?.toLowerCase())
                    );
                });
            });
        } else {
            setNewChatHistory(chatHistory);
        }
    }, [searchText, chatHistory]);

    useEffect(() => {
        if (jobsifarishChatDrawer) {
            getChatHistory(0, 10);
            getConnectionsList(0, 10);
        }
    }, [jobsifarishChatDrawer]);

    return (
        <Offcanvas style={{ width: "100%" }} className={styles.container} show={jobsifarishChatDrawer} onHide={() => dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }))}>
            <div className={styles.leftPanel}>
                <div className={styles.leftPanelHead}>
                    <div className={styles.profileName}>
                        <div className={styles.profileImageContainer}>
                            {personalDetails && personalDetails.profileImageUrl ? <img src={personalDetails.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={36} color="#dfdfdf" />}
                        </div>
                        <div className={styles.profileNameText}>{personalDetails && personalDetails.fullName}</div>
                    </div>
                    <div className={styles.hamburgerContainer} onClick={() => dispatch(modalController({ modal: "jobsifarishChatDrawer", state: false }))}>
                        <AiOutlineClose />
                    </div>
                </div>
                <div className={styles.leftPanelContent}>
                    <div className={styles.searchContainer}>
                        <input type="text" value={searchText} onChange={(event) => setSearchText(event.target.value)} />
                        <BsSearch className={styles.searchIcon} />
                    </div>
                    <div className={styles.chatUsersContainer}>
                        <div className={styles.fitBox}>
                            {newChatHistory.length > 0
                                ? newChatHistory.map((curElem) => {
                                      return <ChatUsersContainer userData={curElem} setPageNum={setPageNum} />;
                                  })
                                : connectionsList &&
                                  connectionsList.map((curElem) => {
                                      return <ChatUsersContainer userData={curElem} setPageNum={setPageNum} />;
                                  })}
                        </div>
                    </div>
                </div>
            </div>
        </Offcanvas>
    );
};

export default JobsifarishChatDrawer;
