import React, { useEffect, useMemo, useState } from "react";
import styles from "./contactUsPage.module.css";
import { TiLocation } from "react-icons/ti";
import { postTReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";
import { toasterControl } from "../../Services/commonService";
import { commonValidateField, validationErrorChecker } from "../../Services/commons";
import { contactUsValidation } from "../../Services/validationObjects";
import { CircularProgress } from "@mui/material";
import { MdPhone } from "react-icons/md";

const ContactUsPage = () => {
    const initialData = useMemo(() => {
        return {
            fullName: "",
            emailAddress: "",
            mobileNumber: "",
            subject: "",
            query: "",
        };
    }, []);
    const [inputData, setInputData] = useState(initialData);
    const [errorData, setErrorData] = useState({});
    const [loading, setLoading] = useState(false);
    const handleInput = (event) => {
        const { name, value } = event.target;
        setInputData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        let valText = commonValidateField(contactUsValidation[name], value);
        setErrorData((prev) => {
            return {
                ...prev,
                [name]: valText,
            };
        });
    };

    const handleSubmit = async () => {
        let formFields = Object.keys(contactUsValidation);
        if (validationErrorChecker(inputData, errorData, contactUsValidation)) {
            formFields.forEach((curElem) => {
                let valText = commonValidateField(contactUsValidation[curElem], inputData[curElem]);
                setErrorData((prev) => {
                    return {
                        ...prev,
                        [curElem]: valText,
                    };
                });
            });
        } else {
            setLoading(true);
            const res = await postTReq(apiEndpoints.contactUs, inputData);
            if (res && !res.error) {
                setInputData(initialData);
                setLoading(false);
            } else {
                console.log(res.error);
                setLoading(false);
                toasterControl("errorToaster", true, "An error occured");
            }
        }
    };

    useEffect(() => {
        return () => {
            setErrorData({});
            setInputData(initialData);
        };
    }, [initialData]);

    return (
        <div className="main-content-container" style={{ overflowY: "scroll" }}>
            <div className={styles.container}>
                <div className={styles.contactUsHeading}>Contact Us</div>
                <div className={`${styles.dataBox} mb-3 d-flex align-items-center`}>
                    <div className={styles.locationIcon}>
                        <TiLocation />
                    </div>
                    <div className="d-flex flex-column">
                        <div className={styles.cityName}>Sangita Devi, Shela, Ahmedabad</div>
                        <div className={styles.countryName}>India</div>
                    </div>
                </div>
                <div className={`${styles.dataBox} mb-3`}>
                    <div className={styles.heading}>Contact Us</div>
                    <div className={styles.formContainer}>
                        <div className="d-flex align-items-center mb-2">
                            <div className="w-100 pe-1">
                                <div className={styles.inputLabel}>Full Name</div>
                                <input type="text" className={`${styles.formInput} ${errorData.fullName ? styles.errorInput : ""}`} name="fullName" value={inputData.fullName} onChange={handleInput} />
                                {errorData.fullName && <div className="input-error-text">{errorData.fullName}</div>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="w-100 pe-1">
                                <div className={styles.inputLabel}>Subject</div>
                                <input type="text" className={`${styles.formInput} ${errorData.subject ? styles.errorInput : ""}`} name="subject" value={inputData.subject} onChange={handleInput} />
                                {errorData.subject && <div className="input-error-text">{errorData.subject}</div>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="w-100">
                                <div className={styles.inputLabel}>Email Address</div>
                                <input
                                    type="text"
                                    className={`${styles.formInput} ${errorData.emailAddress ? styles.errorInput : ""}`}
                                    name="emailAddress"
                                    value={inputData.emailAddress}
                                    onChange={handleInput}
                                />
                                {errorData.emailAddress && <div className="input-error-text">{errorData.emailAddress}</div>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="w-100">
                                <div className={styles.inputLabel}>Contact Number (Optional)</div>
                                <input
                                    type="text"
                                    className={`${styles.formInput} ${errorData.mobileNumber ? styles.errorInput : ""}`}
                                    name="mobileNumber"
                                    value={inputData.mobileNumber}
                                    onChange={handleInput}
                                />
                                {errorData.mobileNumber && <div className="input-error-text">{errorData.mobileNumber}</div>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="w-100">
                                <div className={styles.inputLabel}>Your query</div>
                                <textarea
                                    rows={4}
                                    className={`${styles.formTextarea} ${errorData.query ? styles.errorInput : ""}`}
                                    name="query"
                                    value={inputData.query}
                                    onChange={handleInput}
                                ></textarea>
                                {errorData.query && <div className="input-error-text">{errorData.query}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="normal-button" disabled={loading} onClick={handleSubmit}>
                            {loading ? <CircularProgress size={12} style={{ color: "#f1bb46" }} /> : "Submit"}
                        </button>
                    </div>
                </div>
                <div className={`${styles.dataBox} mb-3 d-flex align-items-center`}>
                    <div className={styles.locationIcon}>
                        <MdPhone />
                    </div>
                    <div className="d-flex flex-column">
                        <div className={styles.cityName}>+91-9023053106</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
