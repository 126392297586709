import Authentication from "../service/entityService/authentication";
import ProfileService from "../service/entityService/profileDetails";
import JobService from "../service/entityService/jobService";
import ConnectService from "../service/entityService/connectService";

class ServiceProvider {
    authentication = new Authentication();
    profileService = new ProfileService();
    jobService = new JobService();
    connectService = new ConnectService();

    returnAuthenticationService = () => {
        return this.authentication;
    };

    returnProfileService = () => {
        return this.profileService;
    };

    returnJobService = () => {
        return this.jobService;
    };

    returnConnectService = () => {
        return this.connectService;
    };
}

export default ServiceProvider;
