export const apiEndpoints = {
    signUp: "/signup",
    validateEmail: "/validateEmail",
    login: "/login",
    validateProfile: "/validateProfile",
    getPersonalDetails: "/getPersonalDetails",
    updatePersonalDetails: "/updatePersonalDetails",
    getEducationalDetails: "/getEducationDetails",
    updateEducationalDetails: "/updateEducationDetails",
    getProfessionalDetails: "/getProfessionalDetails",
    updateProfessionalDetails: "/updateProfessionalDetails",
    getConnectionList: "/getFriendList",
    getReceivedRequests: "/getRecievedRequest",
    getSentRequest: "/getSendRequest",
    respondRequest: "/respondRequest",
    unfriend: "/removeFromFriendList",
    getSuggestedUserList: "/getSujjestedUserList",
    getNotifications: "/getNotification",
    sendFriendRequest: "/sendRequest",
    getMessages: "/messageQuery",
    sendMessage: "/sendMessage",
    deleteMessage: "/deleteMessage",
    scheduleMeeting: "/scheduleMeeting",
    chatHistory: "/chatHistory",
    readMessages: "/readMessage",
    scheduledMeetList: "/getScheduleMeetingList",
    uploadProfileImage: "/uploadProfileImage",
    globalSearch: "/globalSearch",
    getUserProfile: "/viewUserProfile",
    uploadCoverImage: "/uploadCoverImageImage",
    requestLiveSessionApproval: "/requestForLiveSessionApproval",
    deleteSkill: "/deleteSkillFromUser",
    deleteLanguage: "/deleteLanguageFromUser",
    getSkills: "/getSkillList",
    deleteProfessionalDetail: "/deleteProfessionalDetail",
    viewJob: "/viewJob",
    ratingUser: "/ratingUser",
    getUserRateRemark: "/getUserRateRemark",
    getPostedJob: "/getPostedJob",
    applyJob: "/applyJob",
    deleteJob: "/job/delete",
    getAppliedJobList: "/getAppliedJobList",
    getJobApplicants: "/getAppliedJobUserList",
    addProject: "/professional/project/saveProject",
    deleteProject: "/professional/project/deleteProject",
    updateProject: "/professional/project/updateProject",
    bookmarkJob: "/job/makeBookmark",
    removeBookmark: "/job/removeBookmark",
    reportJob: "/job/report",
    postJob: "/postJob",
    getAdvJobSearchData: "/getAdvJobSearchData",
    saveSkill: "/saveSkill",
    cancelRequest: "/deleteRequest",
    acceptMeetingRequest: "/acceptMeetingRequest",
    deleteEducationalDetail: "/deleteEducationDetail",
    saveMeetLink: "/saveMeetLink",
    reportUser: "/reportUser",
    getTopHeaderUnReadCount: "/getTopHeaderUnReadCount",
    openForSession: "/openForSession",
    readNotification: "/readNotification",
    validateOTP: "/validateOTP",
    generateOTP: "/generateOTP",
    sendRequestMail: "/sendRequestMail",
    respondRequestMail: "/respondRequestMail",
    scheduleMeetingMail: "/scheduleMeetingMail",
    applyJobMail: "/applyJobMail",
    contactUs: "/contactUs",
    postJobMail: "/postJobMail",
    getBookmarkJobListByUser: "/job/getBookmarkJobListByUser",
    sendOtp: "/password/forgot/sendOTP",
    verifyOtp: "/password/forgot/verifyOTP",
    changePassword: "/password/forgot/change",
    changeUserPassword: "/password/change/sendOTP",
    changeVerifyOtp: "/password/change/verifyOTP",
    changeToNewPassword: "/password/change",
    savePaymentData: "/payment/save",
    checkUserRole: "/user/role",

    //admin routes
    getLiveSessionRequestList: "/admin/user/getLiveSessionApprovalRequestUserList",
    approveUser: "/admin/user/acessForLiveSession",
    getUserList: "/admin/user/userList",
    blockUser: "/admin/user/blockUserOrActive",
    remarks: "/admin/user/getRateRemarkListForUser",
    profile: "/admin/user/viewUserProfile",
    reportedUser: "/admin/user/getReportedUserList",
    userChat: "/admin/message/getMessageList",
    getReportedListForUser: "/admin/user/getReportedListForUser",
    getPaymentList: "/admin/payment/list",
};

export const navigationRoutes = {
    home: "/",
    profile: "/profile",
    connections: "/connections",
    userProfile: "/user-profile",
    jsSearchResult: "/search-results",
};
