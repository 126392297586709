import React, { useEffect, useState } from "react";
import styles from "./scheduleSessionDrawer.module.css";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { socket } from "../../../Services/socket";
import { getToken } from "../../../Services/authService";
import { scheduleMeetingMail } from "../../../Services/commonService";

const ScheduleSessionDrawer = () => {
    const dispatch = useDispatch();
    const { scheduleSessionDrawer } = useSelector((state) => state.nopersist);
    const { selectedChat } = useSelector((state) => state.chatting);
    const { personalDetails } = useSelector((state) => state.persist);

    const initialData = {
        meet_start_time: "",
        meet_end_time: "",
        message: "",
        meeting_url: "",
        comment: "",
        meetDate: "",
        meetTimeStart: "",
        meetTimeEnd: "",
    };
    const [inputData, setInputData] = useState(initialData);
    const [newInputData, setNewInputData] = useState({});
    const handleInput = (event) => {
        const { name, value } = event.target;
        setInputData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        // const res = await postTReq(apiEndpoints.scheduleMeeting, { reciever_id: selectedChat.emailAddress, ...newInputData });
        // if (res && !res.error) {
        //     getChatByUserEmail(selectedChat.emailAddress, 0, 10);
        //     setInputData(initialData);
        //     dispatch(modalController({ modal: "scheduleSessionDrawer", state: false }));
        // } else {
        //     console.log(res.error);
        // }

        socket.emit("messageSent", {
            requestBody: { reciever_email: selectedChat.emailAddress, ...newInputData },
            authTok: getToken(),
            receiverUserId: selectedChat.userId,
            senderUserId: personalDetails.userId,
            type: "meeting",
        });
        setInputData(initialData);
        dispatch(modalController({ modal: "scheduleSessionDrawer", state: false }));
        scheduleMeetingMail(personalDetails.fullName, selectedChat.fullName, selectedChat.emailAddress, new Date(newInputData.meetDate).getTime(), newInputData.meet_start_time);
    };

    useEffect(() => {
        let x = JSON.parse(JSON.stringify(inputData));
        let meetStart = `${x.meetDate}T${x.meetTimeStart}:00.000Z`;
        let meetEnd = `${x.meetDate}T${x.meetTimeEnd}:00.000Z`;
        x["meet_start_time"] = new Date(meetStart).getTime();
        x["meet_end_time"] = new Date(meetEnd).getTime();
        setNewInputData(x);
    }, [inputData]);

    return (
        <Offcanvas show={scheduleSessionDrawer} placement="bottom" className="transparent-offcanvas" onHide={() => dispatch(modalController({ modal: "scheduleSessionDrawer", state: false }))}>
            <Offcanvas.Header closeButton className={styles.headingText}>
                Schedule Session
            </Offcanvas.Header>
            <Offcanvas.Body className={`${styles.offcanvasBody} p-0`}>
                <div className={styles.contentContainer}>
                    <div className="d-flex align-items-center mb-3">
                        <div>To: </div>
                        <div className={styles.namePill}>{selectedChat && selectedChat.fullName ? selectedChat.fullName : "-"}</div>
                    </div>
                    <div className={styles.eachInputSection}>
                        <div>Title</div>
                        <input type="text" placeholder="e.g. Live Session with John Doe" className={styles.eachInput} name="message" onChange={handleInput} />
                    </div>
                    <div className={styles.eachInputSection}>
                        <div>Live Session Date</div>
                        <input type="date" className={styles.eachInput} name="meetDate" onChange={handleInput} />
                    </div>
                    <div className={styles.eachInputSection}>
                        <div>Session Time</div>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <input type="time" className={styles.eachTimeInput} name="meetTimeStart" onChange={handleInput} />
                            <div>to</div>
                            <input type="time" className={styles.eachTimeInput} name="meetTimeEnd" onChange={handleInput} />
                        </div>
                    </div>
                    <div className={styles.eachInputSection}>
                        <div>Share Meet Link</div>
                        <input type="text" placeholder="e.g. https://meet.google.com/abc-def-ghi" className={styles.eachInput} name="meeting_url" onChange={handleInput} />
                    </div>
                    <div className={styles.eachInputSection}>
                        <div>Add Description</div>
                        <textarea type="text" rows={5} placeholder="Add description" className={styles.eachTextarea} name="comment" onChange={handleInput} />
                    </div>
                </div>
                <div className={styles.submitButtonContainer}>
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default ScheduleSessionDrawer;
