import React from "react";
import "../../style/PersonalDetails.css";
import { useNavigate } from "react-router-dom";
import EditJob from "../../component/Modal/EditJob";

const EditJobContainer = () => {
    const navigateTo = useNavigate();

    return (
        <React.Fragment>
            <div className="container-fluid pageBody">
                <div className="row">
                    <div className="col-12 pl-md-5 pt-3">
                        <span style={{ cursor: "pointer" }} onClick={() => navigateTo(-1)}>
                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            &nbsp;&nbsp;Back
                        </span>
                    </div>
                </div>
                <div className="row pt-2 pl-md-5">
                    <div className="col-xs-12 col-sm-12 col-md-8 ant-card">
                        <div className="ant-card-head">
                            <h3>
                                <b>Edit Job</b>
                            </h3>
                        </div>
                        <div className="ant-card-bodyMinor">
                            <EditJob />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditJobContainer;
