import React from 'react';
import '../../style/accordion.css'

const SearchConnection = (props) => {
    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='search_container'>
                    <div className='row mt-5'>
                        <div className='col-1 no_col_padding'></div>
                        <div className={props.mobileView ? 'col-7 mx-0 px-0':'col-7 mx-0 px-0 no_col_padding'}>
                            <input
                                type='text'
                                name='connectionName'
                                className='searchItem'
                                placeholder='Search by connections'
                                onChange={e => props.updateName(e.target.value)}
                            />
                        </div>
                        <div className='col-3 mx-0 px-0'>
                            <button onClick={() => { props.onSearch() }} id='search_connection_button'>
                                Search
                            </button>
                        </div>
                        <div className='col-md-1 no_col_padding'></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SearchConnection;