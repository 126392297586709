import React, { useEffect, useState } from "react";
import styles from "./ReportedUser.module.css";
import { MdBlock } from "react-icons/md";
import { getReportedUser } from "../../../Services/commonService";
import { useSelector } from "react-redux";
import { CircularProgress, Tooltip } from "@mui/material";
import { IoPersonOutline } from "react-icons/io5";
import { BsPersonCheckFill } from "react-icons/bs";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import UserReportList from "../UserReportList/UserReportList";

const ReportedUser = () => {
    const { allReportedList } = useSelector((state) => state.admin);

    const [loadingList, setLoadingList] = useState([]);

    useEffect(() => {
        getReportedUser(0, 10);
    }, []);

    const handleUserBlockOrReactive = async (email, status, id) => {
        const newLoading = loadingList.filter((curElem) => {
            return curElem !== id;
        });
        const res = await postTReq(apiEndpoints.blockUser, {
            emailAddress: email,
            is_blocked: status,
        });
        if (res && !res.error) {
            getReportedUser(0, 10);
            setLoadingList(newLoading);
        } else {
            console.log(res.error);
            setLoadingList(newLoading);
        }
    };

    const [emailOfUser, setEmailOfUser] = useState("");
    const openUserReports = (email, blockStatus) => {
        setEmailOfUser(email);
    };

    return (
        <React.Fragment>
            {emailOfUser ? (
                <UserReportList emailAddress={emailOfUser} emailSetter={setEmailOfUser} reportedUserList={allReportedList} />
            ) : (
                <div>
                    <div className={styles.mainHeading}>REPORTED USER</div>
                    <div className={styles.actualTable}>
                        <div className={styles.fittingBox}>
                            <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                                <div className={styles.headingName} style={{ paddingLeft: "1rem" }}>
                                    S.NO
                                </div>
                                <div className={styles.headingName}>NAME</div>
                                <div className={styles.headingName}>EMAIL</div>
                                <div className={`${styles.headingName} text-center`}>NUMBER OF REPORTS</div>
                                <div className={`${styles.headingName} text-center`}>ACTIONS</div>
                            </div>

                            {/* details */}
                            <div className="d-flex flex-column mt-2">
                                {allReportedList?.map((curelem, index) => {
                                    return (
                                        <>
                                            <div
                                                className="d-flex align-items-center py-3"
                                                style={curelem.is_blocked ? { backgroundColor: "#f6f6f6", borderBottom: "2px solid #e5e5e6" } : { borderBottom: "2px solid #e5e5e6" }}
                                            >
                                                <div className={`${styles.detailsText} ps-3`}>{index + 1}.</div>
                                                <div className={styles.detailsText}>{curelem.fullName}</div>
                                                <div className={styles.detailsText}>{curelem.emailAddress}</div>
                                                <div className={`${styles.detailsText} text-center`}>{curelem.report_count}</div>
                                                <div className={`${styles.detailsText} d-flex justify-content-center`}>
                                                    {loadingList.includes(curelem.userId) ? (
                                                        <button className="small-button">
                                                            <CircularProgress size={12} style={{ color: "#444444" }} />
                                                        </button>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Tooltip title="Block User">
                                                                <button
                                                                    className={`${curelem.is_blocked ? "small-active-button" : "small-delete-button"} me-1`}
                                                                    onClick={() => {
                                                                        if (curelem.is_blocked) {
                                                                            setLoadingList((prev) => {
                                                                                return [...prev, curelem.userId];
                                                                            });
                                                                            handleUserBlockOrReactive(curelem.emailAddress, false, curelem.userId);
                                                                        } else {
                                                                            setLoadingList((prev) => {
                                                                                return [...prev, curelem.userId];
                                                                            });
                                                                            handleUserBlockOrReactive(curelem.emailAddress, true, curelem.userId);
                                                                        }
                                                                    }}
                                                                >
                                                                    {curelem.is_blocked ? <BsPersonCheckFill /> : <MdBlock />}
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip title="View User Reports">
                                                                <button className="small-secondary-button" onClick={() => openUserReports(curelem.emailAddress, curelem.is_blocked)}>
                                                                    <IoPersonOutline />
                                                                </button>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ReportedUser;
