import React, { useEffect, useMemo, useState } from "react";
import "../../style/PersonalDetails.css";
import "../../style/CompleteProfile.css";
import "../../style/Resume.css";
import { Button } from "react-bootstrap";
import ResumeNew1 from "../ResumeTemplate/ResumeNew1";
import ResumeNew2 from "../ResumeTemplate/ResumeNew2";
import ResumeNew3 from "../ResumeTemplate/ResumeNew3";
import ResumeNew4 from "../ResumeTemplate/ResumeNew4";
import Resume420 from "../ResumeTemplate/Resume420";
import { useParams } from "react-router-dom";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import ShiviResume from "../ResumeTemplate/ShiviResume";
import ShiviResume2 from "../ResumeTemplate/ShiviResume2";
import NewResume69 from "../ResumeTemplate/NewResume69";
import PlatformSharingModal from "../Modal/PlatformSharingModal";

const ResumeBuilder = (props) => {
  const { id } = useParams();
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  // let header = headerUtility.returnHeaderContainingToken();
  // let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [showP, setShowP] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const handleClose = () => {
    setShowP(false);
  };
  const handleShow = () => {
    setShowP(true);
    setAllowed(true);
  };
  const updateAllowed = () => {
    setAllowed(false);
  };
  const returnSelectedResume = (resume) => {
    switch (resume) {
      // case "1":
      //   return <ResumeOne />;
      // case "2":
      //   return <ResumeTwo />;
      // case "3":
      //   return <ResumeThree />;
      // case "4":
      //   return <ResumeFour />;
      // case "5":
      //   return <ElisonaResume />;
      //   break;
      // case "6":
      //   return <LauraResume />;
      // case "7":
      //   return <RovenAndresen />;
      // case "8":
      //   return <shiviresume3 />;
      // case "9":
      //   return <AlexSimpson />;
      case "10":
        return <ResumeNew1 />;
      case "11":
        return <ResumeNew2 />;
      case "12":
        return <ResumeNew3 />;
      case "16":
        return <ShiviResume />;
      case "17":
        return <ShiviResume2 />;
      case "13":
        return <ResumeNew4 />;
      case "14":
        return <NewResume69 />;
      case "15":
        return <Resume420 />;
      default:
        return <ResumeNew1 />;
    }
  };

  // const printPageArea = (areaID) => {
  //   var printContent = document.getElementById(areaID);
  //   var WinPrint = window.open("", "_blank", "width=1200,height=650");
  //   if (printContent !== null && WinPrint !== null) {
  //     WinPrint.document.write(printContent.innerHTML);
  //     WinPrint.document.write('<link rel="stylesheet" href="../../style/PersonalDetails.css" type="text/css" />');
  //     WinPrint.document.close();
  //     WinPrint.focus();
  //     WinPrint.print();
  //     WinPrint.close();
  //     return true;
  //   }
  // };
  const printUsingPrintJs = (areaID) => {
    // printJS({
    //     printable: areaID,
    //     type: "html",
    //     targetStyles: ['*']
    // });
    let ele = document.getElementById(areaID);
    if (ele !== null) {
      let val = ele.innerHTML;
      let jsonVal = JSON.stringify(val);
      console.log(jsonVal);
      serviceProvider.returnProfileService().createPdf(headerUtility.returnHeaderContainingToken(), { content: val, name: personalData.userId });
    }
  };

  // const returnTemplateString = (value) => {
  //   return `${value}`;
  // };

  useEffect(() => {
    const getUserInfo = () => {
      serviceProvider
        .returnProfileService()
        .getPersonalDetail(headerUtility.returnHeaderContainingToken())
        .then((res) => {
          if (res.status === "200 OK") {
            setPersonalData(res.data);
          }
        });
    };
    getUserInfo();
  }, [headerUtility, serviceProvider]);
  return (
    <React.Fragment>
      <PlatformSharingModal allowed={allowed} updateAllowed={updateAllowed} printResume={printUsingPrintJs} isShow={showP} handleClose={handleClose} />
      <div className="container-fluid" style={{ background: "#FBFBFF" }} id="profilePage">
        <div className="row pt-3 pb-3">
          <h4 className="educationalDegree">
            <b>Your Resume:</b>
          </h4>
        </div>
        <div className="resumeBuilderMiddleSection" id="downloadResume">
          {returnSelectedResume(id)}
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-1"></div>
        </div>
        <div className="row pt-3">
          <div className="col-sm-12 col-md-6 col-lg-6"></div>
          <div className="col-sm-12 col-md-4 col-lg-4"></div>
          <div className="col-sm-12 col-md-2 col-lg-2">
            {/* <Button className="rounded-pill loginButton" onClick={() => printUsingPrintJs("downloadResume")}>Print Resume</Button> */}
            <Button className="rounded-pill loginButton" onClick={() => handleShow()}>
              Print Resume
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResumeBuilder;
