import React, { useEffect } from "react";
import { getJobsBookmarkedByUser, toasterControl } from "../../Services/commonService";
import { useSelector } from "react-redux";
import styles from "./BookmarkJobs.module.css";
import { IoSearchOutline } from "react-icons/io5";
import { GoClockFill } from "react-icons/go";
import { getDurationString } from "../../Services/commons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { BsFillBookmarkXFill } from "react-icons/bs";
import { delReq } from "../../Services/api";
import { apiEndpoints } from "../../PrEnums/prEnums";

const BookmarkJobs = () => {
    const navigateTo = useNavigate();
    const { jobsBookmarkedByUser } = useSelector((state) => state.jobslice);

    const removeBookmark = async (bookId) => {
        const res = await delReq(apiEndpoints.removeBookmark, {
            job_bookmarked_id: bookId,
        });
        if (res && !res.error) {
            getJobsBookmarkedByUser(0, 10);
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "Something went wrong");
        }
    };

    useEffect(() => {
        getJobsBookmarkedByUser(0, 10);
    }, []);
    return (
        <>
            <div className={styles.container}>
                {jobsBookmarkedByUser && jobsBookmarkedByUser.data && jobsBookmarkedByUser.data.length > 0 ? (
                    jobsBookmarkedByUser.data.map((curElem) => {
                        return (
                            <div className={styles.jobCard}>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div>
                                        <div className={styles.joiningType}>{curElem.job_data.joiningType}</div>
                                        <div className={styles.jobTitle}>{curElem.job_data.jobTitle}</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title="View Job">
                                            <div className={`${styles.actionButton} me-2`} onClick={() => navigateTo(`/view-job/${curElem.job_data.job_id}`)}>
                                                <IoSearchOutline />
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Remove Bookmark">
                                            <div className={`${styles.deleteAction} me-2`} onClick={() => removeBookmark(curElem.job_bookmarked_id)}>
                                                <BsFillBookmarkXFill />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 flex-wrap">
                                    {curElem.job_data.job_type_details.map((cur) => {
                                        return <div className={styles.typePill}>{cur.job_type}</div>;
                                    })}
                                </div>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Role:</div>
                                        <div className={styles.dataValue}>{curElem.job_data.role}</div>
                                    </div>
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Location:</div>
                                        <div className={styles.dataValue}>{curElem.job_data.location}</div>
                                    </div>
                                    <div className="d-flex me-3">
                                        <div className={`${styles.label} me-1`}>Job Stream:</div>
                                        <div className={styles.dataValue}>{curElem.job_data.jobStreams}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className={`${styles.label} mb-1`}>Skills Required:</div>
                                    <div className={`${styles.dataValue} d-flex flex-wrap`}>
                                        {curElem.job_data.skillset.map((cur) => {
                                            return <div className={styles.skillPill}>{cur.skill}</div>;
                                        })}
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end align-items-center ${styles.uploadTime}`}>
                                    <GoClockFill color="#888888" fontSize={12} className="me-1" />
                                    {getDurationString(curElem.job_data.date)}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="colorback-nothing-to-show">No Created Jobs</div>
                )}
            </div>
        </>
    );
};

export default BookmarkJobs;
