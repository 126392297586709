import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paginationPageNum: 1,
    paginationRecordsNum: 10,
    paginationTotalPages: 1,
    actionToPerformAfterLogin: {},
};

export const generalSlice = createSlice({
    name: "generalSlice",
    initialState,
    reducers: {
        savePaginationPageNum: (state, action) => {
            state.paginationPageNum = action.payload;
        },
        savePaginationRecordsNum: (state, action) => {
            state.paginationRecordsNum = action.payload;
        },
        savePaginationTotalPages: (state, action) => {
            state.paginationTotalPages = action.payload;
        },
        saveActionToPerformAfterLogin: (state, action) => {
            state.actionToPerformAfterLogin = action.payload;
        },
        clearActionToPerformAfterLogin: (state) => {
            state.actionToPerformAfterLogin = {};
        },
    },
});

export const { savePaginationPageNum, savePaginationRecordsNum, savePaginationTotalPages, saveActionToPerformAfterLogin, clearActionToPerformAfterLogin } = generalSlice.actions;

export default generalSlice.reducer;
