import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toasterController } from "../../Redux/Slices/toasterSlice";

const RequestSentSuccessfully = () => {
  const dispatch = useDispatch();
  const { requestSentSuccessfully } = useSelector((state) => state.toaster);
  return (
    <ToastContainer className="toaster-top-right">
      <Toast show={requestSentSuccessfully === true} bg="success" onClose={() => dispatch(toasterController({ toaster: "requestSentSuccessfully", state: false }))} autohide delay={4000}>
        <Toast.Body style={{ color: "#ffffff" }}>Friend request sent successfully.</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default RequestSentSuccessfully;
