import { BsFillPersonCheckFill, BsFillPersonVcardFill, BsSearch } from "react-icons/bs";
import { RiBuilding2Fill } from "react-icons/ri";
import { GrMail } from "react-icons/gr";
import { FaHandshakeSimple } from "react-icons/fa6";
import { HiChatBubbleLeftRight, HiMiniVideoCamera } from "react-icons/hi2";
import { MdManageAccounts, MdPhone } from "react-icons/md";

export const headerLinksArray = [
    {
        forKey: 1,
        label: "Profile",
        icon: false,
        route: false,
        children: [
            {
                forKey: 11,
                label: "View My Profile",
                route: "/profile",
            },
        ],
        withoutLogin: false,
    },
    {
        forKey: 2,
        label: "Home",
        route: "/",
        children: false,
        withoutLogin: true,
    },
    {
        forKey: 3,
        label: "Post Jobs",
        route: "/postJob",
        children: false,
        withoutLogin: false,
    },
    {
        forKey: 6,
        label: "Live Session Search",
        route: "/search-results",
        children: false,
        withoutLogin: true,
    },
    {
        forKey: 4,
        label: "Connections",
        route: "/connections",
        children: false,
        withoutLogin: false,
    },
    {
        forKey: 5,
        label: "Message",
        route: "/message",
        children: false,
        onlyIcon: {
            state: true,
            icon: HiChatBubbleLeftRight,
        },
        withoutLogin: false,
    },
    {
        forKey: 6,
        label: "Admin Panel",
        route: "/admin-panel",
        children: false,
        onlyIcon: {
            state: true,
            icon: MdManageAccounts,
        },
        withoutLogin: false,
    },
];

export const easyStepsArray = [
    {
        step: 1,
        label: "Create Account",
        icon: BsFillPersonCheckFill,
    },
    {
        step: 2,
        label: "Make Profile",
        icon: BsFillPersonVcardFill,
    },
    {
        step: 3,
        label: "Search Job Reference",
        icon: BsSearch,
    },
    {
        step: 4,
        label: "Connect With Referrals",
        icon: FaHandshakeSimple,
    },
    {
        step: 5,
        label: "Get Live Session",
        icon: HiMiniVideoCamera,
    },
];

export const footerLinks = [
    {
        label: "Important Links",
        linksArray: [
            {
                label: "About Us",
                icon: false,
                route: "/about-us",
            },
            {
                label: "Refund And Cancellation Policy",
                icon: false,
                route: "/refund-and-cancellation",
            },
            {
                label: "Terms & Conditions",
                icon: false,
                route: "/terms-and-conditions",
            },
            {
                label: "Privacy Policy",
                icon: false,
                route: "/privacy-policy",
            },
        ],
    },
    // {
    //     label: "Services",
    //     linksArray: [
    //         {
    //             label: "Resume Builder",
    //             icon: false,
    //             route: false,
    //         },
    //         {
    //             label: "Master Video",
    //             icon: false,
    //             route: false,
    //         },
    //         {
    //             label: "Messages",
    //             icon: false,
    //             route: false,
    //         },
    //     ],
    // },
    {
        label: "Contact Details",
        linksArray: [
            {
                label: "Contact Us",
                icon: RiBuilding2Fill,
                route: "/contact-us",
            },
            {
                label: "service@jobsifarish.com",
                icon: GrMail,
                route: "/contact-us",
            },
            {
                label: "+91-9023053106",
                icon: MdPhone,
                route: "/contact-us",
            },
        ],
    },
];

export let textList = [
    {
        id: 0,
        text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
    },
    {
        id: 1,
        text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
    },
    {
        id: 2,
        text: 'The old HTML standard, HTML4, required a script to have a type. Usually it was type="text/javascript". It’s not required anymore. Also, the modern HTML standard totally changed the meaning of this attribute. Now, it can be used for JavaScript modules. But that’s an advanced topic, we’ll talk about modules in another part of the tutorial.',
    },
];

export let skillList = [
    {
        id: 0,
        name: "React JS",
    },
    {
        id: 1,
        name: "JavaScript",
    },
    {
        id: 2,
        name: "Node JS",
    },
];

export const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const aboutUsData = [
    {
        heading: "About Us",
        content:
            "Welcome to JobSifarish, where bridging the gap between experience and aspiration is our mission. In a world teeming with untapped potential and a wealth of knowledge, our platform serves as a dynamic hub for professionals and newcomers alike, facilitating connections that matter.",
        headFontSize: "20px",
        headingStyle: {
            fontSize: "20px",
            backgroundColor: "#444444",
            color: "#f1bb46",
            padding: "0.5rem 1rem",
            borderRadius: "10px",
            marginBottom: "0.5rem",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: false,
    },
    {
        heading: "Our Vision",
        content:
            "At JobSifarish, we envision a future where knowledge-sharing transcends traditional barriers. We believe in creating opportunities for freshers to leapfrog the learning curve by connecting with seasoned professionals through live chat and video calls. This not only democratizes learning but also enriches the professional community by weaving a tapestry of shared experiences and insights.",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: false,
    },
    {
        heading: "What We Do",
        content:
            "At JobSifarish, we envision a future where knowledge-sharing transcends traditional barriers. We believe in creating opportunities for freshers to leapfrog the learning curve by connecting with seasoned professionals through live chat and video calls. This not only democratizes learning but also enriches the professional community by weaving a tapestry of shared experiences and insights.",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "Live Knowledge Sharing",
                text: "Freshers have the unique opportunity to engage in one-on-one live sessions with experts, gaining invaluable insights and practical knowledge. These interactive sessions are designed to foster a deeper understanding of the industry, enhancing the learning experience beyond the conventional.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Monetized Expertise",
                text: "Professionals are rewarded for their contribution to nurturing the next generation of talent. By sharing their expertise, they not only earn but also contribute to creating a vibrant, knowledge-rich ecosystem.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Career Opportunities",
                text: "Our platform goes beyond learning; it's a springboard for career advancement. Professionals can post job opportunities, allowing freshers to apply directly through JobSifarish. This creates a streamlined path from learning to employment, all within a supportive community.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "Why Choose Us?",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "Direct Access to Industry Experts",
                text: "Unprecedented access to professionals ready to share their knowledge in real-time.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Interactive Learning Experience",
                text: "Engage with content that goes beyond textbooks and lectures, diving into real-world applications and insights.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Monetize Your Knowledge",
                text: "A unique opportunity for professionals to give back to the community while earning.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Seamless Job Application Process",
                text: "A holistic platform that not only nurtures talent but also connects them with potential career opportunities.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
];

export const refundCancellationData = [
    {
        heading: "Refund and Cancellation Policy",
        content:
            "At JobSifarish, we strive to ensure that every interaction on our platform enriches your professional journey. We understand that plans can change, and flexibility is essential. Below, you'll find our policies on refunds and cancellations, designed with your best interests in mind.",
        headFontSize: "20px",
        headingStyle: {
            fontSize: "20px",
            backgroundColor: "#444444",
            color: "#f1bb46",
            padding: "0.5rem 1rem",
            borderRadius: "10px",
            marginBottom: "0.5rem",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: false,
    },
    {
        heading: "Cancellation Policy",
        content:
            "At JobSifarish, we envision a future where knowledge-sharing transcends traditional barriers. We believe in creating opportunities for freshers to leapfrog the learning curve by connecting with seasoned professionals through live chat and video calls. This not only democratizes learning but also enriches the professional community by weaving a tapestry of shared experiences and insights.",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "Initiating Cancellations",
                text: "Users have the flexibility to cancel a scheduled live session up to 24 hours before the start time. To cancel, simply navigate to your upcoming sessions in your account dashboard and select the session you wish to cancel.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Last-Minute Cancellations",
                text: "Cancellations made less than 24 hours before the session start time are considered last-minute. While we understand emergencies can occur, please note that last-minute cancellations are ineligible for refunds due to the time committed by our professionals.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "Refund Policy",
        content: "Understanding the value of your time and investment is paramount to us. Our refund policy is designed to ensure fairness to both our users and professionals:",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "Sessions Longer Than 30 Minutes",
                text: "We believe that a session of 30 minutes or more provides substantial value. Therefore, we do not offer refunds for sessions that meet or exceed this duration.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Sessions Shorter Than 30 Minutes",
                text: "If a session is shorter than 30 minutes, the circumstances will be reviewed on a case-by-case basis. Both the user and the professional involved have the option to:\nReconnect: Opt for a new session to complete the learning experience at no additional cost.\nRefund: Request a refund. To ensure fairness and accuracy, the decision for a refund will be based on the details surrounding the",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "How to Request a Refund or Reconnection",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "Report the Issue",
                text: 'Immediately after a session shorter than 30 minutes, report the issue through your account dashboard under the "My Sessions" tab.',
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Select Your Preference",
                text: "Choose whether you prefer to reconnect with the professional for another session or request a refund.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Review Process",
                text: "Our team will review the session details. For refund requests, please allow up to 7 business days for the review process. We may contact both parties for additional information.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Outcome Notification",
                text: "You will be notified via email about the outcome of your request.",
                labelStyling: {
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "Minimum Session Fee",
        content: "Please note that the minimum cost for a session on our platform is 900 Ruppees. This pricing reflects the expert quality and value of the knowledge shared by our professionals.",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: false,
    },
    {
        heading: "Final Thoughts",
        content:
            "At JobSifarish, we're dedicated to ensuring that every engagement on our platform is meaningful and rewarding. If you have questions or need further assistance with a refund or cancellation, please don't hesitate to contact our support team.",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: false,
    },
];

export const termsAndConditionsData = [
    {
        heading: "Terms & Conditions",
        content:
            "Welcome to JOBSIFARISH. These terms and conditions outline the rules and regulations for the use of Jobsifarish's Website, located at jobsifarish.com. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Jobsifarish if you do not agree to take all of the terms and conditions stated on this page.",
        headFontSize: "20px",
        headingStyle: {
            fontSize: "16px",
            backgroundColor: "#444444",
            color: "#f1bb46",
            fontWeight: "600",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            marginBottom: "0.5rem",
        },
        contentStyle: {
            fontSize: "12px",
        },
        points: false,
    },
    {
        heading: "1. Definitions",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "• User",
                text: "Anyone who accesses or uses our website.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "• Expert",
                text: "Subject matter experts who provide services and may post job opportunities.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "• Content",
                text: "All material, including text, images, and other multimedia, created and posted on our platform.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "• Services",
                text: "Online one-on-one sessions and job postings provided through our website.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "2. User Accounts",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• You must be at least 16 years old to create an account.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Users are responsible for maintaining the confidentiality of their account information.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Jobsifarish reserves the right to suspend or terminate accounts that are found to be in violation of these terms.",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "3. Services Description",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• We provide a platform for experts to offer one-on-one sessions and to post job listings.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Fees for these services will be clearly stated and may vary based on the expert and the type of service offered.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "4. User Conduct",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• Users are expected to behave respectfully and legally when using Jobsifarish.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Prohibited activities include harassment, spreading malicious software, and infringing on others' intellectual property.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "5. Content Ownership and Rights",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• Users retain ownership of content they post but grant Jobsifarish a license to use, distribute, and display this content.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Jobsifarish does not claim ownership of content posted by users but has the right to remove content that violates these terms or laws.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },

    {
        heading: "7. Disclaimers and Limitation of Liability",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: '• Jobsifarish is provided on an "as is" and "as available" basis.',
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Jobsifarish is not liable for any damages resulting from the use of the website.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },

    {
        heading: "8. Indemnification",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• Users agree to indemnify and hold harmless Jobsifarish from any claims, damages, and expenses arising from their use of the website.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },

    {
        heading: "9. Amendments and Termination",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• Jobsifarish reserves the right to amend these terms at any time. Continued use of the website after such changes constitutes consent to the new terms.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },

    {
        heading: "10. Contact Information",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• For any questions or concerns about these terms, please contact us at service@jobsifarish.com.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },

    {
        heading: "11. Miscellaneous",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• If any part of these terms is held to be invalid or unenforceable, the remaining parts will continue in effect.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
];

export const privacyPolicyData = [
    {
        heading: "Privacy Policy",
        content: "Last updated: May 2024.",
        headFontSize: "20px",
        headingStyle: {
            fontSize: "16px",
            backgroundColor: "#444444",
            color: "#f1bb46",
            fontWeight: "600",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            marginBottom: "0.5rem",
        },
        contentStyle: {
            fontSize: "12px",
        },
        points: [
            {
                label: "",
                text: "At Jobsifarish, accessible from www. Jobsifarish.com one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Jobsifarish and how we use it.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "General Data Protection Regulation (GDPR)",
        content: "",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "We are a Data Controller of your information.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "Jobsifarish legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Jobsifarish needs to perform a contract with you",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• You have given Jobsifarish permission to do so",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Processing your personal information is in Jobsifarish legitimate interests",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Jobsifarish needs to comply with the law",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "Jobsifarish will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information, we hold about you and if you want it to be removed from our systems, please contact us.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "Information we collect",
                text: "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
    {
        heading: "How we use your information",
        content: "We use the information we collect in various ways, including to:",
        headingStyle: {
            fontSize: "16px",
            fontWeight: "600",
        },
        contentStyle: {
            fontSize: "14px",
        },
        points: [
            {
                label: "",
                text: "• Provide, operate, and maintain our website",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Improve, personalize, and expand our website",
                labelStyling: {
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Understand and analyze how you use our website",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Develop new products, services, features, and functionality",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Send you emails",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
            {
                label: "",
                text: "• Find and prevent fraud",
                labelStyling: {
                    fontSize: "142500",
                    color: "#444444",
                    marginRight: "0.25rem",
                },
                textStyling: {
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#444444",
                },
            },
        ],
    },
];
