import AboutUsPage from "../Pages/AboutUsPage/AboutUsPage";
import AdminPanel from "../Pages/AdminPanel/AdminPanel";
import Connections from "../Pages/Connections/Connections";
import ContactUsPage from "../Pages/ContactUsPage/ContactUsPage";
import JobsifarishChatCombine from "../Pages/JobsifarishChat/JobsifarishChatCombiner/JobsifarishChatCombine";
import JsSearchResult from "../Pages/JsSearchResult/JsSearchResult";
import NotificationPage from "../Pages/NotificationPage/NotificationPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import RefundCancellationPage from "../Pages/RefundCancellationPage/RefundCancellationPage";
import TermsAndConditionsPage from "../Pages/TermsAndConditionsPage/TermsAndConditionsPage";
import UserProfilePage from "../Pages/UserProfilePage/UserProfilePage";
import ViewJob from "../Pages/ViewJob/ViewJob";
import ApplicantsModal from "../component/Modal/ApplicantsModal";
import ConnectionModal from "../component/Modal/ConnectionModal";
// import Profile from "../component/Profile/Profile";
import ViewProfile from "../component/Profile/ViewProfile";
import ResumeSelector from "../component/ResumeBuilder/ResumeSelector";
import ChangePassword from "../container/CreateProfileContainer/ChangePassword";
import CreateProfile from "../container/CreateProfileContainer/CreateProfile";
import FilteredConnections from "../container/HomeContainer/FilteredConnections";
import FilteredJobs from "../container/HomeContainer/FilteredJobs";
import HomeContainer from "../container/HomeContainer/HomeContainer";
import JobInfo from "../container/JobInfoContainer/JobInfo";
import JobInfoApply from "../container/JobInfoContainer/JobInfoApply";
import MasterVideo from "../container/MasterVideo/MasterVideo";
import EditJobContainer from "../container/PostJobContainer/EditJobContainer";
import PostJobContainer from "../container/PostJobContainer/PostJobContainer";
import ResumeBuilderContainer from "../container/ResumeBuilder/ResumeBuilderContainer";

export const routingArray = [
    {
        route: "/",
        element: <HomeContainer />,
        always: true,
    },
    {
        route: "/filterJobs",
        element: <FilteredJobs />,
        always: true,
    },
    {
        route: "/createProfile",
        element: <CreateProfile />,
        always: false,
    },
    {
        route: "/profile",
        element: <ProfilePage />,
        always: false,
    },
    {
        route: "/user-profile",
        element: <UserProfilePage />,
        always: false,
    },
    {
        route: "/resumeBuilder/:id",
        element: <ResumeBuilderContainer />,
        always: false,
    },
    {
        route: "/resumeBuilder",
        element: <ResumeSelector />,
        always: false,
    },
    {
        route: "/postJob",
        element: <PostJobContainer />,
        always: false,
    },
    {
        route: "/jobInfo",
        element: <JobInfo />,
        always: false,
    },
    {
        route: "/jobInfoApply",
        element: <JobInfoApply />,
        always: false,
    },
    {
        route: "/searchConnection",
        element: <FilteredConnections />,
        always: false,
    },
    {
        route: "/connections",
        element: <Connections />,
        always: false,
    },
    {
        route: "/editJob/:id",
        element: <EditJobContainer />,
        always: false,
    },
    {
        route: "/masterVideo",
        element: <MasterVideo />,
        always: false,
    },
    {
        route: "/message",
        element: <JobsifarishChatCombine />,
        always: false,
    },
    {
        route: "/changePassword",
        element: <ChangePassword />,
        always: false,
    },
    {
        route: "/notification",
        element: <NotificationPage />,
        always: false,
    },
    {
        route: "/viewProfile/:email",
        element: <ViewProfile />,
        always: false,
    },
    {
        route: "/connectedUser",
        element: <ConnectionModal />,
        always: false,
    },
    {
        route: "/applicants/:id",
        element: <ApplicantsModal />,
        always: false,
    },
    {
        route: "/search-results",
        element: <JsSearchResult />,
        always: false,
    },
    {
        route: "/view-job/:jobId",
        element: <ViewJob />,
        always: false,
    },
    {
        route: "/admin-panel",
        element: <AdminPanel />,
        always: false,
    },
    {
        route: "/about-us",
        element: <AboutUsPage />,
        always: false,
    },
    {
        route: "/refund-and-cancellation",
        element: <RefundCancellationPage />,
        always: false,
    },
    {
        route: "/contact-us",
        element: <ContactUsPage />,
        always: false,
    },
    {
        route: "/terms-and-conditions",
        element: <TermsAndConditionsPage />,
        always: false,
    },
    {
        route: "/privacy-policy",
        element: <PrivacyPolicyPage />,
        always: false,
    },
];
