import React, { useEffect, useState, Fragment } from "react";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../style/Resume.css";

const NewResume69 = (props) => {
  let serviceProvider = new ServiceProvider();
  let headerUtility = new HeaderUtility();
  let header = headerUtility.returnHeaderContainingToken();
  let profileService = serviceProvider.returnProfileService();
  let [personalData, setPersonalData] = useState();
  let [professionalData, setProfessionalData] = useState([]);
  let [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const getUserInfo = () => {
      profileService.getPersonalDetail(header).then((res) => {
        if (res.status === "200 OK") {
          let skills = res.data.skillset ? res.data.skillset : [];
          setSkillList(skills);
          setPersonalData(res.data);
          getUserInfoEducation();
        }
      });
    };
    const getUserInfoEducation = () => {
      profileService.getEducationDetail(header).then((res) => {
        if (res.status === "200 OK") {
          getProfessionalDetails();
        }
      });
    };

    const getProfessionalDetails = () => {
      profileService.getProfessionalDetais(header).then((res) => {
        if (res.status === "200 OK") {
          setProfessionalData(res.data);
        }
      });
    };
    getUserInfo();
  }, [header, profileService]);

  return (
    <React.Fragment>
      <div style={{ color: "#323233", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", border: "1px solid rgb(216, 216, 216)" }}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "175.65px" }}>
          <div style={{ display: "flex", flexDirection: "column", marginTop: "21.71px", marginLeft: "25.33px" }}>
            <div
              style={{
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                fontSize: "42.24px",
                fontWeight: "100",
                color: "#313133",
              }}
            >
              {personalData && personalData.firstName}
            </div>
            <div
              style={{
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                fontSize: "42.24px",
                fontWeight: " 700",
                color: "#313133",
              }}
            >
              {personalData && personalData.lastName}
            </div>
            <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "12px", color: "#313133", letterSpacing: " 0.3px", fontWeight: "600" }}>
              {professionalData.length > 0 ? professionalData[0].designation : ""}
            </div>
          </div>
          <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "9px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", color: "#313133", marginTop: "54.5px", marginRight: "58.08px" }}>
              <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+101.svg" alt="phonepic" />
              <p style={{ marginLeft: "7.6px" }}>{personalData && personalData.mobileNumber}</p>
            </div>
            <div style={{ color: "#313133", display: "flex" }}>
              <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+103.svg" alt="emailpic" />
              <p style={{ marginLeft: "7.6px" }}>{personalData && personalData.emailAddress}</p>
            </div>
            <div style={{ color: "#313133", display: "flex" }}>
              <img src="https://jobsifarish.s3.ap-south-1.amazonaws.com/resumeIcon/Group+105.svg" alt="websitepic" />
              <p style={{ marginLeft: "7.6px" }}>www.lauraanderson.com</p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "218.74px", backgroundColor: "#313133" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "white",
                  width: "79.45px",
                  height: "18.88px",
                  marginTop: "35.57px",
                  marginLeft: "26.07px",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  fontWeight: "700",
                  paddingLeft: "7px",
                }}
              >
                Skills
              </div>
              <div style={{ backgroundColor: "white", marginTop: "44.9px", height: "0.5px", width: "113.22px" }}></div>
            </div>
            <div style={{ marginTop: "15.7px", width: "61px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px", color: "white", marginLeft: "32.35px" }}>
              {/* <p>Communication</p>
                        <p>Creativity</p>
                        <p>Teamwork</p>
                        <p>Organisation</p>
                        <p>Leadership</p>
                        <p>Teamplayer</p> */}
              {skillList.length > 0 ? (
                <>
                  {/* <h4 className='resume8_heading mt-3'>Skills</h4>  */}
                  {/* < hr className='devider_8' /> */}
                  {/* <ul className='resume8_skills'> */}
                  {skillList.length > 0
                    ? skillList.map((res, index) => (
                        <p key={index} style={{ marginBottom: "5px" }}>
                          {res.skill}
                        </p>
                      ))
                    : null}
                  {/* </ul>  */}
                </>
              ) : null}
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "white",
                  width: "79.45px",
                  height: "18.88px",
                  marginTop: "34.19px",
                  marginLeft: "26.07px",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  fontWeight: "700",
                  paddingLeft: "7px",
                }}
              >
                Software
              </div>
              <div style={{ backgroundColor: "white", height: "0.5px", width: "113.22px", marginTop: "43.4px" }}></div>
            </div>
            <div style={{ color: "white", width: "49px", height: "92px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px", marginTop: "14.59px", marginLeft: "32.35px" }}>
              <p>Photoshop</p>
              <p>Illustrator</p>
              <p>After Effects</p>
              <p>InDesign</p>
              <p>Coding/CSS</p>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "white",
                  width: "130.53px",
                  height: "18.88px",
                  marginTop: "21.14px",
                  marginLeft: "26.07px",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  fontWeight: "700",
                  paddingLeft: "7px",
                }}
              >
                Honours/Awards
              </div>
              <div style={{ backgroundColor: "white", width: "82.14px", height: "0.5px", marginTop: "30.46px", padding: "0" }}></div>
            </div>
            <div style={{ marginTop: "15.59px" }}>
              <div style={{ color: "white", fontFamily: "'Lato', sans-serif", marginLeft: "32.35px" }}>
                <p style={{ marginTop: "15.59px", marginBottom: "10.98px", fontSize: "8.74px" }}>2010</p>
                <p style={{ fontFamily: "'Lato', sans-serif", fontSize: "10.68px" }}>Best Graphic Designer Award</p>
                <p style={{ fontFamily: "'Lato', sans-serif", fontSize: "9.71px", marginBottom: "20.89px" }}>Compony|www.compony.com</p>
              </div>
              <div style={{ color: "white", fontFamily: "'Lato', sans-serif", marginLeft: "32.35px" }}>
                <p style={{ fontSize: "8.74px" }}>2010</p>
                <p style={{ fontSize: "10.68px" }}>Best Graphic Designer Award</p>
                <p style={{ fontSize: "9.71px" }}>Compony|www.compony.com</p>
              </div>
              <div style={{ color: "white", fontFamily: "'Lato', sans-serif", marginLeft: "32.35px" }}>
                <p style={{ fontSize: "8.74px" }}>2010</p>
                <p style={{ fontSize: "10.68px" }}>Best Graphic Designer Award</p>
                <p style={{ fontSize: "9.71px" }}>Compony|www.compony.com</p>
              </div>
            </div>
          </div>

          <div style={{ width: "376.54px", height: "100%" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  color: "white",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  fontWeight: "600",
                  width: "107.83px",
                  height: "18.87px",
                  paddingLeft: "9.55px",
                  paddingTop: "0.82px",
                  backgroundColor: "#313133",
                  marginLeft: "22.41px",
                }}
              >
                About me
              </div>
              <div style={{ height: "0.5px", width: "246.3px", marginTop: "9.5px", backgroundColor: "#313133" }}></div>
            </div>
            <div style={{ width: "315.84px", height: "77.8px", fontFamily: "'Lato', sans-serif", fontSize: "10px", overflow: "hidden", marginLeft: "22.61px", marginTop: "14.07px" }}>
              {personalData && personalData.about_me}
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#313133",
                  width: "107.83px",
                  height: "18.88px",
                  marginLeft: "22.41px",
                  color: "white",
                  marginTop: "5.71px",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  paddingLeft: "9.55px",
                  paddingTop: "0.82px",
                  fontWeight: "600",
                }}
              >
                Education
              </div>
              <div style={{ width: "274.46px", height: "0.5px", marginTop: "15.03px", backgroundColor: "#323233" }}></div>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15.69px" }}>
                <div style={{ marginLeft: "22.64px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px", color: "#323233" }}>2008/2010</div>
                <div style={{ fontSize: "9.71px", marginRight: "159.7px" }}>
                  <div style={{ fontFamily: "'Lato', sans-serif", fontWeight: "600" }}>Certificate of Web Training</div>
                  <div style={{ fontFamily: "'Lato', sans-serif" }}>University of Lorem</div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "21.02px" }}>
                <div style={{ marginLeft: "22.64px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px", color: "#323233" }}>2008/2010</div>
                <div style={{ fontSize: "9.71px", marginRight: "159.7px" }}>
                  <div style={{ fontFamily: "'Lato', sans-serif", fontWeight: "600" }}>Certificate of Web Training</div>
                  <div style={{ fontFamily: "'Lato', sans-serif" }}>University of Lorem</div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "25.01px" }}>
                <div style={{ marginLeft: "22.64px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px", color: "#323233" }}>2008/2010</div>
                <div style={{ fontSize: "9.71px", marginRight: "159.7px" }}>
                  <div style={{ fontFamily: "'Lato', sans-serif", fontWeight: "600" }}>Certificate of Web Training</div>
                  <div style={{ fontFamily: "'Lato', sans-serif," }}>University of Lorem</div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontSize: "12.62px",
                  fontWeight: "600",
                  color: "white",
                  width: "107.83px",
                  height: "18.87px",
                  backgroundColor: "#313133",
                  marginTop: "28.93px",
                  marginLeft: "22.41px",
                  paddingLeft: "9.55px",
                  paddingTop: "1.07px",
                }}
              >
                Experience
              </div>
              <div style={{ width: "274.46px", height: "0.5px", backgroundColor: "#323233", marginTop: "38.24px" }}></div>
            </div>
            <div>
              <div style={{ marginLeft: "22.64px" }}>
                <div style={{ marginTop: "14.59px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px" }}>01.2011-08.2017</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "9.71px", fontWeight: "bold", marginTop: "8.21px" }}>Junior Graphic Designer</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "9.71px", fontWeight: "bold" }}>Company Name | Location</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "8.25px", marginTop: "9.8px", width: "267.66px", height: "30.89px" }}>
                  Discription. If successful in the tests, those who are considered by a hiring service to be suitable to their requirements will be invited.
                </div>
              </div>
              <div style={{ marginLeft: "22.64px" }}>
                <div style={{ marginTop: "8.21px", fontFamily: "'Lato', sans-serif", fontSize: "8.74px" }}>01.2011-08.2017</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "9.71px", fontWeight: "bold", marginTop: "8.21px" }}>Junior Graphic Designer</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "9.71px", fontWeight: "bold" }}>Company Name | Location</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "8.25px", marginTop: "9.8px", width: "267.66px", height: "30.89px" }}>
                  Discription. If successful in the tests, those who are considered by a hiring service to be suitable to their requirements will be invited.
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "107.83px",
                  height: "18.87px",
                  backgroundColor: "#313133",
                  color: "white",
                  marginTop: "19.26px",
                  fontSize: "12.62px",
                  paddingLeft: "9.55px",
                  paddingTop: "1.07px",
                  marginLeft: "22.41px",
                  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                  fontWeight: "600",
                }}
              >
                Organisation
              </div>
              <div style={{ width: "274.46px", height: "0.5px", backgroundColor: "#323233", marginTop: "28.58px" }}></div>
            </div>
            <div style={{ height: "129.72px" }}>
              <div style={{ marginLeft: "22.64px" }}>
                <div style={{ fontSize: "8.74px", marginTop: "8.28px", fontFamily: "'Lato', sans-serif" }}>2011-Present</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "10px", fontWeight: "bold", marginTop: "8.31px" }}>American Management Association</div>
              </div>
              <div style={{ marginLeft: "22.64px" }}>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "8.74px", marginTop: "12.39px" }}>2011-Present</div>
                <div style={{ fontFamily: "'Lato', sans-serif", fontSize: "10px", fontWeight: "bold", marginTop: "8.31px" }}>American Management Association</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewResume69;
