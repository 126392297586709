import { HttpMethod } from "../../enum/APIEnums";
import apiConfig from "../../config.json";
import { DocumentUtility } from "../../utility/documentUtility";
import axios from "axios";
export class HttpService {
    baseUrl = apiConfig.apiConfig.apiEndPoint2;
    fetch = (method, urlRoute, headers, body) => {
        return new Promise((resolve, reject) => {
            let url = this.baseUrl + (urlRoute !== undefined ? urlRoute : "");
            fetch(url, {
                method: method,
                headers: headers === undefined ? apiConfig.headers : headers,
                body: JSON.stringify(body),
                mode: "cors",
                cache: "no-cache",
            })
                .then((res) => res.json())
                .then((data) => resolve(data))
                .catch((err) => {
                    if (String(err).indexOf("Failed to fetch") > -1) {
                        // window.location.href = `/connectionError?${uidSync(18)}=${CryptoUtility.encrypt(window.location.href)}`;
                    } else reject(err);
                })
                .finally();
        });
    };
    uploadFile(url, header, fileElementId) {
        const body = DocumentUtility.getFormData(fileElementId);
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + url, {
                method: HttpMethod.Post,
                headers: header,
                body: body,
            })
                .then((res) => res.json())
                .then((data) => {
                    const response = data;
                    if (response.responseCode === "500") {
                        reject(response.responseCode);
                    } else {
                        resolve(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    uploadFileUsingJosn(url, header, inputJson) {
        const body = DocumentUtility.getFormDataWithJsonVideo(inputJson);
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + url, {
                method: HttpMethod.Post,
                headers: header,
                body: body,
            })
                .then((res) => res.json())
                .then((data) => {
                    const response = data;
                    if (response.responseCode === "500") {
                        reject(response.responseCode);
                    } else {
                        resolve(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    uploadReume(url, header, inputJson) {
        const body = DocumentUtility.getFormDataWithResume(inputJson);
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + url, {
                method: HttpMethod.Post,
                headers: header,
                body: body,
            })
                .then((res) => res.json())
                .then((data) => {
                    const response = data;
                    if (response.responseCode === "500") {
                        reject(response.responseCode);
                    } else {
                        resolve(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    downloadPdf(header, inputJson) {
        console.log(inputJson);
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: "https://jobsifarish.com/node/createPdf",
                headers: header,
                data: inputJson,
                responseType: "blob",
            })
                .then((res) => {
                    if (res.status === 200) {
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        // const blob = new Blob([res.data]);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", inputJson.name + ".pdf");
                        document.body.appendChild(link);
                        link.click();
                    }
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    getQuery = (urlRoute, headers) => {
        return this.fetch(HttpMethod.Get, urlRoute, headers, undefined);
    };
    postQuery = (urlRoute, headers, body) => {
        return this.fetch(HttpMethod.Post, urlRoute, headers, body);
    };
    putQuery = (urlRoute, headers, body) => {
        return this.fetch(HttpMethod.Put, urlRoute, headers, body);
    };
    patchQuery = (urlRoute, headers, body) => {
        return this.fetch(HttpMethod.PATCH, urlRoute, headers, body);
    };
    deleteQuery = (urlRoute, headers, body) => {
        return this.fetch(HttpMethod.DELETE, urlRoute, headers, body);
    };
}
