import React, { useEffect, useState } from "react";
import styles from "./rescheduleModal.module.css";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../Redux/Slices/noPersistSlice";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { getScheduledMeetList } from "../../../Services/commonService";
import DatePicker from "react-datepicker";

const RescheduleModal = () => {
    const dispatch = useDispatch();
    const { rescheduleModal, meetToReschedule } = useSelector((state) => state.nopersist);

    const initialData = {
        meet_start_time: new Date(),
        meet_end_time: new Date(),
        message: "",
        meeting_url: "",
        comment: "",
        meetDate: new Date(),
        meetTimeStart: "",
        meetTimeEnd: "",
    };
    const [inputData, setInputData] = useState(initialData);
    const [newInputData, setNewInputData] = useState({});
    const handleInput = (event) => {
        const { name, value } = event.target;
        setInputData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        const res = await postTReq(apiEndpoints.scheduleMeeting, newInputData);
        if (res && !res.error) {
            getScheduledMeetList(0, 10);
            setInputData(initialData);
            dispatch(modalController({ modal: "rescheduleModal", state: false }));
        } else {
            console.log(res.error);
        }
    };

    useEffect(() => {
        let x = { ...inputData };
        // let meetStart = `${x.meetDate}T${x.meetTimeStart}:00.000Z`;
        // let meetEnd = `${x.meetDate}T${x.meetTimeEnd}:00.000Z`;
        x["meetDate"] = x.meetDate.getTime();
        x["meet_start_time"] = x.meet_start_time.getTime();
        x["meet_end_time"] = x.meet_end_time.getTime();
        setNewInputData(x);
    }, [inputData]);

    useEffect(() => {
        if (meetToReschedule && Object.keys(meetToReschedule).length > 0) {
            let x = JSON.parse(JSON.stringify(meetToReschedule.message_type));
            x["meetDate"] = new Date(x.meet_start_time);
            x["meet_start_time"] = new Date(x.meet_start_time);
            x["meet_end_time"] = new Date(x.meet_end_time);
            setInputData(x);
        }
    }, [meetToReschedule]);

    return (
        <Modal
            className="transparentModalSession"
            dialogClassName="d-flex justify-content-center"
            centered
            show={rescheduleModal}
            onHide={() => dispatch(modalController({ modal: "rescheduleModal", state: false }))}
            size="lg"
        >
            <Modal.Body className="p-0">
                <div className={styles.container}>
                    <div className={styles.modalHeading}>
                        <div>Reschedule Live Session</div>
                        <div className={styles.closeButtonContainer} onClick={() => dispatch(modalController({ modal: "rescheduleModal", state: false }))}>
                            <AiOutlineClose fontSize={20} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Title</div>
                            <div className={styles.inputContainer}>
                                <input type="text" placeholder="e.g. Live session with John Doe" value={inputData.message} name="message" onChange={handleInput} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-49`}>
                            <div className={styles.inputHeading}>Live Session Date</div>
                            <div className={styles.inputContainer}>
                                <DatePicker showIcon selected={inputData.meetDate} onChange={(date) => handleInput({ target: { name: "meetDate", value: date } })} />
                            </div>
                        </div>
                        <div className={`${styles.eachInputSection} w-49`}>
                            <div className={styles.inputHeading}>Session Time</div>
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className={styles.durationInputContainer}>
                                    {/* <input type="time" value={inputData.meetTimeStart} name="meetTimeStart" onChange={handleInput} /> */}
                                    <DatePicker
                                        selected={inputData.meet_start_time}
                                        onChange={(date) => handleInput({ target: { name: "meet_start_time", value: date } })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        minTime={new Date()}
                                        maxTime={inputData.meet_end_time}
                                    />
                                </div>
                                <div style={{ width: "2rem" }} className="d-flex justify-content-center">
                                    to
                                </div>
                                <div className={styles.durationInputContainer}>
                                    {/* <input type="time" value={inputData.meetTimeEnd} name="meetTimeEnd" onChange={handleInput} /> */}
                                    <DatePicker
                                        selected={inputData.meet_end_time}
                                        onChange={(date) => handleInput({ target: { name: "meet_end_time", value: date } })}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        minTime={inputData.meet_start_time}
                                        maxTime={new Date(inputData.meetDate.setHours(23, 45, 0, 0))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Meeting Link</div>
                            <div className={styles.inputContainer}>
                                <input type="text" placeholder="e.g. https://meet.google.com/abc-def-ghi" disabled value={inputData.meeting_url} name="meeting_url" onChange={handleInput} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className={`${styles.eachInputSection} w-100`}>
                            <div className={styles.inputHeading}>Add Description</div>
                            <div className={styles.inputContainer}>
                                <textarea rows={6} type="text" placeholder="Add Description" value={inputData.comment} name="comment" onChange={handleInput} />
                            </div>
                        </div>
                    </div>
                    <div className="js-submit-button-container">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RescheduleModal;
