import { HttpService } from '../common/httpService';

class Authentication extends HttpService {
    urls = {
        loginUrl: 'login',
        signUpUrl: 'signup',
        emailValidationUrl: 'validateEmail',
        forgetPassword:'forgotPassword',
        changePassword: 'changePassword'
    }
    onLogin = (inputJson) => {
        return this.postQuery(this.urls.loginUrl, undefined, inputJson);
    }
    onSignUp = (inputJson) => {
        return this.postQuery(this.urls.signUpUrl, undefined, inputJson);
    }
    onEmailValidation = (inputJson) => {
        return this.postQuery(this.urls.emailValidationUrl, undefined, inputJson);
    }

    onForgotPassword =  (inputJson) => {
        return this.postQuery(this.urls.forgetPassword, undefined, inputJson)
    }
    onChangePassword = (inputJson, header) => {
        return this.postQuery(this.urls.changePassword, header, inputJson)
    }
}

export default Authentication;