import React from "react";
import styles from "./remark.module.css";
import { MdDelete } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";

const RemarkPage = ({ data, openSetter }) => {
    return (
        <>
            <div>
                <div className={styles.mainHeading}>REMARKS</div>
                <div className="d-flex justify-content-start">
                    <div className={styles.backButton} onClick={() => openSetter("")}>
                        <IoMdArrowRoundBack /> Back
                    </div>
                </div>
                <div className={styles.actualTable}>
                    <div className={styles.fittingBox}>
                        <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                            <div className={styles.headingName} style={{ paddingLeft: "1rem" }}>
                                S.NO
                            </div>
                            <div className={styles.headingName}>REMARKS</div>
                            <div className={styles.headingName}>RATING</div>
                            <div className={styles.headingName}>GIVEN BY</div>
                            <div className={styles.headingName}>ACTIONS</div>
                        </div>

                        {/* details */}
                        <div className="d-flex flex-column mt-2">
                            {data?.map((curelem, index) => {
                                return (
                                    <>
                                        <div
                                            className="d-flex align-items-center p-3"
                                            style={curelem.is_blocked ? { backgroundColor: "#f6f6f6", borderBottom: "2px solid #e5e5e6" } : { borderBottom: "2px solid #e5e5e6" }}
                                        >
                                            <div className={styles.detailsText}>{index + 1}.</div>
                                            <div className={styles.detailsText}>{curelem.remark}</div>
                                            <div className={styles.detailsText}>{curelem.rating}</div>
                                            <div className={styles.detailsText}>{curelem.remark_given_by_email_name}</div>
                                            <div className={styles.detailsText} style={{ paddingLeft: "1rem" }}>
                                                <button className="small-delete-button ">
                                                    <MdDelete />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RemarkPage;
