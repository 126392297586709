import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const GeneralLoadingBackdrop = () => {
    const { generalLoading } = useSelector((state) => state.nopersist);
    return (
        <Backdrop open={generalLoading} style={{ zIndex: "999" }}>
            <CircularProgress style={{ color: "#ffffff" }} />
        </Backdrop>
    );
};

export default GeneralLoadingBackdrop;
