import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toasterController } from "../../Redux/Slices/toasterSlice";

const EmailAlreadyExists = () => {
  const dispatch = useDispatch();
  const { emailAlreadyExists } = useSelector((state) => state.toaster);
  return (
    <ToastContainer className="toaster-top-right">
      <Toast show={emailAlreadyExists === true} bg="danger" onClose={() => dispatch(toasterController({ toaster: "emailAlreadyExists", state: false }))} autohide delay={4000}>
        <Toast.Body style={{ color: "#ffffff" }}>This Email ID already exists. Please use another Email ID.</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default EmailAlreadyExists;
