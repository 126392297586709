import React from "react";
import "../../style/CompleteProfile.css";

const CreateProfileSidebar = (props) => {
  const completeProfileStatus = [
    {
      statusName: "Welcome",
      screenIndex: 0,
    },
    {
      statusName: "Personal Details",
      screenIndex: 1,
    },
    {
      statusName: "Education Details",
      screenIndex: 2,
    },
    {
      statusName: "Professional Details",
      screenIndex: 3,
    },
  ];
  const updateScreenIndex = (index) => {
    props.updateScreenIndex(index);
  };
  return (
    <div className="row pb-0" id="createProfileSideBar">
      {completeProfileStatus.map((items, index) => {
        return (
          <div
            className={`statusContainer-create col-xs-12 col-sm-12 col-md-12 col-lg-12 py-3 px-5 text-center ${items.screenIndex === props.screenIndex ? "statusActive" : ""}`}
            onClick={() => {
              updateScreenIndex(items.screenIndex);
            }}
            key={"-sidebar-status-" + index}
          >
            <p className="pt-3 status-name">{items.statusName}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CreateProfileSidebar;
