import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ImageContent from "../../content/ImageContent";
import ErrorModal from "../../component/Modal/ErrorModal";
import ServiceProvider from "../../provider/serviceProvider";

const ForgotPassword = (props) => {
  const imageContent = new ImageContent();
  const serviceProvider = new ServiceProvider();

  // let headerUtility = new HeaderUtility();

  // let header = headerUtility.returnHeaderContainingToken();

  const [email, setEmail] = useState("");
  const [alerts, setAlerts] = useState({
    show: false,
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let authService = serviceProvider.returnAuthenticationService();
    authService.onForgotPassword({ userid: email }).then((res) => {
      if (res.status === "200" && res.dataString === "no user found") {
        setAlerts({ ...alerts, show: true, message: "No user found with this email!" });
        // setTimeout(() => props.handleClose(), 800)
      } else if (res.status === "200" && res.dataString === "forgot password succes") {
        setAlerts({ ...alerts, show: true, message: "Please check your email." });
        setTimeout(() => props.handleClose(), 2000);
      } else {
        setAlerts({ ...alerts, show: true, message: "Something went wrong, please contact admin!" });
      }
    });
  };

  return (
    <div style={{ minHeight: "350px" }}>
      <ErrorModal icon="text-danger" title="Warning" message={alerts.message} isShow={alerts.show} handleClose={() => setAlerts({ ...alerts, show: false })} />
      <img className="crossicon" alt="cross" onClick={props.handleClose} src={imageContent.getCrossImg()} />
      <h2 className="login">
        <b>Forgot Password</b>
      </h2>
      <form onSubmit={handleSubmit}>
        {/* <input className="ll" type="email" value={email} onChange={e => setEmail(e.target.value) } placeholder='Enter email here!' />

                <button type='submit'>Submit</button> */}
        <div className="row p-3 mt-4">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <Form.Control className="rounded-pill" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email here!" />
            <span className="text-danger" id="emailError"></span>
          </div>
          <div className="col-sm-2"></div>
        </div>
        <div className="row p-3 mt-4">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
            <Button className="rounded-pill" style={{ minWidth: "100%" }} type="submit" disabled={!email} variant="primary">
              Submit
            </Button>
          </div>
          <div className="col-sm-4"></div>
        </div>
        <h6 className="newuser">
          <b>If you are an existing user ?</b>
        </h6>
        <h6 className="newuserReg">
          <div
            onClick={() => {
              props.manageModal("L");
            }}
            className="link"
          >
            <b>Login</b>
          </div>
          <b> Here</b>
        </h6>
      </form>
    </div>
  );
};

export default ForgotPassword;
