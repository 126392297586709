import { HttpService } from "../common/httpService";

class ConnectService extends HttpService {
    urls = {
        suggestedConnection: 'getSujjestedUserList',
        sendRqst: 'sendRequest',
        friendList:'getFriendList',
        pendingList:'getRecievedRequest',
        sendList:'getSendRequest',
        removeFromFriendList:'removeFromFriendList',
        respondRequest:'respondRequest',
        postMessage: 'sendMessage',
        getMessage: 'messageQuery',
        deleteMessage: 'deleteMessage',
        notification:'getNotification',
        getChatHistory: 'chatHistory',
        getSuggest: 'suggest'
    }

    suggest(header, inputJson) {
        return this.postQuery(this.urls.getSuggest, header, inputJson)
    }

    chatHistory(header, inputJson) {
        return this.postQuery(this.urls.getChatHistory, header, inputJson)
    }

    postMessage(header, inputJson) {
        return this.postQuery(this.urls.postMessage, header, inputJson)
    }

    getMessage(header, inputJson) {
        return this.postQuery(this.urls.getMessage, header, inputJson)
    }

    deleteMessage(header, inputJson) {
        return this.postQuery(this.urls.deleteMessage, header, inputJson)
    }

    getSuggestedConnections(header, inputJson) {
        return this.postQuery(this.urls.suggestedConnection, header, inputJson);
    }
    sendRequest(header, inputJson) {
        return this.postQuery(this.urls.sendRqst, header, inputJson);
    }
    getFriendList(header,inputJson){
        return this.postQuery(this.urls.friendList, header, inputJson);
    }
    getPendingList(header,inputJson){
        return this.postQuery(this.urls.pendingList, header, inputJson);
    }
    getSendList(header,inputJson){
        return this.postQuery(this.urls.sendList    , header, inputJson);
    }
    removeFromFriendListRequest(header, inputJson) {
        return this.postQuery(this.urls.removeFromFriendList, header, inputJson);
    }
    respondRequest(header, inputJson) {
        return this.postQuery(this.urls.respondRequest, header, inputJson);
    }
    getNotification(header,inputJson){
        return this.postQuery(this.urls.notification, header, inputJson);
    }
}

export default ConnectService;