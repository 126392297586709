import React, { useEffect } from "react";
import styles from "./verificationTable.module.css";
import { getLiveSessionRequestList, toasterControl } from "../../../Services/commonService";
import { useSelector } from "react-redux";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { BsPersonCircle } from "react-icons/bs";
import { Tooltip } from "@mui/material";

const VerificationTable = () => {
    const { sessionRequestList } = useSelector((state) => state.admin);

    const handleUserApproval = async (email, status) => {
        const res = await postTReq(apiEndpoints.approveUser, {
            emailAddress: email,
            is_approved: status,
        });
        if (res && !res.error) {
            if (status) {
                toasterControl("successToaster", true, "User Verified");
            } else {
                toasterControl("successToaster", true, "User Rejected");
            }
            getLiveSessionRequestList(0, 10);
        } else {
            toasterControl("somethingWentWrong", true);
        }
    };

    useEffect(() => {
        getLiveSessionRequestList(0, 10);
    }, []);

    console.log(sessionRequestList);
    return (
        <div className={styles.container}>
            <div className={styles.heading}>REQUESTS FOR LIVE SESSION VERIFICATION</div>
            <div className={styles.tableContainer}>
                <div className={styles.theTable}>
                    <div className={styles.headingsContainer}>
                        <div className={styles.eachTableHead} style={{ paddingLeft: "1rem" }}>
                            NAME
                        </div>
                        <div className={styles.eachTableHead}>JOINING DATE</div>
                        <div className={styles.eachTableHead}>POSITION</div>
                        <div className={styles.eachTableHead}>ACTION</div>
                    </div>

                    {/* details */}
                    <div className="d-flex flex-column mt-2">
                        {sessionRequestList &&
                            sessionRequestList.map((curElem, index) => {
                                return (
                                    <div className="d-flex align-items-center p-2" style={{ borderBottom: "1px solid #e5e5e6" }}>
                                        <div className={`${styles.eachRowData} d-flex align-items-center`} style={{ paddingLeft: "0.5rem" }}>
                                            <div className="me-1">
                                                {curElem.profileImageUrl ? (
                                                    <div className={styles.profileImageContainer}>
                                                        <img src={curElem.profileImageUrl} alt="" />
                                                    </div>
                                                ) : (
                                                    <BsPersonCircle fontSize={32} />
                                                )}
                                            </div>
                                            <div>
                                                <div>{curElem.fullName}</div>
                                                <div className={styles.emailPill}>{curElem.emailAddress}</div>
                                            </div>
                                        </div>
                                        <div className={styles.eachRowData}>{curElem.professional_details && curElem.professional_details.length > 0 ? "data" : "N/A"}</div>
                                        <div className={styles.eachRowData}>{curElem.job_profile_name ? curElem.job_profile_name : "-"}</div>
                                        <div className={`${styles.eachRowData} d-flex ps-2`}>
                                            {curElem.is_verified_from_admin_for_live_session ? (
                                                <Tooltip title="Reject Request">
                                                    <button className="small-delete-button" onClick={() => handleUserApproval(curElem.emailAddress, false)}>
                                                        <AiOutlineClose />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Accept Request">
                                                    <button className="small-active-button" onClick={() => handleUserApproval(curElem.emailAddress, true)}>
                                                        <AiOutlineCheck />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationTable;
