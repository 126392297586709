import React from "react";
import styles from "./aboutUsPage.module.css";
import { aboutUsData } from "../../Services/constants";

const AboutUsPage = () => {
    return (
        <div className="main-content-container">
            <div className={styles.container}>
                <div className={styles.dataBox}>
                    {aboutUsData.map((curElem) => {
                        return (
                            <div className="mb-3">
                                <div style={curElem.headingStyle}>{curElem.heading}</div>
                                <div style={curElem.contentStyle}>{curElem.content}</div>
                                {curElem.points &&
                                    curElem.points.map((cur) => {
                                        return (
                                            <div className="d-flex mt-2">
                                                <div style={cur.textStyling}>
                                                    <b style={cur.labelStyling}>{cur.label}:</b>
                                                    {cur.text}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
                    <div className={`${styles.contentText} mb-2`}>
                        At JobSifarish, we're committed to creating a world where knowledge-sharing becomes a catalyst for growth and opportunity. Join us in building a future where every fresher can
                        stand on the shoulders of giants, and every professional can pave the way for the next generation.
                    </div>
                    <div className={styles.contentText}>Together, let's turn aspirations into achievements. Welcome to JobSifarish.</div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;
