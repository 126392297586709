import React, { useCallback, useEffect, useState } from "react";
import styles from "./userchat.module.css";
import { useSelector } from "react-redux";
import { postTReq } from "../../../Services/api";
import { apiEndpoints } from "../../../PrEnums/prEnums";
import { getEveryUser } from "../../../Services/commonService";
import { BsPersonCircle } from "react-icons/bs";
import { VscVerifiedFilled } from "react-icons/vsc";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import ChatBubble from "../../JobsifarishChat/ChatBubble/ChatBubble";

const UserChats = () => {
    const { allUserList } = useSelector((state) => state.admin);

    const [filteredUserList, setFilteredUserList] = useState(allUserList);

    const [userOneEmail, setUserOneEmail] = useState("");
    const [userTwoEmail, setUserTwoEmail] = useState("");

    const [searchText, setSearchText] = useState("");

    const selectUsers = (email) => {
        if (userOneEmail) {
            setUserTwoEmail(email);
        } else {
            setUserOneEmail(email);
        }
        setSearchText("");
    };

    const [userMessages, setUserMessages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const getMessageList = useCallback(
        async (pageNum, recordNum) => {
            try {
                const res = await postTReq(apiEndpoints.userChat, {
                    page_no: pageNum,
                    no_of_records: recordNum,
                    sender_email: userOneEmail,
                    reciever_email: userTwoEmail,
                });
                if (res && !res.error) {
                    setUserMessages((prev) => {
                        return [...prev, ...res.data.data];
                    });
                } else {
                    console.log(res.error);
                }
            } catch (error) {
                console.error("Error fetching message list:", error);
            }
        },
        [userOneEmail, userTwoEmail]
    );

    useEffect(() => {
        if (userOneEmail && userTwoEmail) {
            getMessageList(pageCount, 10);
        }
    }, [getMessageList, userOneEmail, userTwoEmail, pageCount]);

    useEffect(() => {
        if (allUserList) {
            let newData = allUserList.filter((curElem) => {
                return curElem.fullName.includes(searchText) || curElem.emailAddress.includes(searchText);
            });
            setFilteredUserList(newData);
        }
    }, [searchText, allUserList]);

    useEffect(() => {
        getEveryUser(0, 1000);
    }, []);

    return (
        <>
            <div className="h-100">
                <div className={styles.mainHeading}>USER CHATS</div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex ps-2">
                        <div className={`${styles.selectedUsers} me-2`}>
                            <div className={styles.selectedUserLabel}>Sender:</div>
                            <div className={styles.selectedUserValue}>
                                {userOneEmail ? userOneEmail : "Not Selected"}{" "}
                                <AiOutlineCloseCircle
                                    color="#ff0000"
                                    style={{ cursor: "pointer" }}
                                    className="ms-1"
                                    onClick={() => {
                                        setUserOneEmail("");
                                        setSearchText("");
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`${styles.selectedUsers} me-2`}>
                            <div className={styles.selectedUserLabel}>Receiver:</div>
                            <div className={styles.selectedUserValue}>
                                {userTwoEmail ? userTwoEmail : "Not Selected"}
                                <AiOutlineCloseCircle color="#ff0000" style={{ cursor: "pointer" }} className="ms-1" onClick={() => setUserTwoEmail("")} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <input type="text" placeholder="Search User" className={styles.searchUserInput} value={searchText} onChange={(event) => setSearchText(event.target.value)} />
                    </div>
                </div>
                {userOneEmail && userTwoEmail ? (
                    <div className={styles.chatActualTable}>
                        <div className={styles.fittingBox}>
                            <div className={`d-flex flex-column mt-2 ${styles.chatRowsContainer}`}>
                                {userMessages &&
                                    userMessages.map((curElem) => {
                                        return <ChatBubble type={userOneEmail === curElem.message_type.sender_email ? "sender" : "receiver"} messageData={curElem} />;
                                    })}
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <button className="normal-secondary-button" onClick={() => setPageCount((prev) => prev + 1)}>
                                    See More
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.actualTable}>
                        <div className={styles.fittingBox}>
                            <div className="d-flex" style={{ backgroundColor: "#f1bb46", borderRadius: "6px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
                                <div className={`${styles.headingName} ps-3`}>USER</div>
                                <div className={`${styles.headingName} text-center`}>EMAIL VERIFIED</div>
                                <div className={`${styles.headingName}`}>VERIFIED FOR LIVE SESSION</div>
                                <div className={`${styles.headingName}`}>CONTACT NUMBER</div>
                                <div className={`${styles.headingName}`}>ACTION</div>
                            </div>

                            {/* details */}
                            <div className={`d-flex flex-column mt-2 ${styles.rowsContainer}`}>
                                {filteredUserList &&
                                    filteredUserList.map((curElem) => {
                                        return (
                                            <div className="d-flex align-items-center py-3" style={{ borderBottom: "2px solid #e5e5e6" }}>
                                                <div className={`${styles.detailsText} d-flex align-items-center ps-3`}>
                                                    <div className={styles.profileImageContainer}>
                                                        {curElem.profileImageUrl ? <img src={curElem.profileImageUrl} alt="" /> : <BsPersonCircle fontSize={32} color="#888888" />}
                                                    </div>
                                                    <div style={{ width: "calc(100% - 2rem)" }}>
                                                        <div>{curElem.fullName}</div>
                                                        <div className={styles.emailPill}>{curElem.emailAddress}</div>
                                                    </div>
                                                </div>
                                                <div className={`${styles.detailsText} text-center`}>
                                                    {curElem.is_email_verifed ? <VscVerifiedFilled color="#f1bb46" fontSize={20} /> : "Unverified"}
                                                </div>
                                                <div className={styles.detailsText}>
                                                    {curElem.is_verified_from_admin_for_live_session ? <VscVerifiedFilled color="#f1bb46" fontSize={20} /> : "Unverified"}
                                                </div>
                                                <div className={styles.detailsText}>{curElem.mobileNumber}</div>
                                                <div className={styles.detailsText}>
                                                    <Tooltip title={userOneEmail ? "Select Receiver" : "Select sender"}>
                                                        <button className="small-secondary-button" onClick={() => selectUsers(curElem.emailAddress)}>
                                                            <AiOutlinePlus />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserChats;
