import React, { useEffect, useState } from "react";
import styles from "./educationBox.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { modalController } from "../../../../Redux/Slices/noPersistSlice";
import { convertDateMMYYYY, getEducationalDetails, modalControl, toasterControl } from "../../../../Services/commonService";
import { BiSolidInstitution } from "react-icons/bi";
import { AiOutlinePercentage } from "react-icons/ai";
import { LiaUniversitySolid } from "react-icons/lia";
import { FaLocationDot } from "react-icons/fa6";
import { saveEditingEducationObject } from "../../../../Redux/Slices/profileSlice";
import { getPercentage } from "../../../../Services/commons";
import { Dialog, DialogContent } from "@mui/material";
import { postTReq } from "../../../../Services/api";
import { apiEndpoints } from "../../../../PrEnums/prEnums";

const EducationBox = () => {
    const dispatch = useDispatch();
    const { educationalDetails } = useSelector((state) => state.persist);

    const [confirmation, setConfirmation] = useState(false);
    const [savedEducationId, setSavedEducationId] = useState("");

    const handleEdit = (elem) => {
        dispatch(saveEditingEducationObject(elem));
        modalControl("editEducationModal", true);
    };
    const handleDelete = async () => {
        const res = await postTReq(apiEndpoints.deleteEducationalDetail, {
            educationId: savedEducationId,
        });
        if (res && !res.error) {
            toasterControl("successToaster", true, "Educational detail removed");
            setSavedEducationId("");
            setConfirmation(false);
            getEducationalDetails();
        } else {
            console.log(res.error);
            toasterControl("errorToaster", true, "An error occured");
        }
    };

    useEffect(() => {
        getEducationalDetails();
    }, []);

    return (
        <React.Fragment>
            <Dialog open={confirmation}>
                <DialogContent>
                    <div className="mb-2">Are you sure you want to remove this education history?</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="normal-disabled-button me-2"
                            onClick={() => {
                                setSavedEducationId("");
                                setConfirmation(false);
                            }}
                        >
                            No
                        </button>
                        <button className="normal-button" onClick={handleDelete}>
                            Yes
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <div className={`content-card`}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="content-card-heading">Education</div>
                    <div className={`content-card-add-more`} onClick={() => dispatch(modalController({ modal: "addEducationalDetailModal", state: true }))}>
                        <AiOutlinePlus /> Add
                    </div>
                </div>
                {educationalDetails && educationalDetails.length > 0 ? (
                    educationalDetails.map((curElem) => {
                        return (
                            <div className={styles.educationCard}>
                                <div className={styles.line1}>
                                    <div className={styles.educationType}>{curElem.educationType}</div>
                                    <div className={styles.sessionDuration}>
                                        <div className={styles.sessionDate}>{convertDateMMYYYY(curElem.session_start)}</div>
                                        <div style={{ fontSize: "14px", margin: "0 0.5rem" }}>to</div>
                                        <div className={styles.sessionDate}>{convertDateMMYYYY(curElem.session_end)}</div>
                                    </div>
                                </div>
                                <div className={`${styles.line2} mt-2`}>
                                    <div className="d-flex align-items-center">
                                        <BiSolidInstitution color="#f1bb46" />
                                        <div style={{ fontSize: "14px", fontWeight: "500", marginLeft: "0.5rem" }}>{curElem.schoolName}</div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ fontSize: "14px" }}>
                                        <AiOutlinePercentage color="#f1bb46" />
                                        <div className="ms-2">{getPercentage(curElem.scoredMarks, curElem.totalMarks)}</div>
                                    </div>
                                </div>
                                <div className={`${styles.line2} mt-1 mb-3`}>
                                    <div className="d-flex align-items-center">
                                        <LiaUniversitySolid color="#f1bb46" />
                                        <div style={{ fontSize: "14px", fontWeight: "500", marginLeft: "0.5rem" }}>{curElem.boardName}</div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ fontSize: "14px" }}>
                                        <FaLocationDot color="#f1bb46" />
                                        <div className="ms-2">{curElem.location}</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button className="normal-button me-2" onClick={() => handleEdit(curElem)}>
                                        Edit
                                    </button>
                                    <button
                                        className="normal-delete-button"
                                        onClick={() => {
                                            setSavedEducationId(curElem.educationId);
                                            setConfirmation(true);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div style={{ padding: "1.5rem", display: "flex", justifyContent: "center", alignItems: "center", background: "#f6f6f6", borderRadius: "8px" }}>
                        <div className="nothing-to-show">Nothing to show</div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default EducationBox;
