import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ValidationUtility } from "../../utility/ValidationUtility";
import "../../style/Home.css";
import { ValidationType } from "../../enum/ValidationType";
import ServiceProvider from "../../provider/serviceProvider";
import HeaderUtility from "../../utility/HeaderUtility";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { skillsArray } from "../../json/commonData";

const optionsSkills = skillsArray.map((item, index) => ({ value: index, label: item }));

const EditJob = (props) => {
  let serviceProvider = useMemo(() => {
    return new ServiceProvider();
  }, []);
  let headerUtility = useMemo(() => {
    return new HeaderUtility();
  }, []);
  let params = useParams();
  let initElasticItem = [];
  let jobTypeList = [
    {
      name: "Full Time Jobs",
      selected: false,
    },
    {
      name: "Part time jobs",
      selected: false,
    },
    {
      name: "Remote jobs",
      selected: false,
    },
    {
      name: "Internship jobs",
      selected: false,
    },
    {
      name: "Contracted Jobs",
      selected: false,
    },
    {
      name: "Training Jobs",
      selected: false,
    },
  ];
  let [listOfElasticText, setListOfElasticText] = useState(initElasticItem);
  let [jobTitle, setJobTitle] = useState("");
  let [jobDesc, setJobDesc] = useState("");
  let [jobStream, setJobStream] = useState("");
  let [companyName, setCompanyName] = useState("");
  let [exp, setExp] = useState("");
  let [ofrPkg, setOfrPkg] = useState("");
  let [role, setRole] = useState("");
  let [joiningType, setJoiningType] = useState("");
  let [location, setLocationType] = useState("");
  let [isSkill, setIsSkill] = useState(false);
  let [listOfJobType, setListOfJobType] = useState(jobTypeList);
  let [skillsref, setSkillsRef] = useState([]);
  let [jobref, setJobRef] = useState([]);
  let [btnLoader, setBtnLoader] = useState(false);
  let [jobId, setJobId] = useState("");
  let jobService = serviceProvider.returnJobService();
  let header = headerUtility.returnHeaderContainingToken();
  let navigateTo = useNavigate();
  const onJobTitleInput = (value) => {
    setJobTitle(value);
    ValidationUtility.validateElement("jobTitle", ValidationType.Text, true, "jobTitleError", "Job Title");
  };
  const onJobStreamInput = (value) => {
    setJobStream(value);
    ValidationUtility.validateElement("jobStream", ValidationType.Text, true, "jobStreamError", "Job Stream");
  };
  const onJobDescInput = (value) => {
    setJobDesc(value);
    ValidationUtility.validateElement("jobDesc", ValidationType.Text, true, "jobDescError", "Job Description");
  };
  const onCompanyNameInput = (value) => {
    setCompanyName(value);
    ValidationUtility.validateElement("companyName", ValidationType.Text, true, "companyNameError", "Company Name");
  };

  //   const addMoreElasticSearchText = (text) => {
  //     let index = listOfElasticText.length;
  //     let obj = {
  //       id: index,
  //       text: text,
  //     };
  //     if (listOfElasticText.length < 7) {
  //       setIsSkill(false);
  //       setListOfElasticText((oldData) => [...oldData, obj]);
  //     }
  //   };

  // const handleKeyPress = (event) => {
  //     if (event.charCode === 13) {
  //         event.preventDefault();
  //         if (productName !== "") {
  //             addMoreElasticSearchText(productName);
  //         }
  //         productName = "";
  //         setProductName("");
  //     }
  // };

  //   const removeELasticSearchItem = (index) => (e) => {
  //     let tempListOfElasticSearchItem = [...listOfElasticText];
  //     let subTempListOfElasticSearchItem = tempListOfElasticSearchItem.filter((item, subIndex) => subIndex !== index);
  //     setListOfElasticText([]);
  //     setListOfElasticText(subTempListOfElasticSearchItem);
  //   };

  //   const returnElasticSearchList = () => {
  //     return listOfElasticText.map((item, index) => {
  //       return (
  //         <div className="ml-2 mt-0 mb-0 elasticSearchItem" id={item.text + "elasticSearch" + index + "id" + item.id}>
  //           <span className="pl-2">{item.text}&nbsp;</span>
  //           <span className="pr-2 elasticSearchCross" onClick={removeELasticSearchItem(index)}>
  //             <i className="fa fa-times" aria-hidden="true"></i>
  //           </span>
  //         </div>
  //       );
  //     });
  //   };

  const onJobTypeSelect = (index, value) => {
    let list = [...listOfJobType];
    let newList = [];
    list.forEach((item, subindex) => {
      if (subindex === index) {
        item.selected = value;
      }
      newList.push(item);
    });
    console.log(list);
    setListOfJobType([]);
    setListOfJobType(newList);
  };
  const getJobTypeForInputJson = () => {
    let newList = [...listOfJobType];
    console.log(newList);
    let list = [];
    newList.forEach((item) => {
      if (item.selected) {
        let obj = {
          job_type: item.name,
        };
        list.push(obj);
      }
    });
    return list;
  };
  const generateInputJson = () => {
    let newSkills = [...skillsref];
    let skillInfoList = listOfElasticText.map((data, index) => ({ skill_id: data.value, skill: data.label, skill_reference_id: null }));
    for (let i = 0; i < skillInfoList.length; ++i) {
      for (let j = 0; j < newSkills.length; ++j) {
        if (newSkills[j].skill === skillInfoList[i].skill) {
          if (skillInfoList[i].skill_reference_id === null) {
            skillInfoList[i].skill_reference_id = newSkills[j].skill_reference_id;
          }
        }
      }
    }
    let inputJson = {
      job_id: jobId,
      jobTitle: jobTitle,
      roleAndResposibility: role,
      companyName: companyName,
      description: jobDesc,
      skillset: skillInfoList,
      location: location,
      joiningType: joiningType,
      offerPackage: ofrPkg,
      expRequired: exp,
      job_type_details: getJobTypeForInputJson(),
      jobStreams: jobStream,
    };
    return inputJson;
  };
  const onSubmit = () => {
    if (listOfElasticText.length > 0) {
      setIsSkill(false);
    } else {
      setIsSkill(true);
    }
    validatePostJobs().then(() => {
      if (!isSkill) {
        setBtnLoader(true);
        jobService.deleteSkillFromJob(getObjectForDeleteSkill(), header).then((resp) => {
          if (resp.status === "200 OK") {
            jobService.deleteJobTypeFromJob(getObjectForDeleteJobId(), header).then((response) => {
              if (response.status === "200 OK") {
                jobService.postJobDetails(generateInputJson(), header).then((res) => {
                  if (res.status === "200 OK") {
                    // onClose();
                    setBtnLoader(false);
                    navigateTo("/profile");
                  }
                });
              }
            });
          }
        });
      }
    });
  };
  const getObjectForDeleteJobId = () => {
    let inputJson = {};
    inputJson.job_id = jobId;
    inputJson.job_type_details = jobref.map((job) => ({ job_type_id: job.job_type_id }));
    return inputJson;
  };
  const getObjectForDeleteSkill = () => {
    let inputJson = {};
    let skillsRefList = [];
    let isSkillAva = false;
    let newSkills = [...skillsref];
    let skillInfoList = listOfElasticText.map((data, index) => ({ skill_id: data.value, skill: data.label, skill_reference_id: null }));
    for (let j = 0; j < newSkills.length; ++j) {
      for (let i = 0; i < skillInfoList.length; ++i) {
        if (newSkills[j].skill === skillInfoList[i].skill) {
          isSkillAva = false;
        } else {
          isSkillAva = true;
        }
      }
      if (isSkillAva) {
        skillsRefList.push(newSkills[j]);
        isSkillAva = false;
      }
    }
    inputJson.skillset = skillsRefList;
    inputJson.job_id = jobId;
    return inputJson;
  };

  //   const onClose = () => {
  //     setListOfElasticText([]);
  //     props.handleClose();
  //   };

  //   const imageContent = new ImageContent();

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  useEffect(() => {
    setJobId(parseInt(params.id));
    serviceProvider
      .returnJobService()
      .viewJob(headerUtility.returnHeaderContainingToken(), { job_id: params.id })
      .then((res) => {
        if (res.status === "200 OK") {
          setJobTitle(res.data.jobTitle);
          setJobDesc(res.data.description);
          setCompanyName(res.data.companyName);
          setExp(res.data.expRequired);
          setOfrPkg(res.data.offerPackage);
          setRole(res.data.role);
          setLocationType(res.data.location);
          setJoiningType(res.data.joiningType);
          setJobStream(res.data?.jobStreams);
          let skills = res?.data?.skillset;
          setSkillsRef(skills);
          setJobRef(res.data?.job_type_details);
          let jTypeList = [...listOfJobType];
          for (let i = 0; i < res.data?.job_type_details.length; ++i) {
            for (let j = 0; j < jTypeList.length; ++j) {
              if (jTypeList[j].name === res.data?.job_type_details[i].job_type) {
                jTypeList[j].selected = true;
              }
            }
          }
          setListOfJobType(jTypeList);
          let newSkills = [];
          for (let i = 0; i < skills.length; ++i) {
            let obj = { value: i, label: skills[i].skill };
            newSkills.push(obj);
          }
          setListOfElasticText(newSkills);
        }
      });
  }, [headerUtility, listOfJobType, params.id, serviceProvider]);

  return (
    <div className="">
      <div className="container-fluid pl-0 pr-0" >
        <div className="row pl-0 pr-0">
          <div className="col-12">
            <label>
              <strong>Job Title</strong>
            </label>
            <Form.Control
              value={jobTitle}
              placeholder="What are you looking for"
              className="inputFieldStyle"
              onKeyDown={ValidationUtility.onKeyUpText}
              id="jobTitle"
              type="text"
              onChange={(e) => onJobTitleInput(e.target.value)}
            />
            <span className="text-danger float-right" id="jobTitleError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Job Description</strong>
            </label>
            <Form.Control
              value={jobDesc}
              placeholder="What they have to do"
              className="inputFieldStyle"
              rows="4"
              cols="33"
              id="jobDesc"
              as="textarea"
              onChange={(e) => onJobDescInput(e.target.value)}
            />
            <span className="text-danger float-right" id="jobDescError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>{"Roles & Responsibility"}</strong>
            </label>
            <Form.Control value={role} placeholder={"Roles & Responsibility"} id="role" rows="4" cols="33" className="inputFieldStyle" as="textarea" onChange={(e) => setRole(e.target.value)} />
            <span className="text-danger float-right" id="roleError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Location</strong>
            </label>
            <Form.Control value={location} placeholder="Location ex : Delhi" id="loc" className="inputFieldStyle" type="text" onChange={(e) => setLocationType(e.target.value)} />
            <span className="text-danger float-right" id="locError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Experienced (in yr)</strong>
            </label>
            <Form.Control
              onInput={maxLengthCheck}
              onKeyDown={ValidationUtility.onkeyUpNumber}
              pattern="\d*"
              maxLength={2}
              value={exp}
              placeholder="Choose experience"
              className="inputFieldStyle"
              rows="5"
              cols="33"
              id="expRqd"
              type="text"
              onChange={(e) => setExp(e.target.value)}
            />
            <span className="text-danger float-right" id="expRqdError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Offer Package (in lacs)</strong>
            </label>
            <Form.Control
              onInput={maxLengthCheck}
              onKeyDown={ValidationUtility.onkeyUpNumber}
              pattern="\d*"
              maxLength={10}
              value={ofrPkg}
              placeholder="Offer"
              className="inputFieldStyle"
              id="ofrPkg"
              type="text"
              onChange={(e) => setOfrPkg(e.target.value)}
            />
            <span className="text-danger float-right" id="ofrPkgError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Joining Type</strong>
            </label>
            {/* <Form.Control placeholder="Choose joining Type" id="joint" className="inputFieldStyle" type="text" onChange={e => setJoiningType(e.target.value)} /> */}
            <select value={joiningType} style={{ width: "100%" }} id="jobStream" className="inputFieldStyle" onChange={(e) => setJoiningType(e.target.value)}>
              <option value="-1" selected disabled>
                Choose Joining Type
              </option>
              <option value="IMMEDIATE">IMMEDIATE</option>
              <option value="LESS THAN 1 MONTH">LESS THAN 1 MONTH</option>
              <option value="LESS THAN 2 MONTH">LESS THAN 2 MONTH</option>
            </select>
            <br />
            <span className="text-danger float-right" id="jointError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Company Name (Optional)</strong>
            </label>
            <Form.Control
              value={companyName}
              placeholder="Company Name"
              className="inputFieldStyle"
              onKeyDown={ValidationUtility.onKeyUpText}
              id="companyName"
              type="text"
              onChange={(e) => onCompanyNameInput(e.target.value)}
            />
            <span className="text-danger float-right" id="companyNameError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Job Stream</strong>
            </label>
            <br />
            {/* <select value={jobStream} style={{ width: "100%" }} id="jobStream" className="inputFieldStyle" onChange={e => onJobStreamInput(e.target.value)}>
                            <option value="-1" selected disabled>Choose job stream</option>
                            <option value="IT Services">IT Services</option>
                            <option value="BBA">BBA</option>
                            <option value="ARTS">ARTS</option>
                        </select><br /> */}
            <Form.Control placeholder="Job Stream" value={jobStream} className="inputFieldStyle" id="jobStream" type="text" onChange={(e) => onJobStreamInput(e.target.value)} />
            <span className="text-danger float-right" id="jobStreamError"></span>
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Skills</strong>
            </label>
            <Select value={listOfElasticText} options={optionsSkills} isMulti onChange={setListOfElasticText} />
            {/* {listOfElasticText.length > 0 ? <div className="container mt-2">
                            <div className="row ml-0 mr-0">
                                {returnElasticSearchList()}
                            </div>
                        </div> : null}
                        <Form.Control onKeyDown={ValidationUtility.onKeyUpText} className="inputFieldStyle" placeholder="Skills" id="skillSet" value={productName} type="text" onKeyPress={handleKeyPress} onChange={e => { setProductName(e.target.value) }} /> */}
            {isSkill ? (
              <span className="text-danger float-right" id="skillSetError">
                Please define one or more required skill set
              </span>
            ) : null}
          </div>
          <div className="col-12 pt-4">
            <label>
              <strong>Job Type</strong>
            </label>
            <div className="row">
              {listOfJobType &&
                listOfJobType.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div id={"jobTYpe" + index} className="col-xs-6 col-sm-6 col-md-3">
                        {item.selected ? (
                          <input
                            type="checkbox"
                            onClick={() => {
                              onJobTypeSelect(index, false);
                            }}
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            onClick={() => {
                              onJobTypeSelect(index, true);
                            }}
                          />
                        )}
                        &nbsp;&nbsp;{item.name}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className="col-12 pt-4">
            <div className="row pt-3 pb-0">
              <div className="col-xs-12 col-sm-12 col-md-4">
                {!btnLoader ? (
                  <Button className="text-white connectButton" onClick={onSubmit}>
                    Update Job
                  </Button>
                ) : (
                  <Button className="text-white connectButton">
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const validatePostJobs = () => {
  let responseArray = [];
  responseArray.push(ValidationUtility.validateElement("jobTitle", ValidationType.Text, true, "jobTitleError", "Job Title"));
  responseArray.push(ValidationUtility.validateElement("companyName", ValidationType.Text, true, "companyNameError", "Company Name"));
  responseArray.push(ValidationUtility.validateElement("jobDesc", ValidationType.Text, true, "jobDescError", "Job Description"));
  responseArray.push(ValidationUtility.validateElement("jobStream", ValidationType.Text, true, "jobStreamError", "Job Stream"));
  let errors = responseArray.filter((x) => {
    return !x.isValid;
  });
  return new Promise((resolve, reject) => {
    errors?.length > 0 ? reject(false) : resolve(true);
  });
};

export default EditJob;
